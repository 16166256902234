import React from 'react';
import '../assets/css/style.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Button from '@material-ui/core/Button';

export default class Signup extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error_message: "Something Wrong In Site, Please Try Again Later",
            modal: localStorage.getItem("error_occured") == "true"?true:false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        // const { dynamic_content } = this.state
        return (
            <div >
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                    <ModalBody className="ntcBody">
                        <div className="ntvcontent">
                            {/* <img src={url.family_one_image_url + 'Group168.png'} alt="" title="" className="ntcimgClose" /> */}
                            <div className="ntc_head">
                                <h3>Error In System</h3>
                                <div className="ntc_headInr">
                                    <p>{this.state.error_message}
                                    </p>
                                </div>
                                <div className="ntcUpdate"><Button onClick={() => { this.toggle() }} size="medium" color="primary">Cancel</Button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

