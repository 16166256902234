import React from 'react';
import Button from '@material-ui/core/Button';
import '../../assets/css/style.css';
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import { Modal, ModalBody } from 'reactstrap';
import LottieLoader from '../LottieLoader';
import url from '../../assets/url';
import axios from "axios";
export default class notification_setting extends React.Component {
    constructor(props) {
        super(props);
        functions.handleBackOne(this, 'notification_setting')
        this.state = {
            Partners: [],
            loader: false,
            Partner_Categories: [],
            show_error_message: "",
            Program_Categories: [],
            Notification_Types: [],
            blocked_notifications: {},
            dynamic_content: "",
            open_dailog: false,
            ischecked: false,
            ischeckedsecond: false,
            second_modal: false,
            confirm: "",
            close_my_account_tnc1: "I am aware that I may have consented to have family.one transfer specific details about me to specified third parties. In these cases, for legal reasons including CASL compliance, I am aware that family.one retains a log of the data transfer and consent specifics.",
            close_my_account_tnc2: "I accept that should I wish to also unenroll from any third party programs, I must contact said programs directly.",
            close_account_tnc1_accept_date: "",
            close_account_tnc1_accept_time: "",
            close_account_tnc1_accept_timestamp: "",
            close_account_tnc2_accept_date: "",
            close_account_tnc2_accept_time: "",
            close_account_tnc2_accept_timestamp: "",
            close_account_confirm_date: "",
            close_account_confirm_time: "",
            close_account_confirm_timestamp: "",
            error_found: false,
            response_message: "",
            account_close_link_sent: false,
            // eslint-disable-next-line no-dupe-keys
            loader: false
        }
    }

    toggle() {
        this.setState({
            open_dailog: !this.state.open_dailog
        })
    }

    handleChangePassword = () => {
        let success_action = "User clicked on change password button on setting page"
        let image = 'change_password_button_setting_page.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, 'change_password', 'setting')
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if dynamicContent changed from empty to non-empty
        if (prevState.dynamic_content === "" && this.state.dynamic_content !== "") {
            console.log("1")
            this.scrollToUserSetting();
          }
      }
    
      scrollToUserSetting = () => {
        // Get the hash value from the URL
        const hashValue = window.location.hash;
        console.log("2")
    
        if (hashValue) {
            console.log("3", hashValue)

          // Extract the section ID (removing the leading '#')
          const sectionId = hashValue.substring(1);
          console.log("4", sectionId)
    
          // Scroll to the section with the matching ID
          const sectionElement = document.getElementById(sectionId);
          console.log("5", sectionElement)

          if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
          } else {
            console.warn(`Element with ID '${sectionId}' not found.`);
          }
        } else {
          console.warn(`No hash value found in the URL.`);
        }
      }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let self = this;
        let auto_id = cookie.load("auto_id");
        self.setState({ loader: true })
        functions.getNotificationsListing(this, auto_id, function (json_response) {
            let response = JSON.parse(json_response)

            if (response.data) {

                let Partner_temp_array = [];
                let Notification_Types_temp_array = [];
                let Partner_Categories_temp_array = [];
                let Program_Categories_temp_array = [];
                let Partners = [];
                let Notification_Types = [];
                let Partner_Categories = [];
                let Program_Categories = [];
                let blocked_notifications = {}
                let duplicate_key1 = [];
                let duplicate_key2 = [];
                let duplicate_key3 = [];
                let duplicate_key4 = [];

                response.data.Partners.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key1.push(element.name)
                        let temp_object = element
                        Partner_temp_array.push(temp_object)
                        blocked_notifications.Partners = Partner_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Partners = response.data.Partners
                if (!blocked_notifications.Partners) {
                    blocked_notifications.Partners = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique = Partners.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set()));
                if (duplicate_key1?.length > 0) {
                    duplicate_key1.forEach((element) => {
                        var index = unique.findIndex(function (data) {
                            return data.name == element
                        })
                        unique[index].flag = "No";
                    })
                    Partners = unique;
                }
                self.setState({
                    Partners: Partners
                })

                response.data.Notification_Types.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key2.push(element.name)
                        let temp_object = element
                        Notification_Types_temp_array.push(temp_object)
                        blocked_notifications.Notification_Types = Notification_Types_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Notification_Types = response.data.Notification_Types
                if (!blocked_notifications.Notification_Types) {
                    blocked_notifications.Notification_Types = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique1 = Notification_Types.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set()));
                if (duplicate_key2?.length > 0) {
                    duplicate_key2.forEach((element) => {
                        var index = unique1.findIndex(function (data) {
                            return data.name == element
                        })
                        unique1[index].flag = "No";
                    })
                    Notification_Types = unique1;
                }
                self.setState({
                    Notification_Types: Notification_Types
                })

                response.data.Partner_Categories.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key3.push(element.name)
                        let temp_object = element
                        Partner_Categories_temp_array.push(temp_object)
                        blocked_notifications.Partner_Categories = Partner_Categories_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Partner_Categories = response.data.Partner_Categories
                if (!blocked_notifications.Partner_Categories) {
                    blocked_notifications.Partner_Categories = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique2 = Partner_Categories.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set()));
                if (duplicate_key3?.length > 0) {
                    duplicate_key3.forEach((element) => {
                        var index = unique2.findIndex(function (data) {
                            return data.name == element
                        })
                        unique2[index].flag = "No";
                    })
                    Partner_Categories = unique2;
                }
                self.setState({
                    Partner_Categories: Partner_Categories
                })

                response.data.Program_Categories.forEach((element, index) => {
                    if (element.flag == "No") {
                        duplicate_key4.push(element.name)
                        let temp_object = element
                        Program_Categories_temp_array.push(temp_object)
                        blocked_notifications.Program_Categories = Program_Categories_temp_array;
                        self.setState({
                            blocked_notifications: blocked_notifications
                        })
                    }
                })
                Program_Categories = response.data.Program_Categories
                if (!blocked_notifications.Program_Categories) {
                    blocked_notifications.Program_Categories = [];
                    self.setState({
                        blocked_notifications: blocked_notifications
                    })
                }
                let unique3 = Program_Categories.filter((set => f => !set.has(f.name) && set.add(f.name))(new Set()));
                if (duplicate_key4?.length > 0) {
                    duplicate_key4.forEach((element) => {
                        var index = unique3.findIndex(function (data) {
                            return data.name === element
                        })
                        unique3[index].flag = "No";
                    })
                    Program_Categories = unique3;
                }
                self.setState({
                    Program_Categories: Program_Categories,
                    loader: false
                })
            }
        });
    }

    handleChange = (flag, index, array) => {
        let { blocked_notifications } = this.state; // Ensure to clone state object
        let new_array = Array.isArray(this.state[array]) ? [...this.state[array]] : [];
        let key_name = '';
    
        if (new_array.length > index && new_array[index]) {
            if (flag === 'Yes') {
                key_name = new_array[index]?.name || '';
                new_array[index].flag = 'No';
            } else if (flag === 'No') {
                key_name = new_array[index]?.name || '';
                new_array[index].flag = 'Yes';
            }
    
            this.setState({ [array]: new_array });
    
            if (flag === "No") {
                if (blocked_notifications[array] && blocked_notifications[array].length > 0) {
                    let final_array = blocked_notifications[array].filter(element => element?.name !== key_name);
                    blocked_notifications[array] = final_array;
                }
            } else {
                let array_with_spaces = array.replace(/_/g, ' ');
                let temp_array = blocked_notifications[array] ? [...blocked_notifications[array]] : [];
    
                if (temp_array.length > 0) {
                    let found = temp_array.some(element => element?.name === key_name);
    
                    if (!found) {
                        let temp_object = {
                            name: key_name,
                            flag: flag === 'No' ? 'Yes' : 'No'
                        };
    
                        if (array_with_spaces !== "Notification Types" && new_array[index]?.program_ids) {
                            temp_object.program_ids = new_array[index].program_ids;
                            // Include partner_id if available
                            if (new_array[index]?.partner_id) {
                                temp_object.partner_id = new_array[index].partner_id;
                            }
                        }
    
                        temp_array.push(temp_object);
                    }
                } else {
                    let temp_object = {
                        name: key_name,
                        flag: flag === 'No' ? 'Yes' : 'No'
                    };
    
                    if (array_with_spaces !== "Notification Types" && new_array[index]?.program_ids) {
                        temp_object.program_ids = new_array[index].program_ids;
                        // Include partner_id if available
                        if (new_array[index]?.partner_id) {
                            temp_object.partner_id = new_array[index].partner_id;
                        }
                    }
    
                    temp_array.push(temp_object);
                }
    
                blocked_notifications[array] = temp_array;
            }
    
            // Assuming `functions.updateUserData` is correctly implemented
            let userdata = [];
            let dynamo_userdata = { 'blocked_notifications': blocked_notifications };
            functions.updateUserData(this, userdata, dynamo_userdata);
        } else {
            console.error(`Index ${index} out of bounds for array ${array}`);
        }
    }
    
    
    
    // handleChange = (flag, index, array) => {
    //     let blocked_notifications = this.state.blocked_notifications;
    //     let new_array = this.state[array];
    //     let key_name = '';
    //     if (flag == 'Yes') {
    //         key_name = new_array[index].name
    //         new_array[index].flag = 'No'
    //     }
    //     else if (flag == 'No') {
    //         key_name = new_array[index].name
    //         new_array[index].flag = 'Yes'
    //     }

    //     this.setState({ [array]: new_array })



    //     if (flag == "No") {
    //         if (Object.keys(blocked_notifications) && Object.keys(blocked_notifications).length > 0) {
    //             let final_array = []
    //             let temp_array = blocked_notifications[array] ? blocked_notifications[array] : [];
    //             let temp_blocked_notifications = blocked_notifications[array]
    //             if (temp_blocked_notifications && typeof temp_blocked_notifications != undefined && Object.keys(temp_blocked_notifications).length > 0) {
    //                 temp_blocked_notifications.forEach((element, index) => {
    //                     if (element.name == key_name) {
    //                         console.log("key_name matched")
    //                     }
    //                     else {
    //                         final_array.push(element)

    //                     }
    //                 })
    //             }
    //             blocked_notifications[array] = final_array
    //         }

    //     }
    //     else {
    //         if (Object.keys(blocked_notifications) && Object.keys(blocked_notifications).length > 0) {
    //             let array_with_spaces = array.replace(/_/g, ' ');
    //             let temp_array = blocked_notifications[array] ? blocked_notifications[array] : [];
    //             let temp_blocked_notifications = blocked_notifications[array]

    //             if (temp_blocked_notifications && typeof temp_blocked_notifications != undefined && Object.keys(temp_blocked_notifications).length > 0) {
    //                 temp_blocked_notifications.forEach((element, index) => {

    //                     if (element.name != key_name && flag == 'Yes') {
    //                         let temp_object = {};
    //                         temp_object.name = key_name
    //                         if (flag == 'No') {
    //                             temp_object.flag = 'Yes'
    //                         }
    //                         else {
    //                             temp_object.flag = "No";
    //                         }

    //                         if (array_with_spaces !== "Notification Types") {
    //                             if (new_array[index] && new_array[index].program_ids) {
    //                                 temp_object.program_ids = new_array[index].program_ids
    //                             }
    //                         }

    //                         temp_array.push(temp_object);
    //                     }
    //                 });
    //             }
    //             else {

    //                 temp_array = [];
    //                 let temp_object = {};
    //                 temp_object.name = key_name
    //                 if (flag == 'No') {
    //                     temp_object.flag = 'Yes'
    //                 }
    //                 else {
    //                     temp_object.flag = "No";
    //                 }
    //                 if (array_with_spaces !== "Notification Types") {
    //                     if (new_array[index] && new_array[index].program_ids) {
    //                         temp_object.program_ids = new_array[index].program_ids
    //                     }
    //                 }
    //                 temp_array.push(temp_object);
    //             }
    //             blocked_notifications[array] = temp_array
    //         }
    //     }


    //     let userdata = [];
    //     let dynamo_userdata = { 'blocked_notifications': blocked_notifications };
    //     functions.updateUserData(this, userdata, dynamo_userdata);
    // }

    handleInputChange(event, event_name) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        if (name == 'ischecked' && value == true) {
            this.setState({ "close_account_tnc1_accept_date": new Date().toLocaleDateString(), "close_account_tnc1_accept_time": new Date().toLocaleTimeString(), "close_account_tnc1_accept_timestamp": new Date().getTime(), show_error_message: "" })
        }
        if (name == 'ischeckedsecond' && value == true) {
            this.setState({ "close_account_tnc2_accept_date": new Date().toLocaleDateString(), "close_account_tnc2_accept_time": new Date().toLocaleTimeString(), "close_account_tnc2_accept_timestamp": new Date().getTime() })
        }

        this.setState({
            [event_name]: value,
            "error_found": false,
            "response_message": ""
        });
    }

    handleConfirm = () => {
        if (this.state.ischecked == true) {
            this.setState({ second_modal: true })
            this.setState({ show_error_message: "" })

        }
       
            else{
                this.setState({ show_error_message: "Please select checkbox..." })

        }
    }

    handleDone = () => {
        let self = this;
        if (this.state.confirm == "CLOSE") {
            this.setState({ show_error_message: "" })

            this.setState({
                "error_found": false,
                "response_message": "",
                "loader": true
            })

            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
            // let finaljson = {
            //     "email": email
            // }

            let data_to_close_account = {
                "action": "send-close-account-link",
                "sub_id": sub_id,
                "email": email,
                "terms_and_conditions_1": self.state.close_my_account_tnc1,
                "terms_and_conditions_2": self.state.close_my_account_tnc2,
                "terms_and_condition_1_accept_date": self.state.close_account_tnc1_accept_date,
                "terms_and_condition_1_accept_time": self.state.close_account_tnc1_accept_time,
                "terms_and_condition_1_accept_timestamp": self.state.close_account_tnc1_accept_timestamp,
                "terms_and_condition_2_accept_date": new Date().toLocaleDateString(),
                "terms_and_condition_2_accept_time": new Date().toLocaleTimeString(),
                "terms_and_condition_2_accept_timestamp": new Date().getTime(),
                "close_account_confirm_date": self.state.close_account_confirm_date,
                "close_account_confirm_time": self.state.close_account_confirm_time,
                "close_account_confirm_timestamp": self.state.close_account_timestamp
            }


            axios
                .request({
                    url: url.closeMyAccount,
                    method: "POST",
                    data: JSON.stringify(data_to_close_account),
                    headers: {
                        "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                    }
                })
                .then(function (close_Account_Response) {
                    
                    if (close_Account_Response && close_Account_Response.data && close_Account_Response.data.success == 1) {
                        self.setState({
                            "account_close_link_sent": true,
                            "response_message": close_Account_Response.data.message,
                            "loader": false
                        })
                        setTimeout(() => {
                            let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
                            let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
                            let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
                            let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
                            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
                            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                            let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
                            localStorage.clear();
                            localStorage.setItem("perks_or_main", perks_or_main)
                            localStorage.setItem("previous_state", previous_state)
                            localStorage.setItem("current_state", current_state)
                            localStorage.setItem("program", JSON.stringify(program))
                            localStorage.setItem("api_auth", api_auth)
                            localStorage.setItem("program_list", JSON.stringify(program_list))
                            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                            cookie.remove("auto_id", { path: "/" })
                            cookie.remove("social_medium", { path: "/" })
                            cookie.remove("social_shared_prog_id", { path: "/" })
                            cookie.remove("social_shared_refered_id", { path: "/" })
                            cookie.remove("winner_later", { path: "/" })
                            cookie.remove("provider", { path: "/" })
                            cookie.remove("facebook_id", { path: "/" })
                            cookie.remove("google_id", { path: "/" })
                            cookie.remove("tracking_id", { path: "/" })
                            cookie.remove("username", { path: "/" })
                            cookie.remove("accept_auto_id", { path: "/" })
                            cookie.remove("relative_id", { path: "/" })
                            cookie.remove("winner_id", { path: "/" })
                            cookie.remove("winner_program_id", { path: "/" })
                            cookie.remove("winner_claim_days", { path: "/" })
                            cookie.remove("claim_status", { path: "/" })
                            cookie.remove("email_changed", { path: "/" })
                            cookie.remove("alternative_email", { path: "/" })
                            self.props.history.push("/")
                        }, 10000)
                    } else {
                        self.setState({
                            "account_close_link_sent": false,
                            "response_message": close_Account_Response.data.message,
                            "loader": false,
                            "error_found": true
                        })
                    }
                })
        }
        else{
            this.setState({ show_error_message: "Please type CLOSE in input field" })

        }

    }

    handleClose = () => {
        this.toggle()
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
        let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
        localStorage.clear();
        localStorage.setItem("perks_or_main", perks_or_main)
        localStorage.setItem("previous_state", previous_state)
        localStorage.setItem("current_state", current_state)
        localStorage.setItem("program", JSON.stringify(program))
        localStorage.setItem("api_auth", api_auth)
        localStorage.setItem("program_list", JSON.stringify(program_list))
        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
        cookie.remove("auto_id", { path: "/" })
        cookie.remove("social_medium", { path: "/" })
        cookie.remove("social_shared_prog_id", { path: "/" })
        cookie.remove("social_shared_refered_id", { path: "/" })
        cookie.remove("winner_later", { path: "/" })
        cookie.remove("provider", { path: "/" })
        cookie.remove("facebook_id", { path: "/" })
        cookie.remove("google_id", { path: "/" })
        cookie.remove("tracking_id", { path: "/" })
        cookie.remove("username", { path: "/" })
        cookie.remove("accept_auto_id", { path: "/" })
        cookie.remove("relative_id", { path: "/" })
        cookie.remove("winner_id", { path: "/" })
        cookie.remove("winner_program_id", { path: "/" })
        cookie.remove("winner_claim_days", { path: "/" })
        cookie.remove("claim_status", { path: "/" })
        cookie.remove("email_changed", { path: "/" })
        cookie.remove("alternative_email", { path: "/" })
        let {history} = this.props;
        if(history) history.push("/");
    }

    handleCrossClose = () => {
        this.toggle()
        if (this.state.account_close_link_sent && this.state.account_close_link_sent == true) {
            let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
            let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
            let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
            let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
            localStorage.clear();
            localStorage.setItem("perks_or_main", perks_or_main)
            localStorage.setItem("previous_state", previous_state)
            localStorage.setItem("current_state", current_state)
            localStorage.setItem("program", JSON.stringify(program))
            localStorage.setItem("api_auth", api_auth)
            localStorage.setItem("program_list", JSON.stringify(program_list))
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
            cookie.remove("auto_id", { path: "/" })
            cookie.remove("social_medium", { path: "/" })
            cookie.remove("social_shared_prog_id", { path: "/" })
            cookie.remove("social_shared_refered_id", { path: "/" })
            cookie.remove("winner_later", { path: "/" })
            cookie.remove("provider", { path: "/" })
            cookie.remove("facebook_id", { path: "/" })
            cookie.remove("google_id", { path: "/" })
            cookie.remove("tracking_id", { path: "/" })
            cookie.remove("username", { path: "/" })
            cookie.remove("accept_auto_id", { path: "/" })
            cookie.remove("relative_id", { path: "/" })
            cookie.remove("winner_id", { path: "/" })
            cookie.remove("winner_program_id", { path: "/" })
            cookie.remove("winner_claim_days", { path: "/" })
            cookie.remove("claim_status", { path: "/" })
            cookie.remove("email_changed", { path: "/" })
            cookie.remove("alternative_email", { path: "/" })
            let {history} = this.props;
            if(history) history.push("/");
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    {dynamic_content != "" &&
                        <div className="common_main">
                           <div className="sign_backlink sign_backlinkNw" onClick={() => {
    localStorage.removeItem('unsubscribe_red'); // Remove 'unsubscribe_red' from localStorage
    functions.pushToRoute(this, "setting", 'notification_setting'); // Redirect to notification_setting route
}}>
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>

                            <div className="profileTp">
                                <h1>Profile</h1>
                                {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.notifications ?
                                    <h2>{dynamic_content.static_text.notification_setting.notifications.en}</h2> :
                                    <h2>Notifications</h2>
                                }
                            </div>

                            {this.state.loader &&
                                <center className="loader width_auto">
                                    <LottieLoader className="loader_class" style={{ width: "40px !important" }} color="secondary" />
                                </center>
                            }
                            {!this.state.loader &&
                                <div className="setting_mainInner">
                                    <div className="usr_setting">
                                        {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.partners ?
                                            <label>{dynamic_content.static_text.notification_setting.partners.en}</label> :
                                            <label>Partners</label>
                                        }
                                        {this.state.Partners && this.state.Partners.length > 0 && this.state.Partners.map((element, index) => (
                                            <div key={index} className={'setting_notify' + (element.flag === 'Yes' ? ' settingChecked' : ' settingCheckedNo') + (element.disabled === 'Yes' ? ' settingdisabled' : '')}>
                                                <p><span>{element.name.replace(/_/g, ' ')}</span></p>

                                                {/* <Switch
                                                    checked={element.flag == "Yes" ? true : false}
                                                    onChange={() => { this.handleChange(element.flag, index, 'Partners') }}
                                                    value="checkedA"
                                                    disabled={element.disabled !== "Yes" ? false : true}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                /> */}

                                               <div className='notifyCheck'>
                                                <input 
                                                   className="inp-cbx" 
                                                   style={{ display: 'none' }}
                                                   type="checkbox" 
                                                   id={"PartnersFirst-" + index} 
                                                   checked={element.flag === "Yes" ? true : false}
                                                   onChange={() => { this.handleChange(element.flag, index, 'Partners') }}
                                                   disabled={element.disabled !== "Yes" ? false : true}
                                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                   />
                                                <label className="cbx" for={"PartnersFirst-" + index}>
                                                   <span>
                                                    <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                      <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                   </span>
                                                 </label>
                                              </div>

                                            </div>
                                        ))}
                                    </div>
                                    <div className="usr_setting">
                                        {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.partner_categories ?
                                            <label>{dynamic_content.static_text.notification_setting.partner_categories.en}</label> :
                                            <label>Partner Categories</label>
                                        }
                                        {this.state.Partner_Categories && this.state.Partner_Categories.length > 0 && this.state.Partner_Categories.map((element, index) => (
                                            <div key={index} className={'setting_notify' + (element.flag === 'Yes' ? ' settingChecked' : ' settingCheckedNo') + (element.disabled === 'Yes' ? ' settingdisabled' : '')}>
                                                <p><span>{element.name.replace(/_/g, ' ')}</span></p>

                                                {/* <Switch
                                                    checked={element.flag == "Yes" ? true : false}
                                                    onChange={() => { this.handleChange(element.flag, index, 'Partner_Categories') }}
                                                    value="checkedA"
                                                    disabled={element.disabled !== "Yes" ? false : true}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                /> */}

                                               <div className='notifyCheck'>
                                                <input 
                                                   className="inp-cbx" 
                                                   style={{ display: 'none' }}
                                                   type="checkbox" 
                                                   id={"PartnerCat-" + index} 
                                                   checked={element.flag === "Yes" ? true : false}
                                                   onChange={() => { this.handleChange(element.flag, index, 'Partner_Categories') }}
                                                   disabled={element.disabled !== "Yes" ? false : true}
                                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                   />
                                                <label className="cbx" for={"PartnerCat-" + index}>
                                                   <span>
                                                    <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                      <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                   </span>
                                                 </label>
                                              </div>

                                            </div>
                                        ))}
                                    </div>
                                    <div className="usr_setting"> 

                                        {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.program_categories ?
                                            <label>{dynamic_content.static_text.notification_setting.program_categories.en}</label> :
                                            <label>Program Categories</label>
                                        }
                                        {this.state.Program_Categories && this.state.Program_Categories.length > 0 && this.state.Program_Categories.map((element, index) => (
                                            <div key={index} className={'setting_notify' + (element.flag === 'Yes' ? ' settingChecked' : ' settingCheckedNo') + (element.disabled === 'Yes' ? ' settingdisabled' : '')}>
                                                <p><span>{element.name.replace(/_/g, ' ')}</span></p>

                                                {/* <Switch
                                                    checked={element.flag == "Yes" ? true : false}
                                                    onChange={() => { this.handleChange(element.flag, index, 'Program_Categories') }}
                                                    value="checkedA"
                                                    disabled={element.disabled !== "Yes" ? false : true}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                /> */}

                                               <div className='notifyCheck'>
                                                <input 
                                                   className="inp-cbx" 
                                                   style={{ display: 'none' }}
                                                   type="checkbox" 
                                                   id={"ProgramCat-" + index} 
                                                   checked={element.flag === "Yes" ? true : false}
                                                   onChange={() => { this.handleChange(element.flag, index, 'Program_Categories') }}
                                                   disabled={element.disabled !== "Yes" ? false : true}
                                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                   />
                                                <label className="cbx" for={"ProgramCat-" + index}>
                                                   <span>
                                                    <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                      <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                   </span>
                                                 </label>
                                              </div>


                                            </div>
                                        ))}
                                    </div>
                                    <div className="usr_setting" id="usr_setting">
                                        {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.notification_types ?
                                            <label>{dynamic_content.static_text.notification_setting.notification_types.en}</label> :
                                            <label>Notification Types</label>
                                        }
                                        {this.state.Notification_Types && this.state.Notification_Types.length > 0 && this.state.Notification_Types.map((element, index) => (
                                            <div key={index} className={'setting_notify' + (element.flag === 'Yes' ? ' settingChecked' : ' settingCheckedNo') + (element.disabled === 'Yes' ? ' settingdisabled' : '')}>
                                                <p><span>{element.name.replace(/_/g, ' ')}</span></p>

                                                {/* <Switch
                                                    checked={element.flag == "Yes" ? true : false}
                                                    onChange={() => { this.handleChange(element.flag, index, 'Notification_Types') }}
                                                    value="checkedA"
                                                    disabled={element.disabled !== "Yes" ? false : true}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                /> */}
                                                
                                               <div className='notifyCheck'>
                                                <input 
                                                   className="inp-cbx" 
                                                   style={{ display: 'none' }}
                                                   type="checkbox" 
                                                   id={"NotificationTypes-" + index} 
                                                   checked={element.flag === "Yes" ? true : false}
                                                   onChange={() => { this.handleChange(element.flag, index, 'Notification_Types') }}
                                                   disabled={element.disabled !== "Yes" ? false : true}
                                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                   />
                                                <label className="cbx" for={"NotificationTypes-" + index}>
                                                   <span>
                                                    <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                      <polyline points="1 5 4 8 11 1"></polyline>
                                                    </svg>
                                                   </span>
                                                 </label>
                                              </div>

                                            </div>
                                        ))}
                                    </div>

                                    {/* <div className="acuntStatus">
                                        {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.account_status ?
                                            <label>{dynamic_content.static_text.notification_setting.account_status.en}</label> :
                                            <label>Account Status</label>
                                        }
                                        <div className="settingActvAc">
                                            <a>Active</a>
                                        </div>
                                     </div>
                                     
                                     <div className="acuntSetting">   
                                        <div className="settingClsAc">
                                          <a onClick={() => { this.setState({ open_dailog: true }) }}>Close my account </a>
                                          <p>and purge all personally identifiable information</p>
                                        </div>
                                    </div> */}

                                </div>
                            }
                            <div className="open_mail_model">
                                <Modal isOpen={this.state.open_dailog} toggle={this.toggle} className={this.props.className+" custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                    <ModalBody className="ntcBody">
                                        <div className="ntvcontent">

                                            <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.handleCrossClose() }} className="ntcimgClose" />
                                            
                                            {this.state.second_modal == false &&
                                                <div className="ntc_head">
                                                    {/* <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.multiple.en : "Multiples!!!"}</h3> */}
                                                    <h3>Notice!</h3>
                                                    <div className="notice_warning1">
                                                        <p>I hereby request my account be closed and all of my personally identifiable information be purged.
                                                    </p>
                                                    </div>

                                                    <div className="ntc_headInr noticAgreeInput">
                                                        {/* <p>
                                                            <input
                                                                name="ischecked"
                                                                type="checkbox"
                                                                checked={this.state.ischecked}
                                                                onChange={(event) => { this.handleInputChange(event, 'ischecked') }}
                                                            />
                                                        </p> */}

                                                        <div>
                                                        <input 
                                                            className="inp-cbx" 
                                                            id="noticAgree"
                                                            style={{ display: 'none' }}
                                                            name="ischecked"
                                                            type="checkbox"
                                                            checked={this.state.ischecked}
                                                            onChange={(event) => { this.handleInputChange(event, 'ischecked') }}
                                                          />
                                                          <label className="cbx" for="noticAgree">
                                                            <span>
                                                                <svg width="12px" height="9px" viewbox="0 0 12 9">
                                                                <polyline points="1 5 4 8 11 1"></polyline>
                                                                </svg>
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <p>{this.state.close_my_account_tnc1}</p>
                                                    </div>

                                                    {/* <div className="ntcUpdateMultiple"><Button onClick={() => { this.handleAgree() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_multiples.en : "Add multiples"}</Button></div> */}
                                                    <div className="ntcUpdate"><Button onClick={() => { this.handleConfirm() }} size="medium" color="primary">Continue</Button></div>
                                                </div>
                                            }
                                            {this.state.second_modal == true && !this.state.account_close_link_sent &&
                                                <div className="ntc_head">
                                                    {/* <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.multiple.en : "Multiples!!!"}</h3> */}
                                                    <h3>Notice!</h3>
                                                    <div className="notice_warning1">
                                                        <p>
                                                            Close My Account
                                                        </p>
                                                    </div>
                                                    <div className="ntc_headInr" style={{ marginBottom: "10px" }}>
                                                        <p>
                                                            {/* <input
                                                                name="ischeckedsecond"
                                                                type="checkbox"
                                                                checked={this.state.ischeckedsecond}
                                                                onChange={(event) => { this.handleInputChange(event, 'ischeckedsecond') }}
                                                            /> */}
                                                            {this.state.close_my_account_tnc2}
                                                        </p>
                                                    </div>
                                                    <div className="notice_warning1 notice_warningcstm">
                                                        <p>
                                                            To affirm your consent, type <span>CLOSE</span> in the box below:
                                                        </p>
                                                        <input
                                                            placeholder='Type'
                                                            className="notice_warning_text"
                                                            name="confirm"
                                                            type="text"
                                                            value={this.state.confirm}
                                                            onChange={(event) => { this.setState({ confirm: event.target.value, close_account_confirm_date: new Date().toLocaleDateString(), close_account_confirm_time: new Date().toLocaleTimeString(), close_account_confirm_timestamp: new Date().getTime(), error_found: false, response_message: "", show_error_message: "" }) }}
                                                        />
                                                    </div>
                                                    {/* <div className="ntcUpdateMultiple"><Button onClick={() => { this.handleAgree() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_multiples.en : "Add multiples"}</Button></div> */}
                                                    <div className="ntcUpdate"><Button onClick={() => { this.handleDone() }} size="medium" color="primary">DONE</Button></div>
                                                </div>
                                            }

                                            {
                                                this.state.account_close_link_sent == true &&
                                                <div className="account_close_link_sent ntc_head closeEmailAc">
                                                    <h3>Check Your Email</h3>
                                                    <div className='closeEmailAcInr'>
                                                        <label>Close My Account</label>
                                                        <h4>We have sent you an email.</h4>
                                                        <div><img src={require('../../assets/images/emailSent.svg')} alt="" title="" /></div>
                                                        <p>Please follow the instructions in the email to complete your account closure request. The email will only be valid for 30 minutes.</p>
                                                    </div>
                                                    <div className="ntcUpdate">
                                                        <Button onClick={() => { this.handleClose() }} size="medium" color="primary">Close</Button>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                !this.state.loader && this.state.error_found == true && this.state.response_message && this.state.response_message.trim().length > 0 &&
                                                <div className={"response_error"}>
                                                    {this.state.response_message}
                                                </div>
                                            }
                                            {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }

                                            {this.state.loader &&
                                                <center>
                                                    <LottieLoader className="loader_class" color="secondary" />
                                                </center>
                                            }
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </div>
                    }
                  </div>  
                </div>
            </div>
        );
    }
}