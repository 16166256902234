import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import cookie from 'react-cookies';
import ReactHtmlParser, {
    // processNodes,
    // convertNodeToElement,
    // htmlparser2
} from "react-html-parser";

export default class PhoneSent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamic_content: "",
            phone_number: localStorage.getItem("phone_number") ? localStorage.getItem("phone_number") : "",
        };
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            if (dynamic_content.static_text) {
                let temp_message = dynamic_content.static_text.phonesent.message.en
                let new_message = temp_message.replace("%COUNTRY_CODE% %PHONE_NUMBER%", url.phone_code + " " + this.state.phone_number)
                dynamic_content.static_text.phonesent.message.en = new_message
            }

            this.setState({ dynamic_content: dynamic_content })
            let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
            if (session_id !== "null") {
                session.updatePageSession(session_id, "phonesent")
            }
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    {dynamic_content !== "" &&
                        <div className="common_main">
                            {/* <div className="backlink"><img src={require('../../assets/images/Group147.png'} alt="" title="" /></div> */}
                            {/* <div className="clearlink"><img src={require('../../assets/images/Group5.png'} alt="" title="" /></div> */}
                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.restore_email.en : "Restore Email"}</h1>
                            </div>

                            <div className="call_content">
                                <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.sent.en : "Sent!"}</h1>
                                <p>
                                    {dynamic_content.static_text.restorepasswordsent ? ReactHtmlParser(dynamic_content.static_text.phonesent.message.en) : ""}
                                </p>
                            </div>

                            <div className="call_link">
                                <Button size="medium" color="primary" onClick={() => { functions.pushToRoute(this, "login", 'phonesent') }}>
                                    {dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.go_to_login.en : "Go to Log In"}
                                </Button>
                            </div>
                        </div>
                    }
                   </div> 
                </div>
            </div>
        );
    }
}