import React from 'react';
import '../../assets/css/style.css';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

class Allwinners extends React.Component {
    constructor(props) {
        super(props);
        // functions.handleBackOne(this, 'allwinners')
        this.state = {
            dynamic_content: "",
            show_winner_data: []
        }
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let success_action = "User land on all winners page"
        let image = 'all_winners_page_landing.png'
        functions.logUserActivity(success_action, image);
        window.scrollTo(0, 1);
        let show_winner_data = localStorage.getItem("show_winner_data") ? JSON.parse(localStorage.getItem("show_winner_data")) : [];
        this.setState({ show_winner_data: show_winner_data })
    }

    dateFormat = (value) => {
        let date_array = value.split("-");
        let year = date_array[0];
        let date = date_array[2];
        let month = date_array[1] < 10 ? 0 + "" + date_array[1] : date_array[1];
        let month_name = Month[month];
        let final_date = date + " " + month_name + " " + year;
        return final_date;
    }

    handleBack = () => {
        let success_action = "User clicked on back button in all winners page"
        let image = 'back_button_all_winners_page.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("detail_page", 'true')
        let program = JSON.parse(localStorage.getItem('program'))
        let main_id = program.program_type + program.program_id
        localStorage.setItem('main_id', main_id)
        functions.pushToRoute(this, 'main', 'allwinners')
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                    {dynamic_content != "" &&
                      <div className="common_mainAddOn">
                        <div className="common_main">

                            {/* common header */}
                            <div className="moisback_section">
                                <div className="moisback_secone_winner">
                                    <div className="backLinkWidth white_circle_div" onClick={() => { this.handleBack() }}>
                                        <img src={url.family_one_image_url + 'Group147.png'} alt="" title="" />
                                    </div>
                                </div>
                                <div className="moisback_sectwo prodtRvw_lnk"><label>{dynamic_content.static_text.allwinners ? dynamic_content.static_text.allwinners.winners.en : "Winners"}</label></div>
                            </div>
                            {/*End of common header */}

                            {/* Start all winners design */}
                            <div className="all_winer_section">
                                {this.state.show_winner_data && this.state.show_winner_data.length > 0 && this.state.show_winner_data.map((element, index) => (
                                    <div key={index} className="all_winer">
                                        <div className="all_winer_left">
                                            {element.picture && element.picture != "no_image" ?
                                                <img src={url.cloudflare_image_path + element.picture+url.varient} alt="" title="" />
                                                : <label>{initials((element.given_name + " " + element.family_name).toUpperCase())}</label>
                                            }
                                        </div>
                                        <div className="all_winer_rght">
                                            <label>{element.given_name + " " + element.family_name}</label>
                                            <p>{this.dateFormat(JSON.parse(element.winning_detail).winning_date)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* End of all winners design */}
                        </div>
                      </div>  
                    }
                </div>
            </div>
        );
    }
}
export default Allwinners;

