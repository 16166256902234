import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

export default class Sent extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTopRef = React.createRef();
        this.state = {
            email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
            dynamic_content: "",
            isUIReady: false
        };
    }

    scrollToTop = () => {
        if (this.scrollToTopRef.current) {
            this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
            // Perform scroll to top action
            this.scrollToTop();
        }
    }


    componentDidMount = () => {
        localStorage.removeItem('addressType');

        let current_state = localStorage.getItem('current_state')
        if (current_state !== 'verify_account') {
            functions.getLandingPage(this)
        }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content });
        }
        let action = "User landed on verify_account page";
        let image = 'verify_account_page_landing.png'
        let userdata = [];
        let dynamo_userdata = { 'verify_account_seen': 'true' };
        dynamo_userdata.sync_log = false
        functions.updateUserData(this, userdata, dynamo_userdata);
        functions.logUserActivity(action, image);
        // Set isUIReady to true after all async operations are completed
        this.setState({ isUIReady: true });

    }


    handleContinue = () => {
        let action = "User clicked on Continue button on verify_account page";
        let image = 'continue_button_verify_account_page.png'
        functions.logUserActivity(action, image);
        functions.pushToRoute(this, "gender", 'verify_account')
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div ref={this.scrollToTopRef} className="container">
                {this.state.dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div id="scrollToTop" className='verifyAcSetBg'>
                                <div className="common_main common_mainIner commonMainInerBg">
                                    <div className="backlink white_circle_div"></div>

                                    <div className="log_up">
                                        <h1>{dynamic_content.static_text.verify_account.signup.en}</h1>
                                    </div>

                                    {/* <div className="sent_content">
                                <h1>{dynamic_content.static_text.verify_account.welcome_message.en}</h1>
                                <p>
                                    {ReactHtmlParser(dynamic_content.static_text.verify_account.account_activation_message.en)}
                                </p>
                            </div> */}

                                    {/* New Design */}

                                    <div>
                                        {/* <div className="acCelebfie">
                                    <img src={require('../../assets/images/confetti.png')} alt="" title="" /> */}
                                        <div className="actvAccountImg">
                                            <img src={require('../../assets/images/signUpEmail.svg')} alt="" title="" />
                                        </div>
                                        <div className="actvAC">
                                            <h2>{dynamic_content ? dynamic_content.static_text.verify_account.activate_account.en : "Activate your Account"}</h2>
                                            <div className="actvACpara">
                                                {dynamic_content ? ReactHtmlParser(dynamic_content.static_text.verify_account.welcome_message.en) : ""}
                                            </div>
                                        </div>
                                        {/* <div className="activSent">
                                    <img src={require('../../assets/images/activation-email-sent-icon.png')} alt="" title="" />
                                </div> */}
                                    </div>

                                    <div className="call_link">
                                        <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                            {dynamic_content.static_text.verify_account ? dynamic_content.static_text.verify_account.continue.en : "Continue, I’ll activate later"}
                                        </Button>
                                    </div>

                                    <div className="noteTextAC">
                                        <p>{dynamic_content ? ReactHtmlParser(dynamic_content.static_text.verify_account.note.en) : "Please note: your entry status in Freebies and giveaways will be automatically processed once your account is activated, which can be done via the activation email or when you access the platform next, via any email link."}</p>
                                    </div>

                                    {/* Add Animation Here */}
                                    <div className="signupConectCircleInr">
                                        <div className="signupConectInr"></div>
                                    </div>
                                    {/* End of Animation Here */}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}