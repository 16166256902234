import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from "@material-ui/core/Switch";
import InputAdornment from '@material-ui/core/InputAdornment';
import '../../assets/css/style.css';
import country_list from '../../assets/countries';
import state_list from '../../assets/state';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
import functions from '../../assets/functions/common_functions'
import $ from 'jquery';
import address from '../../assets/get_address';
import cookie from 'react-cookies';

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id != "") {
        }
        else {
            this.props.history.push("/")
        }
        this.state = {

            loader: false,
            last_address: false,
            show_error_message: "",
            address_autocomplete: [],
            modal: false,
            auto_complete_modal: false,
            province: '',
            states: [],
            addressline1: '',
            city: '',
            postalcode: '',
            manual_address: localStorage.getItem("manual_address") == "false" ? false : true,
            openedSub: false,
            openedRural: false,
            modal_account: false,
            country: "",
            show_form: false,
            toggle_element: "",
            show_province: false,
            search_key: "",
            country_options: ["Canada", "USA", "Other"],
            canada_address_options: ["House", "Sub", "Rural"],
            sub_options: ["Apt", "Unit", "Suite", "Penthouse", "Townhouse", "Condo"],
            sub_options_extra: ["Buzzer code", "Entrance", "Building"],
            rural_options: ["RR", "Site", "Comp"],
            RR: localStorage.getItem("RR") ? localStorage.getItem("RR") : "",
            Site: localStorage.getItem("Site") ? localStorage.getItem("Site") : "",
            Comp: localStorage.getItem("Comp") ? localStorage.getItem("Comp") : "",
            selected_country: "",
            RR_select: "false",
            Site_select: "false",
            Comp_select: "false",
            toggle_street_address: false,
            sub_selected: "",
            rural_selected: "",
            countries: [],
            show_additional: false,
            street_address_focus: true,
            general_delivery: "false",
            LCD: "false",
            dynamic_content: "",
            "Buzzer code_select": localStorage.getItem("Buzzer code") ? localStorage.getItem("Buzzer code") : "false",
            Entrance_select: localStorage.getItem("Entrance_select") ? localStorage.getItem("Entrance_select") : "false",
            Building_select: localStorage.getItem("Building_select") ? localStorage.getItem("Building_select") : "false",
            "PO Box": localStorage.getItem("PO Box") ? localStorage.getItem("PO Box") : "",
            STN: localStorage.getItem("STN") ? localStorage.getItem("STN") : ""
        };
    }

    toggleSub = () => {
        this.setState({
            openedSub: true,
            openedRural: false,
            general_delivery: "false",
            LCD: "false",
            manual_address: false
        });
    }

    toggleHome = () => {
        this.setState({
            openedRural: false,
            openedSub: false,
            general_delivery: "false",
            LCD: "false",
            manual_address: false
        });
    }

    toggleRural = () => {
        this.setState({
            openedRural: true,
            openedSub: false,
            general_delivery: "false",
            LCD: "false",
            manual_address: false
        });
    }

    componentDidMount = () => {

        let countries = []
        country_list.forEach((element, index) => {
            countries.push(<option key={index} value={element}>{element}</option>)
        })
        this.setState({ countries: country_list })
        this.setState({ states: state_list })
        // this.setState({ address_type: address_type_list })
    };

    handleSelectCountry = (value) => {
        if (value == "Other") {
            if (this.state.country != "" && this.state.country != "Canada" && this.state.country != "USA") {
                this.setState({ modal_account: true, show_province: false, show_form: true })
            }
            else {
                this.setState({ modal_account: true, show_province: false, show_form: false })
            }
        }
        else {
            if (value == "Canada") {
                this.setState({ show_additional: false, additional: false, toggle_street_address: false, toggle_element: "" })
            }
            else {
                this.setState({ show_form: true })
            }
            this.setState({
                country: value
            })
            this.foucs_address()
        }
        this.toggleHome()
        this.setState({
            selected_country: value,
        })
        this.handleEmptyForm()
    }

    handleEmptyForm = () => {
        this.setState({
            addressline1: "",
            city: "",
            province: "",
            postalcode: "",
            show_error_message: "",
            Apt: "",
            Unit: "",
            Suite: "",
            Penthouse: "",
            Townhouse: "",
            Condo: "",
            ["Buzzer code"]: "",
            Entrance: "",
            Building: "",
            ["PO Box"]: "",
            STN: "",
            Apt_select: "false",
            Unit_select: "false",
            Suite_select: "false",
            Penthouse_select: "false",
            Townhouse_select: "false",
            Condo_select: "false",
            ["Buzzer code_select"]: "false",
            Entrance_select: "false",
            Building_select: "false",
            general_delivery: "false",
            LCD: "false",
            RR: "",
            Site: "",
            Comp: "",
            RR_select: "false",
            Site_select: "false",
            Comp_select: "false"
        })
    }

    handleAddressOption = (value) => {
        this.setState({ toggle_element: value })
        if (value == "House") {
            this.setState({ toggle_street_address: true, show_additional: true, additional: false, sub_selected: "", rural_selected: "" })
            this.toggleHome()
        }
        if (value == "Sub") {
            this.setState({ toggle_street_address: false, show_additional: false, additional: false, sub_selected: "", rural_selected: "" })
            this.toggleSub()
        }
        if (value == "Rural") {
            this.setState({ toggle_street_address: false, show_additional: true, additional: false, sub_selected: "", rural_selected: "" })
            this.toggleRural()
        }
        this.handleEmptyForm()
    }

    handleSubOption = (value) => {
        this.handleEmptyForm()
        // if(this.state.sub_selected == ""){
        this.setState({ sub_selected: value, toggle_street_address: false, show_additional: false, additional: false, rural_selected: "" })
        // }
        // else{
        //     this.setState({ sub_selected: value})
        // }
    }

    handleSubExtraOption = (value) => {
        let prev_value = this.state[value + "_select"]
        if (prev_value == "true") {
            this.setState({ [value + "_select"]: "false" })
            // if(this.state.sub_selected !=""){
            //     this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
            // }
            // else{
            //     this.setState({ toggle_street_address: false, street_address_focus: false, show_additional: false })
            // }
        } else {
            this.setState({ [value + "_select"]: "true" })
        }
    }

    handleRuralClick = (element) => {
        let prev_value = this.state[element + "_select"]
        if (prev_value == "true") {
            this.setState({
                [element + "_select"]: "false",
            })
        }
        else {
            this.setState({
                [element + "_select"]: "true",
            })
        }
        this.setState({
            rural_selected: element
        })
    }

    closeToggle(value, state) {
        let action = "User clicked on close country popup shipping_address page"
        let image = 'close_button_country_popup_shipping_address_page.png'
        functions.logUserActivity(action, image)
        value.setState({
            [state]: !value.state[state]
        });
    }

    // Handle change of country
    handleCountry = (value) => {
        let action = "User selected country on shipping_address page"
        let image  = 'country_select_shipping_address_page.png' 
        functions.logUserActivity(action, image)
        this.setState({
            addressline1: "",
            city: "",
            province: "",
            postalcode: "",
            show_error_message: "",
            modal_account: false,
            show_form: true,
            country: value,
        })
        this.foucs_address()
    }

    foucs_address = () => {
        $('#addressline1').click()
    }

    // Function to handle manual address either canada or other countries
    handleManualAddress = () => {
        let temp_address = {}
        let user_action = "User got error on signup/address file handleManualAddress function";
        let action = "User clicked on Continue while entering manual address on shipping_address page";
        let image = 'continue_button_manual_address_entered_shipping_address_page.png'
        functions.logUserActivity(action, image)
        let toggle_element = this.state.toggle_element;
        let addressline1 = this.state.addressline1 ? this.state.addressline1.trim() : "";
        let city = this.state.city ? this.state.city.trim() : ""
        let province = this.state.province ? this.state.province.trim() : ""
        let postalcode = this.state.postalcode ? this.state.postalcode.trim() : "";
        let postalcode_length = postalcode.length;
        let show_additional = this.state.show_additional;
        let toggle_street_address = this.state.toggle_street_address;
        let STN = this.state.STN ? this.state.STN : "";
        let PO_Box = this.state["PO Box"] ? this.state["PO Box"] : "";
        temp_address.addressline1 = addressline1
        temp_address.city = city
        temp_address.province = province
        temp_address.postalcode = postalcode
        temp_address.postalcode_length = postalcode_length
        temp_address.show_additional = show_additional
        temp_address.toggle_street_address = toggle_street_address
        temp_address.STN = STN
        temp_address.PO_Box = PO_Box

        if (toggle_element == "") {
            let error_body = url.dynamic_content.error_message.select_category.en
            functions.logUserError(user_action, error_body);
            this.setState({ show_error_message: url.dynamic_content.error_message.select_category.en })
        }
        if (toggle_element == "Rural") {
            let RR = this.state.RR ? this.state.RR : ""
            let Site = this.state.Site ? this.state.Site : ""
            let Comp = this.state.Comp ? this.state.Comp : ""
            let LCD = this.state.LCD ? this.state.LCD : "false"
            let general_delivery = this.state.general_delivery ? this.state.general_delivery : "false"

            temp_address.LCD = LCD
            temp_address.general_delivery = general_delivery
            temp_address.rural_selected = this.state.rural_selected

            let RR_select = this.state.RR_select ? this.state.RR_select : "false"
            let Site_select = this.state.Site_select ? this.state.Site_select : "false"
            let Comp_select = this.state.Comp_select ? this.state.Comp_select : "false"

            if (RR_select == "true") {
                temp_address["RR"] = this.state["RR"] ? this.state["RR"] : ""
            }
            if (Site_select == "true") {
                temp_address["Site"] = this.state["Site"] ? this.state["Site"] : ""
            }
            if (Comp_select == "true") {
                temp_address["Comp"] = this.state["Comp"] ? this.state["Comp"] : ""
            }

            if (RR == "" && Site == "" && Comp == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            } else {
                this.makeAddressJson(temp_address)
            }
        }
        if (toggle_element == "Sub") {
            let sub_selected = this.state.sub_selected
            let Entrance_select = this.state.Entrance_select ? this.state.Entrance_select : "false"
            let Building_select = this.state.Building_select ? this.state.Building_select : "false"
            let Buzzer_code_select = this.state["Buzzer code_select"] ? this.state["Buzzer code_select"] : "false"
            temp_address[sub_selected] = this.state[sub_selected] ? this.state[sub_selected] : ""
            if (Buzzer_code_select == "true") {
                temp_address["Buzzer code"] = this.state["Buzzer code"] ? this.state["Buzzer code"] : ""
            }
            if (Entrance_select == "true") {
                temp_address["Entrance"] = this.state["Entrance"] ? this.state["Entrance"] : ""
            }
            if (Building_select == "true") {
                temp_address["Building"] = this.state["Building"] ? this.state["Building"] : ""
            }



            if (temp_address[sub_selected] == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            }
            else if ((Entrance_select == "true" && temp_address.Entrance == "") || (Building_select == "true" && temp_address.Building == "") || (Buzzer_code_select == "true" && temp_address["Buzzer code"] == "")) {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            } else {
                this.makeAddressJson(temp_address)
            }
        }
        if (toggle_element == "House") {
            this.makeAddressJson(temp_address)
        }
    }

    makeAddressJson = (temp_address) => {
        let user_action = "User got error on signup/address file makeAddressJson function";
        if (temp_address.toggle_street_address == true) {
            if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
            }
            else {
                if (temp_address.postalcode_length != 7) {
                    let error_body = url.dynamic_content.error_message.incorrect_postal_code.en
                    functions.logUserError(user_action, error_body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.incorrect_postal_code.en })
                }
                else {
                    this.setState({ show_error_message: "All good" })
                    this.handleSubmitAddress(temp_address)
                }
            }
        } else {
            this.setState({ show_error_message: "All good" })
            this.handleSubmitAddress(temp_address)
        }
    }

    handleSubmitAddress = (temp_address) => {
        delete temp_address.postalcode_length
        let manual_address = true
        if (this.state.toggle_element == "House" && temp_address.PO_Box == "" && temp_address.STN == "" && this.state.manual_address == false) {
            manual_address = false
        }
        let address = temp_address
        address.country = this.state.country
        address.toggle_element = this.state.toggle_element
        address.sub_selected = this.state.sub_selected
        let userdata = [];
        let dynamo_userdata = { 'shipping_address': JSON.stringify(address) };
        localStorage.setItem("shipping_address", JSON.stringify(address))
        functions.updateUserData(this, userdata, dynamo_userdata);
        this.handleAdressContinue()
    }

    handleAdressContinue = () => {
        let winner_program_id = cookie.load("winner_program_id")
        let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : [];
        let auto_id = cookie.load("auto_id");
        functions.updateWinnerStatus(this, auto_id, winner_program_id);
        winner_data.forEach(element => {
            if (element.program_id == winner_program_id) {
                element.claim_status = 'claimed';
            }
        });
        localStorage.setItem("winner_data", JSON.stringify(winner_data));
        cookie.remove("winner_claim_days", { path: "/" })
        cookie.save("claim_status", "claimed", { path: "/" })
        functions.pushToRoute(this, 'prize_detail', 'shipping_address')
    }

    handleChange = (event) => {

        let state = this.state
        this.setState({ ...state, [event.target.name]: event.target.checked });
    }

    handleSubOptionsChange = (event, element) => {
        if (element == 'Apt' || element == 'Unit' || element == 'Suite' || element == 'Entrance') {
            let value = event.target.value;
            // let regex_result = event.target.value.match(/^[0-9]*$/)
            // if (regex_result != null) {
            if (value.length > 0) {
                this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
            } else {
                this.setState({ toggle_street_address: false, street_address_focus: false, show_additional: false })
            }
            this.setState({
                [element]: event.target.value
            })
            // } else {
            // }
        }
        else {
            if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                let value = event.target.value;
                if (value.length > 0) {
                    this.setState({ toggle_street_address: true, street_address_focus: false, show_additional: true })
                } else {
                    this.setState({ toggle_street_address: false, street_address_focus: false, show_additional: false })
                }
                this.setState({ [element]: event.target.value })
            } else {
            }
        }
    }

    handleRuralExtraClick = (element) => {
        let prev_value = this.state[element]
        if (prev_value == "true") {
            this.setState({
                [element]: "false"
            })
        } else {
            this.setState({
                [element]: "true"
            })
        }
    }

    autocomplete_toggle = (value) => {
        let action = "User clicked on " + value + " on shipping address page"
        let image  =  value + '_button_shipping_address_page.png'
        functions.logUserActivity(action, image)
        if (value == "cancel") {
            this.setState(({
                search_key: ''
            }));
        }
        if (value == "open") {
            this.setState(({
                search_key: this.state.addressline1
            }));
        }
        if (value == "enter_manually") {
            localStorage.setItem("manual_address", "true")
            this.setState(({
                search_key: this.state.addressline1,
                manual_address: true,
                addressline1: "",
                city: "",
                province: "",
                postalcode: "",
                show_error_message: ""
            }));
        }
        // if (this.state.selected_country == "Canada") {
        //     if (this.state.manual_address == false) {
        //         this.setState(prevState => ({
        //             auto_complete_modal: !prevState.auto_complete_modal
        //         }))
        //     }
        // }
    }

    handleAutoComplete = (value) => {
        let self = this;
        this.setState({ search_key: value, show_error_message: '', loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA"
        address.getAddress(this, value, country, function (response_json) {
            // let address_autocomplete = []
            // response_json.forEach((element, index) => {
            //     address_autocomplete.push(<option key={index} value={element}>{element}</option>)
            // })
            if (response_json[0].Error == "1001") {
                response_json[0].Description = "Enter Key To Search";
                response_json[0].Text = "";
                self.setState({ loader: false, last_address: false, address_autocomplete: response_json })
            }
            else {
                self.setState({ loader: false, last_address: false, address_autocomplete: response_json })
            }
        })

    }

    getFindAddress = (value) => {
        let self = this;
        this.setState({ show_error_message: '' })
        self.setState({ loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA";
        address.findAddress(this, value.Text, value.Id, country, function (response_json) {
            self.setState({ loader: false })
            if (response_json[0].Error == "1001") {
                response_json[0].Description = "Enter Key To Search";
                response_json[0].Text = "";
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
            else {
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
        })
    }

    getFindAddressById = (value) => {
        let self = this;
        this.setState({ show_error_message: '' })
        self.setState({ loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA";
        address.findAddressById(this, this.state.search_key, value.Id, country, function (response_json) {
            self.setState({ loader: false })
            if (response_json[0].Error == "1001") {
                response_json[0].Description = "Enter Key To Search";
                response_json[0].Text = "";
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
            else {
                self.setState({ last_address: true, address_autocomplete: response_json })
            }
        })
    }

    handleSetAddress = (value) => {
        if (value.Next == "Retrieve" || value.IsRetrievable == true) {
            this.autocomplete_toggle('close')
            this.setState({ address_autocomplete: [] })
            this.handleSetAddressFindByID(value)
        }
        if (value.Next == "Find") {
            this.getFindAddress(value)
        }
        if (value.IsRetrievable == false) {
            this.getFindAddressById(value)
        }
    }

    handleState = (value) => {
        let action = "User selected province on shipping_address page"
        let image = 'province_select_shipping_page.png'
        functions.logUserActivity(action, image)
        this.setState({ province: value, modal_account: false })
        // this.postalcode.focus();
    }

    handleSetAddressFindByID = (value) => {
        let self = this;
        self.setState({ loader: true })
        let address_part = value.Description.trim().split(',')
        self.setState({ loader: false })
        localStorage.setItem("manual_address", "false")
        self.setState({
            manual_address: false,
            search_key: value.Text,
            addressline1: value.Text,
            city: address_part[0],
            province: address_part[1],
            postalcode: address_part[2]
        })
        self.autocomplete_toggle('close')
    }

    GetInfo = () => {
        let self = this
        let sub_id = cookie.load("auto_id")
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")

        functions.getuserChildAndrelative(self, email, sub_id, function (response_json) {
            let response = JSON.parse(response_json);
            if (response.catch_error) {
            }
            else if (!response.catch_error) {
                if (response.status === 200) {
                    let child_data = (response.data.child_data.Items[0] && response.data.child_data.Items[0].child_data) ? response.data.child_data.Items[0].child_data : [];
                    let relation_data = response.data.relation_data ? response.data.relation_data : {}

                    if (child_data && child_data.length > 0) {
                        localStorage.setItem("child_data", JSON.stringify(child_data));
                    }

                    let relation_data_final = [];
                    if (Object.keys(relation_data).length > 0) {
                        for (var key in relation_data) {
                            relation_data[key].forEach(element => {
                                if (key == 'approvedRelations') {
                                    element.relation_status = 'approved_relations'
                                }
                                if (key == 'pendingRelation') {
                                    element.relation_status = 'pending_relation'
                                }
                                if (key == 'pendingInvitation') {
                                    element.relation_status = 'pending_invitation'
                                }
                                relation_data_final.push(element)
                            });
                        }
                        localStorage.setItem("relation_data", JSON.stringify(relation_data_final));
                    } else {
                    }
                }
            }
        })
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })

        }
        this.GetInfo()
        let action = "User landed on shipping_address page"
        let image  = 'shipping_address_page_landing.png'
        functions.logUserActivity(action, image)
        let address = localStorage.getItem("shipping_address") ? JSON.parse(localStorage.getItem("shipping_address")) : {}

        if (address.country == "Canada") {

            this.setState({
                country: address.country ? address.country : "",
                selected_country: address.country ? address.country : "",
                addressline1: address.addressline1 ? address.addressline1 : "",
                city: address.city ? address.city : "",
                province: address.province ? address.province : "",
                postalcode: address.postalcode ? address.postalcode : "",
                STN: address.STN ? address.STN : "",
                ["PO Box"]: address.PO_Box ? address.PO_Box : "",
                [address.sub_selected]: address[address.sub_selected] ? address[address.sub_selected] : "",

                sub_selected: address.sub_selected ? address.sub_selected : "",
                show_additional: address.show_additional ? address.show_additional : false,
                additional: (address.show_additional == true && (address.PO_Box != "" || address.STN != "")) ? true : false,
                toggle_street_address: address.toggle_street_address ? address.toggle_street_address : false,
                toggle_element: address.toggle_element ? address.toggle_element : ""
            })
            if (address.toggle_element == "Sub") {
                this.setState({
                    openedSub: true,
                    ["Buzzer code"]: address["Buzzer code"] ? address["Buzzer code"] : "",
                    Entrance: address.Entrance ? address.Entrance : "",
                    Building: address.Building ? address.Building : "",
                    ["Buzzer code_select"]: address["Buzzer code"] && address["Buzzer code"] != "" ? "true" : "false",
                    Entrance_select: address.Entrance && address.Entrance != "" ? "true" : "false",
                    Building_select: address.Building && address.Building != "" ? "true" : "false",
                })

            }

            if (address.toggle_element == "Rural") {
                this.setState({
                    openedRural: true,
                    RR: address.RR ? address.RR : "",
                    Site: address.Site ? address.Site : "",
                    Comp: address.Comp ? address.Comp : "",

                    RR_select: address.RR && address.RR != "" ? "true" : "false",
                    Site_select: address.Site && address.Site != "" ? "true" : "false",
                    Comp_select: address.Comp && address.Comp != "" ? "true" : "false",

                    LCD: address.LCD ? address.LCD : "false",
                    general_delivery: address.general_delivery ? address.general_delivery : "false",
                    rural_selected: address.rural_selected,
                    [address.rural_selected]: address[address.rural_selected]
                })

            }
        }
    }

    handleAddressFormat = (event, state_name) => {

        if (state_name === 'addressline1') {
            if (!event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
            }
            else {
                this.setState({
                    addressline1: event.target.value
                });
            }
        }
        else if (state_name === 'city') {
            if (!event.target.value.match(/^[a-zA-Z-'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ. ]*$/)) {
            }
            else {
                this.setState({
                    city: event.target.value
                });
            }
        }
        else if (state_name === 'province') {
            if (!event.target.value.match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            }
            else {
                this.setState({
                    province: event.target.value
                });
            }
        }
        //for postal code
        else if (state_name === 'postalcode' && event.target.value.length < 8) {
            if (this.state.postalcode) {
                if (this.state.postalcode.length < event.target.value.length) {
                    if (event.keyCode === 0) {
                        return false;
                    }
                    else if (
                        event.target.value.length === 3 &&
                        this.state.postalcode.length === 2
                    ) {
                        if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                        }
                        else {
                            this.setState({
                                postalcode: event.target.value.toUpperCase() + " ",
                                show_error: false
                            });
                        }

                    }
                    else if (
                        event.target.value.length === 4 &&
                        this.state.postalcode.length === 3
                    ) {
                        if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                        }
                        else {
                            this.setState({
                                postalcode: this.state.postalcode.toUpperCase() + " " + event.target.value.toUpperCase().slice(-1),
                                show_error: false
                            });
                        }
                    }

                    else if ((event.target.value).length < 4) {
                        if ((event.target.value).length % 2 === 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }

                    else if ((event.target.value).length > 4) {
                        if ((event.target.value).length % 2 !== 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }
                }
                else {
                    if (event.keyCode === 0) {
                        return false;
                    }
                    else if (
                        event.target.value.length === 3 &&
                        this.state.postalcode.length === 2
                    ) {
                        this.setState({
                            postalcode: event.target.value.toUpperCase() + " ",
                            show_error: false
                        });
                    }

                    else if ((event.target.value).length < 4) {
                        if ((event.target.value).length % 2 === 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }

                    else if ((event.target.value).length > 4) {
                        if ((event.target.value).length % 2 !== 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }
                    else if ((event.target.value).length === 4) {
                        this.setState({
                            postalcode: (event.target.value.toUpperCase()).trim(),
                            show_error: false
                        });
                        // }
                    }
                }
            } else {
                if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                }
                else {
                    this.setState({
                        postalcode: event.target.value.toUpperCase(),
                        show_error: false
                    });
                }
            }
        }
    }

    handleFocusTextField = () => {
        if (this.state.toggle_element == "Rural") {
            this.Site.focus()
        }
        else if (this.state.toggle_element == "House") {
            this.city.focus()
        }
        else {
            if (this[this.state.toggle_element] && typeof (this[this.state.toggle_element]) === 'object')
                this[this.state.toggle_element].focus()
        }
    }

    handleBack = () => {
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        if (previous_state == "editprofile") {
            this.props.history.push("/editprofile")
        }
        else {
            this.props.history.push("/create_password")
        }
    }

    render() {
        const { dynamic_content } = this.state
        const closePoints = <button className="closeAddressModel" onClick={() => { this.closeToggle(this, 'modal_account') }}>&times;</button>;
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className="common_main common_mainSgn">
                                <div className="mailAdd">
                                    <h1></h1>
                                    <h2>{"Shipping Address"}</h2>
                                </div>

                                {/* For country options */}
                                <div className="selCntry">
                                    {this.state.country_options && this.state.country_options.length > 0 && this.state.country_options.map((element, index) => (
                                        <div key={"country_options_" + index} className="selCntryBtn"><Button onClick={() => { this.handleSelectCountry(element) }} size="medium" color="primary" className={this.state.selected_country == element ? "selCntryActv" : ""} >{element}</Button></div>
                                    ))}
                                </div>
                                {/* For country options */}

                                <div className="addPrz">
                                    <p>This is where your Freebies and Prizes are delivered.
                                Please use the address found on a letter addressed to you.</p>
                                </div>

                                {/* for canada address type options */}
                                <div className="selHome">
                                    {this.state.selected_country == "Canada" && this.state.canada_address_options && this.state.canada_address_options.length > 0 && this.state.canada_address_options.map((element, index) => (
                                        <div key={"canada_address_options" + index} className="selHomeBtn"><Button onClick={() => { this.handleAddressOption(element) }} size="medium" color="primary" className={this.state.toggle_element == element ? "selHomeActv" : ""} >{element}</Button></div>
                                    ))}
                                </div>
                                {/* for canada address type options */}

                                {/* Sub section screen */}
                                {this.state.openedSub && this.state.selected_country == "Canada" &&
                                    <div>
                                        <div className="selSubMain">
                                            <div className="selSub selSubFirst">

                                                {/* for sub options */}
                                                {this.state.selected_country == "Canada" && this.state.sub_options && this.state.sub_options.length > 0 && this.state.sub_options.map((element, index) => (
                                                    <div className="selSubBtn"><Button onClick={() => { this.handleSubOption(element) }} size="medium" color="primary" className={this.state.sub_selected == element ? "selSubActv" : ""}>{element}</Button></div>
                                                ))}
                                                {/* for sub options */}
                                            </div>
                                            {/* Buzzer code buttons */}
                                            {this.state.sub_selected != "" &&
                                                <div className="buzSub">
                                                    {/* for sub options other three*/}
                                                    {this.state.selected_country == "Canada" && this.state.sub_options_extra && this.state.sub_options_extra.length > 0 && this.state.sub_options_extra.map((element, index) => (
                                                        <div className="buzSubBtn"><Button onClick={() => { this.handleSubExtraOption(element) }} size="medium" color="primary" className={this.state[element + "_select"] == "true" ? "buzSubActv" : ""}>{element}</Button></div>
                                                    ))}
                                                    {/* for sub options other three*/}
                                                </div>
                                            }
                                            {/* End of Buzzer code buttons */}

                                            {/* sub apt form */}
                                            <div className="subForm">
                                                {this.state.selected_country == "Canada" && this.state.sub_options && this.state.sub_options.length > 0 && this.state.sub_options.map((element, index) => (
                                                    <div>
                                                        <TextField
                                                            key={index}
                                                            autoComplete='off'
                                                            // label={element}
                                                            type="text"
                                                            margin="normal"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">{element}:</InputAdornment>,
                                                            }}
                                                            inputRef={(input) => { this[element] = input; }}
                                                            value={this.state[element]}
                                                            onChange={(event) => { this.handleSubOptionsChange(event, element) }}
                                                            onKeyPress={event => {
                                                                if (event.key === "Enter") {
                                                                    this.city.focus();
                                                                }
                                                            }}
                                                            style={this.state.sub_selected == element ? { display: "" } : { display: "none" }}
                                                        />
                                                    </div>
                                                ))}
                                                {this.state.selected_country == "Canada" && this.state.sub_options_extra && this.state.sub_options_extra.length > 0 && this.state.sub_options_extra.map((element, index) => (
                                                    <div>
                                                        <TextField
                                                            key={index}
                                                            autoComplete='off'
                                                            // label={element}
                                                            type="text"
                                                            margin="normal"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">{element}:</InputAdornment>,
                                                            }}
                                                            inputRef={(input) => { this[element] = input; }}
                                                            value={this.state[element]}
                                                            onChange={(event) => { this.handleSubOptionsChange(event, element) }}
                                                            onKeyPress={event => {
                                                                if (event.key === "Enter") {
                                                                    this.city.focus();
                                                                }
                                                            }}
                                                            style={this.state[element + "_select"] == "true" ? { display: "" } : { display: "none" }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            {/* sub apt form */}
                                        </div>
                                    </div>
                                }
                                {/* End of Sub section screen */}

                                {/* Rural section screen */}
                                {this.state.openedRural && this.state.selected_country == "Canada" &&
                                    <div>
                                        <div className="selRurlMain">
                                            <div className="selRurl selRurlFirst">
                                                {this.state.selected_country == "Canada" && this.state.rural_options && this.state.rural_options.length > 0 && this.state.rural_options.map((element, index) => (
                                                    <div key={"rural_options" + index} onClick={() => { this.handleRuralClick(element) }} className="selRurlBtn"><Button size="medium" color="primary" className={this.state[element + "_select"] == "true" ? "selSubActv" : ""}>{element}</Button></div>
                                                ))}
                                            </div>
                                            <div className="selRurl selRurlSecnd">
                                                {/* <div className="selRurlBtn"><Button size="medium" color="primary" className="selRurlActv">LCD Main</Button></div> */}
                                                <div onClick={() => { this.handleRuralExtraClick("LCD") }} className="selRurlBtn"><Button size="medium" color="primary" className={this.state.LCD == "true" ? "selRurlActv" : ""}>LCD Main</Button></div>
                                                <div onClick={() => { this.handleRuralExtraClick("general_delivery") }} className="selRurlBtn"><Button size="medium" color="primary" className={this.state.general_delivery == "true" ? "selRurlActv" : ""}>{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.general_delivery.en : "General Delivery"}</Button></div>
                                            </div>
                                            {/* Rural form */}
                                            <div className="rurlForm">
                                                {this.state.selected_country == "Canada" && this.state.rural_options && this.state.rural_options.length > 0 && this.state.rural_options.map((element, index) => (
                                                    <TextField
                                                        autoComplete='off'
                                                        // label={element}
                                                        type="text"
                                                        margin="normal"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{element}:</InputAdornment>,
                                                        }}
                                                        inputRef={(input) => { this[element] = input; }}
                                                        value={this.state[element]}
                                                        onChange={(event) => { this.setState({ [element]: event.target.value }) }}
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                // this.city.focus();
                                                            }
                                                        }}
                                                        style={this.state[element + "_select"] == "true" ? { display: "" } : { display: "none" }}
                                                    />
                                                ))}
                                            </div>
                                            {/* Rural form */}
                                        </div>
                                    </div>
                                }
                                {/* End of Rural content */}

                                {/* House Section   */}
                                {this.state.selected_country == "Canada" && this.state.show_additional == true &&
                                    <div className={this.state.additional == false ? "additionaldiv" : "additionaldiv2"}>
                                        <div className="hmAddon">
                                            <div className="parentaddonlft">
                                                <div className="hmAddonLft">
                                                    {this.state.additional == false &&
                                                        <div><label>{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.additional.en : "Additional"}</label></div>
                                                    }
                                                    {this.state.additional == true &&
                                                        <div>
                                                            <div className="poBoxOptional">
                                                                <TextField
                                                                    autoComplete='off'
                                                                    label="PO Box (Optional):"
                                                                    type="text"
                                                                    margin="normal"
                                                                    inputRef={(input) => { this.PO_Box = input; }}
                                                                    value={this.state["PO Box"]}
                                                                    onKeyPress={event => {
                                                                        if (event.key === "Enter") {
                                                                            this.city.focus();
                                                                        }
                                                                    }}
                                                                    onChange={event => {
                                                                        if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                                            this.setState({ ["PO Box"]: event.target.value, show_error_message: '' })
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <TextField
                                                                    autoComplete='off'
                                                                    label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.stn_label.en : "STN (Optional):"}
                                                                    type="text"
                                                                    margin="normal"
                                                                    inputRef={(input) => { this.PO_Box = input; }}
                                                                    value={this.state["STN"]}
                                                                    onKeyPress={event => {
                                                                        if (event.key === "Enter") {
                                                                            this.city.focus();
                                                                        }
                                                                    }}
                                                                    onChange={event => {
                                                                        if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                                            this.setState({ ["STN"]: event.target.value, show_error_message: '' })
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={this.state.additional == true ? "hmAddonRght" : "hmAddonRghtfalse"}>
                                                    <Switch
                                                        checked={this.state.additional}
                                                        onChange={this.handleChange}
                                                        name="additional"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </div>
                                            </div>
                                            <div style={this.state.openedRural ? { display: "" } : { display: "none" }} className="parentaddonlft">
                                                <div className="hmAddonLft">
                                                    <div><label>{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.street_address.en : "Street Address"}</label></div>
                                                </div>
                                                <div className={this.state.toggle_street_address == true ? "hmAddonRght" : "hmAddonRghtfalse"}>
                                                    <Switch
                                                        checked={this.state.toggle_street_address}
                                                        onChange={this.handleChange}
                                                        name="toggle_street_address"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.show_error_message && this.state.show_error_message != "" && this.state.openedRural && this.state.toggle_street_address == false &&
                                                <p className="error_message">
                                                    {this.state.show_error_message}
                                                </p>
                                            }
                                            {this.state.openedRural && this.state.toggle_street_address == false &&
                                                <div className="houseFormBtn">
                                                    <Button onClick={() => { this.handleManualAddress() }} color="secondary">{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.continue.en : "Continue"}</Button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {/* End of House Section */}

                                {/* Address form screen for Canada country*/}
                                {this.state.selected_country == "Canada" && this.state.toggle_street_address == true &&
                                    <div className="houseForm" style={{ marginTop: "0px" }}>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.street_address.en : "Street Address"}
                                                inputRef={(input) => { this.addressline1 = input; }}
                                                value={this.state.addressline1}
                                                type="text"
                                                // disabled={this.state.manual_address ? false : true}
                                                autoFocus={false}
                                                margin="normal"
                                                onChange={(event) => {
                                                    if (this.state.manual_address == true) {
                                                        if (event.target.value.match(/^[a-zA-Z0-9-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                            this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                        }
                                                    } else {
                                                        this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                        this.handleAutoComplete(event.target.value)
                                                    }
                                                }}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter" && this.state.manual_address == true) {
                                                        this.handleFocusTextField();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {!this.state.loader && this.state.address_autocomplete && this.state.address_autocomplete.length > 0 &&
                                            <div className="address_parent_section">
                                                {this.state.address_autocomplete && this.state.address_autocomplete.length > 0 && this.state.address_autocomplete.map((element, index) => (
                                                    <div key={index} className="address_child_section">
                                                        {!this.state.loader && this.state.last_address == true &&
                                                            <p onClick={() => { this.handleSetAddress(element) }}>{element.Suggestion}</p>
                                                        }
                                                        {!this.state.loader && this.state.last_address == false &&
                                                            <p onClick={() => { this.handleSetAddress(element) }}>{element.Text + " " + element.Description}</p>
                                                        }
                                                        {!this.state.loader && element.Next == "Find" &&
                                                            <img onClick={() => { this.handleSetAddress(element) }} className="right_arrow" src={url.family_one_image_url + 'right_arrow.png'} alt="" title="" />
                                                        }
                                                        {!this.state.loader && !element.Next && element.IsRetrievable == false &&
                                                            <img onClick={() => { this.handleSetAddress(element) }} className="right_arrow" src={url.family_one_image_url + 'right_arrow.png'} alt="" title="" />
                                                        }
                                                    </div>

                                                ))}
                                            </div>
                                        }
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.city_label.en : "City"}
                                                name={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.city_label.en : "City"}
                                                type="text"
                                                inputRef={(input) => { this.city = input; }}
                                                margin="normal"
                                                value={this.state.city}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.province.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    this.setState({ show_error_message: '' });
                                                    this.handleAddressFormat(event, "city")
                                                }}
                                            />
                                        </div>
                                        {!this.state.manual_address &&
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.province_label.en : "Province"}
                                                type="text"
                                                inputRef={(input) => { this.province = input; }}
                                                margin="normal"
                                                disabled={true}
                                                value={this.state.province}
                                            />
                                        }
                                        {this.state.manual_address &&
                                            <div>
                                                <TextField
                                                    autoComplete='off'
                                                    onClick={() => {
                                                        let action = "User open province drop down on address page"
                                                        let image  = 'province_drop_down_address_page.png'
                                                        functions.logUserActivity(action, image);
                                                        this.setState({ modal_account: true, show_province: true })
                                                    }}
                                                    label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.select_province_label.en : "Select Province"}
                                                    value={this.state.province}
                                                    inputRef={(input) => { this.province = input; }}
                                                    type="text"
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            this.postalcode.focus();
                                                        }
                                                        else {
                                                            let action = "User open province drop down on address page"
                                                            let image  = 'province_drop_down_address_page.png'
                                                            functions.logUserActivity(action, image);
                                                            this.setState({ modal_account: true, show_province: true })
                                                        }
                                                    }}
                                                    // disabled={this.state.manual_address ? false : true}
                                                    autoFocus={false}
                                                    margin="normal"
                                                >

                                                </TextField>
                                            </div>
                                        }
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.postalcode_label.en : "Postal code"}
                                                name={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.postalcode_label.en : "Postal code"}
                                                type="text"
                                                margin="normal"
                                                inputRef={(input) => { this.postalcode = input; }}
                                                value={this.state.postalcode}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.handleManualAddress();
                                                    }
                                                }}
                                                onChange={event => {
                                                    this.setState({ show_error_message: '' });
                                                    this.handleAddressFormat(event, "postalcode")
                                                }}
                                            />
                                        </div>
                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        {!this.state.manual_address &&

                                            <div className="enter_manully">
                                                <Button color="secondary" onClick={() => { this.autocomplete_toggle('enter_manually') }}>{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.enter_manually.en : "Enter Manually"}</Button>

                                            </div>
                                        }
                                        <div className="houseFormBtn">
                                            <Button onClick={() => { this.handleManualAddress() }} color="secondary">{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.continue.en : "Continue"}</Button>
                                        </div>
                                    </div>
                                }
                                {/* End of  Address form screen  */}

                                {/* Address form screen for country other than Canada*/}
                                {this.state.selected_country != "" && this.state.selected_country != "Canada" && this.state.show_form == true &&
                                    <div className="houseForm">
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.street_address.en : "Street Address"}
                                                name={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.street_address.en : "Street Address"}
                                                type="text"
                                                id="addressline1"
                                                inputRef={(input) => { this.addressline1 = input; }}
                                                margin="normal"
                                                value={this.state.addressline1}
                                                autoFocus={true}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.city.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    this.setState({ addressline1: event.target.value, show_error_message: '' })
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.city_label.en : "City"}
                                                name={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.city_label.en : "City"}
                                                type="text"
                                                inputRef={(input) => { this.city = input; }}
                                                margin="normal"
                                                value={this.state.city}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.province.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    this.setState({ city: event.target.value, show_error_message: '' })
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={this.state.selected_country == "USA"?"State":"Province/State"}
                                                type="text"
                                                name={this.state.selected_country == "USA"?"State":"Province/State"}
                                                inputRef={(input) => { this.province = input; }}
                                                margin="normal"
                                                value={this.state.province}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.postalcode.focus()
                                                    }
                                                }}
                                                onChange={event => {
                                                    this.setState({ province: event.target.value, show_error_message: '' })
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                autoComplete='off'
                                                label={"Zip Code"}
                                                name={"Zip Code"}
                                                type="text"
                                                margin="normal"
                                                inputRef={(input) => { this.postalcode = input; }}
                                                value={this.state.postalcode}
                                                onKeyPress={event => {
                                                    if (event.key === "Enter") {
                                                        this.handleManualAddress();
                                                    }
                                                }}
                                                onChange={event => {
                                                    if (event.target.value.length < 8) {
                                                        if (event.keyCode === 0) {
                                                            return false;
                                                        }
                                                        else if (
                                                            event.target.value.length === 3 &&
                                                            this.state.postalcode.length === 2
                                                        ) {
                                                            this.setState({
                                                                show_error_message: '',
                                                                postalcode:
                                                                    event.target.value.toUpperCase() + " "
                                                            });
                                                        } else {
                                                            this.setState({
                                                                show_error_message: '',
                                                                postalcode: event.target.value.toUpperCase()
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="houseFormBtn">
                                            <Button onClick={() => { this.handleManualAddress() }} color="secondary">{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.continue.en : "Continue"}</Button>
                                        </div>
                                    </div>
                                }
                                {/* End of  Address form screen  */}

                                {/* End of  Address form screen  */}
                            </div>
                            {/* Country drop down model */}
                            <div className="open_mail_model">
                                <Modal isOpen={this.state.auto_complete_modal} toggle={() => { this.autocomplete_toggle('close') }} className="custom_Addchildmodel custom_Addchildmodel_New">
                                    {/* <ModalHeader toggle={this.toggle}>Modal title</ModalHeader> */}
                                    <ModalBody className="custom_unknwnchildBody custom_unknwnchildBody_New">
                                        <div className="unknwnchild_content unknwnchild_content_New">
                                            <h4>{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.street_address.en : "Street Address"}</h4>
                                            <TextField
                                                name="line"
                                                id="line"
                                                placeholder={dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.street_address.en : "Street Address"}
                                                value={this.state.search_key}
                                                margin="normal"
                                                autoFocus={true}
                                                onChange={event => {
                                                    this.handleAutoComplete(event.target.value)
                                                }}
                                            />
                                            {this.state.loader &&
                                                <center className="loader">
                                                    <LottieLoader className="loader_class" style={{ width: "40px !important" }} color="secondary" />
                                                </center>
                                            }
                                            {!this.state.loader &&
                                                <div className="address_parent_section">
                                                    {this.state.address_autocomplete && this.state.address_autocomplete.length > 0 && this.state.address_autocomplete.map((element, index) => (
                                                        <div key={index} className="address_child_section">
                                                            {!this.state.loader && this.state.last_address == true &&
                                                                <p onClick={() => { this.handleSetAddress(element) }}>{element.Suggestion}</p>
                                                            }
                                                            {!this.state.loader && this.state.last_address == false &&
                                                                <p onClick={() => { this.handleSetAddress(element) }}>{element.Text + " " + element.Description}</p>
                                                            }
                                                            {!this.state.loader && element.Next == "Find" &&
                                                                <img onClick={() => { this.handleSetAddress(element) }} className="right_arrow" src={url.family_one_image_url + 'right_arrow.png'} alt="" title="" />
                                                            }
                                                            {!this.state.loader && !element.Next && element.IsRetrievable == false &&
                                                                <img onClick={() => { this.handleSetAddress(element) }} className="right_arrow" src={url.family_one_image_url + 'right_arrow.png'} alt="" title="" />
                                                            }
                                                        </div>

                                                    ))}
                                                </div>
                                            }
                                            <div className="add_position">
                                                <Button color="secondary" onClick={() => { this.autocomplete_toggle('cancel') }} className="cancelBtns" >{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.cancel.en : "Cancel"}</Button>

                                                <div className="enter_manully">

                                                    <p style={{
                                                        textAlign: "center",
                                                        marginBottom: "auto"
                                                    }}>Address Not Found?</p>
                                                    <Button color="secondary" onClick={() => { this.autocomplete_toggle('enter_manually') }}>{dynamic_content.static_text.shipping_address ? dynamic_content.static_text.shipping_address.enter_manually.en : "Enter Manually"}</Button>

                                                </div>
                                            </div>

                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={this.state.modal_account} className="custom_Addchildmodel">

                                    <ModalBody>

                                        <div className="country_data">

                                            <div className="country_ac">
                                                <div className="country_acLft">
                                                    {!this.state.show_province &&
                                                        <label>Choose Country</label>
                                                    }
                                                    {this.state.show_province &&
                                                        <label>Choose Province</label>
                                                    }
                                                </div>
                                                <div className="country_acRght" style={{
                                                    width: "30%",
                                                    textAlign: "right"
                                                }}>
                                                    <ModalHeader close={closePoints} style={{ textAlign: "right" }}></ModalHeader></div>
                                            </div>

                                            <div className="country_dataList">
                                                {/* <div className="country_active"><a>Canada</a> <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" /></div> */}
                                                {!this.state.show_province && this.state.countries && this.state.countries.length > 0 && this.state.countries.map((element, index) => (
                                                    <div key={index} className={this.state.country == element ? "country_active" : ""} onClick={() => { this.handleCountry(element) }}>
                                                        <a className="other_country">
                                                            {element}
                                                        </a>
                                                        {this.state.country == element &&
                                                            <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" />
                                                        }
                                                    </div>
                                                ))}
                                                {this.state.show_province && this.state.states && this.state.states.length > 0 && this.state.states.map((element, index) => (
                                                    <div key={index} className={this.state.province == element ? "country_active" : ""} onClick={() => { this.handleState(element) }}><a className="other_country">{element}</a>

                                                        {this.state.province == element &&
                                                            <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" />
                                                        }
                                                    </div>
                                                ))}

                                            </div>

                                        </div>

                                    </ModalBody>
                                </Modal>
                            </div>
                            {/* End of Country drop down model*/}
                        </div>
                    </div>
                }
            </div>
        );
    }
}