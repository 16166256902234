import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
const { v1: uuidv1 } = require('uuid');
export default class BuildyourFamily extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTopRef = React.createRef();
        // functions.handleBackOne(this, 'buildyourfamily')
        this.state = {
            isUIReady: false,
            dynamic_content: "",
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : ""
        }
    }

    componentDidMount = () => {
        let current_state = localStorage.getItem('current_state');
        // if (current_state !== 'buildyourfamily') {
        //     functions.getLandingPage(this);
        // }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            // console.log("dynamic_content", dynamic_content)
            this.setState({ dynamic_content: dynamic_content })
        }
        // console.log("buildyourfamily")
        let action = "User landed on buildyourfamily page"
        let image = 'buildyourfamily_page_landing.png';
        let child_timestap_ftm = cookie.load("ftm_child_time_stamp", { path: "/" });
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (auto_id &&child_timestap_ftm) {
            this.setState({ loader: true })
            // console.log("child_timestap_ftm", child_timestap_ftm)
            child_timestap_ftm = child_timestap_ftm ? parseInt(child_timestap_ftm) : '';
            let child_data = localStorage.getItem('child_data') ? JSON.parse(localStorage.getItem('child_data')) : []
            if (child_data && child_timestap_ftm && new Date(child_timestap_ftm)) {

                let child_date = this.formatDate(child_timestap_ftm)
                let temp_child_Data = { child_name: "child " + (child_data.length + 1), child_gender: 'Unknown', child_birthdate: child_date, birthdate_timestamp: child_timestap_ftm, type: 'Expectant', child_id: uuidv1(), relation_with_child: 'parent', creator_auto_id: auto_id }

                child_data.push(temp_child_Data);

                let userdata = []
                let dynamo_userdata = { 'child_data': child_data, 'delete_ftm_ts': true };
                localStorage.setItem("child_data", JSON.stringify(child_data))
                functions.updateUserData(this, userdata, dynamo_userdata);

            }
            setTimeout(() => {
                this.setState({ loader: false })
            }, 500);
        }
        functions.logUserActivity(action, image);
        this.setState({ isUIReady: true });
    }

    scrollToTop = () => {
        if (this.scrollToTopRef.current) {
            this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
            // Perform scroll to top action
            this.scrollToTop();
        }
      }

    formatDate(date) {
        // console.log("date", date)
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleContinue = (path) => {
        let action = "User clicked on " + path + " on buildyourfamily page"
        let image = path + '_button_buildyourfamily_page.png'
        functions.logUserActivity(action, image);
        if (path == "addchild") {
            localStorage.setItem("add_as_relative", "false")
            let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
            if (child_data.length > 0) {
                functions.pushToRoute(this, "buildfamily", 'buildyourfamily');
            }
            else {
                // console.log("add_child");
                localStorage.setItem("form_type", 'add_child')
                functions.pushToRoute(this, "addchild", 'buildyourfamily')
            }
        }
        else if (path == "addrelative") {
            let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : []
            if (relation_data.length > 0) {
                functions.pushToRoute(this, "buildaddrelative", 'buildyourfamily');
            }
            else {
                localStorage.removeItem("edit_index")
                localStorage.setItem("form_type", 'add_child')
                localStorage.setItem("add_as_relative", "true")
                // functions.pushToRoute(this, "addrelative", 'buildyourfamily')
                functions.pushToRoute(this, "addchild", 'buildyourfamily')
            }
        }
    };

    render() {
        const { dynamic_content } = this.state
        return (
            <div ref={this.scrollToTopRef} className="container">
                <div className="row">
                <div className="common_mainAddOn">
                    <div className='usrBuildSetBg'>
                    {dynamic_content != "" &&
                        <div className="common_main">
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                            <div className="sign_up">
                                <h1>{this.state.session_type == "signup" ? dynamic_content.static_text.buildyourfamily ? dynamic_content.static_text.buildyourfamily.signup.en : "Sign Up" : ""}</h1>
                            </div>

                            <div>
                                <div className="add_parent buildFamilyMain">
                                    <div className="add_parent_inner buildFamilyUpr">
                                        <img onClick={() => { this.handleContinue("addchild") }} src={require('../../assets/images/buildFamily.svg')} alt="" title="" />     
                                    </div>
                                </div>
                                <div className="build_content">
                                    <h1>{dynamic_content.static_text.buildyourfamily ? dynamic_content.static_text.buildyourfamily.build_your_family.en : "Build your Family"}</h1>
                                    <p>
                                        {dynamic_content.static_text.buildyourfamily ? dynamic_content.static_text.buildyourfamily.points_and_benfit.en : "Text about points and benefits add family member."}
                                    </p>
                                </div>
                                <div className="prent_submit">
                                    <Button size="medium" color="primary" onClick={() => { this.handleContinue("addchild") }}>
                                        {dynamic_content.static_text.buildyourfamily ? dynamic_content.static_text.buildyourfamily.imexpecting.en : "I’m Parent or Expecting"}
                                    </Button >
                                    <Button className="build_your_family_relative" onClick={() => { this.handleContinue("addrelative") }}>{dynamic_content.static_text.buildyourfamily ? dynamic_content.static_text.buildyourfamily.imrelative.en : "I’m Relative or Friend"}</Button>
                                </div>
                            </div>

                        </div>
                    }
                    </div>
                  </div>  
                </div>
            </div>
        );
    }
}