import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import url from '../../assets/url';
import CSSPlugin from 'gsap/CSSPlugin';
const C = CSSPlugin;
var initials = require("initials");

class Familymembers extends React.Component {
    constructor(props) {
        super(props);
        functions.handleBackOne(this, 'familymembers')
        this.state = {
            dynamic_content: "",
            relations: localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : []
        }
    }

    handleAdd = () => {
        let success_action = "User clicked on add button on familymembers page"
        let image = 'add_button_familymembers_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        localStorage.setItem("form_type", "add_child")
        setTimeout(function () {
            functions.pushToRoute(self, 'addrelative', 'familymembers')
        }, 1000)
    }

    handleBack = () => {
        let success_action = "User clicked on back button on familymembers page"
        let image = 'back_button_familymembers_page.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("detail_page", 'true')
        functions.pushToRoute(this, 'main', 'familymembers')
    }

    componentDidMount = () => {
        let success_action = "User landed on familymembers page"
        let image = 'familymembers_page_landing.png'
        functions.logUserActivity(success_action, image);
        this.handleCircleGrowEffect();
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
    }

    handleCircleGrowEffect = () => {
        var body = document.getElementById("family_members");
        // var body = document.body;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;

        // Viewport
        var vw = canvas.width = body.offsetWidth;
        var vh = canvas.height = body.offsetHeight;

        // var vw = canvas.width = window.innerWidth;
        // var vh = canvas.height = window.innerHeight;


        if (document.getElementById('relative_add_section')) {
            document.getElementById('relative_add_section').addEventListener("click", createRipple);
        }
        // window.addEventListener("resize", onResize);

        //createRipple(this);

        function createRipple(event) {


            // Exit if target is the canvas. That means it's still active.
            if (event.target === canvas) return;

            body.appendChild(canvas);

            // Event coords
            var x = event.clientX;
            var y = event.clientY;


            // Delta - difference between event and farthest corner
            var dx = x < vw / 2 ? vw - x : x;
            var dy = y < vh / 2 ? vh - y : y;


            var radius = Math.sqrt(dx * dx + dy * dy);

            var ripple = {
                alpha: 0,
                radius: 0,
                x: vw / 2,
                y: y
            };

            var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                .to(ripple, 1.5, { alpha: 1, radius: radius })
                .to(ripple, 0.1, { alpha: 0 }, 1.4);

            function drawRipple() {
                ctx.clearRect(0, 0, vw, vh);
                ctx.beginPath();
                ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                ctx.fill();
            }
        }
        function removeCanvas() {
            if (body.contains(canvas)) {
                body.removeChild(canvas);
            }
        }

        function onResize() {
            vw = canvas.width = window.innerWidth;
            vh = canvas.height = window.innerHeight;
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container" id="family_members">
                <div className="row">
                    {dynamic_content != "" &&
                     <div className="common_mainAddOn">
                        <div className="common_main">

                            {/* common header */}
                            <div>
                                {/* className="moisback_section" */}
                                {/* <div className="moisback_secone_winner backLinkWidth white_circle_div">
                                    <div className="backLinkWidth">
                                        <img onClick={() => { this.handleBack() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" />
                                    </div>
                                </div> */}

                                <div className="sign_backlink sign_backlinkNw">
                                    <img onClick={() => { this.handleBack() }} src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>
                                <div className="moisback_sectwo prodtRvw_lnk moisback_sectwoCstm">
                                    <label>{dynamic_content.static_text.familymembers ? dynamic_content.static_text.familymembers.family_members.en : "Family Members"}
                                    </label>
                                </div>

                            </div>

                            {/*End of common header */}

                            {/* Start all winners design */}
                            <div className="all_family_section all_relative_section all_family_sectionCstm">
                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                    <div key={index} className="all_family all_familyCstm">
                                        <div className="all_family_left all_family_leftCstm">
                                            <label>{initials((relation.given_name + " " + relation.family_name).toUpperCase())}</label>
                                        </div>
                                        <div className="all_family_rght all_family_rghtCstm">
                                            <label>{relation.given_name + " " + relation.family_name}</label>
                                            {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation == "In-law" &&
                                                <p>{relation.relation + " " + relation.optional_relation}</p>
                                            }
                                            {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation != "In-law" &&
                                                <p>{relation.optional_relation + " " + relation.relation}</p>
                                            }
                                            {relation.optional_relation && relation.optional_relation == "" &&
                                                <p>{relation.relation}</p>
                                            }
                                            {!relation.optional_relation &&
                                                <p>{relation.relation}</p>
                                            }
                                        </div>
                                        <div className="all_family_tick all_family_tickCstm">

                                            {relation.relation_status && relation.relation_status == 'approved_relations' &&
                                                <a style={{ padding: "0px" }}><img style={{ width: "28px" }} src={url.family_one_image_url + 'family_tick.png'} alt="" title="" /></a>
                                            }
                                            {relation.relation_status != 'approved_relations' &&
                                                <span> <img src={url.family_one_image_url + 'family_wait.png'} alt="" title="" className="waitImg" /></span>
                                            }

                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="addFmlyBtn" id="relative_add_section">
                                <Button size="medium" color="primary" onClick={() => { this.handleAdd() }}>
                                    {dynamic_content.static_text.familymembers ? dynamic_content.static_text.familymembers.add.en : "Add"}
                                </Button>
                            </div>
                            {/* End of all winners 39design40 */}
                        </div>
                      </div>  
                    }
                </div>
            </div>
        );
    }
}
export default Familymembers;

