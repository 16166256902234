import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import LottieLoader from '../../Screens/LottieLoader';

var md5 = require('md5');

const styles = theme => ({
    eye: {
        cursor: 'pointer',
    },
});

class PasswordInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordIsMasked: true,
        };
    }

    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

    render() {
        const { classes } = this.props;
        const { passwordIsMasked } = this.state;

        return (
            <TextField
                type={passwordIsMasked ? 'password' : 'text'}
                {...this.props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {passwordIsMasked ? (
                                <img src={require('../../assets/images/eyeShow.svg')} alt="" title="" className={classes.eye} onClick={this.togglePasswordMask} />
                            ) : (
                                <img src={require('../../assets/images/eyeHide.svg')} alt="" title="" className={classes.eye} onClick={this.togglePasswordMask} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

PasswordInput.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.func.isRequired,
};

PasswordInput = withStyles(styles)(PasswordInput);

/* --------------------------------------------------------- */

class Enterpassword extends Component {
    constructor(props) {
        super(props);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id !== "") {
            if (this.props.history.action === "POP") {
                this.props.history.goForward()
            }
        }
        else {
            this.props.history.push("/home")
        }
        this.state = {
            password: '',
            confirm_password: '',
            focus_password: true,
            focus_confirm_password: false,
            go_next: true,
            loader: false,
            dynamic_content: ""
        };
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        localStorage.setItem("back_on", "create_password")
        let action = "User landed on create_password page"
        let image = 'create_password_page_landing.png'
        functions.logUserActivity(action, image);
    }

    handleUpdate = () => {
        this.setState({ loader: true })
        let username = cookie.load("username")
        let value = md5(this.state.password);
        let userdata = [{ Name: "custom:user_password", Value: value }]
        localStorage.setItem('password', 'true')
        let dynamo_userdata = { password: true };
        functions.updateUserData(this, userdata, dynamo_userdata)
        localStorage.removeItem("back_on")
        functions.getUserData(this, username, 'login')
    }

    CheckPassword(inputtxt) {
        var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (inputtxt.match(pass)) {
            this.setState({ focus_password: '' })
        }
        else {

            this.setState({ focus_password: this.state.dynamic_content.static_text.create_password.focus_password.en, focus_confirm_password: '' })
        }
    }

    CheckConfirmPassword = (inputtxt) => {
        var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (inputtxt.match(pass)) {
            this.setState({ focus_confirm_password: '' })
        }
        else {
            this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.create_password.focus_confirm_password.en, focus_password: '' })
        }
    }

    handleContinue() {
        let action = "User clicked on Continue button on create_password page"
        let image = 'continue_button_create_password_page.png'
        functions.logUserActivity(action, image);
        var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (this.state.password === '' && this.state.confirm_password === '') {
            this.setState({ loader: true })
            let username = cookie.load("username")
            functions.getUserData(this, username)
        }
        else {
            if (this.state.password !== '' && this.state.confirm_password === '') {
                if (this.state.password.match(pass)) {
                    this.setState({ focus_password: '' })
                } else {
                    this.setState({ focus_password: this.state.dynamic_content.static_text.create_password.focus_password.en, focus_confirm_password: '' });
                }
            }
            else if (this.state.confirm_password !== '' && this.state.password === '') {
                if (this.state.confirm_password.match(pass)) {
                    this.setState({ focus_confirm_password: '' })

                } else {
                    this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.create_password.password_not_match.en, focus_password: '' });
                }
            }
            else if (this.state.confirm_password !== '' && this.state.password !== '') {
                if (this.state.password === this.state.confirm_password) {
                    //Update code
                    if (this.state.password.match(pass)) {
                        this.setState({ focus_password: '' })
                        this.handleUpdate()
                    } else {
                        this.setState({ focus_password: this.state.dynamic_content.static_text.create_password.focus_confirm_password.en, focus_confirm_password: this.state.dynamic_content.static_text.create_password.password_not_match.en });
                    }
                } else {
                    this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.create_password.password_not_match.en, focus_password: '' });
                }
            }
        }
    }

    handleBack = () => {
        let action = "User clicked on back button on create_password page"
        let image = 'back_button_create_password_page.png'
        functions.logUserActivity(action, image);
        this.props.history.push("/home")
    }

    myTrim = (x) => {
        return x.replace(/\s/g, '');
    }

    render() {
        const { dynamic_content } = this.state
        const { password, confirm_password } = this.state;
        return (
            <div className="container">
                {this.state.dynamic_content !== "" &&
                    <div className="row">
                       <div className="common_mainAddOn">
                        <div className="common_main">
                            <div className="sign_backlink white_circle_div">
                                <img onClick={() => { this.handleBack() }} src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>
                            {/* <div className="clearlink"><img src={url.family_one_image_url + 'Group5.png')} alt="" title="" /></div> */}
                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.create_password.create_password.en}</h1>
                            </div>
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                            {!this.state.loader &&
                                <div className="signup_pass">
                                    <form autoComplete="off">

                                        <div className="signup_password" >
                                            <PasswordInput
                                                autoComplete='off'
                                                hiddenlabel="Enter password"
                                                placeholder="Enter password"
                                                name="password"
                                                value={password}
                                                autoFocus={true}
                                                inputRef={(input) => { this.password = input; }}
                                                onChange={(e) => { this.setState({ password: this.myTrim(e.target.value), go_next: false }) }}
                                                onKeyUp={event => {
                                                    if (event.key === "Enter") {
                                                        this.confirm_password.focus()
                                                    }
                                                    else if (event.key) {
                                                        this.CheckPassword(this.state.password)
                                                    }
                                                }}
                                                className="signuptype_password"
                                            />
                                            {this.state.focus_password.length > 1 &&
                                                <p>{this.state.focus_password}</p>
                                            }
                                            <PasswordInput
                                                autoComplete='off'
                                                hiddenlabel="Confirm password"
                                                placeholder="Confirm password"
                                                name="confirm_password"
                                                value={confirm_password}
                                                inputRef={(input) => { this.confirm_password = input; }}
                                                onChange={(e) => { this.setState({ confirm_password: this.myTrim(e.target.value), go_next: false }) }}
                                                onKeyUp={event => {
                                                    if (event.key === "Enter") {
                                                        this.handleContinue()
                                                    }
                                                    else if (event.key) {
                                                        this.CheckConfirmPassword(this.state.confirm_password)
                                                    }
                                                }}
                                                className="signuptype_password"
                                            />
                                            {/* <TextField
                                                autoComplete='off'
                                                label="Confirm password"
                                                type="password"
                                                margin="normal"
                                                inputRef={(input) => { this.confirm_password = input; }}
                                                onChange={(e) => { this.setState({ confirm_password: e.target.value, go_next: false }) }}
                                                onKeyUp={event => {
                                                    if (event.key === "Enter") {
                                                        this.handleContinue()
                                                    }
                                                    else if (event.key) {
                                                        this.CheckConfirmPassword(this.state.confirm_password)
                                                    }
                                                }}
                                                className="logup_first"
                                            /> */}
                                            {this.state.confirm_password.length > 0 && this.state.focus_confirm_password.length > 1 &&
                                                <p>{this.state.focus_confirm_password}</p>
                                            }
                                        </div>

                                        <div className="signup_submit">
                                            <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                                {dynamic_content.static_text.create_password.continue.en}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                       </div> 
                    </div>
                }
            </div>
        );
    }
}

export default Enterpassword;
