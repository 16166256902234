const INIT_STATE = {
    child_invitation_switch: false
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SWITCH_CHILD_INVITATION":
            return {
                ...state,
                child_invitation_switch: action.payload
            };
        default:
            return state;
    }
};