import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

export default class PhoneSent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamic_content: "",
            email: localStorage.getItem("email") ? localStorage.getItem("email") : ""
        };
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            if (dynamic_content.static_text) {
                let temp_email_sent_description = dynamic_content.static_text.restorepasswordsent.message.en
                let new_email_sent_description = temp_email_sent_description.replace("%EMAIL%", this.state.email)
                dynamic_content.static_text.restorepasswordsent.message.en = new_email_sent_description
            }
            this.setState({ dynamic_content: dynamic_content })
            
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    {dynamic_content != "" &&
                        <div className="common_main">
                            {/* <div className="backlink"><img src={require('../../assets/images/Group147.png'} alt="" title="" /></div> */}
                            {/* <div className="clearlink"><img src={require('../../assets/images/Group5.png'} alt="" title="" /></div> */}
                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.restorepasswordsent.restore_password.en : "Restore Password"}</h1>
                            </div>

                            <div className="restoreemail_content">
                                <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.restorepasswordsent.sent.en : "Sent!"}</h1>
                                <p>
                                {dynamic_content.static_text.restorepasswordsent ? ReactHtmlParser(dynamic_content.static_text.restorepasswordsent.message.en) : ""}
                             </p>
                            </div>

                            <div className="restoreemail_link">
                                {/* <Button size="medium" color="primary">
                               Open Email App
                            </Button> */}
                            </div>
                        </div>
                    }
                  </div>  
                </div>
            </div>
        );
    }
}