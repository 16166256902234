import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from './loader-ball-circle.json'; 

class Index extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className='lottieLoader'>
        <Lottie options={defaultOptions} />
      </div>
    );
  }
}

export default Index;
