import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import Swiper from 'react-id-swiper';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import { TimelineMax, Back, Sine } from "gsap/all"
import CSSPlugin from 'gsap/CSSPlugin';
// import Footer from '../../Screens/innerpages/footer';
import ReactHtmlParser, {
    // processNodes,
    // convertNodeToElement,
    // htmlparser2
} from "react-html-parser";
// const C = CSSPlugin;


class Boarding extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            next1: true,
            activeIndex: 0,
            show_scroll_button: true,
            next2: false,
            next3: false,
            skip: false,
            dynamic_content:""
        }
        this.goNext = this.goNext.bind(this)
        this.goPrev = this.goPrev.bind(this)
        this.swiper = null
    }

    componentDidMount() {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            this.setState({dynamic_content: dynamic_content})

        }

        this.Boarding1();
        let action = "User landed on no network page"
        let image  = 'network_landing_page.png' 
        functions.logUserActivity(action, image);
    }

    handleNext = () => {

        if (this.state.activeIndex === 2) {
            this.setState({ skip: true })
            // functions.pushToRoute(this.props.props, 'noconnection', 'boarding')
        }
        else if (this.state.activeIndex < 2) {
            this.goNext();
        }
    }


    goNext() {
        let action = "User clicked on next button on no network page"
        let image  = 'next_button_network_page.png'
        functions.logUserActivity(action, image);
        if (this.state.activeIndex === 0) {
            this.setState({ activeIndex: this.state.activeIndex + 1 })
            this.Boarding2()
        }
        if (this.state.activeIndex === 1) {
            this.setState({ activeIndex: this.state.activeIndex + 1 })
            this.setState({ show_scroll_button: false });
            this.Boarding3()
        }
    }

    goPrev() {
        let action = "User clicked on previous button on no network page"
        let image  = 'previous_button_network_page.png' 
        functions.logUserActivity(action, image);
        if (this.state.activeIndex === 2) {
            this.Boarding2()
        }
        if (this.state.activeIndex === 1) {
            this.Boarding1()
        }
        this.setState({ activeIndex: this.state.activeIndex - 1 })
    }

    Boarding1 = () => {
        var timeline = new TimelineMax();
        timeline.fromTo('.boarding_inner', 0.4, { "transform": "scaleY(0)" }, { "transform": "scaleY(1)" })
            .fromTo('.pink_underline', 0.4, { width: "10px" }, { width: "30px" }, 0)
            .fromTo('.boarding_cup', 0.4, { rotation: -30, y: 0, opacity: 1 }, { rotation: 0, ease: Sine.easeOut, y: -30 }, "-=0.2")
            .fromTo('.boarding_cup', 0.2, { y: -30 }, { ease: Sine.easeOut, y: 0 }, "-= 0.2")
            .fromTo('.pink_underline', 0.2, { width: "30px" }, { width: "10px" }, "-=0.2")
            .fromTo('.heart_icon img', 0.4, { width: "0px", height: "auto", opacity: 1 }, { width: "34px" }, "0.2")
            .fromTo('.rb_dot,.lb_dot', 0.4, { "width": "15px", opacity: 0 }, { "width": "0px", opacity: 1 })
            .fromTo('.lt_dot,.rt_dot', 0.4, { "width": "10px", opacity: 0 }, { "width": "0px", opacity: 1 }, "-=0.4")
    }


    Boarding2 = () => {
        var timeline = new TimelineMax();
        timeline.fromTo('.boarding_inner', 0.4, { "transform": "scaleY(0)" }, { "transform": "scaleY(1)" })
            .add("gap", "-=0.4")
            .fromTo('.usericon_1', 0.2, { opacity: 0 }, { opacity: 1 })
            .fromTo('.usericon_2', 0.4, { opacity: 0, x: 20 }, { x: 0, opacity: 1 })
            .fromTo('.plus_icon img', 0.4, { width: "0px", height: "auto", opacity: 1 }, { width: "34px" }, "-=0.4")
    }

    Boarding3 = () => {
        var timeline = new TimelineMax();
        timeline.fromTo('.getmore_l2', 0.4, { "transform": "scaleY(0)", opacity: 1 }, { "transform": "scaleY(1)" })
            .fromTo('.getmore_l1', 0.4, { "transform": "scaleX(0)", opacity: 1 }, { "transform": "scaleX(1)", ease: Back.easeOut.config(1.7) }, "-=0.2")
            .fromTo('.getmore_l3', 0.4, { opacity: 0 }, { opacity: 1 })
            .fromTo('.getmore_l3 img', 0.4, { width: "0px", height: "auto", opacity: 1 }, { width: "40px", ease: Back.easeOut.config(1.7) }, "-=0.4")
            .fromTo(".boarding_dot_particle1, .boarding_dot_particle3, .boarding_dot_particle6, .boarding_dot_particle8", 1,
                {
                    width: "20px", opacity: 1, display: "none"
                },
                {
                    width: "0px", opacity: 0, display: "block"
                }, "-=0.2")

            .fromTo(".boarding_dot_particle2, .boarding_dot_particle4, .boarding_dot_particle5, .boarding_dot_particle7", 1,
                {
                    width: "12px", opacity: 1, display: "none"
                },
                {
                    width: "0px", opacity: 0, display: "block"
                }, "-=1")
    }

    handlerefresh() {
        let action = "User clicked on refresh button on no network page"
        let image = 'refresh_button_network_page.png'
        functions.logUserActivity(action, image);
        window.location.reload()
    }

    render() {
        const { dynamic_content } = this.state
        const params = {
            slidesPerView: 1,
            spaceBetween: 35,
            centeredSlides: true,
            //slidesPerView: 'auto',
            //loop: true,
            //loopFillGroupWithBlank: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                // clickable: true,
                // renderBullet: (index, className) => {
                //     return '<span class="' + className + '">' + (index + 1) + '</span>';
                // },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            allowSlidePrev: true,
            allowSlideNext: true
        }

        return (
            <div className="container">
                {!this.state.skip && dynamic_content !="" &&
                    <div className="row">
                        <div className="common_main">
                            <div className="custom_swiper">
                                <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
                                    {/* {this.state.next1 && */}


                                    <div className="slide_one">
                                        <div onClick={() => { functions.pushToRoute(this.props.props, 'noconnection', 'boarding') }} className="bording_clearlink">
                                        {dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.skip ? dynamic_content.static_text.boarding.skip.en : "Skip"}
                                            </div>
                                        <div className="bording_up">
                                            {/* ../../assets/images/logo2.png */}
                                            <img src={require('../../assets/images/logo2.png')} alt="" title="" />
                                        </div>
                                        <div className="boarding">
                                            <div className="boarding_inner">
                                                <img className="boarding_cup" src={require('../../assets/images/cup.png')} alt="" title="" />
                                                <p className="pink_underline">
                                                    <span className="lt_dot op-0"></span>
                                                    <span className="rt_dot op-0"></span>
                                                    <span className="lb_dot op-0"></span>
                                                    <span className="rb_dot op-0"></span>
                                                </p>
                                                <a className="heart_icon" ><img src={require('../../assets/images/Group229.png')} alt="" title="" /></a>
                                            </div>
                                        </div>
                                        <div className="boarding_content">
                                            <h3>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.family_plarform ? dynamic_content.static_text.boarding.family_plarform.en : "Family Plarform"} </h3>
                                            <p>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.check_internet ? dynamic_content.static_text.boarding.check_internet.en : "Check your internet connection and try again."}</p>
                                        </div>

                                    </div>
                                    {/* } */}

                                    {/* {this.state.next2 && */}

                                    <div className="slide_two">
                                        <div onClick={() => { functions.pushToRoute(this.props.props, 'noconnection', 'boarding') }} className="bording_clearlink">{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.skip ? dynamic_content.static_text.boarding.skip.en : "Skip"}</div>
                                        <div onClick={this.goPrev} className="bording_backlink swiper-button-prev"><img src={require('../../assets/images/Group147.png')} alt="" title="" /></div>
                                        <div className="bording_up">
                                            <img src={require('../../assets/images/logo2.png')} alt="" title="" />
                                        </div>

                                        <div className="boarding">
                                            <div className="boarding_inner">
                                                <img className="usericon_1" src={require('../../assets/images/11.png')} alt="" title="" />
                                                <img className="usericon_2" src={require('../../assets/images/22.png')} alt="" title="" />
                                                <a className="plus_icon"><img src={require('../../assets/images/Group163.png')} alt="" title="" /></a>
                                            </div>
                                        </div>
                                        <div className="boarding_content">
                                            <h3>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.share_with_friend ? ReactHtmlParser(dynamic_content.static_text.boarding.share_with_friend.en) : "Share with Your<br /> Friends and Family"} </h3>
                                            <p>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.check_internet ? dynamic_content.static_text.boarding.check_internet.en : "Check your internet connection and try again."}</p>
                                        </div>
                                        <div className="boarding_submit" style={{ display: "none" }}>
                                            <Button size="medium" color="primary" className="swiper-button-next" onClick={this.goNext}>
                                            {dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.next ? dynamic_content.static_text.boarding.next.en : "Next"}
                                        </Button>
                                        </div>
                                    </div>

                                    {/* }
                                {this.state.next3 && */}


                                    <div className="slide_three">
                                        <div onClick={() => { functions.pushToRoute(this.props.props, 'noconnection', 'boarding') }} className="bording_clearlink">{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.skip ? dynamic_content.static_text.boarding.skip.en : "Skip"}</div>
                                        <div onClick={this.goPrev} className="bording_backlink swiper-button-prev"><img src={require('../../assets/images/Group147.png')} alt="" title="" /></div>
                                        <div className="bording_up">
                                            <img src={require('../../assets/images/logo2.png')} alt="" title="" />
                                        </div>

                                        <div className="boarding">
                                            <div className="rel_div">
                                                <div className="getmore_l1"></div>
                                                <div className="getmore_l2">
                                                    <div className="rel_block star_contain">
                                                        <div className="boarding_dot_particle1"></div>
                                                        <div className="boarding_dot_particle2"></div>
                                                        <div className="boarding_dot_particle3"></div>
                                                        <div className="boarding_dot_particle4"></div>
                                                        <div className="boarding_dot_particle5"></div>
                                                        <div className="boarding_dot_particle6"></div>
                                                        <div className="boarding_dot_particle7"></div>
                                                        <div className="boarding_dot_particle8"></div>
                                                    </div>
                                                </div>
                                                <div className="getmore_l3">
                                                    <img src={require('../../assets/images/Star.png')} alt="" title="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="boarding_content">
                                            <h3>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.come_back_and_get ? dynamic_content.static_text.boarding.come_back_and_get.en : "Come Back and Get More"}</h3>
                                            <p>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.check_internet ? dynamic_content.static_text.boarding.check_internet.en : "Check your internet connection and try again."}</p>
                                        </div>
                                        <div className="boarding_submit" style={{ display: "none" }}>
                                            <Button size="medium" color="primary" className="last" onClick={() => { functions.pushToRoute(this.props.props, 'noconnection', 'boarding') }}>
                                            {dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.next ? dynamic_content.static_text.boarding.next.en : "Next"}
                                        </Button>
                                        </div>
                                    </div>

                                    {/* } */}
                                </Swiper>
                                {/* {this.state.show_scroll_button && */}
                                <div className="boarding_submit">
                                    <Button size="medium" color="primary" className="swiper-button-next" onClick={() => { this.handleNext() }}>
                                    {dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.next ? dynamic_content.static_text.boarding.next.en : "Next"}
                                    </Button>
                                </div>
                                {/* } */}
                                {!this.state.show_scroll_button &&
                                    <div className="boarding_submit2">
                                        <Button size="medium" color="primary" onClick={() => { this.handleNext() }}>
                                        {dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.next ? dynamic_content.static_text.boarding.next.en : "Next"}
                                        </Button>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                }
                {this.state.skip && dynamic_content !="" &&
                    <div className="row">
                        <div className="common_main">

                            {/* Head bar */}
                            <div className="discover_head">
                                <div className="discover_headLeft"><label>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.discover ? dynamic_content.static_text.boarding.discover.en : "Discover"}</label></div>
                                <div className="discover_headRght"><img src={url.family_one_image_url + 'Group159.png'} alt="" title="" /></div>
                            </div>
                            {/* Head bar */}

                            <div className="nocontion">
                                <div className="nocontion_inner">
                                    <img src={require('../../assets/images/Group228.png')} alt="" title="" />
                                </div>
                            </div>
                            <div className="nocontion_content">
                                <h3>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.no_connection ? dynamic_content.static_text.boarding.no_connection.en : "No connection"}</h3>
                                <p>{dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.check_internet ? dynamic_content.static_text.boarding.check_internet.en : "Check your internet connection and try again."}</p>
                            </div>
                            <div className="nocontion_submit">
                                <Button size="medium" color="primary" onClick={() => { this.handlerefresh() }}>
                                {dynamic_content.static_text.boarding && dynamic_content.static_text.boarding.refresh ? dynamic_content.static_text.boarding.refresh.en : "Refresh"}
                                </Button>
                            </div>

                            {/* Site menu */}
                            {/* <Footer props={this.props} /> */}
                            {/*End of Site menu */}

                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default Boarding;

