import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import cookie from 'react-cookies';
// import { isMobile } from 'react-device-detect';
import { browserName, isMobile, CustomView, isAndroid, isIOS } from 'react-device-detect';
// import { Offline, Online } from "react-detect-offline";
// import OfflineComponent from '../boarding/index';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import LottieLoader from '../LottieLoader';
import $ from "jquery";
import axios from 'axios';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import { getFacebookLoginStatus } from "./../../utils/FacebookSDK";
var base64 = require('base-64');
class Home extends React.Component {

    constructor(props) {
        let search = window.location.search;
        super(props);
        this.scrollToTopRef = React.createRef();

        this.state = {
            loader: false,
            dynamic_content: "",
            migrate_note: false,
            search: search && search.substring(0, 6) == "?code=" ? true : false,
            show_ftm_home: localStorage.getItem("ftm_home") && localStorage.getItem("ftm_home") == "true" ? true : false,
            fb_button: true,
            isUIReady: false,
            fb_value: false
        }

    }

    responseFailFacebook = (error) => {
        alert(error)
        let self = this;
        self.setState({ loader: false })
        let action = "User got error response from Facebook on home page"
        let image = 'facebook_error_response.png';
        functions.logUserActivity(action, image);
        functions.logUserErrors(error)
    }

    handleClickLoginSingup = (value) => {
        let action = "User clicked on " + value + " on home page"
        let image = 'user_clicked_on_' + value + '.png'
        functions.logUserActivity(action, image);

        if (value == "signup") {
            let element = document.getElementById("signup_button")
            element.click();
        } else if (value == "login") {
            let element = document.getElementById("login_button")
            element.click();
        }
        $('html, body').css('overflow', 'initial');
        functions.pushToRoute(this, value, 'home');
    }

    componentWillMount = () => {
        let self = this;
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        // let irclickid = cookie.load("irclickid") ? cookie.load("irclickid") : ""
        // if (irclickid) {
        //     cookie.save("provider", "impact", { path: "/" });
        // }
        let fb_value = localStorage.getItem("fb_value") ? localStorage.getItem("fb_value") !== "false" ? true : false : false;
        self.setState({fb_value})
        
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        else {
            // functions.pushToRoute(this, '', 'home');
        }

        functions.getFbSetting(self, function (json_response) {
            let response = JSON.parse(json_response)
            if (response && response.data && response.data) {
                localStorage.setItem("fb_value",response.data.fb_value);
                self.setState({ fb_value: response.data.fb_value !== "false" ? true : false })
            }
        })
    }

    scrollToTop = () => {
        // Debugging: Check if ref is set and is a valid element
        console.log('scrollToTopRef.current:', this.scrollToTopRef.current);

        if (this.scrollToTopRef.current) {
            try {
                this.scrollToTopRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            } catch (error) {
                console.error('Error scrolling into view:', error);
            }
        } else {
            console.warn('scrollToTopRef.current is null or undefined');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
            // Perform scroll to top action
            this.scrollToTop();
        }
    }

    componentDidMount = () => {
        let self = this;
        

        let action = "User landed on home page"
        let image = 'homepage_landing.png';
        functions.logUserActivity(action, image);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        // console.log("auto_id", auto_id)
        let back_on = localStorage.getItem("back_on") && localStorage.getItem("back_on") == "create_password" ? "create_password" : ""
        if (auto_id && auto_id != "" && back_on == "") {
            functions.getLandingPage(this);
        } else {
            localStorage.removeItem("back_on")
        }
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
        // console.log("session_id", session_id)
        if (session_id != "null") {
            session.updatePageSession(session_id, "home")
        }
        let element = document.getElementById("home_view")
        // console.log("element", element)
        element.click();
        this.setState({ isUIReady: true });
        let hashpath = window.location.hash;

        if (hashpath.length) {
            let temp_path = hashpath.trim();
            let path = temp_path.split("=");

            if (path[0] == "#access_token") {
                let temp_token = path[1].trim();
                let token_rp = temp_token.split("&");
                if (token_rp.length) {
                    self.setState({ loader: true });
                    axios
                        .request({
                            url: `https://graph.facebook.com/me?fields=email,id,name,picture&access_token=` + token_rp[0],
                            method: "GET"
                        })
                        .then(function (repFb) {
                            // console.log("repFb", repFb)
                            let response = repFb.data;
                            // console.log("response", response);
                            if (response.email) {
                                let action = "User got success response from Facebook on home page"
                                let image = 'facebook_success_response.png';
                                functions.logUserActivity(action, image);

                                let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
                                let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
                                let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
                                let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
                                let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
                                let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
                                let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
                                let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                                let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
                                localStorage.clear();
                                localStorage.setItem("user_signup_process", user_signup_process)
                                localStorage.setItem("session_entered_id", session_entered_id)
                                localStorage.setItem("perks_or_main", perks_or_main)
                                localStorage.setItem("previous_state", previous_state)
                                localStorage.setItem("current_state", current_state)
                                localStorage.setItem("program", JSON.stringify(program))
                                localStorage.setItem("program_list", JSON.stringify(program_list))
                                localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                                localStorage.setItem("api_auth", api_auth)
                                let email = response.email;
                                cookie.save("provider", "facebook", { path: "/" });
                                cookie.save("facebook_id", response.id, { path: "/" });
                                localStorage.setItem("given_name", response.name.split(" ")[0]);
                                localStorage.setItem("picture", response.picture.data.url)
                                localStorage.setItem("family_name", response.name.split(" ")[1])
                                localStorage.setItem("email", response.email)


                                setTimeout(() => {
                                    self.checkUserExistence(email, "facebook")
                                }, 100);
                            }
                            else {
                                self.setState({ loader: false })
                                let action = "User got error response from Facebook on home page"
                                let image = 'facebook_error_response.png';
                                functions.logUserActivity(action, image);
                            }
                        })
                }
            }
        }
    }


    checkUserExistence = (email, provider) => {
        let self = this;
        let finaljson = {
            email: email
        }
        functions.CheckUserExistenceInOldNewSytsem(this, finaljson, function (json_response) {
            let response = JSON.parse(json_response)
            // console.log("check_user_existence response", response)
            if (response && response.data && response.data.user_exist_in_old_system == 1) {
                // console.log("call migrate function")
                self.migrateUser(email, provider)
            } else if (response?.data?.success == 0) {
                functions.pushToRoute(self, 'signup', 'home')
            } else if (response?.data?.success == 1 && response?.data?.message == "User Already Exist") {
                functions.getUserData(self, email, provider);
            }
        })
    }

    migrateUser = (email, provider) => {
        let self = this
        let migration_uri = 'https://request.family.one/request/cron_makeSync.php';
        let dataset = {
            "email": email,
            sync: 0
        }
        self.setState({
            migrate_note: true
        })
        axios
            .request({
                url: migration_uri,
                data: JSON.stringify(dataset),
                method: "post"
            })
            .then(function (response) {
                // console.log("getUserData response", response);
                functions.getUserData(self, email, provider);
            });
    }

    responseFacebook = (response) => {
        // functions.sendEmail(JSON.stringify(response))
        let self = this;
        self.setState({ loader: true })
        if (response.email) {
            let action = "User got success response from Facebook on home page"
            let image = 'facebook_success_response.png';
            functions.logUserActivity(action, image);

            let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
            let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
            let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
            let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
            let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
            let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
            let perk_response = localStorage.getItem("perk_response") ? JSON.parse(localStorage.getItem("perk_response")) : "";
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
            localStorage.clear();
            localStorage.setItem("user_signup_process", user_signup_process)
            localStorage.setItem("session_entered_id", session_entered_id)
            localStorage.setItem("perks_or_main", perks_or_main)
            localStorage.setItem("previous_state", previous_state)
            localStorage.setItem("current_state", current_state)
            localStorage.setItem("program", JSON.stringify(program))
            localStorage.setItem("program_list", JSON.stringify(program_list))
            localStorage.setItem("perk_response", JSON.stringify(perk_response))
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
            localStorage.setItem("api_auth", api_auth)
            let email = response.email;
            cookie.save("provider", "facebook", { path: "/" });
            cookie.save("facebook_id", response.id, { path: "/" });
            localStorage.setItem("given_name", response.name.split(" ")[0]);
            localStorage.setItem("picture", response.picture.data.url)
            localStorage.setItem("family_name", response.name.split(" ")[1])
            localStorage.setItem("email", response.email)


            self.checkUserExistence(email, "facebook")
        }
        else {
            self.setState({ loader: false })
            let action = "User got error response from Facebook on home page"
            let image = 'facebook_error_response.png';
            functions.logUserActivity(action, image);
            functions.logUserErrors(response)
        }
    }

    FBInit = () => {
        let self = this;
        self.setState({ loader: true })
        getFacebookLoginStatus().then((response1) => {
            // console.log("response", response1)
            if (response1 == null || response1.status !== "connected" || !response1.authResponse) {
                window.location = encodeURI(`https://www.facebook.com/dialog/oauth?client_id=231822734692164&redirect_uri=${url.family_one_url}&response_type=token&scope=email`);
            } else {
                if (response1.authResponse) {
                    axios
                        .request({
                            url: `https://graph.facebook.com/${response1.authResponse.userID}?fields=id,name,email,picture&access_token=` + response1.authResponse.accessToken,
                            method: "GET"
                        })
                        .then(function (repFB) {
                            // console.log("repFB", repFB);

                            self.setState({ loader: true });
                            let response = repFB.data;
                            if (response.email) {
                                let action = "User got success response from Facebook on home page"
                                let image = 'facebook_success_response.png';
                                functions.logUserActivity(action, image);

                                let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
                                let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
                                let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
                                let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
                                let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
                                let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
                                let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
                                let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                                let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
                                localStorage.clear();
                                localStorage.setItem("user_signup_process", user_signup_process)
                                localStorage.setItem("session_entered_id", session_entered_id)
                                localStorage.setItem("perks_or_main", perks_or_main)
                                localStorage.setItem("previous_state", previous_state)
                                localStorage.setItem("current_state", current_state)
                                localStorage.setItem("program", JSON.stringify(program))
                                localStorage.setItem("program_list", JSON.stringify(program_list))
                                localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                                localStorage.setItem("api_auth", api_auth)
                                let email = response.email;
                                cookie.save("provider", "facebook", { path: "/" });
                                cookie.save("facebook_id", response1.authResponse.userID, { path: "/" });
                                localStorage.setItem("given_name", response.name.split(" ")[0]);
                                localStorage.setItem("picture", response.picture.data.url)
                                localStorage.setItem("family_name", response.name.split(" ")[1])
                                localStorage.setItem("email", response.email)
                                self.checkUserExistence(email, "facebook")
                            }
                            else {
                                // self.setState({ loader: false })
                                // let action = "User got error response from Facebook on home page"
                                // let image = 'facebook_error_response.png';
                                // functions.logUserActivity(action, image);

                                window.location = encodeURI(`https://www.facebook.com/dialog/oauth?client_id=231822734692164&redirect_uri=${url.family_one_url}&response_type=token&scope=email`);
                            }

                        })
                        .catch(error => {
                            console.log("error", error)
                            self.setState({ loader: false })
                            let action = "User got error response from Facebook on home page"
                            let image = 'facebook_error_response.png';
                            functions.logUserActivity(action, image);
                        });
                }
                // })
            }
        }).catch(error => {
            console.log("error", error)
            self.setState({ loader: false })
            let action = "User got error response from Facebook on home page"
            let image = 'facebook_error_response.png';
            functions.logUserActivity(action, image);
        });
        // })
    }

    responseGoogle = (response) => {
        // console.log("response", response)
        let self = this;
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
        let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
        let perk_response = localStorage.getItem("perk_response") ? JSON.parse(localStorage.getItem("perk_response")) : "";
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
        let user_signup_process = localStorage.getItem("user_signup_process") ? localStorage.getItem("user_signup_process") : "";
        localStorage.clear();
        localStorage.setItem("user_signup_process", user_signup_process)
        localStorage.setItem("perks_or_main", perks_or_main)
        localStorage.setItem("session_entered_id", session_entered_id)
        localStorage.setItem("previous_state", previous_state)
        localStorage.setItem("current_state", current_state)
        localStorage.setItem("program", JSON.stringify(program))
        localStorage.setItem("api_auth", api_auth)
        localStorage.setItem("program_list", JSON.stringify(program_list))
        localStorage.setItem("perk_response", JSON.stringify(perk_response))

        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))


        if (response.profileObj) {
            // console.log("response.profileObj", response.profileObj)
            let action = "User got success response from Google on home page"
            // functions.logUserActivity(action);
            let email = response.profileObj.email;
            cookie.save("provider", "google", { path: "/" });
            cookie.save("google_id", response.profileObj.googleId, { path: "/" });
            localStorage.setItem("given_name", response.profileObj.givenName)
            localStorage.setItem("picture", response.profileObj.imageUrl)
            localStorage.setItem("family_name", response.profileObj.familyName)
            localStorage.setItem("email", response.profileObj.email)
            self.setState({ loader: true })
            self.checkUserExistence(email, "google")
        }
        else {
            let action = "User got error response from Google on home page"
            // functions.logUserActivity(action);
        }
    }

    handleClick = (value) => {
        let action = "User clicked on " + value + " on home page"
        let image = 'user_clicked_on_' + value + '.png'
        functions.logUserActivity(action, image);
        if (value == "facebook") {
            let element = document.getElementById("facebook_button")
            // console.log("element", element)
            element.click();
        }
    }

    render() {
        const { dynamic_content, show_ftm_home, fb_button, fb_value } = this.state
        return (
            <div>
                {!show_ftm_home &&
                    <div ref={this.scrollToTopRef} className="container">
                        {/* <Online> */}
                        <div id="home_view"></div>
                        <div id="facebook_button"></div>
                        <div id="login_button"></div>
                        <div id="signup_button"></div>
                        <div className="row">
                        <div className="common_mainAddOn">
                    <div className="common_main common_mainCstm">
                                {this.state.loader &&
                                    <div className="loader_div">
                                        <center>
                                        <LottieLoader />
                                        </center>
                                        {this.state.migrate_note &&
                                            <div style={{ marginTop: "20px" }}>
                                                <p className="migrate_note">Welcome to the new family.one</p>
                                                <p className="migrate_note">It may take up to 10 seconds to migrate your profile.</p>
                                                <p className="migrate_note">We'll email you a login link when migration is complete.</p>
                                            </div>
                                        }
                                    </div>
                                }
                                {!this.state.loader &&
                                    <div className='loginSocialArea'>
                                        <div className="welcmStup">
                                            <div className="welcmStupBg">
                                            <img src={require('../../assets/images/purpalLine.svg')} alt="" title="" className='signUpPurpalBg' />
                                                <div className="logo_upr">
                                                    <div className="welLogin">
                                                        <label>
                                                            {dynamic_content ? dynamic_content.static_text.home.welcome_to.en : "Welcome to"}
                                                        </label>
                                                    </div>
                                                    <div><img src={require('../../assets/images/familyOneNewLogo.svg')} alt="" title="" /></div>
                                                </div>
                                                <div className="membrsProv">
                                                    {dynamic_content ? ReactHtmlParser(dynamic_content.static_text.home.community_for_families.en) : ""}
                                                </div>
                                                <div className="signupHere">
                                                    <img src={require('../../assets/images/ARROWBOX.svg')} alt="" title="" />
                                                </div>
                                            </div>
                                            <img src={require('../../assets/images/yellowDot2.svg')} alt="" title="" className='outerDotBg' />
                                        </div>

                                        <div className="signupConectCircle">
                                            <div className="signupConect">
                                                <div className={browserName == "Mobile Safari" ? "signupConectIner safari-signupConectIner" : "signupConectIner"}>
                                                    {fb_value && browserName !== "Facebook" && <div className="loginFB">
                                                        {browserName == "Mobile Safari" && isIOS ?
                                                            // <FacebookLogin
                                                            //     cssClass="facebook"
                                                            //     appId="231822734692164"
                                                            //     isMobile={false}
                                                            //     autoLoad={false}
                                                            //     onClick={() => { this.handleClick('facebook') }}
                                                            //     redirectUri={url.family_one_url}
                                                            //     disableMobileRedirect={true}
                                                            //     fields="name,email,picture"
                                                            //     callback={this.FBInit}
                                                            //     icon="fa-facebook"
                                                            //     textButton={dynamic_content ? dynamic_content.static_text.home.login_with_facebook.en : "Login with Facebook"}
                                                            // /> 
                                                            <div className="loginFB"> <button className='facebook' onClick={this.FBInit}>{dynamic_content ? dynamic_content.static_text.home.login_with_facebook.en : "Login with Facebook"}</button></div>
                                                            :
                                                            <FacebookLogin
                                                                cssClass="facebook"
                                                                appId="231822734692164"
                                                                isMobile={isMobile ? true : false}
                                                                autoLoad={false}
                                                                onClick={() => { this.handleClick('facebook') }}
                                                                redirectUri={url.family_one_url}
                                                                fields="name,email,picture"
                                                                callback={this.responseFacebook}
                                                                icon="fa-facebook"
                                                                textButton={dynamic_content ? dynamic_content.static_text.home.login_with_facebook.en : "Login with Facebook"}
                                                            />
                                                        }
                                                    </div>}
                                                    <div className="signup_opt">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleClickLoginSingup('signup') }}>
                                                            {dynamic_content ? dynamic_content.static_text.home.sign_up.en : "Sign Up"}
                                                        </Button>
                                                    </div>
                                                    <div className="login_opt">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleClickLoginSingup('login') }}>
                                                            {dynamic_content ? dynamic_content.static_text.home.log_in.en : "Log In"}
                                                        </Button>
                                                    </div>


                                                    <div className="social_icon">
                                                        <p>{dynamic_content ? dynamic_content.static_text.home.enter_via.en : "or enter using"}</p>
                                                        <div>
                                                            <div className="socialLoginBtns">
                                                                {/* <a href="#"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" className="twiterLgin" /></a> */}

                                                                <div className="loginGogle">
                                                                    <a onClick={() => { this.handleClick('google') }} className="custom_googleMain">
                                                                        <GoogleLogin
                                                                            clientId="376413996313-invib4050j4vdd0picfh71shq40hdvep.apps.googleusercontent.com"
                                                                            // clientId="630950640067-2joesrmd9s9gdvibnslieu9p5831gqlj.apps.googleusercontent.com"
                                                                            buttonText={dynamic_content ? dynamic_content.static_text.home.login_with_google.en : "Login with Google"}
                                                                            className="google"
                                                                            icon={false}
                                                                            onSuccess={this.responseGoogle}
                                                                            onFailure={this.responseGoogle}
                                                                            cookiePolicy={'single_host_origin'}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                        {/* </Online>
                <Offline>
                    <OfflineComponent />
                </Offline> */}
                    </div>
                }
                {show_ftm_home &&
                    <div className="ftm_container">

                        <header>
                            <nav className="navbar_ftm navbar-expand-lg navbar-light_ftm">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <a className="navbar-brand" href="#">
                                                <img src={require("../../assets/images/ftm1.png")} className="img-fluid logo" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </header>
                        {/* <Online> */}
                        <div id="home_view"></div>
                        <div id="facebook_button"></div>
                        <div id="login_button"></div>
                        <div id="signup_button"></div>
                        <section id="login-sec_ftm">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="login-card_ftm text-center">
                                            <h2 className="title_ftm justify-content-center d-flex align-items-center gap-10 pb-10">
                                                <font>Welcome to</font>
                                                <img src="https://first.time.mom/assets/img/family-img.png" className="img-fluid family-img_ftm" alt="" />
                                            </h2>
                                            <h5 className="sb-title_ftm pb-20">The largest online network for families in Canada!</h5>
                                            <p className="desc">
                                                Dedicated to providing families with freebies. giveaways, special offers, packages, and more...
                                            </p>
                                            <ul>
                                                {/* {browserName !== "Facebook" && <li>
                                                    <div className="homeFB_ftm">
                                                        <div className="homeFBIner_ftm">
                                                            <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.86264 24.1505V13.705H0.521484V9.44279H3.86264V5.80229C3.86264 2.94155 5.83627 0.314453 10.3839 0.314453C12.2252 0.314453 13.5868 0.479826 13.5868 0.479826L13.4795 4.46003C13.4795 4.46003 12.0909 4.44736 10.5757 4.44736C8.93571 4.44736 8.67297 5.1554 8.67297 6.33057V9.44279H13.6098L13.395 13.705H8.67297V24.1505H3.86264Z" fill="white"></path>
                                                            </svg>
                                                            <FacebookLogin
                                                                cssClass="custom-btn_ftm blue"
                                                                appId="231822734692164"
                                                                isMobile={false}
                                                                autoLoad={false}
                                                                onClick={() => { this.handleClick('facebook') }}
                                                                redirectUri={url.family_one_url}
                                                                disableMobileRedirect={true}
                                                                fields="name,email,picture"
                                                                callback={this.responseFacebook}
                                                                textButton="login with facebook"
                                                            />
                                                        </div>
                                                    </div>
                                                </li>} */}
                                                <li>
                                                    <a onClick={() => { this.handleClickLoginSingup('signup') }} className="custom-btn_ftm " >
                                                        Sign up
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="custom-btn_ftm blank_ftm" onClick={() => { this.handleClickLoginSingup('login') }}>
                                                        log in
                                                    </a>
                                                </li>
                                            </ul>
                                            <img src="https://first.time.mom/assets/img/girl-sm.png" className="img-fluid girl-sm_ftm" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </div>
                }
            </div>);
    }
}
export default Home;

