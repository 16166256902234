import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';

import { TimelineLite, TweenLite, TweenMax, CSSPlugin, TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import cookie from 'react-cookies';
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

class maintwo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            program: [],
            dynamic_content: "",
            session_entry: cookie.load("auto_id") ? false : true
        }
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let program_list = this.props.program ? this.props.program : [];
        if (program_list && Object.keys(program_list).length > 0) {
            let program = []
            program.push(program_list)
            this.setState({ program: this.props.program })
        }
        window.scrollTo(0, 0)
        var timeline = new TimelineMax();
        timeline.fromTo('#root', 2, { opacity: "0" }, { opacity: "1" });
    }

    handleClick = (program) => {
        this.setState({ program: program })
        let page = this.state.session_entry == false ? "main" : "main index"
        let camp_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (camp_id != "" && auto_id != "") {
            let finaljson = {
                program_id: program.program_id ? program.program_id : program.auto_id,
                sub_id: auto_id,
                action: "add_view",
                campaign_id: camp_id,
                visited_page: program.program_id ? "program_detail" : "perkdetail"
            }
            functions.saveCampaignView(this, finaljson)
        }
        if (program.program_id && program.program_id != "") {
            let action = "User clicked on program " + program.program_id + " on " + page + " page"
            let image = "user_clicked_program_" + program.program_id + '_' + page + '.png'
            functions.logUserActivity(action, image);
            localStorage.setItem("entered_id", program.program_id)
            localStorage.setItem("show_program_id", program.program_id)
            if ((program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == false) || (program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 && program.recommended == "false")) {
                let button_id = 'pending_button_' + program.program_id
                let button_id_div = document.getElementById(button_id)
                if (button_id_div) {
                    button_id_div.click()
                }
            } else if (program.program_id == "5667" && localStorage.getItem("enter_relative_program_id") == "5667") {
                let button_id = 'clicked_giveaway_' + program.program_id
                let button_id_div = document.getElementById(button_id)
                if (button_id_div) {
                    button_id_div.click()
                }
            }
            if (this.props.usedIn && this.props.usedIn == "home") {
                if (program.program_id && program.program_type !== 'story')
                    functions.pushToRoute(this.props, "session_enter", "main_index")
                else if (program.program_type == 'story')
                    functions.pushToRoute(this.props, "story_detail", "main_index")
                else
                    functions.pushToRoute(this.props, "perks_detail", "main_index")
            }
            else {
                if (this.props.usedIn && this.props.usedIn == "innerpage") {
                    if (program.program_id && program.program_type !== 'story')
                        functions.pushToRoute(this.props, "program_detail", "main_index")
                    else if (program.program_type == 'story')
                        functions.pushToRoute(this.props, "story_detail", "main_index")
                    else
                        functions.pushToRoute(this.props, "perks_detail", "main_index")
                }
                this.props.handleProgramdetailClick()
            }
        }
        else if (program.auto_id && program.auto_id != "") {
            let action = "User clicked on program " + program.auto_id + " on perk page"
            let image = 'user_clicked_' + program.auto_id + '_perk.png'
            functions.logUserActivity(action, image);
            localStorage.setItem("entered_id", program.auto_id)
            if (this.props.usedIn && this.props.usedIn == "home") {
                if (program.program_id && program.program_type !== 'story')
                    functions.pushToRoute(this.props, "session_enter", "main_index")
                else if (program.program_type == 'story')
                    functions.pushToRoute(this.props, "story_detail", "main_index")
                else {
                    functions.pushToRoute(this.props, "perks_detail", "main_index")
                }
            } else {
                if (this.props.usedIn && this.props.usedIn == "innerpage") {
                    localStorage.setItem('detail_page', true);
                    if (program.program_id && program.program_type !== 'story')
                        functions.pushToRoute(this.props, "program_detail", "main_index")
                    else if (program.program_type == 'story')
                        functions.pushToRoute(this.props, "story_detail", "main_index")
                    else
                        functions.pushToRoute(this.props, "perks_detail", "main_index")

                } else if (this.props.usedIn && this.props.usedIn == "perk") {
                    functions.pushToRoute(this.props, "perks_detail", "perks")
                }
                if (this.props.usedIn == "innerpage") this.props.handleProgramdetailClick()
            }

        }
        if (program.perk_image && program.perk_location)
            localStorage.setItem("program_div", "perks_" + program.auto_id)
        else
            localStorage.setItem("program_div", program.program_type + program.program_id)
        localStorage.setItem("activeTab", this.props.activeTab);

    }

    render() {
        const { dynamic_content } = this.state
        const { program, auto_id, activeTab } = this.props
        program.display = false
        if (activeTab == "all" && program.show_in_all) {
            program.display = true
        } else if (program.program_type == activeTab) {
            program.display = true
        } else if (program.auto_id && activeTab == "perks") {
            program.display = true;
            program.is_visible = true;
        }
        if (program?.display || program.is_visible) {
            return (
                <div onClick={() => { this.handleClick(program) }} style={(program?.display == false || program.is_visible == false) ? { display: "none" } : { display: "block" }} id={program.program_id ? program.program_type + program.program_id : "perks_" + program.auto_id} className="sessionCntntDtails program-tile hero-1">
                    <div className="container sessionEntrContainer">
                        {/* {dynamic_content != "" && */}
                        <div className={`row ${program.program_type === "contest" ? "giveAwayCard" : ""} ${program.program_type === "freebie" ? "freebieCard" : ""} ${program.program_type === "story" ? "storyCardInfo" : ""} ${program.perk_image ? 'perkCardInfo' : ''}`}>
                            <div className="common_mainAddOn">
                            <div className={this.props.program.program_type == "survey" ? "survey_section common_main detailspace_rmv" : "common_main detailspace_rmv"}>
                                        
                               {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false && program.program_type === "contest" &&
                                    <a><img src={require('../../assets/images/GiveawaysRight.svg')} className="giveAwayRight" alt='Giveaways' title='Giveaways' /></a>
                                }
                                {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true && program.program_type === "contest" &&
                                    <a><img src={require('../../assets/images/GiveawaysRight.svg')} className="giveAwayRight" alt='Giveaways' title='Giveaways' /></a>
                                }

                                {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false && program.program_type === "freebie" &&
                                    <a><img src={require('../../assets/images/freebieRight.svg')} className="giveAwayRight" alt='freebie' title='freebie' /></a>
                                }
                                {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true && program.program_type === "freebie" &&
                                    <a><img src={require('../../assets/images/freebieRight.svg')} className="giveAwayRight" alt='freebie' title='freebie' /></a>
                                }

                                {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false && program.program_type === "survey" &&
                                    <a><img src={require('../../assets/images/SurveyRight.svg')} className="giveAwayRight" alt='survey' title='survey' /></a>
                                }
                                {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true && program.program_type === "survey" &&
                                    <a><img src={require('../../assets/images/SurveyRight.svg')} className="giveAwayRight" alt='survey' title='survey' /></a>
                                }
                                
                                {program &&
                                    <div className={this.props.program.program_type == "survey" ? "survey_sub_section contests_baby sessionAddOns 1212" : "contests_baby sessionAddOns 1313"} style={{ background: program.is_upgraded == 1 ? program.detail_custom.background : "" }}>
                                        <div className='freeies_babyImg'>
                                            <div className="freebiesImges">
                                            {program.program_image && program.program_image.length > 0 && <div><img src={(program.program_id == '1636' ? url.family_one_image_url + program.program_image : url.cloudflare_image_path + program.program_image + url.varient)} alt="" title="" /></div>}
                                                {program.perk_image && program.perk_image.length > 0 && <div><img src={program.program_id == '1636' ? url.family_one_image_url + program.perk_image : url.cloudflare_image_path + program.perk_image + url.varient} alt="" title="" /></div>}
                                            </div>
                                            {program.logo && program.logo.length > 0 &&
                                                <div className="program_detail_logo main_detail_logo">
                                                    {program.program_image && program.program_type != "review" && program.program_type != "story" && program.logo && program.logo.map((logo1) => (
                                                        <a key={logo1}><img src={program.program_id == '1636' ? url.family_one_image_url + logo1 : url.cloudflare_image_path + logo1 + url.varient} alt="" title="" /></a>
                                                    ))}
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false &&
                                                <div className="white_overlay">
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true &&
                                                <div className="white_overlay">
                                                </div>
                                            }
                                            
                                            
                                            {/* {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false && program.program_type === "contest" &&
                                                <div className="enterConst_btn">
                                                    <a><img src={require('../../assets/images/GiveawaysRight.svg')} className="giveAwayRight" alt='Giveaways' title='Giveaways' /></a>
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true && program.program_type === "contest" &&
                                                <div className="enterConst_btn">
                                                    <a><img src={require('../../assets/images/GiveawaysRight.svg')} className="giveAwayRight" alt='Giveaways' title='Giveaways' /></a>
                                                </div>
                                            }

                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false && program.program_type === "freebie" &&
                                                <div className="enterConst_btn">
                                                    <a><img src={require('../../assets/images/freebieRight.svg')} className="giveAwayRight" alt='freebie' title='freebie' /></a>
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true && program.program_type === "freebie" &&
                                                <div className="enterConst_btn">
                                                    <a><img src={require('../../assets/images/freebieRight.svg')} className="giveAwayRight" alt='freebie' title='freebie' /></a>
                                                </div>
                                            }

                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == false && program.program_type === "survey" &&
                                                <div className="enterConst_btn">
                                                    <a><img src={require('../../assets/images/SurveyRight.svg')} className="giveAwayRight" alt='survey' title='survey' /></a>
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == true && program.program_type === "survey" &&
                                                <div className="enterConst_btn">
                                                    <a><img src={require('../../assets/images/SurveyRight.svg')} className="giveAwayRight" alt='survey' title='survey' /></a>
                                                </div>
                                            } */}


                                            {/* {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.recommended == "true" &&
                                                <div className="pendingContest_btn">
                                                    {program.relative_has_eligible_child == false &&
                                                        <a style={{ "color": "red" }}>Pending Recommend</a>
                                                    }
                                                    {program.relative_has_eligible_child == true &&
                                                        <a>Recommended</a>
                                                    }
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 && program.recommended == "true" &&
                                                <div className="pendingContest_btn">
                                                    {program.relative_has_eligible_child == false &&
                                                        <a style={{ "color": "red" }}>Pending Recommend</a>
                                                    }
                                                    {program.relative_has_eligible_child == true &&
                                                        <a>Recommended</a>
                                                    }
                                                </div>
                                            }
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 && program.recommended == "false" &&
                                                <div className="pendingContest_btn" >
                                                    <a style={{ "color": "red" }}>{dynamic_content && dynamic_content.static_text.landing_page ? dynamic_content.static_text.landing_page.pending_entry.en : "Pending Entry"}</a>
                                                </div>
                                            }
    
                                            {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 && program.partner_id == "1" && program.recommended == "false" && program.relative_eligible == true && program.relative_has_eligible_child == false &&
                                                <div className="pendingContest_btn" >
                                                    <a style={{ "color": "red" }}>{dynamic_content && dynamic_content.static_text.landing_page ? dynamic_content.static_text.landing_page.pending_entry.en : "Pending Entry"}</a>
                                                </div>
                                            } */}

                                        </div>
                                        <div className="details_freebies">
                                            {program.program_image &&
                                                <div className="instant_details instantDetailsRw">
                                                    <a className={`instant_tags ${program.program_type === "contest" ? "giveAwayWrap 8989" : ""}${program.program_type === "freebie" ? "freebieMainWrap" : ""}${program.program_type === "survey" ? "surveyMainWrap" : ""}${program.program_type === "story" ? "storyMainWrap" : ""}`}>
                                                    
                                                    {  program.program_type === "contest" && program.program_id !== "4380" ? (
    <img src={require('../../assets/images/giveAwayCardIcon.svg')} alt="" title="" />
  ) : program.program_id === "4380" ? (
    <img src={require('../../assets/images/surveyIcon.svg')} alt="" title="" />
  ) : null
                                                        }
                                                        {program.program_type == "survey" &&
                                                            <img src={require('../../assets/images/surveyIcon.svg')} alt="" title="" />
                                                        }
                                                        {program.program_type == "freebie" &&
                                                            <img src={require('../../assets/images/FreebieIcon.svg')} alt="" title="" />
                                                        }
                                                        {program.program_type == "story" &&
                                                            <img src={require('../../assets/images/storyIconGrey.svg')} alt="" title="" />
                                                        }
                                                        {program.program_type == "review" &&
                                                            <img src={url.family_one_image_url + 'orange-icon-REVIEW_128.png'} alt="" title="" />
                                                        }

<span className='giveAwayTag 2323'>
  {capitalize(
    program?.program_type === "contest" && program.program_id === "4380"
      ? "Study"
      : program?.program_type === "contest"
      ? "Giveaways"
      : program.program_id === "1636"
      ? "Perks"
      : program?.program_type
  )}
</span>


                                                    </a>
                                                    {program.program_category == 'Instant' &&
                                                        <a className="instant_tags"><img src={url.family_one_image_url + 'Vector3.png'} alt="" title="" />
                                                            <span>{dynamic_content && dynamic_content.static_text.session_enter.instant.en}</span></a>
                                                    }
                                                    {program?.is_lock_program == 1 &&
                                                        <a className="bonusTagNw">
                                                            <img src={require('../../assets/images/BounsStar.svg')} alt="" title="" />
                                                            <span>{dynamic_content && dynamic_content.static_text.session_enter.exclusive.en}</span></a>
                                                    }
                                                    {program.program_type == "contest" && program.has_enter > 0 && program.recommended == "false" &&
                                                        <a className="entered_points entrPointUpdte">
                                                            <label>{program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter}</label>
                                                            <span>{dynamic_content && dynamic_content.static_text.program_detail ? dynamic_content.static_text.program_detail.pts.en : "pts"}</span>
                                                        </a>
                                                    }
                                                </div>
                                            }
                                            {program.perk_image &&
                                                <div className="family_fun rom_collection perksTagWrap 5656">
                                                    {/* <Button size="medium" color="primary" style={{
                                                        background: program.is_upgraded == 1 ? program.card_tab_background : program.active_menu_tab_background,
                                                        color: program.is_upgraded == 1 ? program.card_tab_text_color : program.active_menu_tab_text_color
                                                    }}>

                                                        {program.category}
                                                    </Button> */}

                                                    <div className="perksTagBtn"
                                                        style={{
                                                            background: program.is_upgraded == 1 ? program.card_tab_background : program.active_menu_tab_background,
                                                            color: program.is_upgraded == 1 ? program.card_tab_text_color : program.active_menu_tab_text_color
                                                        }} >
                                                        <img src={require('../../assets/images/perkIcon.png')} alt="" title="" />{program.category}
                                                    </div>

                                                    {auto_id != "" && program.upgrade == 1 && program.is_upgraded == 0 &&
                                                        <Button size="medium" color="primary" className="upgrade_btn"> <img src={require('../../assets/images/Group0201.png')} alt="" title="" /> {dynamic_content && dynamic_content.static_text.landing_page ? dynamic_content.static_text.landing_page.upgrade.en : "Upgrade"} </Button>
                                                    }
                                                    {auto_id != "" && program.is_locked == 1 && program.locked == 1 &&
                                                        <Button size="medium" color="primary" className="unlock_btn"> <img src={require('../../assets/images/Group01190.png')} alt="" title="" /> {dynamic_content && dynamic_content.static_text.landing_page ? dynamic_content.static_text.landing_page.locked.en : "Locked"} </Button>
                                                    }
                                                    {/* {auto_id != "" && program.is_locked == 1 && program.locked == 0 &&
                                                    <Button size="medium" color="primary" className="unlock_btn"> <img src={require('../../assets/images/upgrade-lock-icon-white.png')} alt="" title="" /> Locked </Button>
                                                } */}
                                                    {auto_id != "" && program.is_locked == 1 && program.locked == 0 &&
                                                        <Button size="medium" color="primary" className="unlock_btn"> <img src={require('../../assets/images/upgrade-lock-icon-white.png')} alt="" title="" /> {dynamic_content && dynamic_content.static_text.landing_page ? dynamic_content.static_text.landing_page.unlocked.en : "Unlocked"} </Button>
                                                    }
                                                    {/* new upgrade Button */}
                                                    {auto_id != "" && program.is_upgraded == 1 && program.upgrade == 1 &&
                                                        <Button size="medium" style={{ background: "#f0f0f0" }} className="upgradeRght_btn"><img src={require('../../assets/images/newArows.png')} alt="" title="" />{dynamic_content && dynamic_content.static_text.landing_page ? dynamic_content.static_text.landing_page.upgraded.en : "Upgraded"}</Button>
                                                    }{/* new Button */}
                                                </div>
                                            }
                                            {program.program_image &&
                                                <div className={`freebies_iner program_title01 ${program.program_type === "contest" ? "giveAwayWrapTxt" : ""} ${program.program_type === "freebie" ? "freeBieWrapTxt" : ""}`}>
                                                    <label>{ReactHtmlParser(program.program_title)}</label>
                                                    <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                                </div>
                                            }
                                            {program.perk_image &&
                                                <div className="freebies_iner program_title01">

                                                    <label style={program.is_upgraded == 1 ? { color: program.detail_custom.title_color } : {}}>{program.is_upgraded == 1 ? ReactHtmlParser(program.upgrade_value.title) : ReactHtmlParser(program.title)}</label>
                                                    <p style={program.is_upgraded == 1 ? { color: program.detail_custom.title_color, opacity: "0.5" } : {}}>{ReactHtmlParser(program.subtitle)}</p>
                                                </div>
                                            }
                                        </div>
                                        {/* End of  three in One section */}
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                        {/* } */}
                    </div>
                    {/* Footer fix Button */}
                    {/* {this.state.session_entry &&
                    
                } */}
                    {/*End of Footer fix Button */}
                </div>
            );
        } else {
            return <></>
        }
    }
}
export default maintwo;

