import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import { TimelineLite, TweenLite, TweenMax, CSSPlugin, TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import cookie from 'react-cookies';
import { isMobileSafari } from 'react-device-detect';
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};
class maintwo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            program: [],
            dynamic_content: "",
            fixedAtBottom: false,
            lastScrollTop: 0
        }
    }

    componentDidMount = () => {
        // let action = "User landed on session detail page"
        // functions.logUserActivity(action);
        window.addEventListener('scroll', this.handleScroll);
        setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth' // You can use 'smooth' if you prefer smooth scrolling
            });
          }, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth' // You can use 'smooth' if you prefer smooth scrolling
            });
          }, 0);
        }
      }
    

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillMount = () => {
        let program_list = localStorage.getItem("program_list")?JSON.parse(localStorage.getItem("program_list")):[];
        let entered_id = localStorage.getItem("entered_id")?
                            JSON.parse(JSON.stringify(localStorage.getItem("entered_id"))):    
                                localStorage.getItem('session_entered_id') ? localStorage.getItem('session_entered_id') : "";

        let program_enter = program_list.find(pro=>pro.program_id==entered_id)
        this.setState({program:program_enter})
        if (program_list && Object.keys(program_list).length > 0) {
            let program = []
            program.push(program_enter)
            this.setState({ program: program })
        }
        window.scrollTo(0, 0)
        var timeline = new TimelineMax();
        timeline.fromTo('#root', 2, { opacity: "0" }, { opacity: "1" });

        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        window.removeEventListener('scroll', this.handleScroll);
    }

    formatDate = (value) => {
        let final_date = ""
        if (value && value != "" && typeof value != undefined && value != "undefined") {
            let date_array = value.split("-");
            let year = date_array[0];
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            final_date = date + " " + month_name + " " + year;
        }
        return final_date;
    }

    handleGetStarted = () => {
        let action = "User clicked on Get Started on session detail page"
        let image = 'user_clicked_get_started_session_detail.png'
        functions.logUserActivity(action, image);
        let is_sub_id = 'no';
        let sub_id = is_sub_id == 'yes' ? cookie.load("auto_id") : cookie.load("session_id");
        let program_id = this.state.program && this.state.program[0].program_id;
        let partner_id = this.state.program && this.state.program[0].partner_id;
        let registration_source =  this.state.program && this.state.program[0].registration_source ? this.state.program[0].registration_source : "";
        let point_type = 'entry';
        let self = this;
        let is_direct = 1;
        let program = this.state.program && this.state.program[0] ? this.state.program[0] : {};
        if (program?.is_lock_program == 1) {
        } else {
            if (program?.program_type == "contest" || program?.program_type == "freebie") {
                let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                // functions.contestEntry(this, is_sub_id, sub_id, tracking_id, program_id,partner_id,registration_source, point_type, is_direct, '', function (response_json) {
                //     let response = JSON.parse(response_json);
                //     if (response.status == 200) {

                //         self.setState({ loader: false });
                //     }
                // })

            }
            localStorage.setItem("session_entered_id", program_id)
        }
        functions.pushToRoute(self, 'home')
    }

    handleBackSession = () => {
        let action = "User clicked on back button of " + this.state.program.program_id + " on session detail page"
        let image = 'user_clicked_back_button_of_' + this.state.program.program_id + '_session_detail.png'
        functions.logUserActivity(action, image);
        functions.pushToRoute(this, '', 'session_enter')
    }

    handleScroll = () => {
        const { lastScrollTop, fixedAtBottom } = this.state;
        const currentScrollTop = window.scrollY;
        const scrollToTop = currentScrollTop < lastScrollTop || currentScrollTop === 0;

        // Check if scrolled to the bottom
        if (!fixedAtBottom && scrollToTop) {
            // Add a small delay before updating the state to allow the button to animate smoothly
            setTimeout(() => {
                this.setState({ fixedAtBottom: true });
            }, 50);
        } else if (fixedAtBottom && !scrollToTop) {
            // Check if scrolled to top from bottom
            this.setState({ fixedAtBottom: false });
        }

        this.setState({ lastScrollTop: currentScrollTop });
    };

    render() {
        const { dynamic_content, fixedAtBottom } = this.state
        let programArr = this.state.program;
        console.log('programArr',programArr)
        return (
            <div id={programArr?.length > 0 && programArr[0] != undefined && programArr[0]?.program_type && programArr[0]?.program_id ? programArr[0].program_type + programArr[0].program_id : ''} className={"program-page hero-1 hero_" + programArr?.length > 0 && programArr[0] != undefined && programArr[0].program_id ? programArr[0].program_id : ''}>
                <div className={`row ${programArr?.length && programArr[0] != undefined &&  programArr[0]?.program_type === "contest" ? "giveAwaySession" : ""} ${programArr?.length && programArr[0] != undefined && programArr[0]?.program_type === "freebie" ? "freebieSession" : ""}`}>
                  <div className="common_mainAddOn"> 
                        <div className="common_main detailspace_rmv">
                            {programArr && programArr.length > 0 && programArr.map((program, index) => (

                                <div key={index} className="sessionAddOns">
                                    <div className="freeies_babyImg">
                                        <div className="detailback_section white_circle_div">
                                            <div onClick={() => { this.handleBackSession() }} className="backLinkWidth close_btn">
                                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                            </div>
                                        </div>
                                        <div className="contests_dtlImges" onClick={() => {
                                            localStorage.setItem("entered_id", program?.program_id ?? '')
                                        }}>
                                            <div><img src={program.program_id == '1636' ? url.family_one_image_url + program?.program_image :url.cloudflare_image_path + program?.program_image+url.varient} alt="" title="" /></div>
                                        </div>

                                        <div className="program_detail_logo">
                                            {program?.program_type != "review" && program?.program_type != "story" &&
                                                program?.logo && program.logo.map((logo1) => (
                                                    <a key={logo1}><img src={program.program_id == '1636' ? url.family_one_image_url +logo1 : url.cloudflare_image_path + logo1+url.varient} alt="" title="" /></a>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={isMobileSafari ? "details_freebies height_ios" : "details_freebies"} >
                                        <div className="instant_details instantDetailsSpc 12121">
                                            <a className={`instant_tags 6767 ${program?.program_type === "contest" ? "giveAwayWrap" : ""} ${program?.program_type === "freebie" ? "freebieSesonWrap" : ""} ${program?.program_type === "survey" ? "surveyMainWrap" : ""}`}>
                                            {
  program.program_type === "contest" && program.program_id !== "4380" ? (
    <img src={require('../../assets/images/giveAwayCardIcon.svg')} alt="" title="" />
  ) : program.program_id === "4380" ? (
    <img src={require('../../assets/images/surveyIcon.svg')} alt="" title="" />
  ) : null
}

                                                {program?.program_type == "survey" &&
                                                    <img src={require('../../assets/images/surveyIcon.svg')} alt="" title="" />
                                                }
                                                {program?.program_type == "freebie" &&
                                                    //<img src={url.family_one_image_url + 'orange-icon-FREEBIE_128.png'} alt="" title="" />
                                                    <img src={require('../../assets/images/FreebieIcon.svg')} alt="" title="" />
                                                }
                                                {program?.program_type == "story" &&
                                                    <img src={url.family_one_image_url + 'orange-icon-STORY_128.png'} alt="" title="" />
                                                }
                                                {program?.program_type == "review" &&
                                                    <img src={url.family_one_image_url + 'orange-icon-REVIEW_128.png'} alt="" title="" />
                                                }
                                                <span className='giveAwayTag'>
  {capitalize(
    program?.program_type === "contest" && program.program_id === "4380"
      ? "Study"
      : program?.program_type === "contest"
      ? "Giveaways"
      : program.program_id === "1636"
      ? "Perks"
      : program?.program_type
  )}
</span>

                                            </a>
                                            {program?.program_category == 'Instant' &&
                                                <a className="instant_tags"><img src={url.family_one_image_url + 'Vector3.png'} alt="" title="" />
                                                    <span>{dynamic_content ? dynamic_content.static_text.session_enter.instant.en : "Instant"}</span></a>
                                            }
                                            {program?.is_lock_program == 1 &&
                                                <a className="bonusTagNw">
                                                    <img src={require('../../assets/images/BounsStar.svg')} alt="" title="" />
                                                    <span>{dynamic_content ? dynamic_content.static_text.session_enter.exclusive.en : "Exclusive"}</span>
                                                </a>
                                            }
                                        </div>
                                        <div className="freebies_iner program_title01">
                                            <label>{ReactHtmlParser(program?.program_title)}</label>
                                            <p>{ReactHtmlParser(program?.program_subtitle)}</p>
                                        </div>
                                        <div className="descp_freebies">
                                            <label>{dynamic_content ? dynamic_content.static_text.session_enter.description.en : "Description"}</label>

                                            {ReactHtmlParser(program?.program_description)}
                                        </div>
                                        {program?.hide_expiry_date == "true" ?
                                            <div></div> :
                                            <div className="ends_date session_end_date">
                                                <p> {"Ends " + this.formatDate(program?.expiry_date)} </p>
                                            </div>
                                        }
                                        {/* <div className="ends_date session_end_date">
                                            <p> {"Ends " + this.formatDate(new Date(program.expiry_date))} </p>
                                        </div> */}

                                        <div onClick={() => { this.handleGetStarted() }}>
                                            <div className="homeSession_upr">
                                                {/* <div className="hometwo_hendupr"> */}
                                                <div className="session_hendupr">
                                                    <div className="session_hend">
                                                        <div className='startRocket'><img src={require('../../assets/images/rocketIconNw.png')} alt="" title="" /></div>
                                                        <h3>{dynamic_content ? dynamic_content.static_text.session_enter.get_started.en : "Get Started"}</h3>
                                                        <p>{dynamic_content ? dynamic_content.static_text.session_enter.login_to_redeem.en : "To redeem this offer you need login or sign up"}</p>
                                                    </div>
                                                </div>
                                                <div className="hometwo_redem_session">
                                                    <Button size="medium" color="primary" onClick={() => { this.handleGetStarted() }}>
                                                        {dynamic_content ? dynamic_content.static_text.session_enter.get_started_button.en : "Get Started"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* End of  three in One section */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default maintwo;

