import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import ReactPlayer from 'react-player';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import TermsCondition from '../TermsCondition/index';
import $ from 'jquery';
import Iframe from 'react-iframe'
const C = CSSPlugin;

const CalculateHeight = (width, dimention) => {
    dimention = dimention.split(':');
    let newHeight = (width / dimention[0]) * dimention[1];
    newHeight = parseInt(newHeight);
    return newHeight + 'px';
}
class uploadreviewrequest extends React.Component {
    constructor(props) {
        super(props);
        this.uploadReview = this.uploadReview.bind(this);
        this.state = {
            review_state: '',
            dynamic_content: "",
            session_entry: cookie.load("auto_id") ? false : true,
            loader: false,
            current_program: {},
            show_video: false,
            show_t_and_c: false,
            show_request_review: localStorage.getItem('review_request_sent') && localStorage.getItem('review_request_sent') == 1 ? false : true
        }
    }

    uploadReview = () => {
        let success_action = "User clicked on upload review button on reviewrequest page"
        let image = 'upload_review_button_reviewrequest_page.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this.props, 'uploadimg', 'upload_review')
    }

    insertReviewRequest = () => {
        let success_action = "User clicked on request for review button on reviewrequest page"
        let image = 'request_for_review_button_reviewrequest_page.png'
        functions.logUserActivity(success_action, image);
        let self = this
        this.handleAnimation();
        setTimeout(this.handleInsertReviewRequest(self), 1000);
    }

    handleInsertReviewRequest(self) {
        let self2 = self;
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : ''
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : cookie.load("session_id")
        let program_id = self.state.program_array[0].program_id;
        let program_title = self.state.program_array[0].program_title;
        let username = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        let for_entry = 1;
        if (self.state.program_array[0].review_type == "product_review") {
            functions.insert_request_for_review(this, username, program_title, auto_id, program_id, for_entry, function (json_response) {
                let response = JSON.parse(json_response)
                if (sub_id != '') {

                    if (response.data.success == 1 && Object.keys(response.data.review_data).length > 0) {
                        localStorage.setItem("review_timestamp", response.data.review_data.request_timestmp)
                        localStorage.setItem("review_request_id", response.data.review_data.review_request_id)
                    }
                    if (response.data.success == 1) {

                        // functions.pushToRoute(self, 'home', 'main')

                        if (Object.keys(response.data.review_data).length > 0) {
                            localStorage.setItem("review_timestamp", response.data.review_data.request_timestmp)
                            localStorage.setItem("review_request_id", response.data.review_data.review_request_id)
                        }
                        let TL = new TimelineMax();
                        TL.to('.ht-200', 0.1, { display: "none" })
                            .to('.program_entry_loader', 0.1, { opacity: 0 }, 0)
                        if (response.data.review_data.request_status == "pending") {
                            self2.setState({ review_state: 'pending' })
                        }
                        else if (response.data.review_data.request_status == "rejected") {
                            self2.setState({ review_state: 'rejected' })
                        }
                        else if (response.data.review_data.request_status == "approved") {
                            self2.setState({ review_state: 'approved' })
                        }
                        // functions.pushToRoute(self,'upload_review','reviewrequest');
                    }
                    else if (response.data.success == 0) {
                        self2.setState({ review_state: '' })
                    }
                }
            });
        }

        if (sub_id != '') {
        }
        else {
            localStorage.setItem("session_entered_id", program_id)
            functions.pushToRoute(self.props, 'home', 'session_enter')
        }
    }

    componentWillMount = () => {
        let self = this;
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : '';
        if (auto_id != '') {
            // Set program related info
            let program = this.props.program
            let all_program = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];

            let program_id = this.props.program.program_id;
            let for_entry = 0;
            self.handleReviewClick()
            let program_array = []
            if (program.short_description) {
                let terms = program.short_description
                let new_terms = terms.replace("Terms and Conditions", "<span class='terms_all perks_terms'>Terms and Conditions</span>");
                program.short_description = new_terms
            }
            program_array.push(program)
            localStorage.setItem("review_logo_image", program.program_image);
            this.setState({ program_array: program_array, program_type: program.program_type })
        } else {
            let program = this.props.program
            let program_id = this.props.program.program_id;
            let program_array = []
            program_array.push(program)
            localStorage.setItem("review_logo_image", program.program_image);
            this.setState({ program_array: program_array, program_type: program.program_type })
        }
    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.uploadrqest_redem button', 0.3, { opacity: 1 }, { opacity: 0, y: -40, display: "none" })
            .to('.ht-200', 0.1, { display: "block" }, "-0.15")
            .to('.program_entry_loader', 0.1, { opacity: 1 })
            .to('.uploadrqest_redem button', 0.1, { opacity: 1, y: 0, display: "initial" })
    }

    handleReviewBack = () => {
        let success_action = "User clicked on back button on reviewrequest page"
        let image = 'back_button_reviewrequest_page.png'
        functions.logUserActivity(success_action, image);
        let previous_state = localStorage.getItem("previous_state")

        // localStorage.setItem("review_detail", 'false');

        // if (previous_state != 'main') {
        //     localStorage.setItem("main_load", 'true')
        // }
        // else {
        //     localStorage.setItem("main_load", 'false')
        // }
        // this.props.onReviewBack()
    }

    handleReviewClick = () => {
        let self = this;
        self.setState({ loader: true })
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : '';
        let username = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        let program_id = this.props.program.program_id;
        let program_title = this.props.program.program_title;
        let for_entry = 0;
        // localStorage.setItem("entered_id", this.props.program.program_id)
        if (auto_id != '' && this.props.program.review_type == "product_review") {
            functions.insert_request_for_review(this, username, program_title, auto_id, program_id, for_entry, function (json_response) {
                let response = JSON.parse(json_response)
                if (response.data.success == 1) {
                    if (Object.keys(response.data.review_data).length > 0) {
                        localStorage.setItem("review_timestamp", response.data.review_data.request_timestmp)
                        localStorage.setItem("review_request_id", response.data.review_data.review_request_id)
                    }
                    let review_complete = localStorage.getItem("review_complete") && localStorage.getItem("review_complete") == program_id ? "true" : "false"
                    if (response.data.review_data.request_status == "approved" && (response.data.review_data.user_review || review_complete == "true")) {
                        self.setState({ loader: false, review_state: 'uploaded' })
                    }
                    else if (response.data.review_data.request_status == "pending") {
                        self.setState({ loader: false, review_state: 'pending' })
                    }
                    else if (response.data.review_data.request_status == "rejected") {
                        self.setState({ loader: false, review_state: 'rejected' })
                    }
                    else if (response.data.review_data.request_status == "approved") {
                        self.setState({ loader: false, review_state: 'approved' })
                    }
                    // functions.pushToRoute(self,'upload_review','reviewrequest');
                }
                else if (response.data.success == 0) {
                    self.setState({ loader: false, review_state: '' })
                }
            });
        }
    }

    handleTerms = (program) => {
        let success_action = "User clicked on terms button on reviewrequest page"
        let image = 'terms_button_reviewrequest_page.png'
        functions.logUserActivity(success_action, image);
        this.setState({ current_program: program, show_t_and_c: true });
    }

    handleCloseTerms = () => {
        this.setState({ show_t_and_c: false })
    }

    handleGetStarted = () => {
        let success_action = "User clicked on get started button on reviewrequest page"
        let image = 'get_started_button_reviewrequest_page.png'
        functions.logUserActivity(success_action, image);
        let self = this
        this.handleInsertReviewRequest(self)
    }

    componentDidMount = () => {
        this.handleTermsClick()
    }

    handleTermsClick = () => {
        let self = this;
        $(document).on('click', '.perks_terms', function () {
            let program = self.state.program_array[0]
            self.handleTerms(program)
        })
    }

    handleImageClick = () => {
        this.setState({ show_video: true })
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="program-page hero-1">
                 <div className="common_mainAddOn">
                <div className="common_main detailspace_rmv">

                    {/* start of One section */}

                    {this.state.program_array && this.state.program_array.length > 0 && this.state.program_array.map((program, index) => (
                        <div id={"review_" + program.program_id} key={index} className="reviewListMain">
                            <div className="mois_babyImg">
                                <div className="moisback_section">
                                    <div onClick={() => { this.handleReviewBack() }} className="moisback_secone">
                                        <div className="backLinkWidth close_btn white_circle_div" onClick={() => { this.handleReviewBack() }}><img src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div></div>
                                </div>
                                <div className="freebiesImges">
                                    <img src={url.cloudflare_image_path + program.program_image+url.varient} alt="" title="" />
                                </div>
                                <div className="program_detail_logo">
                                    {/* {program.logo && program.logo.map((logo1) => (
                                        <a key={logo1}><img src={url.image_path + logo1} alt="" title="" /></a>
                                    ))} */}
                                </div>
                            </div>
                            {program.review_type == "product_review" &&
                                <div className="details_mois">
                                    {this.state.review_state != '' && this.state.review_state != 'pending' &&
                                        <div className="mois_details">
                                            <a><img style={{ width: "16px" }} src={url.family_one_image_url + 'Untitled555.png'} alt="" title="" />
                                                <span>Review</span></a>
                                        </div>
                                    }
                                    <div className={(this.state.review_state != '' && this.state.review_state != 'pending') ? "mois_iner program_title01" : "mois_iner mois_inerhght program_title01"}>
                                        <label>{ReactHtmlParser(program.program_title)}</label>
                                        <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                    </div>

                                    <div className="revwDescp_cotain paddingbottom">
                                        <div className="descp_mois">
                                            <label>{dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.description.en : "Description"}</label>
                                            <p>{ReactHtmlParser(program.program_description)}</p>
                                        </div>

                                        <div className="rel_block ht-200">
                                            <div className="program_entry_loader">
                                                <LottieLoader className="loading" />
                                            </div>
                                        </div>
                                        {this.state.loader &&
                                            <center>
                                                <LottieLoader className="loader_class" color="secondary" />
                                            </center>
                                        }
                                        {!this.state.loader && !this.state.session_entry && this.state.review_state == '' &&
                                            <div className="uploadrqest_redem">
                                                <Button size="medium" color="primary" onClick={() => { this.insertReviewRequest() }}>
                                                    {dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.request_for_review.en : "Request for Review"}
                                                </Button>
                                            </div>
                                        }

                                        {!this.state.loader && !this.state.session_entry && this.state.review_state == 'pending' &&
                                            <div className="lend_hend review_gap">
                                                <h3>{dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.request_for_review_sent.en : "Request for Review Sent"}</h3>
                                            </div>
                                        }
                                        {!this.state.loader && !this.state.session_entry && this.state.review_state == 'rejected' &&
                                            <div className="lend_hend review_gap">
                                                <h3>{dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.request_for_review_rejected.en : "Request for Review Rejected"}</h3>
                                            </div>
                                        }
                                        {!this.state.loader && !this.state.session_entry && this.state.review_state == 'approved' &&
                                            <div className="uploadbtn_redem">
                                                <Button size="medium" color="primary" onClick={() => { this.uploadReview() }}>
                                                    {dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.upload_review.en : "Upload Review"}
                                                </Button>
                                            </div>
                                        }

                                        {!this.state.loader && !this.state.session_entry && this.state.review_state == 'uploaded' &&
                                            <div className="mois_redem">
                                                <Button size="medium" color="primary">
                                                    <img src={url.family_one_image_url + 'Vector2.1.png'} alt="" title="" /> {dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.uploaded.en : "Uploaded"}
                                                </Button>
                                            </div>
                                        }
                                        {!this.state.session_entry &&
                                            <div className="declaration_mois">
                                                <p> {ReactHtmlParser(program.short_description)}</p>
                                            </div>
                                        }
                                        {this.state.session_entry &&
                                            <div style={{ paddingBottom: "20%" }}>
                                                <div className="hometwo_upr">
                                                    <div className="hometwo_hendupr">
                                                        <div className="hometwo_hend">
                                                            <div><img src={url.family_one_image_url + 'Group791.png'} alt="" title="" /></div>
                                                            <h3>{dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.get_started.en : "Get Started"}</h3>
                                                            <p>{dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.login_to_redeem.en : "To redeem this offer you need login or sign up"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="hometwo_redem">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleGetStarted() }}>
                                                            {dynamic_content && dynamic_content.static_text.reviewrequest ? dynamic_content.static_text.reviewrequest.get_started.en : "Get Started"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            }
                            {program.review_type == "story_review" &&
                                <div>
                                    <div className="details_mois">
                                        <div className="mois_iner program_title01">
                                            <label>{ReactHtmlParser(program.program_title)}</label>
                                            <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                        </div>
                                    </div>
                                    <div className="dtl_details_sub">
                                        <div className="dtl_descp_sub">
                                            <label>{ReactHtmlParser(program.story_subtitle1)}</label>
                                            {ReactHtmlParser(program.story_paragraph1)}
                                        </div>
                                        {program.story_media && program.story_media.length > 0 && program.story_media.map((element, index) => (
                                            <div className="story_media">
                                                {element.url && element.url != "" &&
                                                    <div key={"story_media" + index} className="vdo_show" style={{ marginLeft: "3px" }}>
                                                        {program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook') && element.type == "video" && this.state.show_video == false &&
                                                            <div style={{ position: "relative" }} >
                                                                <img style={{ borderRadius: "20px", height:"auto" }} width="324px" src={url.cloudflare_image_path + program.program_image+url.varient} alt="" title="" />
                                                                <img onClick={() => { this.handleImageClick() }} className="play_button" src={url.cloudflare_image_path + "Subtractplay.png"+url.varient} alt="" title="" />
                                                            </div>
                                                        }
                                                        {program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook') && element.type == "video" && this.state.show_video == true &&
                                                            <Iframe url={element.url + "&autoplay=1&mute=1"}
                                                                width="324px"
                                                                height={CalculateHeight(324, program.video_dimention ? program.video_dimention : '1:1')}
                                                                id={"iframe_story_media" + index}
                                                                className="iframe_story_media"
                                                                scrolling="no"
                                                                frameborder="0"
                                                                allow='autoplay; encrypted-media'
                                                                title='video'
                                                                allowTransparency="true"
                                                                data-video-aspect-ratio="5:9"
                                                                autoplay="true"
                                                                webkitallowfullscreen="true"
                                                                mozallowfullscreen="true"
                                                                allowfullscreen="true"
                                                            />
                                                        }
                                                        {!(program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook')) && element.type == "video" &&
                                                            <ReactPlayer
                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { showinfo: 0 }
                                                                    }
                                                                }}
                                                                className="video_story" light={true} controls={true} url={element.url} playing />
                                                        }
                                                        {element.type != "video" &&
                                                            <img className="video_story" src={element.url} alt="" title="" />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        ))}

                                        <div className="dtl_descp_sub">
                                            <label>{ReactHtmlParser(program.story_subtitle2)}</label>
                                            {ReactHtmlParser(program.story_paragraph2)}
                                        </div>
                                        {!this.state.session_entry &&
                                            <div className="dtl_suba_redem">

                                                <a className="story_share" href={program.facebook_link} rel="noopener noreferrer" target="_blank">Share</a>

                                                {program.has_enter != 1 && program.entry_allowed == 1 &&
                                                    <Button size="medium" color="primary" className="dtl_subaenter" onClick={() => { this.handleClick(program) }}>
                                                        {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.enter.en : "Enter"}
                                                    </Button>
                                                }
                                                {program.has_enter == 1 && program.entry_allowed == 1 &&
                                                    <Button size="medium" color="primary" className="dtl_subaenter">
                                                        {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.entered.en : "Entered"}
                                                    </Button>
                                                }
                                            </div>
                                        }
                                        {/* {!this.state.session_entry &&
                                    <div className="fb_connect">
                                        <div> <img src={url.family_one_image_url + 'Path.png'} alt="" title="" /> </div>
                                        <p>{dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.facebook_comments.en : "Facebook comments"}</p>
                                        <FacebookProvider appId="367702427421660">
                                            <Comments href="https://www.facebook.com/WebnexusPankaj/posts/915966112113485" width="100" />
                                        </FacebookProvider>
                                    </div>
                                } */}
                                        {this.state.session_entry &&
                                            <div className="paddingbottom">
                                                <div className="hometwo_upr">
                                                    <div className="hometwo_hendupr">
                                                        <div className="hometwo_hend">
                                                            <div><img src={url.family_one_image_url + 'Group791.png'} alt="" title="" /></div>
                                                            <h3>{dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.get_started.en : "Get Started"}</h3>
                                                            <p>{dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.login_to_redeem.en : "To redeem this offer you need login or sign up"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="hometwo_redem">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleClick(program) }}>
                                                            {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.get_started.en : "Get Started"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            }

                            {/* End of One section */}
                        </div>
                    ))}
                    {this.state.show_t_and_c &&
                        <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_program} />
                    }
                </div>
                </div>
            </div>
        );
    }
}
export default uploadreviewrequest;

