import React from 'react';
import ContentLoader from 'react-content-loader';

export default function MyLoader() {
    return (
        <ContentLoader
            width={371}
            height={1000}
            style={{ display:'flex', alignSelf:'center' }}
            >
            <rect x="65" y="275" rx="12" ry="12" width="251" height="160" /> 
            <rect x="70" y="162" rx="12" ry="12" width="226" height="12" /> 
            <rect x="100" y="191" rx="12" ry="12" width="173" height="12" /> 
            <rect x="108" y="8" rx="12" ry="12" width="162" height="129" /> 
            <rect x="-7" y="46" rx="12" ry="12" width="74" height="83" /> 
            <rect x="299" y="48" rx="12" ry="12" width="75" height="82" /> 
            <rect x="15" y="227" rx="12" ry="12" width="67" height="15" /> 
            <rect x="95" y="227" rx="12" ry="12" width="74" height="15" /> 
            <rect x="188" y="227" rx="12" ry="12" width="72" height="15" /> 
            <rect x="275" y="228" rx="12" ry="12" width="74" height="15" /> 
            <rect x="66" y="463" rx="12" ry="12" width="251" height="160" />
        </ContentLoader>

    );
}
