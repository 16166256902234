import React from 'react';
import cookie from 'react-cookies';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
var QRCode = require('qrcode.react');

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: false,
            login_link: ""
        }
    }


    componentDidMount() {
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : null
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : null;
        let self = this
        let program_id = this.props.location.state.program_id;
        if (program_id) {
            functions.sendMailForMacUser(self, auto_id, email, program_id, (sendData) => {
                let response = JSON.parse(sendData).data
                if (response) {
                    self.setState({ login_link: response.login_link })

                }
            })
        }
    }

    render() {
        const { login_link } = this.state;
        return (
            <div className="container">
                <div className="row">
                   <div className="common_mainAddOn">
                    <div className="common_main">
                        <a className="discardClose" onClick={() => this.props.history.push("\main")}>
                            <img src={url.family_one_image_url + 'Group5.png'} style={{ height: 10 }} alt="" title="" /></a>
                        <div className="mailBoxMain">

                            {/* <h1>Continue recording on a mobile device</h1> */}
                            <div className="mailBoxUpr">
                                <div className="mailBox">
                                    <span className="Union1"><img src={require('../../assets/images/Union1.svg')} alt="" title="" /></span>
                                    <img src={require('../../assets/images/mailbox.svg')} alt="" title="" />
                                    <span className="Union2"><img src={require('../../assets/images/Union2.svg')} alt="" title="" /></span>
                                </div>
                            </div>
                            <div className="chkMail">
                                <label>Check your</label>
                                <label>e-mail inbox</label>
                                <p>Survey link send to you inbox</p>
                            </div>
                            {login_link &&<div className="scanMail">
                                 <div>
                                    <QRCode fgColor="#F27381" bgColor="transparent" height={"130px"} width={"130px"} style={{ position: "initial" }} value={login_link} />
                                </div>
                            </div>}
                            {login_link && <div className="scanMailTxt">
                                <div className="scanMailArow">
                                    <label>Scan</label>
                                    <label>QR CODE</label>
                                </div>
                                <p>This survey is available only for mobile app</p>
                            </div>}
                        </div>
                    </div>
                   </div> 
                </div>
            </div>
        );
    }
}

export default Index;