import React from 'react';
import '../../assets/css/style.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';

export default function InvalidEmail(props) {
    return (
        <div >
            <Modal isOpen={props.isInvalidEmail} toggle={props.toggleInvalidEmailPopup} className="custom_Addchildmodel" backdrop={'static'} keyboard={false}>
                <ModalBody className="ntcBody">
                    <div className="ntvcontent">
                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" className="ntcimgClose" onClick={props.toggleInvalidEmailPopup} />
                        <div className="ntc_head">
                            <h3>Notice</h3>
                            <div className="ntc_headInr">
                                <p>
                                    The email address provided was flagged as a potential masked/proxy/burner address. Such email addresses, while often used to protect the privacy of the user,  contribute to duplicate and account access issues.
                                    To protect the integrity of our services and your experience, we request that you use your real email address.
                                    family.one remains dedicated to the most stringent of privacy policies and ZERO SPAM ethics.
                                    <p>Press continue to enter your real email address.</p>
                                </p>
                            </div>
                            <div className="ntcUpdate"><Button size="medium" color="primary" onClick={props.handleContinueInvalidEmail}>Continue</Button></div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}