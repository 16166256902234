import React from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
const C = CSSPlugin;


export default class EnterGuardTreeText extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show_error_message: "",
            send_code: false,
            loader: false,
            dynamic_content: "",
            guardTree_text: ""
        }
    }

    componentDidMount = () => {
        this.setState({ show_error_message: "" })
        // let TL22 = new TimelineMax();
        // TL22.fromTo('.fade_it_up', 0.4, { opacity: 0, y: -10 }, { opacity: 1, y: 0 })
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        this.setState({ show_error_message: "" })

    }


    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
            .to('.program_entry_loader', 0.5, { opacity: 1 })
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }


    handleNext = () => {
        let success_action = "User clicked on nect button enterphone page"
        let image = 'next_button_enterphone_page.png'
        functions.logUserActivity(success_action, image);
        this.handleAnimation();
        let self = this;
        let guardTree_text = self.state.guardTree_text.trim()
        if (!guardTree_text) {
            let user_action = "User got error on innerpages/enterGuardTreeText file handleNext function";
            let body = "Please Enter the text"
            functions.logUserError(user_action, body);
            self.setState({
                show_error_message: "Please Enter the text"
            });
            self.removeAnimationEffect();
        } else if (guardTree_text.toLowerCase() == "guardtree" || guardTree_text.toLowerCase() == "guard tree" || guardTree_text.toLowerCase() == "guard-tree") {
            let userdata = [];
            let dynamo_userdata = { 'guardtree_text': guardTree_text };
            localStorage.setItem("guardtree_text", guardTree_text);
            functions.updateUserDataPass(this, userdata, dynamo_userdata, function (err, passData) {
                if (err) {
                    self.setState({
                        show_error_message: err
                    });
                }
                else {
                    let success_action = "User's successfully enterGuardTreeText page"
                    let image = 'phone_number_verified_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    // self.removeAnimationEffect();
                    localStorage.setItem("guardtree_text_required", "false")
                    localStorage.setItem("show_phone", "false");
                    self.setState({ show_error_message: "" })
                    self.props.onSubmit()
                }
            })
        } else {
            let user_action = "User got error on innerpages/enterGuardTreeText file handleNext function";
            let body = "Try again. Hint: The answer is in the GuardTree email"
            functions.logUserError(user_action, body);
            self.setState({
                show_error_message: "Try again. Hint: The answer is in the GuardTree email"
            });
            self.removeAnimationEffect();
        }
    }


    render() {
        const { dynamic_content } = this.state
        return (
            <div>
                <div>
                    <div className="row rel_block fade_it_up">
                        <div className="phone_verification_block" style={{ position: "relative" }}>
                            <div className="phone_verify_backlink"><img onClick={() => { this.props.Back() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                            <div className="contest_up">
                                <h1>{"Complete your entry"}</h1>
                                <h1>{"Who offers Canada's only affordable and subscription-based used car warranty?"}</h1>
                            </div>
                            <div className="contest_process2">
                                <TextField
                                    autoFocus={true}
                                    autoComplete='off'
                                    label="Type here"
                                    margin="normal"
                                    type="text"
                                    value={this.state.guardTree_text}
                                    onChange={(event) => {
                                        this.setState({ guardTree_text: event.target.value,show_error_message:"" })

                                    }}
                                    onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            this.handleNext()
                                        }
                                    }}
                                />
                            </div>
                            {this.state.show_error_message && this.state.show_error_message != "" &&
                                <p className="error_message" style={{ marginLeft: "0px" }}>
                                    {this.state.show_error_message}
                                </p>
                            }

                            <div className="contest_msg">
                                <div className="contest_opt">
                                    <Button size="medium" color="primary" onClick={() => { this.handleNext() }} disabled={this.state.show_error_message ? true : false}>
                                        {"Continue"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="program_entry_loader">
                            <LottieLoader />
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}