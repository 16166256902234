import React from 'react';
import Button from '@material-ui/core/Button';
import '../../assets/css/style.css';
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import session from '../../assets/functions/session'

export default class Settings extends React.Component {
    constructor(props) {
        super(props)
        functions.handleBackOne(this, 'setting')
        this.state = {
            linked_account: localStorage.getItem('provider') ? JSON.parse(localStorage.getItem('provider')) : [],
            fb_linked: false,
            twitter_linked: false,
            dynamic_content: ""
        }
        this.passcodefaceid = this.passcodefaceid.bind(this);
    }

    passcodefaceid() {
        this.props.history.push('/passcodefaceid');
    }

    handleLogout = () => {
        let success_action = "User clicked on logout button on setting page"
        let image = 'logout_button_setting_page.png'
        functions.logUserActivity(success_action, image);
        let all_local_storage = this.allLocalStorage();
        let api_auth = localStorage.getItem("api_auth")
        all_local_storage.forEach((element, index) => {
            for (var key in element) {
                if (key != "api_auth" && key != "dynamic_content") {
                    localStorage.removeItem(key)
                }
                else {
                }
            }
        });
        let all_cookie = cookie.loadAll();
        let all_cookie_length = Object.keys(all_cookie).length;
        let index = 0;
        for (var key in all_cookie) {
            if (key != 'session_id') {
                cookie.remove(key, { path: "/" })
            }
            index = index + 1;
            let local_storage = this.allLocalStorage();
            if (local_storage && local_storage.length == 2) {
                if (index == all_cookie_length) {
                    localStorage.setItem("logged_in", 'false')
                    localStorage.setItem("api_auth", api_auth)
                    localStorage.setItem('logout-event', 'logout' + Math.random());
                    this.props.history.push("/")
                    let session_auto_id = cookie.load("session_auto_id") ? cookie.load("session_auto_id") : null
                    session.checkSession(session_auto_id, '', 'true');
                }
            }
            else {
                // this.handleLogout()
            }
        }
    }

    allLocalStorage = () => {
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while (i--) {
            let keyname = keys[i];
            values.push({ [keyname]: localStorage.getItem(keys[i]) });
        }
        return values;
    }

    handleChangePassword = () => {
        functions.pushToRoute(this, 'change_password', 'setting')
    }

    handleNotification = () => {
        let success_action = "User clicked on notification button on setting page"
        let image = 'notification_button_setting_page.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, 'notification_setting', 'setting')
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let {linked_account} = this.state
        if (linked_account.length > 0) {
            linked_account.forEach(element => {
                if (element === "facebook") {
                    this.setState({ fb_linked: true })
                }
                else if (element === "twitter") {
                    this.setState({ twitter_linked: true })
                } 
                else if(element === 'familyone'){
                    this.setState({ fmo_linked: true })
                }
            });
        }
    }

    render() {
        const { dynamic_content, fb_linked, twitter_linked, fmo_linked } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    {dynamic_content != "" &&
                        <div className="common_main">

                            <div className="sign_backlink sign_backlinkNw" onClick={() => { functions.pushToRoute(this, "profile", 'setting') }}>
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>
                            <div className="profileTp">
                                <h1>Profile</h1>
                                <h2>{dynamic_content.static_text.setting ? dynamic_content.static_text.setting.settings.en : "Settings"}</h2>
                            </div>

                            <div className="setting_mainInner">
                                
                                <div className="setng_contentUpr">
                                  <label>{dynamic_content.static_text.setting ? dynamic_content.static_text.setting.privacy.en : "Privacy"}</label>
                                  
                                  <div className="setng_content" onClick={() => { this.handleNotification() }}>
                                    <div className="setng_contentLeft">
                                      <span>Notifications</span>
                                    </div>
                                    <div className="setng_contentRght">
                                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                                    </div>
                                  </div>

                                  <div className="setng_content" onClick={() => { this.handleChangePassword() }}>
                                    <div className="setng_contentLeft">
                                      <span>Change Password</span>
                                    </div>
                                    <div className="setng_contentRght">
                                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                                    </div>
                                  </div>

                                </div> 

                                {/* <div className="setng_contentUpr">
                                  <label>{dynamic_content.static_text.setting ? dynamic_content.static_text.setting.security.en : "Security"}</label>
                                  <div className="setng_content">
                                    <div className="setng_contentLeft">
                                      <span>Change Password</span>
                                    </div>
                                    <div className="setng_contentRght">
                                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                                    </div>
                                  </div>
                                </div>  */}

                                <div className="usr_setting2 userlogout2">
                                {(fb_linked || twitter_linked) ?<label>{dynamic_content.static_text.setting ? dynamic_content.static_text.setting.linked_accounts.en : "Linked Accounts"}</label>:""}
                                    {/* <p className="faceBokLink2">
                                        {this.state.fb_linked &&
                                            <span><img src={url.family_one_image_url + 'Path.png'} alt="" title="" /></span>
                                        }
                                        {!this.state.fb_linked &&
                                            <span><img src={url.family_one_image_url + 'facebook.png'} alt="" title="" /></span>
                                        }
                                        <a>{dynamic_content.static_text.setting ? dynamic_content.static_text.setting.facebook.en : "Facebook"}</a>
                                        {this.state.fb_linked &&
                                            <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="active_ac" />
                                        }
                                    </p>
                                    <p className="twtrLink2">
                                        {this.state.twitter_linked &&
                                            <span><img src={url.family_one_image_url + 'twiter.png'} alt="" title="" /></span>
                                        }
                                        {!this.state.twitter_linked &&
                                            <span><img src={url.family_one_image_url + 'Pathtwiter.png'} alt="" title="" /></span>
                                        }
                                        <a>{dynamic_content.static_text.setting ? dynamic_content.static_text.setting.twitter.en : "Twitter"}</a>
                                        {this.state.twitter_linked &&
                                            <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="active_ac" />
                                        }
                                    </p> */}
                                    
                                    <div className='connectSocial'>
                                        {fb_linked && 
                                            <a><img src={require('../../assets/images/faceBook3.svg')} alt="" title="" /></a>  
                                        }
                                        {twitter_linked &&
                                            <a><img src={require('../../assets/images/Xicon.svg')} alt="" title="" /></a>      
                                        }
                                      
                                    </div>

                                    <div className='userlogout2Btn'>
                                        <Button size="medium" color="primary" onClick={() => { this.handleLogout() }}>
                                            {dynamic_content.static_text.setting ? dynamic_content.static_text.setting.logout.en : "Log Out"}
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                  </div>   
                </div>
            </div>
        );
    }
}