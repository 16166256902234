import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';

class Offersdetail extends React.Component {
    constructor(props) {
        super(props);
        this.orderdetails = this.orderdetails.bind(this);
    }
    orderdetails() {
        this.props.history.push('/orderdetails');
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                   <div className="common_mainAddOn">
                    <div className="common_main detailspace_rmv">
                        <div className="contests_dtl">
                            <div className="contests_dtlImg">
                                <div className="detailback_section">
                                    <div><img src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                                    <div><a>Help</a></div>
                                </div>
                                <div className="offers_dtlImges">
                                    <img src={url.family_one_image_url + 'Rectangle2.48.png'} alt="" title="" />
                                </div>
                                <div className="offersImgs">
                                    <a><img src={url.family_one_image_url + 'doudou-et-compagnie.png'} alt="" title="" /></a>
                                </div>
                            </div>

                            <div className="offers_descp">
                                <div className="offers_dtl_iner">
                                    <div className="orderbtn"><button><img src={url.family_one_image_url + 'Subtract.png'} alt="" title="" /> Ordered</button></div>
                                    <label>Baby’s book of Firsts!</label>
                                    <div className="detail_toy">
                                        <strike className="detail_deactive">$34.95</strike> <span className="detail_active">$33.95</span>
                                    </div>
                                </div>

                                <div className="offers_all">
                                    <label>Description</label>
                                    <p>
                                        We thought of all the most precious memories and moments your baby is about to have, and
                                        made them into a beautiful book for you to keep forever!
                                    </p>
                                    <ul>
                                        <li><a>Hard cover</a></li>
                                        <li><a>48 full colour pages</a></li>
                                        <li><a>High quality print</a></li>
                                        <li><a>Beautiful illustrations</a></li>
                                        <li><a>First ever to include "Baby's how-to's" infographics</a></li>
                                        <li><a>Easy to use</a></li>
                                    </ul>
                                    <div className="ends_offers">
                                        <h3> Ends 19 Dec 2019 </h3>
                                    </div>
                                </div>

                                {/* three in One section */}
                                <div className="offersSocial_upr">
                                    <div className="offers_hendupr">
                                        <div className="offers_price">
                                            <div>
                                                <label>Price</label>
                                                <p>$33.95</p>
                                            </div>
                                            <div>
                                                <label>Price</label>
                                                <p>$33.95</p>
                                            </div>
                                        </div>

                                        <div className="offers_connect_upr">
                                            <div className="offers_redem">
                                                <Button size="medium" color="primary" onClick={this.orderdetails} >
                                                    Order Now
                                               </Button>
                                            </div>
                                            <div className="declaration_offers">
                                                <p>
                                                    By redeeming you are agreeing to the <span>Terms and Conditions</span> below.
                                                    Value may vary depending on date, location and vehicle chosen. Some vehicle class restrictions
                                                    apply. No purchase necessary. See Terms and Conditions for more details.
                                                 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End of  three in One section */}
                        </div>
                    </div>
                   </div> 
                </div>
            </div>
        );
    }
}
export default Offersdetail;

