import React, { Fragment, useState } from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import uuid from 'react-uuid'

export default class Entercontestdob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            policyadvisor_link: "",
            policyadvisor_id: uuid(),
            dynamic_content: ""
        }
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let { policyadvisor_id } = this.state
        let policyadvisor_link = "https://api.fintelconnect.com/t/l/finteltag/a-28153b-25385c-" + policyadvisor_id
        this.setState({ policyadvisor_link: policyadvisor_link })
    }

    handleSubmit = () => {
        let self = this
        this.handleAnimation();
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        let program = this.props.props.program
        let final_json = {
            "auto_id": this.state.policyadvisor_id,
            "optin_status": "pending",
            "email": email,
            "partner_id": program.partner_id,
            "sub_id": auto_id,
            "program_id": program.program_id
        }
        functions.LogInProgramEntry(this, final_json)
        setTimeout(() => {
            self.props.onSubmit()
        }, 500);
        
    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
            .to('.program_entry_loader', 0.5, { opacity: 1 })
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="">
                {dynamic_content != "" &&
                    <div className="row">

                        <div className="phone_verification_block" style={{ position: "relative" }}>
                            <div className="phone_verify_backlink"><img onClick={() => { this.props.DOBBack() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                            <div className="contest_up">
                                <h1>{dynamic_content.static_text.enterpolicyadvisorconsent ? dynamic_content.static_text.enterpolicyadvisorconsent.enter_contest.en : "Complete Your Entry"}</h1>
                                <h2 style={{ fontSize: "26px" }}>{dynamic_content.static_text.enterpolicyadvisorconsent ? dynamic_content.static_text.enterpolicyadvisorconsent.title1.en : "Create a Child's Profile with PolicyAdvisor.com"}</h2>
                            </div>
                            <div>
                                <p style={{ fontWeight: 700 }}>{dynamic_content.static_text.enterpolicyadvisorconsent ? dynamic_content.static_text.enterpolicyadvisorconsent.subtitle1.en : "To complete your entry, you'll be taken offsite to PolicyAdvisor.com, a third-party website, where you will be able to create an online profile for a child life insurance quote."}</p>
                                <p style={{ letterSpacing: 0 }}>{dynamic_content.static_text.enterpolicyadvisorconsent ? dynamic_content.static_text.enterpolicyadvisorconsent.subtitle2.en : "The creation of a profile with PolicyAdvisor.com is quick and secure. No Commitment or Purchase Necessary."}</p>
                            </div>
                            {this.state.show_error_message && this.state.show_error_message != "" &&
                                <p className="error_message">
                                    {this.state.show_error_message}
                                </p>
                            }
                            <div className="contest_msg">
                                <div className="contest_opt_policy_advisor">
                                    <a onClick={() => { this.handleSubmit() }} href={this.state.policyadvisor_link} target="_blank">
                                        <Button size="medium" color="primary">
                                            {dynamic_content.static_text.enterpolicyadvisorconsent ? dynamic_content.static_text.enterpolicyadvisorconsent.submit.en : "Enter & Create"}
                                        </Button>
                                    </a>

                                </div>
                            </div>

                        </div>
                        <div className="program_entry_loader">
                            <LottieLoader />
                        </div>

                    </div>
                }
            </div>
        );
    }
}