import React, { Fragment, useState } from 'react';

// import CircularProgress from "@material-ui/core/CircularProgress";
// import Countdown from "react-countdown-now";
// import Dialog from "material-ui/Dialog";
// import Drawer from "./drawer";
import url from "../../assets/url";
import cookie from "react-cookies";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import functions from "../../assets/functions/common_functions";
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import DateOfBirth from './components/dateofbirth'
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import $ from "jquery";
import uuid from 'react-uuid'
const C = CSSPlugin;
// {ReactHtmlParser(program.program_title)}
// import RaisedButton from "material-ui/RaisedButton";
// import session from "./function/session"
let nextid;

class epilogue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepIndex: 0,
            finished: false,
            dynamic_content: "",
            disable_enter_button: true,
            dynamo_userdata: {},
            show_error_message: "",
            need_will: ""
        };
    }

    componentDidMount = () => {

        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })

        }
        let success_action = "User landed on epilogue question page"
        let image = ''
        functions.logUserActivity(success_action, image);
        let TL22 = new TimelineMax();
        TL22.fromTo('.fade_it_up', 2, { opacity: 0, y: -10 }, { opacity: 1, y: 0 })
    }

    componentWillMount = () => {
        let steps = []
        steps.push(
            {
                'question': "need_will",
                'heading': "Do you need a will?"
            }
        )
        this.setState({ steps: steps })
    }

    handlePrev = () => {
        let success_action = "User clicked on back button on epilogue question page"
        functions.logUserActivity(success_action);
        const { stepIndex } = this.state;
        if (stepIndex > 0) {
            this.setState({ stepIndex: stepIndex - 1 });
        }
        else {
            this.props.Back()
        }
    };

    AddStep = () => {
        const { stepIndex } = this.state;
        this.setState({ stepIndex: stepIndex + 1 });
    }

    handleUpdate = (value, name, index) => {
        let success_action = "User clicked on " + value + " on " + name + " button on epilogue question page"
        functions.logUserActivity(success_action);
        const { stepIndex, steps, dynamo_userdata } = this.state;
        dynamo_userdata[name] = value
        dynamo_userdata[name + "_entry_timestamp"] = new Date().getTime()
        this.setState({ [name]: value, show_error_message: "", dynamo_userdata: dynamo_userdata })
        if (stepIndex == index - 1) {
            this.AddStep()
        }

        if (index == steps.length) {
            this.setState({ disable_enter_button: false })
        }
        if (stepIndex < steps.length - 1) {
            setTimeout(() => {
                let class_name = ".activeLine" + index
                if (name == "parent_smoking_status") {
                    class_name = ".activeLine_smoking"
                }
                $(class_name).addClass('show');
            }, 300);
        }
    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
            .to('.program_entry_loader', 0.5, { opacity: 1 })
    }

    handleEnter = () => {
        let self = this
        let success_action = "User clicked on Enter button on enter epilogue questions page"
        functions.logUserActivity(success_action);
        const { stepIndex, steps, birthdate, dynamo_userdata, policy_unique_id } = this.state;
        if (stepIndex == steps.length) {
            this.handleAnimation();
            let userdata = [];
            functions.updateUserDataPass(this, userdata, dynamo_userdata, function (err, passData) {
                if (err) {
                }
                else {
                    self.props.onSubmit()
                }
            })
        } else {
            let action = "User got error on innerpages/epilogue questions file handleEnter function";
            let error_message = "Answer Required"
            functions.logUserError(action, error_message);
            this.setState({ show_error_message: error_message })
        }
    }

    render() {
        const { steps, stepIndex, dynamic_content } = this.state;
        return (
            <div >
                {dynamic_content != "" &&
                    <div className="row rel_block fade_it_up">
                        <div className="phone_verification_block" style={{ position: "relative" }}>
                            <div className="phone_verify_backlink"><img onClick={() => { this.handlePrev() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                            {/* <p>{""}</p> */}
                            <div className="contest_up" style={{ paddingLeft: "16px" }}>
                                <h1>{"Answer the following to complete your entry."}</h1>
                            </div>
                            <div>
                                {/* <p>{"Answer " + steps.length + " questions to complete your entry"}</p> */}
                                <div className="row">
                                    <div className="policy_me_nextnum_div">
                                        <Stepper className="policyme_nextnum_divIner" linear="false" activeStep={stepIndex} orientation="vertical">
                                            {steps && steps.length && steps.map((steps, index) => (
                                                <Step className={this.state.stepIndex > index ? steps.question == "parent_smoking_status" ? "steperActvLbl steplabel_secondUpr stp2_smoke activeLine_smoking" : "steperActvLbl steplabel_secondUpr stp2_smoke activeLine" + (index + 1) : "steplabel_secondUpr stp2_smoke activeLine" + (index + 1)}>
                                                    <StepLabel className="steplabel_fst" />
                                                    <div className="stp2_smokeRght">
                                                        <div><label>{steps.heading}</label></div>
                                                        <div>
                                                            <Button disabled={this.state.stepIndex > (index - 1) ? "" : "true"} className={this.state[steps.question] == "yes" ? "stperActvBtn" : ""} onClick={() => { this.handleUpdate("yes", steps.question, index + 1) }}>Yes</Button>
                                                            <Button disabled={this.state.stepIndex > (index - 1) ? "" : "true"} className={this.state[steps.question] == "no" ? "stperActvBtn" : ""} onClick={() => { this.handleUpdate("no", steps.question, index + 1) }}>No</Button>
                                                        </div>
                                                    </div>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message">
                                            {this.state.show_error_message}
                                        </p>
                                    }
                                    <div className="contest_msg">
                                        <div className="contest_opt">
                                            <a onClick={() => { this.handleEnter() }} className={this.state.disable_enter_button ? "disable_link" : ""} href={this.state.policyme_link} target="_blank">
                                                <Button size="medium" color="primary" >
                                                    {"Enter"}
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="program_entry_loader">
                            <LottieLoader />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default epilogue;
