import React from 'react';
import '../../assets/css/style.css';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { TimelineLite, TweenLite, TweenMax, TimelineMax, Bounce, Linear, Power1, Power2, Power3, Back, Power4, SlowMo, Circ, Elastic } from "gsap/all";
import functions from '../../assets/functions/common_functions';
import Footer from '../innerpages/footer';
import CSSPlugin from 'gsap/CSSPlugin';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import url from '../../assets/url';
const C = CSSPlugin;
var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

class family extends React.Component {
    constructor(props) {
        super(props);
        // functions.handleBackOne(this, 'family')
        this.state = {
            action: "",
            activeTab: localStorage.getItem("family_activeTab") ? localStorage.getItem("family_activeTab") : '1',
            relation_modal: false,
            relation_pending_modal: false,
            edit_delete_modal: false,
            modal: false,
            relation_status: '',
            child_data: [],
            relations: [],
            delete_confirm_modal: false,
            modal_message: "Do you want to delete this relation?",
            is_child: false,
            dynamic_content: true,
            loader: false,
            selectedParentList: []
        };
        this.dateFormat = this.dateFormat.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("add_as_relative", "false")
        let action = "User landed on family page"
        let image = 'family_page_landing.png'
        functions.logUserActivity(action, image);
        localStorage.setItem("active_button", "2")
        if (this.state.relations.length == 0) {
            setTimeout(function () {
                var timeline = new TimelineMax();
                timeline.fromTo(".two_person_img", 0.4, { width: "18px", opacity: 0, y: 20 }, { width: "36px", opacity: 1, ease: Circ.easeOut, y: 0 })
                    .fromTo(".person_cup", 0.4, { width: "16px", opacity: 0, y: 20 }, { width: "32px", opacity: 1, ease: Circ.easeOut, y: 0 }, "-=0.4")
                    .fromTo(".star_plusOne", 0.4, { left: "115px" }, { left: "0px" }, "-=0.4")
                    .fromTo(".star_plusTwo", 0.4, { left: "115px" }, { left: "13px" }, "-=0.4")
                    .fromTo(".star_plusThree", 0.4, { left: "115px" }, { left: "55px" }, "-=0.4")
                    .fromTo(".star_plusFour", 0.4, { right: "115px" }, { right: "0px" }, "-=0.4")
                    .fromTo(".star_plusFive", 0.4, { right: "115px" }, { right: "13px" }, "-=0.4")
                    .fromTo(".star_plusseven", 0.4, { right: "115px" }, { right: "48px" }, "-=0.4")
            }, 1000);
        }

    }

    componentWillMount = () => {

        let child_data = localStorage.getItem('child_data') ? localStorage.getItem('child_data') : [];
        let selcte
        this.setState({ loader: true })
        let previous_state = localStorage.getItem("previous_state")
        if (previous_state == "") {
            this.GetInfo()
        } else {
            // settimeout for loading updated data
            this.setTimeOut = setTimeout(() => { this.GetInfo() }, 2000);
        }

    }

    GetInfo = () => {
        let self = this
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.checkUserExistence(self, finaljson, function (response1_json) {
                let response1 = JSON.parse(response1_json)
                if (response1.data && response1.data.dynamo && response1.data.dynamo.Items && response1.data.dynamo.Items.length == 0) {
                    // functions.pushToRoute(self, "", "family")
                } else {
                    functions.getuserChildAndrelative(self, email, sub_id, function (response_json) {
                        let response = JSON.parse(response_json);
                        if (response.catch_error) {
                            let action = "User got error on family/index file GetInfo function";
                            let body = response.message
                            functions.logUserError(action, body);
                            self.setState({ loader: false, show_error_message: response.message })
                        }
                        else if (!response.catch_error) {
                            if (response.data.error === 1) {
                                let action = "User got error on family/index file GetInfo function";
                                let body = response.data.message
                                functions.logUserError(action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            } else if (response.data.statusCode === 400) {
                                let action = "User got error on family/index file GetInfo function";
                                let body = response.data.message
                                functions.logUserError(action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            }

                            else if (response.status === 200) {
                                let child_data = (response.data.child_data.Items[0] && response.data.child_data.Items[0].child_data) ? response.data.child_data.Items[0].child_data : [];
                                let relation_data = response.data.relation_data ? response.data.relation_data : {}

                                let success_action = "User get success in response of getuserChildAndrelative function in family/index page"
                                functions.logUserActivity(success_action, null);

                                if (child_data && child_data.length > 0) {
                                    localStorage.setItem("child_data", JSON.stringify(child_data));
                                }
                                else {
                                    localStorage.removeItem("child_data");
                                }

                                let relation_data_final = [];
                                if (Object.keys(relation_data).length > 0) {
                                    for (var key in relation_data) {
                                        relation_data[key].forEach(element => {
                                            if (key == 'approvedRelations' || key == "pendingRelations") {
                                                element.relation_status = key == "pendingRelations" ? 'pending' : 'approved_relations';
                                                relation_data_final.push(element)
                                            }
                                        });
                                    }
                                    self.setState({ child_data: child_data, relations: relation_data_final, loader: false })
                                    localStorage.setItem("relation_data", JSON.stringify(relation_data_final));
                                } else {
                                    functions.pushToRoute(this, "addChildParentelative", 'main');

                                    self.setState({ child_data: child_data, relations: relation_data_final, loader: false })
                                    localStorage.removeItem("relation_data");
                                }
                                self.handleCircleGrowEffect();
                            }
                        }
                    })
                }
            });

        } else {
            // this.props.history.push("/")
        }
    }

    dateFormat = (value) => {
        let date_array = value.split("-");
        if (date_array.length > 1) {
            let year = date_array[0].substring(2, 4);
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            let child_date = date + " " + month_name + " " + year;
            return child_date;
        }
        else {
            date_array = value.split("/");
            if (date_array.length > 1) {
                let year = date_array[0].substring(2, 4);
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                return child_date;
            }
        }
    }



    handleColonClick = (element, index, is_child) => {
        if (is_child == "no") {
            let action = "User open edit relation popup on family page"
            let image = 'edit_relation_popup_family_page.png'
            functions.logUserActivity(action, image);
        } else {
            let action = "User copen edit child popup on family page"
            let image = 'edit_child_popup_family_page.png'
            functions.logUserActivity(action, image);
        }
        if (is_child == "yes") {
            this.setState({ is_child: true, child_index: index });
            this.toggleEditDelete()
        }
        else {
            let relations = this.state.relations
            let relation_status = relations[index].relation_status
            // if (relation_status != "pending_invitation") {
            this.setState({ relation_status: relation_status });
            // }
            this.setState({ is_child: false, relation_index: index });
            this.toggleEditDelete()
        }


    }



    handleAddChild = () => {
        let action = "User clicked on add child button on family page"
        let image = 'add_child_button_family_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        localStorage.setItem("form_type", 'add_child')
        localStorage.removeItem("family_activeTab")
        setTimeout(function () {
            // functions.pushToRoute(self, "addchild", 'family');
        }, 1000)
    }

    handleAddRelationNew = async () => {
        let action = "User clicked on add relation/add on family page"
        let image = 'add_relation_button_family_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        let selectedChild = this.props.location?.state?.stateData?.selectedChild
        // localStorage.setItem("form_type", "add_relation")
        if (selectedChild) await this.setStateAsync({ selectedChild: selectedChild });
        setTimeout(function () {
            functions.pushToRoute(self, 'addChildParentelative', 'existingRelativeList')
        }, 1000)
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    handleAddRelation = () => {
        // let action = "User clicked on add relation/add on family page"
        // let image = 'add_relation_button_family_page.png'
        // functions.logUserActivity(action, image);
        let self = this;
        let { selectedParentList, child_data } = this.state;
        let child_id = localStorage.getItem("parentinviteChild") ? localStorage.getItem("parentinviteChild") : "";
        this.setState({ loader: true })
        let selectedChild = this.props.location?.state?.stateData?.selectedChild
        if (selectedParentList.length) {
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
            sub_id = sub_id ? sub_id : ""
            let notifyrel = {
                sub_id,
                relation_data: selectedParentList,
                child_id: child_id ? child_id : selectedChild.child_id
            }
            functions.RequestChildAddToParent(self, notifyrel, notifyres => {
                self.setState({ loader: false })
                if (notifyres) {
                    child_data.map(childs => {
                        if (childs.child_id === child_id) {
                            // childs['parent_id'] = selectedRelative.relative_id;
                            childs['accepted_child'] = 'pending'
                        }
                    })
                    localStorage.setItem('child_data', JSON.stringify(child_data))
                    functions.pushToRoute(self, 'main', '')
                }
            })
        }
        else {
            this.setState({ loader: false })
        }
    }

    handleCircleGrowEffect = () => {
        var body = document.getElementById("family_listing");
        // var body = document.body;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var piTwo = Math.PI * 2;
        // Viewport
        if (body) {
            var vw = canvas.width = body.offsetWidth;
            var vh = canvas.height = body.offsetHeight;

            if (document.getElementById('allchild_grow')) {
                document.getElementById('allchild_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_child_grow')) {
                document.getElementById('add_child_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_relative_grow')) {
                document.getElementById('add_relative_grow').addEventListener("click", createRipple1);
            }

            if (document.getElementById('add_relative_grow2')) {
                document.getElementById('add_relative_grow2').addEventListener("click", createRipple1);
            }

            window.addEventListener("resize", onResize);

            //createRipple(this);
            function createRipple1(event) {
                // Exit if target is the canvas. That means it's still active.
                if (event.target === canvas) return;

                body.appendChild(canvas);
                // Event coords
                var x = event.clientX;
                var y = event.clientY;
                // Delta - difference between event and farthest corner
                var dx = x < vw / 2 ? vw - x : x;
                var dy = y < vh / 2 ? vh - y : y;
                var radius = Math.sqrt(dx * dx + dy * dy);

                var ripple = {
                    alpha: 0,
                    radius: 0,
                    x: vw / 2,
                    y: y
                };

                var tl = new TimelineMax({ onUpdate: drawRipple, onComplete: removeCanvas })
                    .to(ripple, 1.5, { alpha: 1, radius: radius })
                    .to(ripple, 0.1, { alpha: 0 }, 1.4);

                function drawRipple() {
                    ctx.clearRect(0, 0, vw, vh);
                    ctx.beginPath();
                    ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
                    ctx.fillStyle = "rgba(252,241,239," + ripple.alpha + ")";
                    ctx.fill();
                }
            }
            function removeCanvas() {
                if (body.contains(canvas)) {
                    body.removeChild(canvas);
                }
            }
            function onResize() {
                vw = canvas.width = window.innerWidth;
                vh = canvas.height = window.innerHeight;
            }
        }

    }

    onClickCheckBox = (event, relation) => {
        console.log('event is', event, relation);

        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""

        let selectedChild = this.props.location?.state?.stateData?.selectedChild
        console.log("selectedChild is", selectedChild);

        let { selectedParentList } = this.state
        console.log("selectedParentList", selectedParentList);
        if (!selectedParentList) selectedParentList = []
        if (relation) {

            let relationObject = {
                first_name: relation.given_name,
                last_name: relation.family_name,
                relative_email: relation.email,
                relative_id: relation.relative_id
            }
            if (relation.relation_status == "pending") {
                relationObject["action_invitation"] = "existing_invitation"
                relationObject["relative_id"] = relation.id
            }
            let insert = -1
            selectedParentList.map((parent, index) => {
                if (parent.relative_id == relationObject.relative_id) {
                    insert = index

                }
            })
            if (insert == -1) selectedParentList.push(relationObject);
            else selectedParentList.splice(insert, 1)

        }
        this.setState({
            selectedParentList
        });
        console.log("selectedParentList is", selectedParentList);
    }

    handleInputChange = (event, selectedRelativeopt) => {
        let { selectedRelative } = this.state;
        if (selectedRelative && selectedRelative.relative_id == selectedRelativeopt.relative_id) {
            this.setState({ selectedRelative: "" })
        }
        else this.setState({ selectedRelative: selectedRelativeopt })

    }

    handleBack = () => {
        // let action = "User clicked on back button of add relative page"
        // let image = 'user_clicked_back_button_relative_page.png'
        // functions.logUserActivity(action, image)
        // let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        // functions.pushToRoute(this, "choicebetweenrelative", '');

        let action = "User clicked on back button of add relative page"
        let image = 'user_clicked_back_button_relative_page.png'
        functions.logUserActivity(action, image)
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        let relation_data = localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [];
        let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false;
        // this.props.history.push("/main")
        functions.pushToRoute(this, "main", '');
    }

    checkStatus = (relative) => {
        let { selectedParentList } = this.state;
        console.log("selectedParentList", selectedParentList)
        console.log("relative", relative)
        return selectedParentList.some(el => el.relative_id === relative.relative_id)
    }

    render() {
        const { dynamic_content, selectedRelative, selectedParentList } = this.state;
        return (
            <div className="container" id="family_listing">
                {dynamic_content &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className="common_main resSection_main add_relative_page addRelativeCstm">
                                <div className="sign_backlink white_circle_div" onClick={() => { this.handleBack() }}>
                                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>
                                {/* <div className="clearlink"><img src={url.family_one_image_url + 'Group5.png'} alt="" title="" /></div> */}

                                <div className="point_benefits">
                                    <h2>Invite parent from <br /> relative list</h2>
                                </div>

                                {this.state.loader &&
                                    <center>
                                        <LottieLoader />
                                    </center>
                                }
                                {!this.state.loader && this.state.relations && this.state.relations.length == 0 &&
                                    <div className="your_odds_upr">
                                        <div className="star_group">
                                            {/* <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.4.png'} alt="" title="" className="star_plusOne starComon" />
                                            <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.png'} alt="" title="" className="star_plusTwo starComon" />
                                            <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.3.png'} alt="" title="" className="star_plusThree starComon" />

                                            <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.4.png'} alt="" title="" className="star_plusFour starComon" />
                                            <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.png'} alt="" title="" className="star_plusFive starComon" />
                                            <img style={{ width: "10px" }} src={url.family_one_image_url + 'Group245.3.png'} alt="" title="" className="star_plusseven starComon" /> */}


                                            {/* <span className="two_person">
                                                <img style={{ width: "36px" }} src={url.family_one_image_url + 'Groupt234.png'} alt="" title="" className="two_person_img" />
                                                <img src={url.family_one_image_url + 'Groupt214.png'} alt="" title="" className="person_cup" />
                                            </span> */}
                                            <span className='inviteparentIcon'>
                                                <img src={require('../../assets/images/inviteparent.svg')} alt="" title="" />
                                            </span>
                                        </div>
                                        <div className="your_odds">
                                            <label>Increase Your Odds</label>
                                            <p> Adding relatives helps to increase your odds of winning</p>
                                            <Button id="add_relative_grow" size="medium" color="primary" onClick={() => { this.handleAddRelationNew() }} >
                                                Add Relatives
                                            </Button>
                                        </div>
                                    </div>
                                }
                                {!this.state.loader && this.state.relations && this.state.relations.length > 0 &&
                                    <div className="all_family_section p-108">
                                        {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                            <div key={index} className="all_family" >
                                            {(relation.relation_status == 'approved_relations' || relation.relation_status == "pending") && <div className="all_family_left">
                                                <label>{relation.given_name.charAt(0).toUpperCase() + relation.family_name.charAt(0).toUpperCase()}</label>
                                            </div>}
                                            {(relation.relation_status == 'approved_relations' || relation.relation_status == "pending") && <div className="all_relative_rght">
                                                    <label>{relation.given_name + " " + relation.family_name}</label>
                                                    {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation == "In-law" &&
                                                        <p>{relation.relation + " " + relation.optional_relation}</p>
                                                    }
                                                    {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation != "In-law" &&
                                                        <p>{relation.optional_relation + " " + relation.relation}</p>
                                                    }
                                                    {relation.optional_relation && relation.optional_relation == "" &&
                                                        <p>{relation.relation}</p>
                                                    }
                                                    {!relation.optional_relation &&
                                                        <p>{relation.relation}</p>
                                                    }
                                                </div>}
                                                {(relation.relation_status == 'approved_relations' || relation.relation_status == "pending") && <div >
                                                {/* For Wating icon */}

                                                <div>
                                                    <span className="ntc_headInr" >
                                                        <label id="a-button" className="radio">
                                                            <input
                                                                // name='im-buttons'
                                                                type="checkbox"
                                                                id='a-button'
                                                            //    checked={()=>this.checkStatus(relation)}
                                                                className="checkboxCustom" 
                                                                name={"checkbox-" + (index)} 
                                                                onClick={(event) => { this.onClickCheckBox(event, relation) }}

                                                            />
                                                            <span></span>
                                                            {this.state.close_my_account_tnc1}
                                                        </label>
                                                    </span>
                                                </div>

                                            </div>}
                                            </div>
                                        ))}
                                        <div className="">
                                            <div className="plusChildNW" onClick={() => { this.handleAddRelationNew() }}><a>+</a></div>
                                        </div>
                                    </div>
                                }
                                {!this.state.loader && this.state.relations && this.state.relations.length > 0 &&
                                    <div className="familyadd_upr relative_add_section2 familyaddNwOne">
                                        <Button disabled={selectedParentList.length==0} id="button_email" size="medium" color="primary" onClick={() => { this.handleAddRelation() }} >
                                            Send Invitation
                                        </Button>
                                    </div>
                                }
                                {/* End of Tabs content */}

                                <Footer props={this.props} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default family;

