import React from "react";
import MyRouter from './router'
import cookie from 'react-cookies';
import session from './assets/functions/session';
import functions from './assets/functions/common_functions';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import {configureStore} from './store';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import url from "./assets/url";
import LottieLoader from '../src/Screens/LottieLoader';

require('dotenv').config()
var QRCode = require('qrcode.react');
var base64 = require('base-64');
    
Sentry.init({   
  dsn: "https://38ff0aa96d7c42c68eef5982ade097de@o4505117900406784.ingest.us.sentry.io/4505124548902912",
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //     const feedback = {
  //       event_id: event.event_id
  //     }
  //     Sentry.captureUserFeedback(feedback);
  //   }
  //   return event;
  // },

  // Add browser profiling integration to the list of integrations
  integrations: [new Integrations.BrowserTracing(),
                 new Sentry.Replay({
                  stickySession: true,
                  mutationLimit: 1000,
                  mutationBreadcrumbLimit:100,
                  minReplayDuration: 5000
                })],
  ignoreErrors: ["Maximum call stack size exceeded", "RangeError", /^call stack size exceeded$/, 'clarity', 'shadowRoot', "Failed to execute 'removeChild' on 'Node'", 'isTouched' , 'querySelectorAll', 'Network Error', 'Failed to fetch', 'Load failed', 'NotFoundError', 'Request aborted', 'Object captured as exception with keys: code, message, name', 'too much recursion'],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment:url.staging,
  profilesSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 0% while in development and then sample at a lower rate in production.
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: 'FamilyOneFront@6.14.11',
});

// To federated sign in from Facebook
class App extends React.Component {
  constructor(props) {
    // console.log(process.env)
    let search = window.location.search;
    let encoded_email = localStorage.getItem("email") ? base64.encode(localStorage.getItem("email")) : "";
    // console.log("encoded_email", encoded_email)
    super(props);
    this.state = {
      search_string: search ? decodeURIComponent(search) : "",
      qr_value: localStorage.getItem("email") ? "https://app2.family.one/#search_user?email=" + encoded_email : "https://app2.family.one",
      load: false
    }
  }



  handleLogout = () => {
    let all_local_storage = this.allLocalStorage();

    all_local_storage.forEach((element, index) => {
      for (var key in element) {
        localStorage.removeItem(key)
      }
    });
    let all_cookie = cookie.loadAll();
    let all_cookie_length = Object.keys(all_cookie).length;
    let index = 0;
    for (var key in all_cookie) {
      cookie.remove(key, { path: "/" })
      index = index + 1;
      let local_storage = this.allLocalStorage();
      if (local_storage && local_storage.length == 0) {
        if (index == all_cookie_length) {
          localStorage.setItem("logged_in", 'false')
          let {history} = this.props
          if(history) history.push("/")
        }
      }
      else {
        this.handleLogout()
      }
    }
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
  
  toggle = () => {
    this.setState({
      open_dailog: !this.state.open_dailog
    });
  }

  componentDidMount = () => {
    const self = this;
    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
    let search = window.location.search;
    let parameters = this.getUrlVars();

    if (parameters && parameters.phoneTransferRequest === 'abort') {
      let transfer_data = {
        "action": parameters.phoneTransferRequest,
        "id": parameters.id
      };
      let url = 'https://xy1uysx9qk.execute-api.us-east-1.amazonaws.com/prod/PhoneTransferHandler';
      axios.request({
        url: url,
        data: JSON.stringify(transfer_data),
        method: "post"
      }).then(function (response) {
        console.log("PhoneTransferHandler response", response);
        self.toggle();
        setTimeout(() => {
          self.setState({ open_dailog: false });
        }, 5000);
      });
    }

    if (parameters.sid) {
      cookie.save("session_id", parameters.sid, { path: "/" });
    }
    if (parameters.ref_nme) {
      cookie.save("http_referer", parameters.ref_nme, { path: "/" });
    } else {
      cookie.remove("http_referer", { path: "/" });
    }

    auto_id = auto_id ? auto_id : "";
    if (auto_id !== "" && auto_id !== "undefined") {
      cookie.save("auto_id", auto_id, { path: "/" });
      localStorage.setItem("auto_id", auto_id);
    }
    let session_auto_id = auto_id ? auto_id : null;

    let location = window.location.pathname;
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : "";
    if ((session_id === "" || localStorage.getItem("current_state") === "main_home") && location === "/") {
      session.checkSession(session_auto_id, '', 'true');
      self.setState({ load: true });
      functions.getDynamicContent(function (response_json) {
        functions.Loadscript();
      });
    } else {
      session.checkSession(session_auto_id, '', 'true');
      functions.getDynamicContent(function (response_json) {
        self.setState({ load: true });
        functions.Loadscript();
      });
    }

    window.addEventListener("storage", (function (event) {
      if (event.key === 'logout-event') {
        localStorage.clear();
        cookie.remove('auto_id', { path: "/" });
        const { history } = this.props;
        if (history) {
          history.push("/");
        } else {
          console.error('History prop is undefined');
        }
      }
    }).bind(this));

    if (this.state.search_string !== "") {
      let id = this.state.search_string.substring(0, 8);
      if (id === "?fbclid=") {
        window.location.search = '';
      } else if (id === "?Contest") {
        let final_search = this.state.search_string.split("&");
        let social_shared_prog_id = final_search[0].trim().split("=").pop();
        let social_shared_refered_id = final_search[1].trim().split("=").pop();
        let social_medium = final_search[2].trim().split("=").pop();
        cookie.save("social_medium", social_medium, { path: "/" });
        cookie.save("social_shared_prog_id", social_shared_prog_id, { path: "/" });
        cookie.save("social_shared_refered_id", social_shared_refered_id, { path: "/" });
        localStorage.removeItem("given_name");
        localStorage.removeItem("family_name");
        localStorage.removeItem("email");
        localStorage.removeItem("picture");
      }
    }

    const link_1 = document.createElement('link');
    link_1.href = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.1/css/swiper.css";
    link_1.rel = "stylesheet";
    document.body.appendChild(link_1);
  };

  render() {
    return (
      <Provider store={configureStore()}>
      <div>
        {/* <div className="onMobileShow"> */}
        {isMobile &&
          <div >
            {
              this.state.load == true ?

                <div>
                  <MyRouter prop={this} />
                </div> :
                <div className="main_site_load">
                  <center>
                    {/* <CircularProgress className="loader_class" color="secondary" /> */}
                    <LottieLoader />
                  </center>
                </div>
            }
          </div>
        }
        {!isMobile &&
          <div className="onwebShow" >
            <div className="AddsiteSidebar">
              <div className="">
                <div className="siteSidebarIner2">
                  <div >
                    {
                      this.state.load == true ?

                        <div>
                          <MyRouter prop={this} />
                        </div> :
                        <div className="main_site_load">
                          <center>
                            {/* <CircularProgress className="loader_class" color="secondary" /> */}
                            <LottieLoader />
                          </center>
                        </div>
                    }
                  </div>
                </div>
              </div>
              {
                this.state.load == true &&
                <div className="siteSidebar">
                  <h1><b>family.one</b> <br />
                    is designed for <br />
                    <strong>smartphones.</strong>
                  </h1>
                  <p>
                    You can <b>SCAN</b> this <b>QR Code</b> <br />
                    with your phone to continue <br />
                    your session there.
                  </p>
                  {/* <QRCode value="http://facebook.github.io/react/" /> */}
                  <div>
                    <QRCode style={{ height: "66px", width: "66px", position: "initial" }} value={this.state.qr_value} />
                    {/* <img src={require('../src/assets/images/barcodeNW.jpg')} alt="" title="" /> */}
                  </div>
                </div>
              }
            </div>
          </div>}

      </div>
      <div className="open_mail_model">
        <Modal isOpen={this.state.open_dailog} toggle={this.toggle} className={this.props.className+" custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
            <ModalBody className="ntcBody_phoneTransferRequest">
                <div className="ntvcontent_app padding_top">

                    <img src={require('./assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle() }} className="ntcimgClose" />
                    
                    <div className="ntc_head">
                        <h3>Request Terminated!</h3>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </div>
    
    
    </Provider>
    );
  }
}
export default App;
