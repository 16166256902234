import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import Footer from './footer';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';

export default class Discover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamic_content: ""
        }
        // this.sharegain = this.sharegain.bind(this);
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let action = "User landed on no connection page"
        let image  = 'connection_page_landing.png' 
        functions.logUserActivity(action, image);
    }

    handlerefresh() {
        functions.getLandingPage(this)
    }
    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                        <div className="common_main">

                            {/* Head bar */}
                            <div className="discover_head">
                                {/* <div className="discover_headLeft"><label>{dynamic_content.static_text.main && dynamic_content.static_text.main.discover ? dynamic_content.static_text.main.discover.en : "Discover"}</label></div> */}
                                <div className="discover_headLeft"><img className="discover_image" src={url.cloudflare_image_path + "familyOneLogo_500px.png"+url.varient} alt="" title="" /></div>
                                <div className="discover_headRght"><img src={url.family_one_image_url + 'Group159.png'} alt="" title="" /></div>
                            </div>
                            {/* Head bar */}

                            <div className="nocontion">
                                <div className="nocontion_inner">
                                    <img src={url.family_one_image_url + 'Group228.png'} alt="" title="" />
                                </div>
                            </div>
                            <div className="nocontion_content">
                                <h3>{dynamic_content.static_text.noconnection && dynamic_content.static_text.noconnection.no_connection ? dynamic_content.static_text.noconnection.no_connection.en : "No connection"}</h3>
                                <p>{dynamic_content.static_text.noconnection && dynamic_content.static_text.noconnection.check_internet ? dynamic_content.static_text.noconnection.check_internet.en : "Check your internet connection and try again."}</p>
                            </div>
                            <div className="nocontion_submit">
                                <Button size="medium" color="primary" onClick={() => { this.handlerefresh() }}>
                                    {dynamic_content.static_text.noconnection && dynamic_content.static_text.noconnection.refresh ? dynamic_content.static_text.noconnection.refresh.en : "Refresh"}
                                </Button>
                            </div>

                            {/* Site menu */}
                            <Footer props={this.props} />
                            {/*End of Site menu */}

                        </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


