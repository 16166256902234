/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import programReducer from './programReducer';
import childInvitationReducer from './childInvitationReducer';


const reducers = combineReducers({
    program:programReducer,
    child_invitation:childInvitationReducer
});

export default reducers;
