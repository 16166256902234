import React from 'react';
import axios from "axios";
import '../../assets/css/style.css';
import cookie from 'react-cookies';
import Button from '@material-ui/core/Button';
import LottieLoader from '../LottieLoader';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
// const Cryptr = require('cryptr');
class CloseAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            error_found: false,
            link_expired: false,
            account_close_link_sent: false,
            account_deleted: false,
            response_message: "",
            sub_id: ""
        }
    }

    hexToArrayBuffer = (hex) => {
        const typedArray = new Uint8Array(hex.length / 2);
        for (let i = 0; i < hex.length; i += 2) {
            typedArray[i / 2] = parseInt(hex.substr(i, 2), 16);
        }
        return typedArray.buffer;
    }
    
    // Function to decrypt sub_id
    decryptSubId = async (encryptedString) => {
        // Split the encrypted string into IV and encrypted data
        const [ivHex, encryptedDataHex] = encryptedString.split('/');
    
        // Convert IV and encrypted data from hex to ArrayBuffer
        const iv = this.hexToArrayBuffer(ivHex);
        const encryptedData = this.hexToArrayBuffer(encryptedDataHex);
    
        // Define the key using the same secret (Family.one) as in Lambda
        const secretKey = 'Family.one';
        const keyBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(secretKey));
    
        // Import the key into the Web Crypto API
        const key = await crypto.subtle.importKey(
            'raw',
            keyBuffer,
            { name: 'AES-CBC' },
            false,
            ['decrypt']
        );
    
        // Decrypt the data
        const decryptedData = await crypto.subtle.decrypt(
            { name: 'AES-CBC', iv: iv },
            key,
            encryptedData
        );
    
        // Convert the decrypted data to a string (utf-8)
        const decoder = new TextDecoder();
        return decoder.decode(decryptedData);
    }
    
    componentDidMount = async  () => {
        let encrypted_subid_with_hash = this.props.location.hash ? this.props.location.hash : null
        if (encrypted_subid_with_hash) {
            try {
                let encrypted_subid = encrypted_subid_with_hash.replace("#", "")
                let sub_id = await this.decryptSubId(encrypted_subid);
                this.setState({ sub_id: sub_id })
          
                if (sub_id) {
                    this.VerifyAccountCloseLink(sub_id)
                } else {
                    this.setState({
                        error_found: true,
                        response_message: "Requested link is not valid"
                    })
                }
            }
            catch (error) {
                this.setState({
                    error_found: true,
                    response_message: "Requested link is not valid"
                })
            }
        } else {
            this.setState({
                error_found: true,
                response_message: "Requested link is not valid"
            })
        }
    }

    VerifyAccountCloseLink = (sub_id) => {
        let self = this
        let finaljson = {
            "action": "verify-close-account-link",
            "sub_id": sub_id
        }
      
        this.setState({
            "loader": true
        })
        axios
            .request({
                url: url.closeMyAccount,
                method: "POST",
                data: JSON.stringify(finaljson),
                headers: {
                    "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                }
            })
            .then(function (response) {
              
                if (response && response.data && response.data.success == 1) {
                    self.setState({
                        "loader": false,
                        "account_deleted": true,
                        "link_expired": false,
                        "account_close_link_sent": false
                    })

                    setTimeout(() => {
                        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
                        let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
                        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
                        let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
                        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
                        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
                        localStorage.clear();
                        localStorage.setItem("perks_or_main", perks_or_main)
                        localStorage.setItem("previous_state", previous_state)
                        localStorage.setItem("current_state", current_state)
                        localStorage.setItem("program", JSON.stringify(program))
                        localStorage.setItem("api_auth", api_auth)
                        localStorage.setItem("program_list", JSON.stringify(program_list))
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        cookie.remove("auto_id", { path: "/" })
                        cookie.remove("social_medium", { path: "/" })
                        cookie.remove("social_shared_prog_id", { path: "/" })
                        cookie.remove("social_shared_refered_id", { path: "/" })
                        cookie.remove("winner_later", { path: "/" })
                        cookie.remove("provider", { path: "/" })
                        cookie.remove("facebook_id", { path: "/" })
                        cookie.remove("google_id", { path: "/" })
                        cookie.remove("tracking_id", { path: "/" })
                        cookie.remove("username", { path: "/" })
                        cookie.remove("accept_auto_id", { path: "/" })
                        cookie.remove("relative_id", { path: "/" })
                        cookie.remove("winner_id", { path: "/" })
                        cookie.remove("winner_program_id", { path: "/" })
                        cookie.remove("winner_claim_days", { path: "/" })
                        cookie.remove("claim_status", { path: "/" })
                        cookie.remove("email_changed", { path: "/" })
                        cookie.remove("alternative_email", { path: "/" })
                        self.props.history.push("/")
                    }, 10000)
                }
                else if (response && response.data && response.data.error == 1 && response.data.time_expired) {
                    self.setState({
                        "loader": false,
                        "link_expired": true,
                        "account_deleted": false,
                        "account_close_link_sent": false
                    })
                } else if (response && response.data && response.data.error == 1) {
                    self.setState({
                        "loader": false,
                        "link_expired": false,
                        "account_deleted": false,
                        "account_close_link_sent": false,
                        "error_found": true,
                        "response_message": response.data.message
                    })
                    functions.pushToRoute(self, "main", "closemyaccount")
                }

            })
        // setTimeout(function () {
        //     self.setState({
        //         "loader": false,
        //         "account_deleted": true
        //     })
        // }, 2000)
    }

    ResendCloseAccountLink = () => {
        let self = this
        let data_to_close_account = {
            "action": "send-close-account-link",
            "sub_id": this.state.sub_id
        }
      
        this.setState({
            "loader": true
        })
        axios
            .request({
                url: url.closeMyAccount,
                method: "POST",
                data: JSON.stringify(data_to_close_account),
                headers: {
                    "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                }
            })
            .then(function (close_Account_Response) {
               
                if (close_Account_Response && close_Account_Response.data && close_Account_Response.data.success == 1) {
                    self.setState({
                        "account_close_link_sent": true,
                        "link_expired": false,
                        "account_deleted": false,
                        "error_found": false,
                        "response_message": close_Account_Response.data.message,
                        "loader": false
                    })
                } else {
                    self.setState({
                        "account_close_link_sent": false,
                        "link_expired": false,
                        "account_deleted": false,
                        "response_message": close_Account_Response.data.message,
                        "loader": false,
                        "error_found": true
                    })
                }
            })
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <Modal isOpen={true} className="custom_Addchildmodel" backdrop={'static'} keyboard={false}>
                        <ModalBody className="ntcBody">
                            <div className="ntvcontent">
                                <div className="close-account">
                                    <h5>Account Close Confirmation </h5>
                                    {this.state.loader &&
                                        <center className="loader">
                                            <LottieLoader className="loader_class" />
                                        </center>
                                    }
                                    {!this.state.loader && this.state.account_deleted == true && !this.state.link_expired && !this.state.account_close_link_sent &&
                                        <p>
                                            Your account closure request has been successfully submitted. The account closure and data deletion process has commenced. Once it has completed, you will receive an email as a record of account closure.
                                        </p>
                                    }
                                    {!this.state.loader && this.state.link_expired == true && !this.state.account_close_link_sent && !this.state.account_deleted &&
                                        <div>
                                            <p>
                                                The account closure link has expired. Please submit a new request to close your account.
                                        </p>
                                            <div className="ntcUpdate">
                                                <Button onClick={() => { this.ResendCloseAccountLink() }} size="medium" color="primary">Close My Account</Button>
                                            </div>
                                        </div>
                                    }

                                    {!this.state.loader && this.state.account_close_link_sent == true && !this.state.account_deleted &&
                                        <p>
                                            We have sent you an email. Please follow the instructions in the email to complete your account closure request. <br />The email will only be valid for 30 minutes.
                                        </p>
                                    }

                                    {
                                        this.state.error_found && this.state.response_message != "" &&
                                        <div className="error_message">
                                            <p>{this.state.response_message}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        )
    }
}
export default CloseAccount;