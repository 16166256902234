import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';
import { Modal, ModalBody } from 'reactstrap';
import url from '../../assets/url';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import functions from '../../assets/functions/common_functions';
import Footer from '../innerpages/footer';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';

var initials = require("initials");
const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: lighten('#FFDD3F', 0.7),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#FFDD3F',
    },
})(LinearProgress);

const BorderLinearProgressgreen = withStyles({
    root: {
        height: 6,
        backgroundColor: lighten('#66BD99', 0.5),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#4A66AB',
    },
})(LinearProgress);

export default class Edituser extends React.Component {
    constructor(props) {
        super(props);
        functions.handleBackOne(this, 'profile')
        this.toggleTab = this.toggleTab.bind(this);
        this.edituserverified = this.edituserverified.bind(this);
        this.setting = this.setting.bind(this);
        this.help = this.help.bind(this);
        this.openAccounts = this.openAccounts.bind(this);
        this.getProgress = this.getProgress.bind(this);
        this.state = {
            activeTab: '1',
            freebie_array: [],
            stories_array: [],
            offers_array: [],
            contest_array: [],
            program_array: [],
            winner_data: [],
            sort_index: 0,
            facebook_link: "",
            twitter_link: "",
            dynamic_content: "",
            loader: false,
            relations: [],
            display_name: localStorage.getItem("given_name") + ' ' + localStorage.getItem("family_name"),
            email: localStorage.getItem("email"),
            user_status: '',
            picture: (localStorage.getItem("picture") && localStorage.getItem("picture") != "no_image") ? localStorage.getItem("picture") : ""
        };
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
       
            this.setState({ dynamic_content: dynamic_content })
        }
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.setState({ loader: true })
        localStorage.setItem("active_button", "4")
        let success_action = "User landed on profile page"
        let image = 'profile_page_landing.png'
        functions.logUserActivity(success_action, image);

        let self = this;
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : "null"

        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : "null"
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {

            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.getUserProfile(this, finaljson, function (response_json) {
                let response = JSON.parse(response_json);
              
                if (response.catch_error) {
                    let user_action = "User got error on profile/index file componentWillMount function";
                    let body = response.message
                    functions.logUserError(user_action, body);
                    self.setState({ loader: false, show_error_message: response.message })
                }
                else if (!response.catch_error) {

                    if (response.data.error === 1) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        self.setState({ loader: false, show_error_message: response.data.message })
                    } else if (response.data.statusCode === 400) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        self.setState({ loader: false, show_error_message: response.data.message })
                    }

                    else if (response.status === 200) {
                        if (response.data && response.data.dynamo && response.data.dynamo.Items && response.data.dynamo.Items.length == 0) {
                            self.setState({ loader: false })
                            functions.pushToRoute(self, "", "profile")
                        } else {
                            let data = response.data;
                            let cognito_data = data.cognito ? data.cognito.UserAttributes : []
                            let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {}
                            let relation_data = data.relation_data ? data.relation_data : {}
                            let program_data = data.program_data ? data.program_data : []
                            let winner_data = data.winner_data ? data.winner_data : [];
                            let user_status = dynamo_data.email_verified == 'true' || dynamo_data.email_verified == true ? 'Confirmed' : 'Not verified'
                            functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data);
                            if (dynamo_data.picture && dynamo_data.picture.trim().length > 0 && dynamo_data.picture != "no_image") {
                                self.setState({ picture: dynamo_data.picture })
                            }
                            if(dynamo_data.provider) localStorage.setItem("provider", dynamo_data.provider)
                            self.setState({ user_status: user_status })
                            let relation_data_final = [];
                            if (Object.keys(relation_data).length > 0) {
                                for (var key in relation_data) {
                                   
                                    relation_data[key].forEach(element => {
                                        if (key != 'pendingInvitation') {
                                            relation_data_final.push(element)
                                        }
                                    });
                                }
                          
                                if (relation_data_final.length > 4) {
                                    self.setState({ relations: relation_data_final.slice(0, 4) })
                                }
                                else {
                                    self.setState({ relations: relation_data_final })
                                }
                            }
                            if (Array.isArray(winner_data) && winner_data.length > 0) {
                                let final_winner_data = [];
                                winner_data.forEach(element => {
                                    if (element.claim_status == "pending") {
                                        let date1 = new Date();
                                        let date2 = new Date(element.claim_expiry_date);

                                        var Difference_In_Time = date2.getTime() - date1.getTime();
                                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                    
                                        if (Difference_In_Days < 8) {
                                   
                                            final_winner_data.push(element);
                                        }
                                    }
                                });
                                self.setState({ winner_data: winner_data })
                           
                                localStorage.setItem("winner_data", JSON.stringify(final_winner_data))
                            }
                            let winner_id = cookie.load("winner_id") ? cookie.load("winner_id") : ""
                            if (winner_id != "" && typeof winner_id != undefined && winner_id != dynamo_data.auto_id) {
                                cookie.remove("winner_id", { path: "/" })
                                cookie.remove("winner_program_id", { path: "/" })
                            }
                            let all_program = response.data.program_data;
                            let freebie_array = [];
                            let contest_array = [];
                            let program_array_enter = []
                            let program_array_is_eligble = []
                            let program_array_notenter = []
                            all_program.map((ele) => {
                                if (ele.has_enter) {
                                    program_array_enter.push(ele)
                                }
                                else {
                                    if (ele.is_eligible)
                                        program_array_is_eligble.push(ele);
                                    else
                                        program_array_notenter.push(ele);
                                }
                            })
                            program_array_enter.sort(function (a, b) {
                                return a.order_number - b.order_number
                            })
                            program_array_notenter.sort(function (a, b) {
                                return a.order_number - b.order_number
                            })
                            program_array_is_eligble.sort(function (a, b) {
                                return a.order_number - b.order_number
                            })
                            all_program = [...program_array_is_eligble, ...program_array_notenter, ...program_array_enter]
                            let cst_visibility = self.checkCSTVisibilty(all_program)
                            all_program.forEach((element, index) => {
                                if (element.program_status === "running") {
                                    let element_visibility = true
                                    if (cst_visibility || element.partner_id !== '1') {
                                        if (element.notentry_entry_type) {
                                            element_visibility = self.check_notentry_of_after(element, all_program)
                                        }
                                        if (element.hasOwnProperty('visible_to_user_signup') && element.visible_to_user_signup === false) {
                                            element.is_visible = element.visible_to_user_signup
                                        } else {
                                            if (element.hasOwnProperty("visible_to_user") && !element.notentry_after_of) {
                                                element.is_visible = element.visible_to_user
                                            } else {
                                                element.is_visible = element_visibility ? element_visibility : false
                                            }
                                        }
                                    }
                                    else {
                                        element.is_visible = cst_visibility
                                    }
                                    if (element.has_enter == 0 && element.is_eligible == 0 && element.show_to_eligible_user == 1) {
                                        element.show_program = false
                                    } else {
                                        element.show_program = true
                                    }
                                    switch (element.program_type) {
                                        case "freebie":
                                            if (element.show_to_eligible_user != 1) {
                                                element.program_index = index + 1
                                                freebie_array.push(element)
                                            }
                                            break;
                                        case "contest":
                                            if (element.show_to_eligible_user != 1) {
                                                element.program_index = index + 1
                                                contest_array.push(element)
                                            }
                                            break;
                                    }
                                }
                            });
                            freebie_array.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
                        contest_array.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
                            self.setState({ freebie_array: freebie_array, contest_array: contest_array })
                            self.setState({ loader: false })
                        }
                    }
                }
            })
        } else {
            this.props.history.push('/');
        }
    }

    check_notentry_of_after = (element, program_data) => {
        let not_etnery_type = element.notentry_entry_type;
        let type = element.notentry_after_of_type ? element.notentry_after_of_type : 'program';
        let notentry_after_of = JSON.parse(element.notentry_after_of)
        let notentry_after_of_partner = JSON.parse(element.notentry_after_of_partner)
        if (not_etnery_type == "any") {
            let element_visible = true
            
            if (notentry_after_of) {
                notentry_after_of.forEach((program_element, program_index) => {
                    var temp_index = program_data.findIndex(p => (p.program_id == program_element))
                
                    if (temp_index != -1) {
                        let detail = program_data[temp_index]
                   
                        if (detail.has_enter == 1) {
                            element_visible = false
                        }
                    }
                })
            }
            else if (notentry_after_of_partner) {
             
                notentry_after_of_partner.forEach((program_element, program_index) => {
                    program_data.map(p => {
                        if (p.partner_id == program_element) {
                            if (p.has_enter == 1) {
                                element_visible = false
                            }
                        }
                    })
                })
            }
            return element_visible
        } else if (not_etnery_type == "all") {
            let element_visible = false

            if (notentry_after_of) {
                notentry_after_of.forEach((program_element, program_index) => {
                    var temp_index = program_data.findIndex(p => (type == "program" && p.program_id == program_element) || (type == "partner" && p.partner_id == program_element))
             
                    if (temp_index != -1) {
                        let detail = program_data[temp_index]
                        if (detail.has_enter == 0 || detail.is_eligible == 0) {
                            element_visible = true
                        }
                    }
                })
            }
            else if (notentry_after_of_partner)
                notentry_after_of_partner.forEach((program_element, program_index) => {
                    program_data.map(p => {
                        if (p.partner_id == program_element) {
                            if (p.has_enter == 0 || p.is_eligible == 0) {
                                element_visible = true
                            }
                        }
                    })
                })
            return element_visible
        } else {
            return true
        }
    }

    checkCSTVisibilty = (program_data) => {
        let cst = program_data.filter(ele => ele.partner_id == "14" && ele.cst_consent == "yes" && (ele.pending_fields.length==0||!ele.pending_fields))
        if (cst && cst.length) {
            return false
        } else {
            return true
        }
    }

    dateFormat = (value) => {
   
        let date_array = value.split("-");
        let year = date_array[0];
        let date = date_array[2].substring(0, 2);
        let month = date_array[1];
        let final_date = date + "/" + month + "/" + year;
        return final_date;
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                sort_index: 0,
            });
        }
    }

    edituserverified() {
        functions.pushToRoute(this, 'edituserverified', 'profile');
    }

    setting() {
        let success_action = "User clicked on setting button on profile page"
        let image = 'setting_button_profile_page.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, 'setting', 'profile');
    }

    help() {
        let success_action = "User clicked on help button on profile page"
        let image = ''
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, 'help', 'profile');
    }

    openAccounts() {
        this.setState({
            modal_account: !this.state.modal_account
        });
    }

    handleClick = (value_of_array, index) => {
        let success_action = "User clicked on detail button of program on profile page"
        let image = 'detail_button_of_program_profile_page.png'
        functions.logUserActivity(success_action, image);
      
      
        switch (value_of_array) {
            case "freebie_array":
                localStorage.setItem("program", JSON.stringify(this.state.freebie_array[index]))
                localStorage.setItem("program_index", JSON.parse(JSON.stringify(this.state.freebie_array[index].program_index)))
                localStorage.setItem("program_id", JSON.parse(JSON.stringify(this.state.freebie_array[index].program_id)))
                break;
            case "contest_array":
                localStorage.setItem("program", JSON.stringify(this.state.contest_array[index]))
                localStorage.setItem("program_index", JSON.parse(JSON.stringify(this.state.contest_array[index].program_index)))
                localStorage.setItem("program_id", JSON.parse(JSON.stringify(this.state.contest_array[index].program_id)))
                break;
            case "offers_array":
                localStorage.setItem("program", JSON.stringify(this.state.offers_array[index]))
                localStorage.setItem("program_id", JSON.parse(JSON.stringify(this.state.offers_array[index].program_id)))
                break;
            case "stories_array":
                localStorage.setItem("program", JSON.stringify(this.state.stories_array[index]))
                localStorage.setItem("program_id", JSON.parse(JSON.stringify(this.state.stories_array[index].program_id)))
                break;
        }
       
        localStorage.removeItem("phone_number_required");
        localStorage.removeItem("birthdate_required");
        localStorage.removeItem("term_and_condition_required");
        let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : [];
        localStorage.setItem("detail_page", 'true')
        localStorage.setItem("entered_id", program.program_id)
        localStorage.setItem("CheckProfileRoute", 'profile')
        functions.pushToRoute(this, "main", "profile")
    }

    getProgress = (enter_point, gain_family_points, total_family_points) => {
        let percentage = enter_point;
        if (gain_family_points != 0) {
            percentage = (gain_family_points + enter_point) / total_family_points * 100;
        }
        return percentage;
    }

    handleSorting = (active_tab, sortby, index) => {
        let key = "";
        let program_data = [];
    console.log(active_tab)
        if (active_tab === "1") {
            // Clone contest_array before sorting
            program_data = [...this.state.freebie_array];
            key = "freebie_array";
        } else {
            // Clone freebie_array before sorting
            program_data = [...this.state.contest_array];
            key = "contest_array";
        }
    
        console.log("program_data1", program_data);
    
        if (sortby === "older") {
            program_data.sort(function (a, b) {
                var dateA = new Date(a.start_date);
                var dateB = new Date(b.start_date);
                return dateA - dateB;
            });
        }
    
        if (sortby === "recent") {
            program_data.sort(function (a, b) {
                var dateA = new Date(a.start_date);
                var dateB = new Date(b.start_date);
                return dateB - dateA;
            });
        }
    
        if (sortby === "partners") {
            program_data.sort(function (a, b) {
                return parseInt(b.partner_id) - parseInt(a.partner_id);
            });
        }
    
        console.log("program_data2", program_data);
    
        // Update state using functional updates to ensure correct state transition
        this.setState(prevState => ({
            [key]: program_data,
            sort_index: index
        }), () => {
            // Callback function after state is updated
            this.openAccounts();
        });
    }
    

    handleAddRelation = () => {
        let success_action = "User clicked on add relation button on profile page"
        let image = 'add_relation_button_profile_page.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("form_type", "add_relation")
        functions.pushToRoute(this, 'addrelative', 'profile')
    }

    handleClaim = (element) => {
        let success_action = "User clicked on claim button on profile page"
        let image = 'claim_button_profile_page.png'
        functions.logUserActivity(success_action, image);
 

        cookie.save("claim_status", element.claim_status, { path: "/" })
        cookie.save("winner_program_id", element.program_id, { path: "/" })

        cookie.save("winner_id", element.sub_id, { path: '/' })
        this.handleShowPrize()
    }

    handleUploadReview = (element) => {
        let success_action = "User clicked on upload review button on profile page"
        let image = 'upload_review_button_profile_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        self.setState({ loader: true })
        functions.getWinnerProgramDetail(this, element.program_id, element.sub_id, function (response_json) {
            let response = JSON.parse(response_json);
            if (response.catch_error) {
                self.setState({ loader: false })
                let user_action = "User got error on profile/index file handleUploadReview function";
                let body = response.message
                functions.logUserError(user_action, body);
                self.setState({ show_error_message: response.message });
            }
            else if (!response.catch_error) {
                if (response.data.error === 1) {
                    self.setState({ loader: false })
                    let user_action = "User got error on profile/index file handleUploadReview function";
                    let body = response.data.message
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: response.data.message })
                } else {
                    self.setState({ loader: false })
                    let program = response.data.program_detail
                    let program_array = []
                    program_array.push(program)

                    let temp_program = program_array[0]
                    localStorage.setItem("program", JSON.stringify(temp_program))
                    localStorage.setItem("program_index", 0)
                    localStorage.setItem("program_id", element.program_id)
                    localStorage.setItem("winner_review", "true")
                    functions.pushToRoute(self, 'uploadimg', 'profile')
                }
            }
        })
    }

    handleShowPrize = () => {
        localStorage.setItem("detail_page", 'false')
        functions.pushToRoute(this, 'prize_detail', 'profile')
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    <div className="common_main">
                        {this.state.loader &&
                            <center className="lottieLoadCntr">
                                <LottieLoader />
                            </center>
                        }
                        {this.state.loader &&
                            <div className="common_container_width common_main_rmove"></div>
                        }
                        {!this.state.loader && dynamic_content != "" &&
                            <div >
                                <div className="help_label help_labelNw">
                                  <label className="perks_title perks_titleNw">
                                    {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.profile.en : "Profile"}
                                 </label>
                                </div>
                                <div className="profileHead">
                                <div className="profile_pic">
                                    {this.state.picture != "" &&
                                        // <img src={url.image_path + relation.picture+url.varient} alt="" title="" />
                                        <a><img src={this.state.picture} alt="" title="" />
                                            {/* <span>+</span> */}
                                        </a>
                                    }
                                    {this.state.picture == "" &&
                                        <p className="profile_initials">
                                            <span className="initials_class profile_initials_child">
                                                {initials((this.state.display_name).toUpperCase()).substring(0, 2)}
                                            </span>
                                            <a className="profilPlus">+</a>
                                        </p>
                                    }
                                    {/* <a><img src={url.family_one_image_url + 'Rectanglere3.136.png'} alt="" title="" />

                            </a> */}
                                </div>
                                <div className={this.state.user_status == "Confirmed" ? "andres_verified" : "andres_sec_main"}>
                                    <div className="profile_display_name"><h1>{this.state.display_name}</h1>
                                        {this.state.user_status == "Confirmed" &&
                                            <div><img src={require('../../assets/images/StarRate-svg.svg')} alt="" title="" /></div>
                                        }
                                    </div>
                                    <div className="andres_sec">
                                        <div><p>{this.state.email}</p></div>
                                        {/* <div> <Button size="medium" color="primary" className={this.state.user_status == "Confirmed" ? "cus_green" : "cus_red"} >
                                            {this.state.user_status}
                                        </Button>
                                        </div> */}
                                    </div>
                                </div>
                                </div>
                                <div onClick={() => { functions.pushToRoute(this, "editprofile", 'profile') }} className="edit_content">
                                    <div className="edit_contentLeft">
                                      <img src={require('../../assets/images/EditNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.edit_profile.en : "Edit profile"}</span>
                                    </div>
                                    <div className="edit_contentRght">
                                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                                    </div>
                                </div>
                                <div onClick={this.setting} className="edit_content">
                                    <div className="edit_contentLeft">
                                      <img src={require('../../assets/images/FilterNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.settings.en : "Settings"}</span>
                                    </div>
                                    <div className="edit_contentRght">
                                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                                    </div>
                                </div>
                                <div onClick={this.help} className="edit_content">
                                    <div className="edit_contentLeft"> 
                                      <img src={require('../../assets/images/InfoSquareNew.svg')} alt="" title="" /><span>{"Help/Support"}</span>
                                    </div>
                                    <div className="edit_contentRght">
                                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                                    </div>
                                </div>
                                {this.state.winner_data && this.state.winner_data.length > 0 && this.state.winner_data.map((element, index) => (
                                    <div key={index} className="winClaim">
                                        <div className="winClaimLft">
                                            <div className="winClaimInr">
                                                <div className="winClaimInrLft">
                                                    <a>
                                                        {element.claim_status != "claimed" &&
                                                            <img src={url.family_one_image_url + 'winner-purple-icon.png'} alt="" title="" />
                                                        }
                                                        {element.claim_status == "claimed" &&
                                                            <img style={{ marginRight: "5px", marginTop: "9px" }} src={url.family_one_image_url + 'purple-trophy-with-checkmark.png'} alt="" title="" />
                                                        }
                                                    </a>
                                                </div>
                                                <div className="winClaimInrLft">
                                                    <label>{ReactHtmlParser(element.prize_name)} </label>
                                                    <p>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.winner.en : "Winner"},
                                                        <span>{" " + this.dateFormat(element.winning_date)}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {element.claim_status == "claimed" &&
                                            <div className="winClaimRght"
                                                onClick={() => {
                                                    // this.handleClaim(element)
                                                    this.handleUploadReview(element)
                                                }}
                                            >
                                                <a style={{
                                                    padding: "6px 8px",
                                                    fontSize: "14px",
                                                    cursor: "pointer"
                                                }}>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.upload_review.en : "Upload Review"}</a>
                                            </div>
                                        }
                                        {element.claim_status != "claimed" &&
                                            <div className="winClaimRght" onClick={() => { this.handleClaim(element) }}>
                                                <a style={{
                                                    cursor: "pointer"
                                                }}>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.claim.en : "Claim"}</a>
                                            </div>
                                        }

                                    </div>
                                ))}

                                <div className="editagain_tabs">
                                    <div className="tab_btmbar">
                                        {/* <div className="tab_btmbarLft"> */}
                                        <div id="navbar" className="extragain_tabs extragainTabsCustm">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.toggleTab('1'); }}>
                                                        {this.state.activeTab === '1' && (
                                                           <div className='extraTabDtl'> 
                                                            <img src={require('../../assets/images/Freebies-active-Icon.svg')} alt="" title="" />
                                                            <label>Freebies</label>
                                                           </div> 
                                                        )}
                                                        {this.state.activeTab !== '1' && (
                                                            <div className='extraTabDtl'> 
                                                              <img src={require('../../assets/images/Freebies-Icon.svg')} alt="" title="" />
                                                              <label>Freebies</label>
                                                            </div>
                                                        )}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.toggleTab('2'); }}>
                                                        {this.state.activeTab === '2' && (
                                                             <div className='extraTabDtl extraTabDtl2'> 
                                                              <img src={require('../../assets/images/Giveaways-active-Icon.svg')} alt="" title="" />
                                                              <label>Giveaways</label>
                                                            </div>
                                                        )}
                                                        {this.state.activeTab !== '2' && (
                                                             <div className='extraTabDtl extraTabDtl2'> 
                                                              <img src={require('../../assets/images/Giveaways-Icon.svg')} alt="" title="" />
                                                              <label>Giveaways</label>
                                                            </div>
                                                        )}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>

                                        <div className="tab_btmbarRght">
                                            <img src={require('../../assets/images/threeDots.svg')} alt="" title="" onClick={this.openAccounts} />
                                        </div>

                                    </div>
                                </div>

                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        {this.state.loader &&
                                            <center>
                                                <LottieLoader />
                                            </center>
                                        }
                                        {!this.state.loader && this.state.freebie_array && this.state.freebie_array.length > 0 && this.state.freebie_array.map((program, index) => (
                                            program.is_visible && <div key={index}>


                                                <div className="extra_fremain">
                                                    <div className="extra_fre">
                                                        <div className="extra_freLeft"><img src={(program.program_short_image ? url.cloudflare_image_path + program.program_short_image : url.cloudflare_image_path + program.program_image)+url.varient} alt="" title="" /></div>
                                                        <div className="extra_freRght">
                                                            <label>{ReactHtmlParser(program.program_title)}</label>
                                                            <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {program.program_category == 'Instant' &&
                                                            <a className="ticket_16fre">
                                                                <img src={url.family_one_image_url + 'Vector3.png'} alt="" title="" />
                                                                <span>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.instant.en : "Instant"}</span>
                                                            </a>
                                                        }
                                                        {program?.is_lock_program == 1 &&
                                                            <a className="crown_16fre">
                                                                <img src={url.family_one_image_url + 'Group191.png'} alt="" title="" />
                                                                <span>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.exclusive.en : "Exclusive"}</span>
                                                            </a>
                                                        }
                                                        {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 &&
                                                            <div className="add_family">
                                                                <div className="add_familySocial2">
                                                                    {program.social_activity.total_gain_social_share_points > 0 &&
                                                                        <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />

                                                                            <img src={url.family_one_image_url + 'Groupright237.png'} alt="" title="" className="rightsymbol" />
                                                                        </a>
                                                                    }
                                                                    {program.social_activity.total_gain_social_share_points == 0 &&
                                                                        <a href={"https://twitter.com/share?url=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$twitter"} rel="noopener noreferrer" target="_blank">
                                                                            <img src={require('../../assets/images/twiterImg2.svg')} alt="" title="" />
                                                                        </a>
                                                                        // <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />
                                                                        // </a>
                                                                    }
                                                                    <a href={"http://www.facebook.com/sharer.php?u=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$facebook"} rel="noopener noreferrer" target="_blank">
                                                                      <img src={require('../../assets/images/faceBook2.svg')} alt="" title="" />
                                                                    </a>
                                                                </div>
                                                                {this.state.relations && this.state.relations.length == 0 &&
                                                                    <div className="add_familyBtn">
                                                                        <Button size="medium" color="primary" onClick={() => { this.handleAddRelation() }} >
                                                                            {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.add_family.en : "Add Family"}
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                {this.state.relations && this.state.relations.length > 0 &&
                                                                    <div key={index} className="add_familyBtn2 profile_relative">
                                                                        {/* {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                            <div key={index} className="custom_align">
                                                                                {relation.picture && relation.picture.trim().length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                    <a><img src={url.cloudflare_image_path + relation.picture+url.varient} alt="" title="" /></a>
                                                                                    :
                                                                                    <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                                }
                                                                            </div>

                                                                        ))} */}
                                                                        <a className='addFmlyNw'> <Button onClick={() => { this.handleAddRelation() }} size="medium" color="primary">Add family</Button> </a>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {program.has_enter == 0 && program.direct_member == 1 &&
                                                            <div className="add_family3">
                                                                <div className="add_familySocial3">
                                                                    <Button size="medium" color="primary" onClick={() => { this.handleClick("freebie_array", index) }} >
                                                                        {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.details.en : "Details"}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }

                                                        {program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 &&
                                                            <div className="add_family3">
                                                                <div className="add_familySocial3">
                                                                    <Button size="medium" color="primary" onClick={() => { this.handleClick("freebie_array", index) }} >
                                                                        {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.details.en : "Details"}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }

                                                        {program.has_enter == 0 && program.direct_member == 0 && program.is_eligible == 1 &&
                                                            <div className="add_family">
                                                                <div className="add_familySocial2">
                                                                    {program.social_activity.total_gain_social_share_points > 0 &&
                                                                        <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />

                                                                            <img src={url.family_one_image_url + 'Groupright237.png'} alt="" title="" className="rightsymbol" />
                                                                        </a>
                                                                    }
                                                                    {program.social_activity.total_gain_social_share_points == 0 &&
                                                                        <a href={"https://twitter.com/share?url=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$twitter"} rel="noopener noreferrer" target="_blank">
                                                                          <img src={require('../../assets/images/twiterImg2.svg')} alt="" title="" />
                                                                        </a>
                                                                        // <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />
                                                                        // </a>
                                                                    }
                                                                    <a href={"http://www.facebook.com/sharer.php?u=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$facebook"} rel="noopener noreferrer" target="_blank">
                                                                       <img src={require('../../assets/images/faceBook2.svg')} alt="" title="" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>

                                    <TabPane tabId="2">

                                        {!this.state.loader && this.state.contest_array && this.state.contest_array.length > 0 && this.state.contest_array.map((program, index) => (
                                            program.is_visible && <div key={index} className="contests_content">
                                                <div className="extra_contmain">
                                                    <div className="extra_cont">
                                                        <div className="extra_contLeft"><img src={(program.program_short_image ? url.cloudflare_image_path + program.program_short_image : url.cloudflare_image_path + program.program_image)+url.varient} alt="" title="" onClick={() => { this.handleClick("contest_array", index) }} /></div>
                                                        <div className="extra_contRght">
                                                            <label>{ReactHtmlParser(program.program_title)}</label>
                                                            <p>{program.program_subtitle}</p>
                                                        </div>
                                                    </div>
                                                    {program.has_enter == 1 && program.social_activity.total_gain_social_share_points == 0 && program.pending_fields && program.pending_fields.length == 0 &&
                                                        <div className="contProgress">

                                                            {/* <div className="showProgess">
                                                                <div className="showProgess_num"><label>{program.family_activity.gain_family_points + program.has_enter}</label></div>
                                                                <div className="showProgess_txt"><p>Potential <b>{program.family_activity.total_family_points + program.has_enter} {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.pts.en : "pts"}</b></p></div>
                                                            </div> */}

                                                            <div className='countPoints'>
                                                               <label>{program.family_activity.gain_family_points + program.has_enter} pts / </label><span> {program.family_activity.total_family_points + program.has_enter} pts</span>
                                                            </div>
                                                            <BorderLinearProgress
                                                                variant="determinate"
                                                                color="secondary"
                                                                value={this.getProgress(program.has_enter, program.family_activity.gain_family_points, program.family_activity.total_family_points)}
                                                            />
                                                        </div>
                                                    }

                                                    {program.has_enter == 0 && program.direct_member == 0 && program.is_eligible == 1 && program.social_activity.total_gain_social_share_points > 0 &&
                                                        <div className="contProgress_upr">
                                                            <div className="contProgress_lft">
                                                                <div className="showProgess_num2 showProgess_num_pr"><label>{program.social_activity.total_gain_social_share_points}</label></div>
                                                                <BorderLinearProgressgreen
                                                                    variant="determinate"
                                                                    color="secondary"
                                                                    value={100}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {program.has_enter == 1 && program.social_activity.total_gain_social_share_points > 0 && program.pending_fields && program.pending_fields.length == 0 &&
                                                        <div className="contProgress_upr">
                                                            <div className="contProgress_lft">
                                                                <div className="showProgess_num2 showProgess_num_pr"><label>{program.social_activity.total_gain_social_share_points}</label></div>
                                                                <BorderLinearProgressgreen
                                                                    variant="determinate"
                                                                    color="secondary"
                                                                    value={100}
                                                                />
                                                            </div>
                                                            <div className="contProgress_rght">
                                                                <div className="showProgess2">
                                                                    <div className="showProgess_num2"><label>{program.family_activity.gain_family_points + program.has_enter}</label></div>
                                                                    <div className="showProgess_txt2"><p>Potential <b>{program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter} pts</b></p></div>
                                                                </div>
                                                                <BorderLinearProgress
                                                                    variant="determinate"
                                                                    color="secondary"
                                                                    value={50}
                                                                />
                                                            </div>
                                                        </div>
                                                    }



                                                    {program.has_enter == 0 && program.direct_member == 0 && program.is_eligible == 1 &&
                                                        <div className="add_family2">
                                                            <div className="add_familySocial2">
                                                                {program.social_activity.total_gain_social_share_points > 0 &&
                                                                    <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />

                                                                        <img src={url.family_one_image_url + 'Groupright237.png'} alt="" title="" className="rightsymbol" />
                                                                    </a>
                                                                }
                                                                {program.social_activity.total_gain_social_share_points == 0 &&
                                                                    <a href={"https://twitter.com/share?url=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$twitter"} rel="noopener noreferrer" target="_blank">
                                                                        <img src={require('../../assets/images/twiterImg2.svg')} alt="" title="" />
                                                                    </a>
                                                                    // <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />
                                                                    // </a>
                                                                }
                                                                <a href={"http://www.facebook.com/sharer.php?u=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$facebook"} rel="noopener noreferrer" target="_blank">
                                                                    <img src={require('../../assets/images/faceBook2.svg')} alt="" title="" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    }

                                                    {program.has_enter == 1 && program.pending_fields && program.pending_fields.length == 0 &&
                                                        <div className="add_family2">
                                                            <div className="add_familySocial2">
                                                                {program.social_activity.total_gain_social_share_points > 0 &&
                                                                    <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />

                                                                        <img src={url.family_one_image_url + 'Groupright237.png'} alt="" title="" className="rightsymbol" />
                                                                    </a>
                                                                }
                                                                {program.social_activity.total_gain_social_share_points == 0 &&
                                                                    <a href={"https://twitter.com/share?url=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$twitter"} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'Group166.png'} alt="" title="" /></a>
                                                                    // <a><img src={url.family_one_image_url + 'Grouptwiter166.png'} alt="" title="" />
                                                                    // </a>
                                                                }
                                                                <a href={"http://www.facebook.com/sharer.php?u=" + url.social_share_url + "?program_sub_id=" + program.program_id + "$" + cookie.load("auto_id") + "$facebook"} rel="noopener noreferrer" target="_blank">
                                                                  <img src={require('../../assets/images/faceBook2.svg')} alt="" title="" />
                                                                </a>
                                                            </div>
                                                            {this.state.relations && this.state.relations.length == 0 &&
                                                                <div className="add_familyBtn">
                                                                    <Button size="medium" color="primary" onClick={() => { this.handleAddRelation() }} >
                                                                        {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.add_family.en : "Add Family"}
                                                                    </Button>
                                                                </div>
                                                            }
                                                            {this.state.relations && this.state.relations.length > 0 &&
                                                                <div key={index} className="add_familyBtn2 profile_relative">
                                                                    {/* {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                        <div key={index} className="custom_align">
                                                                            {relation.picture && relation.picture.trim().length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                <a><img src={url.cloudflare_image_path + relation.picture+url.varient} alt="" title="" /></a>
                                                                                :
                                                                                <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                            }
                                                                        </div>

                                                                    ))} */}
                                                                    <a className='addFmlyNw'>
                                                                        <Button onClick={() => { this.handleAddRelation() }} size="medium" color="primary">Add family</Button>
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {program.has_enter == 0 && program.direct_member == 1 &&
                                                        <div className="add_family3">
                                                            <div className="add_familySocial3">
                                                                <Button size="medium" color="primary" onClick={() => { this.handleClick("contest_array", index) }} >
                                                                    {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.details.en : "Details"}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }

                                                    {program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 &&
                                                        <div className="pending_entry">
                                                            <a className="ticket_16_two">
                                                                {program.family_activity.gain_family_points + program.social_activity.total_gain_social_share_points + program.has_enter} pts
                                                            </a>
                                                            <span> {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.pending_for_entry.en : "Are Pending for Entry"} </span>
                                                        </div>
                                                    }

                                                    {program.has_enter == 1 && program.pending_fields && program.pending_fields.length > 0 &&
                                                        <div className="add_family3">
                                                            <div className="add_familySocial3">
                                                                <Button size="medium" color="primary" onClick={() => { this.handleClick("contest_array", index) }} >
                                                                    {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.details.en : "Details"}
                                                                </Button>
                                                            </div>
                                                            {this.state.relations && this.state.relations.length == 0 &&
                                                                <div className="add_familyBtn">
                                                                    <Button size="medium" color="primary" onClick={() => { this.handleAddRelation() }} >
                                                                        {dynamic_content.static_text.profile ? dynamic_content.static_text.profile.add_family.en : "Add Family"}
                                                                    </Button>
                                                                </div>
                                                            }
                                                            {this.state.relations && this.state.relations.length > 0 &&
                                                                <div key={index} className="add_familyBtn2 profile_relative">
                                                                    {/* {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                                                        <div key={index} className="custom_align">
                                                                            {relation.picture && relation.picture.trim().length > 0 && relation.picture.trim() !== 'no_image' ?
                                                                                <a><img src={url.cloudflare_image_path + relation.picture+url.varient} alt="" title="" /></a>
                                                                                :
                                                                                <p className=""><span className="initials_class">{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</span> </p>
                                                                            }
                                                                        </div>

                                                                    ))} */}
                                                                    <a className='addFmlyNw'> <Button onClick={() => { this.handleAddRelation() }} size="medium" color="primary">Add family</Button> </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {/*End of First section */}
                                            </div>
                                        ))}

                                    </TabPane>
                                </TabContent>
                                {/* End of Tabs content */}


                                {/* Type of deal model */}
                                <Modal isOpen={this.state.modal_account} className="sort_model">
                                    <ModalBody className="sort_Body">
                                        <div className="sort_deal">
                                            <div className="sort_dealBy">
                                                <span>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.sort_by.en : "Sort by"}</span>
                                                <a onClick={this.openAccounts} ><img src={require('../../assets/images/closeChild.svg')} alt="" title="" /></a>
                                            </div>
                                            <ul>
                                                <li><a className={this.state.sort_index == 0 ? "active_sorting" : "notactive_sorting"} onClick={() => { this.handleSorting(this.state.activeTab, 'recent', 0) }}>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.recent.en : "Recent"}</a>
                                                    {this.state.sort_index == 0 &&
                                                        <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" />
                                                    }
                                                </li>
                                                <li><a className={this.state.sort_index == 1 ? "active_sorting" : "notactive_sorting"} onClick={() => { this.handleSorting(this.state.activeTab, 'older', 1) }}>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.older_first.en : "Older First"}</a>
                                                    {this.state.sort_index == 1 &&
                                                        <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" />
                                                    }
                                                </li>
                                                <li><a className={this.state.sort_index == 2 ? "active_sorting" : "notactive_sorting"} onClick={() => { this.handleSorting(this.state.activeTab, 'partners', 2) }}>{dynamic_content.static_text.profile ? dynamic_content.static_text.profile.partners.en : "Partners"}</a>
                                                    {this.state.sort_index == 2 &&
                                                        <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" />
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                {/* Type of deal model */}


                                {/* Site menu */}
                                <Footer props={this.props} />
                                {/*End of Site menu */}

                            </div>
                        }
                    </div>
                  </div>  
                </div>
            </div>
        );
    }
}