import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import TermsCondition from '../TermsCondition/index'
import $ from "jquery";
import ReactHtmlParser, {
    // processNodes,
    // convertNodeToElement,
    // htmlparser2
} from "react-html-parser";

export default class kidsgofreecode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            current_perk: {},
            show_t_and_c: false,
            show_detail: false,
            dynamic_content: "",
            tomme_coupon_code: ""
        }
    }

    componentDidMount = () => {
        // if (localStorage.getItem("current_state") === "perksonlinecode") {
        // }
        // else {
        //     // Redirect to "/" if current_state is not "story_detail"
        //     this.props.history.push("/");
        //     // Reload the window to ensure any necessary updates or resets
        //     window.location.reload();
        //   }
        let detail = JSON.parse(localStorage.getItem("perks_detail"))
        if (detail.auto_id === "54391057" || detail.auto_id === "69393410" || detail.auto_id === "44888985") {
            this.setState({ tomme_coupon_code: detail.coupon_code })
        }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            let old_enter_terms_policy = dynamic_content.static_text.perksonlinecode.enter_terms_policy.en
            let color = this.state?.detail?.is_upgraded === 1 ? detail.detail_custom.terms_color : ""
            let new_enter_terms_policy = old_enter_terms_policy.replace('<span style="%COLOR_STYLE%">', '<span class ="perk_terms" id = ' + detail.auto_id + 'style=' + color + '>')
            new_enter_terms_policy = old_enter_terms_policy.replace("%TERMS%", detail.terms)
            dynamic_content.static_text.perksonlinecode.enter_terms_policy.en = new_enter_terms_policy
            this.setState({ dynamic_content: dynamic_content })
        }
        let action = "User landed on perksonlinecode page"
        let image = 'perksonlinecode_page_landing.png'
        functions.logUserActivity(action, image);
        window.scrollTo(0, 1);

        this.setState({ detail: detail, show_detail: true })
        this.handleTermsClick()
    }

    handleCopy = () => {
        let action = "User clicked on copy link on perksonlinecode page"
        let image = 'copy_link_perksonlinecode_page.png'
        functions.logUserActivity(action, image);
        var copyText = document.getElementById("onlinecode");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        // alert("Copied: " + copyText.value);
        copyText.blur();
    }

    handleRedeem = () => {
        let action = "User clicked on redeem button on perksonlinecode page"
        let image = 'redeem_button_perksonlinecode_page.png'
        functions.logUserActivity(action, image);
        let perk_id = this.state.detail.auto_id
        let redeem_type = 'online'
        functions.insertInPerks(this, perk_id, redeem_type, function (json_reponse) {
            // let response = JSON.parse(json_reponse);
        })
    }

    handleTerms = (perk) => {
        let action = "User clicked on term and condition link on perksonlinecode page"
        let image = 'term_and_condition_link_perksonlinecode_page.png'
        functions.logUserActivity(action, image);
        perk.term_and_condition = perk.terms
        this.setState({ current_perk: perk, show_t_and_c: true });
    }

    handleTermsClick = () => {
        let self = this;
        $(document).on('click', '.perk_terms', function () {
            self.handleTerms(self.state.detail)
        })
    }

    handleCloseTerms = () => {
        this.setState({ show_t_and_c: false })
    }

    handleClose = () => {
        let action = "User clicked on back button on perksonlinecode page"
        let image = 'back_button_perksonlinecode_page.png'
        functions.logUserActivity(action, image);
        if (localStorage.getItem("perk_source") === "perks") {
            this.props.history.push("/perks")
        }
        else if (localStorage.getItem("perk_source") === "main") {
            this.props.history.push("/main");
        } else {
            this.props.history.push("/perks");
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {this.state.dynamic_content !== "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className="common_main_kid" style={this.state?.detail?.is_upgraded === 1 ? { backgroundSize: "cover", backgroundImage: "url(" + "https://app2.family.one/images/" + this.state.detail.detail_custom.page_background + ")" } : {}}>

                                <div className='redemptionHead'>
                                    <div className='redemptionHeadLft' onClick={() => { this.handleClose() }}>
                                        <img src={require('../../assets/images/bkArorIcon.svg')}  alt="" title="" />
                                    </div>
                                    <div className='redemptionHeadRght' onClick={() => { this.handleClose() }}>
                                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="kidcode">
                                        <div className="ticketcode_inner">
                                            <img src={(this.state?.detail?.is_upgraded === 1 ? url.cloudflare_image_path + this.state.detail.detail_custom.logo : url.cloudflare_image_path + this.state.detail.perk_image) + url.varient} alt="" title="" width="154px" heigth="154px" />
                                        </div>
                                    </div>
                                    {this.state.detail && this.state.detail.title &&
                                        <div className="kidcode_content">
                                            <h1 style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.title_color } : {}}>{this.state.detail.title}</h1>
                                            <p style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}>{this.state.detail.subtitle}</p>
                                            {(this.state.detail.auto_id === "54391057" || this.state.detail.auto_id === "44888985") && <p className="tomme-coupon" style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}>By clicking “Redeem” you’ll be taken to <a target="blank" href="https://amzn.to/3ugmSnj">Amazonn.ca</a> where you can view the discounted products collection</p>}
                                        </div>
                                    }

                                    <div className="kidcode_copy">
                                        {this.state.detail && this.state.detail.online_code !== "No Code" &&
                                            <div>
                                                <p style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.redeem_color, opacity: 0.4 } : {}}>{dynamic_content.static_text.perksonlinecode.redemption_code.en}</p>
                                                <div style={this.state?.detail?.is_upgraded === 1 ? { background: this.state.detail.detail_custom.background } : {}} className="kidcode_inr">
                                                    <input style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.text1_color } : {}} id="onlinecode" type="text" value={this.state?.detail?.is_upgraded === 1 ? this.state.detail.upgrade_value.online_code : this.state.detail.online_code} />
                                                    <span style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.copy_color, cursor: "pointer" } : {}} onClick={() => { this.handleCopy() }} >{dynamic_content.static_text.perksonlinecode.copy.en}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="kidcode_submit" >
                                        <a href={this.state?.detail?.is_upgraded === 1 ? this.state.detail.upgrade_value.link_for_online : this.state.detail.link_for_online} target="_blank">
                                            <Button style={this.state?.detail?.is_upgraded === 1 ? { background: this.state.detail.detail_custom.button_color } : {}} size="medium" color="primary" onClick={() => { this.handleRedeem() }}>
                                                {dynamic_content.static_text.perksonlinecode.redeem.en}
                                            </Button>
                                        </a>
                                        {this.state.detail && this.state.detail.terms &&
                                            <p style={this.state?.detail?.is_upgraded === 1 ? { color: this.state.detail.detail_custom.terms_color } : {}}>
                                                {ReactHtmlParser(dynamic_content.static_text.perksonlinecode.enter_terms_policy.en)}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            {this.state.show_t_and_c &&
                                <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_perk} />
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}