import React from 'react';
import '../../assets/css/style.css';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

export default class Terms extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dynamic_content: ""
        }
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let action = "User landed on support terms page"
        let image  = 'support_terms_page_landing.png'
        functions.logUserActivity(action, image);
    }

    handlePreviousPage = () => {
        let action = "User clicked on back button on support terms page"
        let image  = 'back_button_support_terms_page.png' 
        functions.logUserActivity(action, image);
        this.props.onBack()
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                {dynamic_content != "" &&
                      <div className="common_mainAddOn">
                        <div className="common_main" id="termsCondition">
                            <div>
                            <div className="sign_backlink sign_backlinkNw" onClick={() => { this.handlePreviousPage() }} >
                                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>
                                <div className="sharegain_up">
                                    <h1>{dynamic_content.static_text.extra ? dynamic_content.static_text.extra.terms_and_conditions.en : "Terms and Conditions"}</h1>
                                </div>

                                <div className="terms_content 2323">

                                    <h2>{dynamic_content.static_text.extra ? dynamic_content.static_text.extra.term_and_condition_sub_heading.en : ""}</h2>

                                    <div>{dynamic_content.static_text.extra ? ReactHtmlParser(dynamic_content.static_text.extra.term_and_condition_description.en) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>  
                    }
                </div>
            </div>
        );
    }
}