import React from 'react';
import '../../assets/css/style.css';
import functions from '../../assets/functions/common_functions';
import { TimelineLite, TweenLite, TweenMax, TimelineMax, Bounce, Circ, Linear, Power1, Power2, Power3, Back, Power4, SlowMo } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import url from '../../assets/url';
import Swiper from 'react-id-swiper';
import cookie from 'react-cookies';
const C = CSSPlugin;

class AllNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_urgent: true,
            dynamic_content: "",
            final_array: [],
            final_urgent_data: [],
            urgent_data: localStorage.getItem("urgent_data") ? JSON.parse(localStorage.getItem("urgent_data")) : [],
        };
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let self = this;
        let urgent_data = localStorage.getItem("urgent_data") ? JSON.parse(localStorage.getItem("urgent_data")) : []

        var final_urgent_data = [], size = 3;

        while (urgent_data.length > 0) {
            final_urgent_data.push(urgent_data.splice(0, size));

        }
        let final_array = []
        final_urgent_data.forEach((element, index) => {
            final_array.push(index)
        });
        this.setState({ final_urgent_data: final_urgent_data, final_array: final_array })

        if (this.props.notify_number > 0) {
            TweenMax.to('.notification_pop', 1, { scale: 1.1, ease: Bounce.easeOut, repeat: -1 });
        }
    }

    handleClose = (index, value1, even) => {
        even.stopPropagation()
        let success_action = "User clicked on close button on urgent page"
        let image = 'close_button_urgent_page.png'
        functions.logUserActivity(success_action, image);
        let auto_id = value1.auto_id
        this.setState({ final_urgent_data: [] })
        let urgent_data = localStorage.getItem("urgent_data") ? JSON.parse(localStorage.getItem("urgent_data")) : [];
        let final_urgent_data = this.state.final_urgent_data;
        let new_urgent_data = []
        let new_array = []
        urgent_data.forEach(element => {
            if (element.auto_id != auto_id) {
                new_urgent_data.push(element)
                new_array.push(element)
            }
            else {
                functions.handleDisableUrgentNotification(this, auto_id)
            }
        });
        this.setState({ urgent_data: new_array })
        if (new_array.length < 1) {
            this.setState({ final_urgent_data: [] })
            localStorage.removeItem("urgent_data")
            this.setState({ is_urgent: false })
            this.props.setUrgent()
        } else {
            final_urgent_data = []
            let size = 3;

            while (new_urgent_data.length > 0) {
                final_urgent_data.push(new_urgent_data.splice(0, size));
            }
            let final_array = []
            final_urgent_data.forEach((element, index) => {
                final_array.push(index)
            });
            this.setState({ final_urgent_data: final_urgent_data, final_array: final_array })
            localStorage.setItem("urgent_data", JSON.stringify(new_array))
        }
    }

    handleShowPrize = () => {
        let success_action = "User clicked on winner notification on urgent page"
        let image = 'notification_button_urgent_page.png'
        functions.logUserActivity(success_action, image);
        localStorage.setItem("detail_page", 'false')
        functions.pushToRoute(this.props, 'prize_detail', 'main')
    }

    handleClick = (element1) => {
        let success_action = "User clicked on " + element1.notification_type + " notification on urgent page"
        let image = 'notification_button_urgent_page.png'
        functions.logUserActivity(success_action, image);
        if (element1.notification_type == "winner_review") {
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : []
            if (program_list.length > 0) {
                var index = program_list.findIndex(p => p.program_id == element1.program_id)
                let temp_program = program_list[index]
                localStorage.setItem("program", JSON.stringify(temp_program))
                localStorage.setItem("program_index", index + 1)
                localStorage.setItem("program_id", element1.program_id)
                functions.pushToRoute(this.props, 'winner_review', 'main')
            }
        }
        else if (element1.notification_type == "email_verification") {
            // localStorage.setItem('entered_id', element1.program_id)
            // this.props.handleUrgentProgramClick()
        }
        else if (element1.notification_type == "phone_verification") {
            localStorage.setItem('entered_id', element1.program_id)
            this.props.handleUrgentProgramClick()
        }
        else if (element1.notification_type == "winner_notification") {
            let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : "";
            let winner_program_id = cookie.load("winner_program_id")
            let next = false;
            if (winner_data) {
                winner_data.forEach((element, index) => {
                    if (winner_program_id == element.program_id && element.claim_status == 'pending') {
                        cookie.save("claim_status", element.claim_status, { path: '/' })
                        next = true;
                    }
                });
                if (next) {
                    if (element1.sub_id == cookie.load("auto_id")) {
                        cookie.save("winner_id", element1.sub_id, { path: '/' })
                    }
                    this.handleShowPrize()
                }
                else {
                    cookie.save("claim_status", "claimed", { path: "/" })
                    this.handleShowPrize()
                }
            }
        }

        // else if (element1.notification_type == "WinnerInvitation") {
        //     let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : ""
        //     let winner_program_id = cookie.load("winner_program_id")
        //     let next = true;
        //     winner_data.forEach((element, index) => {
        //         if (winner_program_id == element.program_id && element.claim_status == 'pending') {
        //             cookie.save("claim_status", element.claim_status, { path: '/' })
        //             // next = true;
        //         }
        //     });
        //     if (next) {
        //         if (element1.sub_id == element1.winner_id) {
        //             cookie.save("winner_id", element1.winner_id, { path: '/' })
        //         }
        //         this.handleShowPrize()
        //     }
        // }
        // else if (value.notification_type == "ReviewRejected" || value.notification_type == "ReviewApproved") {
        //     let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : []
        //     if (program_list.length > 0) {
        //         var index = program_list.findIndex(p => p.program_id == value.program_id)
        //         let temp_program = program_list[index]
        //         localStorage.setItem("program", JSON.stringify(temp_program))
        //         localStorage.setItem("program_index", index + 1)
        //         localStorage.setItem("program_id", value.program_id)
        //         functions.pushToRoute(this, 'reviewrequest', 'main')
        //     }

        // }
    }

    render() {
        const { dynamic_content } = this.state
        const params = {
            slidesPerView: 1,
            spaceBetween: 35,
            centeredSlides: true,
            rebuildOnUpdate: true,
            pagination: {
                el: '.swiper-pagination',
                type: this.state.final_urgent_data.length < 2 ? '' : 'bullets',
                clickable: true
            }
        }

        return (
            <div className="row">
                <div className="">
                    <div className="custom_swiper31">
                        {dynamic_content != "" && this.state.final_urgent_data && this.state.final_urgent_data.length > 0 &&
                            <Swiper {...params}>
                                {this.state.final_urgent_data && this.state.final_urgent_data.length > 0 && this.state.final_urgent_data.map((element, index) => (
                                    <div key={index} className="all_notify_section31">

                                        {/* Head bar */}
                                        {this.props.notify_number > 0 && this.state.is_urgent &&
                                            <div className="notify_head31">
                                                <div className="notify_headLeft31"><label>{dynamic_content.static_text.urgent ? dynamic_content.static_text.urgent.urgent.en : "Urgent"}</label></div>

                                                <div className="notify_headRght31">
                                                    <p onClick={() => { functions.pushToRoute(this.props, 'notification', 'main') }} className="notification_count notification_pop">
                                                        <span>{this.props.notify_number}</span>
                                                        <div className="circle delay1"></div>
                                                        <div className="circle delay2"></div>
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                        {!this.state.is_urgent &&
                                            <div className="common_main_space1">
                                                <div className="discover_head">

                                                    {/* <div className="discover_headLeft"><label>{dynamic_content.static_text.main && dynamic_content.static_text.main.discover ? dynamic_content.static_text.main.discover.en : "Discover"}</label></div> */}
                                                <div className="discover_headLeft"><img className="discover_image" src={url.cloudflare_image_path + "familyOneLogo_500px.png" + url.varient} alt="" title="" /></div>
                                                    <div className="discover_headRght"><img onClick={() => { functions.pushToRoute(this.props, 'notification', 'main') }} src={url.family_one_image_url + 'Group159.png'} alt="" title="" /></div>
                                                </div>
                                            </div>
                                        }

                                        {this.props.notify_number < 1 &&
                                            <div className="notify_head31">
                                                <div className="notify_headLeft31"><label>{dynamic_content.static_text.urgent ? dynamic_content.static_text.urgent.urgent.en : "Urgent"}</label></div>
                                                <div className="notify_headRght31"><img onClick={() => { functions.pushToRoute(this.props, 'notification', 'main') }} src={url.family_one_image_url + 'Group159.png'} alt="" title="" /></div>
                                            </div>
                                        }
                                        {/* Head bar */}


                                        {element.map((element1, index1) => (
                                            <div key={index1} className="all_notify31" onClick={() => { this.handleClick(element1) }}>
                                                {element1.program_image && element1.program_image != '' &&
                                                    <div className="all_notify_left31"  >
                                                        <img src={url.cloudflare_image_path + element1.program_image + url.varient} alt="" title="" />
                                                    </div>
                                                }
                                                {element1.prize_image && element1.prize_image != '' &&
                                                    <div className="all_notify_left31"  >
                                                        <img src={url.cloudflare_image_path + element1.prize_image + url.varient} alt="" title="" />
                                                    </div>
                                                }
                                                {element1.notification_type == "email_verification" &&
                                                    <div className="all_notify_rght31"  >
                                                        <p><label>{dynamic_content.static_text.urgent ? dynamic_content.static_text.urgent.requirement.en : "Requirement"}</label></p>
                                                        <span>{url.dynamic_content.urgent_notification.email_verification.en}</span>
                                                    </div>
                                                }
                                                {element1.notification_type == "phone_verification" &&
                                                    <div className="all_notify_rght31"  >
                                                        <p><label>{dynamic_content.static_text.urgent ? dynamic_content.static_text.urgent.requirement.en : "Requirement"}</label></p>
                                                        <span>{url.dynamic_content.urgent_notification.phone_verification.en}</span>
                                                    </div>
                                                }
                                                {element1.notification_type == "winner_review" &&
                                                    <div className="all_notify_rght31"  >
                                                        <p><label>{element1.prize_name}</label></p>
                                                        <span>{url.dynamic_content.urgent_notification.winner_review.en}</span>
                                                    </div>
                                                }
                                                {element1.notification_type == "winner_notification" &&
                                                    <div className="all_notify_rght31"  >
                                                        <p><label>{element1.prize_name}</label></p>
                                                        <span>{url.dynamic_content.urgent_notification.winner_notification.en}</span>
                                                    </div>
                                                }
                                                <div className="all_notify_close">
                                                    <img src={url.family_one_image_url + 'Group88168.png'} alt="" title="" onClick={(e) => { this.handleClose(index, element1, e); }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                ))}
                            </Swiper>
                        }
                    </div>

                    {/* End of notification design */}

                </div>
            </div>
        );
    }
}
export default AllNotifications;

