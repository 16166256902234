import axios from "axios";
import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import CircularProgress from '@material-ui/core/CircularProgress';
import functions from '../../assets/functions/common_functions';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import LottieLoader from '../LottieLoader';

var base64 = require('base-64');
export default class PhoneSent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            username: ""
        };
    }

    getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    componentWillMount = () => {
        let self = this
        let search = window.location.search
        let parameters = this.getUrlVars()
        let { sub_id, username, type, p_id } = parameters
        let unsubscribe_type = type
        if (parseInt(p_id) == 9361 || parseInt(p_id) == 1262 || parseInt(p_id) == 9528) {
            if (type == 1) {
                unsubscribe_type = "try in one month"
            }
            if (type == 2) {
                unsubscribe_type = "not interested in nestle"
            }
            if (type == 3) {
                unsubscribe_type = "not interested in formula freebie"
            }
        }
        if (parseInt(p_id) == 1744) {
            if (type == 1) {
                unsubscribe_type = "try in one month"
            }
            if (type == 2) {
                unsubscribe_type = "not interested in policyme"
            }
            if (type == 3) {
                unsubscribe_type = "not interested in life insurance"
            }
        }
        if (parseInt(p_id) == 3508 || parseInt(p_id) == 5475) {
            if (type == 1) {
                unsubscribe_type = "try in one month"
            }
            if (type == 2) {
                unsubscribe_type = "not interested in guardtree"
            }
        }
        let decoded_username = username && base64.decode(username)
        let final_data = {
            sub_id: sub_id,
            email: decoded_username,
            program_id: p_id,
            unsubscribe_type: unsubscribe_type
        }
        let log_params = {
            sub_id: sub_id ? sub_id : "",
            email: decoded_username,
            program_id: p_id ? p_id : "",
            email_category: parameters.ec ? parameters.ec : "",
            click_link: parameters.cl ? parameters.cl : ""
        }
        functions.callLambdaFunctionUser(self, log_params)
        self.setState({ loader: true, username: decoded_username })
        
        let user_action = "User got error on feedback file componentWillMount function";
        functions.saveFeedbackMysql(this, final_data, function (response_json) {
            let response = JSON.parse(response_json);
            if (response.catch_error) {
                self.setState({ loader: false })
                let error_body = response.message
                functions.logUserError(user_action, error_body);
                self.setState({ show_error_message: response.message })
            }
            else if (!response.catch_error) {
                self.setState({ loader: false })
                if (response.data.error === 1) {
                    let error_body = response.data.message
                    functions.logUserError(user_action, error_body);
                    self.setState({ show_error_message: response.data.message })
                } else if (response.data.statusCode === 400) {
                    let error_body = response.data.message
                    functions.logUserError(user_action, error_body);
                    self.setState({ show_error_message: response.data.message })
                }
                if (response.data.success === 1) {
                    self.setState({ loader: false })
                }
            }
        })
    }

    handleLogin = () => {
        this.setState({ loader: true })
        let user_name = this.state.username
        functions.getUserData(this, user_name, 'login')
    }

    render() {
        return (
            <div className="container">
                <div className="row 123456">
                    {this.state.loader &&
                        <center>
                            <LottieLoader />
                        </center>
                    }
                    {!this.state.loader &&
                        <div className="common_mainAddOn">
                            <div className="common_main">
                                {/* <div className="backlink"><img src={require('../../assets/images/Group147.png'} alt="" title="" /></div> */}
                                {/* <div className="clearlink"><img src={require('../../assets/images/Group5.png'} alt="" title="" /></div> */}
                                <div className="sign_up">
                                    {/* <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.restore_email.en : "Restore Email"}</h1> */}
                                </div>

                                <div className="call_content">
                                    {/* <h1>{dynamic_content.static_text.restorepasswordsent ? dynamic_content.static_text.phonesent.sent.en : "Sent!"}</h1> */}
                                    {<p>Your notification preferences have been updated.</p>}
                                    <p>
                                        {/* {dynamic_content.static_text.restorepasswordsent ? ReactHtmlParser(dynamic_content.static_text.phonesent.message.en) : ""} */}
                                    </p>
                                </div>

                                <div className="call_link">
                                    <Button size="medium" color="primary" onClick={() => { this.handleLogin() }}>
                                        Log In
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}