import React from 'react';
import '../../assets/css/style.css';
import url from '../../assets/url';
import TermsCondition from '../TermsCondition/index'
import functions from '../../assets/functions/common_functions';
import $ from "jquery";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
var Barcode = require('react-barcode');
const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

export default class kidsgofreebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            bar_code_value: "",
            show_detail: false,
            current_perk: {},
            show_t_and_c: false,
            date: new Date(),
            dynamic_content: "",
            name: ''
        };
    }

    componentDidMount = () => {
        let detail = JSON.parse(localStorage.getItem("perks_detail"))
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {

            let old_enter_terms_policy = dynamic_content.static_text.perksinstore.enter_terms_policy.en
            let color = this.state.detail.is_upgraded == 1 ? detail.detail_custom.terms_color : ""
            let new_enter_terms_policy = old_enter_terms_policy.replace('<span style="%COLOR_STYLE%">', '<span class ="perk_terms" id = ' + detail.auto_id + 'style=' + color + '>')
            new_enter_terms_policy = old_enter_terms_policy.replace("%TERMS%", detail.terms)
            dynamic_content.static_text.perksinstore.enter_terms_policy.en = new_enter_terms_policy
            this.setState({ dynamic_content: dynamic_content })
        }

        let action = "User landed on perksinstore page";
        let image = 'perksinstore_page_landing.png'
        functions.logUserActivity(action, image);
        window.scrollTo(0, 1);

        this.setState({ detail: detail, show_detail: true })
        let barcode_formate = detail.is_upgraded == 1 ? detail.upgrade_value.barcode_formate : detail.barcode_formate;
        let barcode_value = localStorage.getItem("bar_code") ? localStorage.getItem("bar_code") : "";
        if (barcode_formate == "full_formatted") {
            barcode_value = detail.is_upgraded == 1 ? detail.upgrade_value.barcode_number : detail.barcode_number;
        }
        else if (barcode_formate == "random_with_prefix") {
            barcode_value = detail.is_upgraded == 1 ? (barcode_value + detail.upgrade_value.barcode_number) : (barcode_value + detail.barcode_number);
        }
        else if (barcode_formate == "random") {
            barcode_value = barcode_value;
        }
        // console.log("barcode_value",barcode_value)
        this.setState({ bar_code_value: barcode_value })
        // JsBarcode("#barcode", barcode_value, {
        //     displayValue: false,
        //     format: "CODE128"
        // })
        let name = this.capitalize(localStorage.getItem("given_name")) + " " + this.capitalize(localStorage.getItem("family_name"));
        this.setState({ name: name })
        this.handleTermsClick()
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    formatDate = () => {
        let date = new Date();
        let year = date.getFullYear();
        let mdate = date.getDate();
        mdate = mdate < 10 ? 0 + "" + mdate : mdate
        let month = monthMap[date.getMonth() + 1];
        let final_date = mdate + " " + month + " " + year
        return final_date;
    }

    handleTerms = (perk) => {
        let action = "User clicked on terms and condition link on perksinstore page"
        let image = 'terms_and_condition_button_perksinstore_page.png'
        functions.logUserActivity(action, image);
        perk.term_and_condition = perk.terms
        this.setState({ current_perk: perk, show_t_and_c: true });
    }

    handleCloseTerms = () => {
        this.setState({ show_t_and_c: false })
    }

    handleClose = () => {
        let action = "User clicked on back button on perksinstore page"
        let image = 'back_button_perkinstore_page.png'
        functions.logUserActivity(action, image);

        if (localStorage.getItem("perk_source") == "perks") {
            this.props.history.push("/perks")
        }
        else if (localStorage.getItem("perk_source") == "main") {
            this.props.history.push("/main")
        } else {
            this.props.history.push("/perks")
        }
    }

    handleTermsClick = () => {
        let self = this;
        $(document).on('click', '.perk_terms', function () {
            self.handleTerms(self.state.detail)
        })
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {this.state.dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            {/* {this.state.show_detail && */}
                            <div className="common_main_kid" style={this.state.detail.is_upgraded == 1 ? { backgroundSize: "cover", height: "none", backgroundImage: "url(" + "https://app2.family.one/images/" + this.state.detail.detail_custom.page_background + ")" } : { height: "none" }}>

                                <div className='redemptionHead'>
                                    <div className='redemptionHeadLft' onClick={() => { this.handleClose() }}>
                                        <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                    </div>
                                    <div className='redemptionHeadRght' onClick={() => { this.handleClose() }}>
                                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" />
                                    </div>
                                </div>

                                {/* <div onClick={() => { this.handleClose() }} className="clearlink">
                                <img src={require('../../assets/images/closeChild.svg')} alt="" title="" />
                            </div> */}

                                <div>
                                    <div className="barcode">
                                        <div className="barcode_inner">
                                            <img src={(this.state.detail.is_upgraded == 1 ? url.cloudflare_image_path + this.state.detail.detail_custom.logo : url.cloudflare_image_path + this.state.detail.perk_image) + url.varient} alt="" title="" width="154px" heigth="154px" />
                                        </div>
                                    </div>
                                    {this.state.detail && this.state.detail.title &&
                                        <div className="barcode_content">
                                            <h1 style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color } : {}}>{this.state.detail.title}</h1>
                                            <p style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}} >{this.state.detail.subtitle}</p>
                                        </div>
                                    }
                                    <div className="barcode_copy">
                                        <p style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.redeem_color, opacity: 0.4 } : {}}>{dynamic_content.static_text.perksinstore.present_to_merchant.en}</p>
                                        <div className="barcode_inr">
                                            {/* <svg id="barcode"></svg> */}
                                            <label className="barcodesvg" style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}><Barcode value={this.state.bar_code_value} /></label>
                                        </div>
                                    </div>

                                    <div className="time_schedule">
                                        <div>
                                            <label style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}>{dynamic_content.static_text.perksinstore.current_date.en}</label>
                                            <p style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}>{this.formatDate()}</p>
                                        </div>
                                        <div>
                                            <label style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}>{dynamic_content.static_text.perksinstore.name.en}</label>
                                            <p style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.subtitle_color } : {}}>{this.state.name}</p>
                                        </div>
                                    </div>
                                    {this.state.detail && this.state.detail.terms &&
                                        <div className="barcode_submit">
                                            {/* <p style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.terms_color } : {}}>By entering you are agreeing to the <span style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.terms_color } : {}} onClick={() => { this.handleTerms(this.state.detail) }}>Terms and Conditions</span> below. {this.state.detail.terms}</p> */}
                                            <p style={this.state.detail.is_upgraded == 1 ? { color: this.state.detail.detail_custom.terms_color } : {}}>
                                                {ReactHtmlParser(dynamic_content.static_text.perksinstore.enter_terms_policy.en)}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* } */}
                            {this.state.show_t_and_c &&
                                <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_perk} />
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}