import React, { Fragment, useState } from "react";
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import axios from 'axios';
import url from '../../assets/url';
import $ from "jquery";
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
{/* Unknown child model */ }

export default class ParentInvitaionPopup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            child_data: [],
            selectedChild: {},
        }
    }

    componentDidMount() {

    }

    calltoupdateIndex = () => {
        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : []
        this.setState({ child_data })
        let selectedChildData = {}
        child_data.map((checkChild) => {
            if (!checkChild.parent_id && !selectedChildData.child_id && (checkChild.relation_with_child !== 'parent' || !checkChild.relation_with_child)) { selectedChildData = checkChild; }
        })
        let parentinviteChildLocalstorage = localStorage.getItem('parentinviteChild');
        if (!parentinviteChildLocalstorage) {
            let selectedChild = selectedChildData
            localStorage.setItem('parentinviteChild', selectedChild.child_id);
            this.setState({ selectedChild })
        }
        else {
            let parentinviteChild = parentinviteChildLocalstorage
            let selectedChild = child_data.find(item => item.child_id === parentinviteChild);
            if (selectedChild && !selectedChild.parent_id && (selectedChild.relation_with_child !== 'parent' || !selectedChild.relation_with_child)) {
                this.setState({ selectedChild })
            } else {
                localStorage.removeItem('parentinviteChild')
                this.calltoupdateIndex()
            }
        }
    }

    handleSkip = () => {
        let { selectedChild, child_data } = this.state;

        let dataIndex = child_data.findIndex(item => item.child_id == selectedChild['child_id'])
        for (let index = dataIndex + 1; index < child_data.length; index++) {
            if (child_data[index] && (child_data[index].relation_with_child !== "parent" || !child_data[index].relation_with_child)) {
                this.setState({ selectedChild: child_data[index] });
                localStorage.setItem('parentinviteChild', child_data[index].child_id);
                return;
            }
            if (index + 1 === child_data.length) {
                localStorage.removeItem('parentinviteChild');
                this.props.toggle(false)
            }
        }
        if (dataIndex + 1 === child_data.length) {
            localStorage.removeItem('parentinviteChild');
            this.props.toggle(false)
        }
    }

    handleAddRelation = () => {
        // functions.pushToRoute(this, "choicebetweenrelative", 'main');
        functions.pushToRoute(this, "existingRelativeList", 'main');
    }

    render() {
        let { selectedChild } = this.state
        return (
            <div className="open_mail_model">
                <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle(false)} className={this.props.className+" custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                    <ModalBody className="custom_unknwnchildBody">
                        {selectedChild && <div className="parentinvite_content unknwnchild_contentNw custom_parentinvite_content" style={{ postion: "relative" }}>
                            <div className="unknwnchild_head">
                                {/* <img src={url.family_one_image_url + 'Group168.png'} onClick={() => { this.props.toggle(false) }} alt="" title="" className="clearcontest_img popup_close_button" /> */}
                                <img src={require('../../assets/images/Group5.png')} onClick={() => { this.props.toggle(false) }} alt="" title="" className="clearcontest_img popup_close_button" />
                                <h4>Increase your chance to win</h4>
                                <h3 className="login_opt">Invite {selectedChild.child_name} Parents</h3>
                                <div className="parent_invite 555">
                                    <div className="add_parent_inner">
                                        <img src="https://app2.family.one/images/Group235.png" alt="" title="" className="person_group" />
                                        {/* <img src="https://app2.family.one/images/Groupt214.png" alt="" title="" className="person_cup_invite" /> */}
                                        <span className="invtCup"><img src={require('../../assets/images/inviteCup.png')} alt="" title="" className="" /></span>
                                    </div>
                                </div>
                                <div className="msgAccount">
                                    <p>We will send them an invite from you</p>
                                    <p>to participate and connect your</p>
                                    <p>accounts</p>
                                </div>
                            </div>
                            <div className="prent_submit">
                                <Button size="medium" color="primary" onClick={() => { this.handleAddRelation() }}>
                                    Invite
                                </Button>
                                <Button className="parent-skip" size="medium" color="primary" onClick={() => { this.handleSkip() }}>
                                    Skip
                                </Button>
                            </div>
                        </div>}
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}