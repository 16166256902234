import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import url from '../../assets/url';
import $ from 'jquery';
class Gender extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTopRef = React.createRef();
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id != "") {
        }
        else {
            this.props.history.push("/")
        }
        this.state = {
            gender: "",
            dynamic_content: "",
            isUIReady: false
        };
    }

    scrollToTop = () => {
        if (this.scrollToTopRef.current) {
            this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
  
    componentDidUpdate(prevProps, prevState) {
      if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
          // Perform scroll to top action
          this.scrollToTop();
      }
    }


    componentDidMount = () => {
        localStorage.removeItem('addressType');
        // functions.getLandingPage(this);
        let current_state = localStorage.getItem('current_state')
        if (current_state !== 'gender') {
            functions.getLandingPage(this)
        }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content });
        }
        let action = "User landed on gender page"
        let image = 'gender_page_landing.png'
        functions.logUserActivity(action, image);
        let previous_page = localStorage.getItem("previous_state")
        this.setState({ isUIReady: true });
       
    }

    handleGender = (gender) => {
        let action = "User clicked on " + gender + " button on gender page"
        let image = gender + '_button_gender_page.png'
        functions.logUserActivity(action, image);
        let self = this;
        let userdata = [{ Name: 'gender', Value: gender }];
        let dynamo_userdata = { 'gender': gender };
        localStorage.setItem("gender", gender);
        this.setState({ gender: gender })
        dynamo_userdata.sync_log = false
        functions.updateUserData(this, userdata, dynamo_userdata);
        let password = localStorage.getItem('password') == 'true' ? true : false
        setTimeout(function () {
            localStorage.setItem("session_type", "signup")
            functions.pushToRoute(self, "address", "gender");
        }, 1000)
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div ref={this.scrollToTopRef} className="container cstmSignUp">
                     

                {this.state.dynamic_content != "" &&
                    <div className="row">
                      <div id="scrollToTop" className="common_mainAddOn">
                       <div className='usrGenderSetBg'>
                        <div className="common_main common_mainIner">
                          <div className="common_mainInerSpc">
                            {/* <div className="sign_backlink"><img src={url.family_one_image_url + 'Group147.png')} alt="" title="" /></div>
                            <div className="clearlink"><img src={url.family_one_image_url + 'Group5.png')} alt="" title="" /></div> */}

                             {/* <div className="sign_backlink sign_backlinkNw" onClick={() => { this.handleGoBack() }}>
                              <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div> */}
                            
                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.gender.sign_up.en}</h1>
                                {/* <h2>{dynamic_content.static_text.gender.personal_info.en}</h2> */}
                            </div>

                            <div className="signup_form">
                              <h3>Create Account</h3>
                            </div>    
                            <div className="signup_gender">
                                {/* <p>{dynamic_content.static_text.gender.your_gender.en}</p> */}
                                <p>About you</p>
                                <Button className='acountFemaleBtn' size="medium" color="primary" onClick={() => { this.handleGender('female') }} >
                                    {dynamic_content.static_text.gender.female.en}
                                </Button>
                                <Button className='acountMaleBtn' size="medium" color="primary" onClick={() => { this.handleGender('male') }} >
                                    {dynamic_content.static_text.gender.male.en}
                                </Button>
                                
                            </div>
                            <div className="self_declare">
                                <p>{dynamic_content.static_text.gender.self_declare.en}</p>
                            </div>
                          </div>  

                            {/* Add Animation Here */}
                            <div className="signupConectCircleInr">
                              <div className="signupConectInr"></div>  
                            </div>  
                            {/* End of Animation Here */}
                        </div>
                       </div> 
                      </div>  
                    </div>
                }
            </div>
        );
    }
}

export default Gender;