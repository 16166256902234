let fsa = {
    "A1B": "ST. JOHN'S NL STN MAIN",
    "A1A": "ST. JOHN'S NL STN MAIN",
    "A1C": "ST. JOHN'S NL STN MAIN",
    "A1E": "ST. JOHN'S NL STN MAIN",
    "A1G": "ST. JOHN'S NL STN MAIN",
    "A1H": "ST. JOHN'S NL STN MAIN",
    "A1K": "TORBAY NL STN MAIN",
    "A1L": "PARADISE NL STN MAIN",
    "A1M": "PORTUGAL COVE-ST PHILIPS NL STN MAIN",
    "A1N": "ST. JOHN'S NL STN MAIN",
    "A1S": "GOULDS NL STN MAIN",
    "A1V": "GANDER NL LCD MAIN",
    "A1W": "MANUELS NL STN MAIN",
    "A1X": "FOXTRAP NL STN MAIN",
    "A1Y": "CARBONEAR NL STN MAIN",
    "A2A": "GRAND FALLS-WINDSOR NL LCD MAIN",
    "A2B": "GRAND FALLS-WINDSOR NL LCD MAIN",
    "A2H": "CORNER BROOK NL LCD MAIN",
    "A2N": "STEPHENVILLE NL LCD MAIN",
    "A2V": "LABRADOR CITY NL LCD MAIN",
    "A5A": "CLARENVILLE NL LCD MAIN",
    "A8A": "DEER LAKE NL STN MAIN",
    "A0A": "ST. JOHN'S  NL DCF",
    "A0B": "ST. JOHN'S  NL DCF",
    "A0C": "CLARENVILLE  NL DCF",
    "A0E": "CLARENVILLE  NL DCF",
    "A0G": "GANDER  NL DCF",
    "A0H": "GRAND FALLS  NL DCF",
    "A0J": "GRAND FALLS  NL DCF",
    "A0K": "DEER LAKE  NL DCF",
    "A0L": "CORNER BROOK  NL DCF",
    "A0M": "PORT-AUX-BASQUES  NL DCF",
    "A0N": "STEPHENVILLE  NL DCF",
    "A0P": "H. VAL-GOOSE BAY  NL DCF",
    "A0R": "MONTREAL  QC CDP",
    "A0Z": "STEPHENVILLE  NL DCF",
    "B1A": "GLACE BAY NS LCD MAIN",
    "B1B": "PORT MORIEN NS STN MAIN",
    "B1C": "LOUISBOURG NS STN MAIN",
    "B1E": "RESERVE MINES NS STN MAIN",
    "B1G": "DOMINION NS STN MAIN",
    "B1H": "NEW WATERFORD NS LCD MAIN",
    "B1J": "SYDNEY AREA NS STN J",
    "B1K": "SYDNEY AREA NS STN K",
    "B1L": "SYDNEY NS STN MAIN",
    "B1M": "SYDNEY NS STN MAIN",
    "B1N": "SYDNEY NS STN MAIN",
    "B1P": "SYDNEY NS STN MAIN",
    "B1R": "SYDNEY NS STN MAIN",
    "B1S": "SYDNEY NS STN MAIN",
    "B1T": "CHRISTMAS ISLAND NS STN MAIN",
    "B1V": "NORTH SYDNEY NS LCD MAIN",
    "B1W": "ESKASONI NS STN MAIN",
    "B1X": "SYDNEY AREA NS STN Y",
    "B1Y": "SYDNEY AREA NS STN Y",
    "B2A": "NORTH SYDNEY NS LCD MAIN",
    "B2C": "IONA NS STN MAIN",
    "B2E": "SYDNEY AREA NS STN J",
    "B2G": "ANTIGONISH NS LCD MAIN",
    "B2H": "NEW GLASGOW NS LCD MAIN",
    "B2J": "SYDNEY AREA NS STN K",
    "B2N": "TRURO NS STN MAIN",
    "B2R": "WAVERLEY NS STN MAIN",
    "B2S": "LANTZ NS STN MAIN",
    "B2T": "ENFIELD NS STN MAIN",
    "B2V": "DARTMOUTH NS LCD EAST",
    "B2W": "DARTMOUTH NS LCD EAST",
    "B2X": "DARTMOUTH NS LCD EAST",
    "B2Y": "DARTMOUTH NS LCD MAIN",
    "B2Z": "DARTMOUTH NS LCD EAST",
    "B3A": "DARTMOUTH NS LCD MAIN",
    "B3B": "DARTMOUTH NS LCD MAIN",
    "B3E": "PORTERS LAKE NS STN MAIN",
    "B3G": "DARTMOUTH NS LCD MAIN",
    "B3H": "HALIFAX NS LCD 2",
    "B3J": "HALIFAX NS LCD 2",
    "B3K": "HALIFAX NS LCD 2",
    "B3L": "HALIFAX NS LCD 2",
    "B3M": "HALIFAX NS LCD 1",
    "B3N": "HALIFAX NS LCD 1",
    "B3P": "HALIFAX NS LCD 1",
    "B3R": "HALIFAX NS LCD 1",
    "B3S": "HALIFAX NS LCD 1",
    "B3T": "LAKESIDE NS LCD ",
    "B3V": "HALIFAX NS LCD 1",
    "B3Z": "TANTALLON NS STN MAIN",
    "B4A": "DARTMOUTH NS STN BEDFORD",
    "B4B": "DARTMOUTH NS STN BEDFORD",
    "B4C": "DARTMOUTH NS LCD LOWER SACKVILLE",
    "B4E": "DARTMOUTH NS LCD LOWER SACKVILLE",
    "B4G": "DARTMOUTH NS LCD LOWER SACKVILLE",
    "B4H": "AMHERST NS LCD MAIN",
    "B4N": "KENTVILLE NS LCD MAIN",
    "B4P": "WOLFVILLE NS STN MAIN",
    "B4R": "COLDBROOK NS STN MAIN",
    "B4V": "BRIDGEWATER NS LCD MAIN",
    "B5A": "YARMOUTH NS LCD MAIN",
    "B6L": "TRURO NS STN MAIN",
    "B9A": "PORT HAWKESBURY NS STN MAIN",
    "B0C": "SYDNEY  NS DCF",
    "B0E": "PORT HAWKESBURY  NS DCF",
    "B0H": "ANTIGONISH  NS DCF",
    "B0J": "HALIFAX  NS DCF",
    "B0K": "NEW GLASGOW  NS DCF",
    "B0L": "AMHERST  NS DCF",
    "B0M": "TRURO  NS DCF",
    "B0N": "HALIFAX  NS DCF",
    "B0P": "KENTVILLE  NS DCF",
    "B0R": "BRIDGEWATER  NS DCF",
    "B0S": "MIDDLETON  NS DCF",
    "B0T": "LIVERPOOL  NS DCF",
    "B0V": "DIGBY  NS DCF",
    "B0W": "YARMOUTH  NS DCF",
    "C1A": "CHARLOTTETOWN PE STN MAIN",
    "C1B": "CHARLOTTETOWN PE STN MAIN",
    "C1C": "CHARLOTTETOWN PE STN MAIN",
    "C1E": "CHARLOTTETOWN PE STN MAIN",
    "C1N": "SUMMERSIDE PE LCD MAIN",
    "C0A": "CHARLOTTETOWN  PE DCF",
    "C0B": "SUMMERSIDE  PE DCF",
    "E1A": "MONCTON NB LCD 2",
    "E1B": "MONCTON NB STN LCD 1",
    "E1C": "MONCTON NB STN LCD 1",
    "E1E": "MONCTON NB STN LCD 1",
    "E1G": "MONCTON NB STN LCD 1",
    "E1H": "MONCTON NB STN LCD 1",
    "E1J": "MONCTON NB STN LCD 1",
    "E1N": "MIRAMICHI NB LCD MAIN",
    "E1V": "MIRAMICHI NB LCD MAIN",
    "E1W": "CARAQUET NB STN MAIN",
    "E1X": "TRACADIE-SHEILA NB STN MAIN",
    "E2A": "BATHURST NB LCD MAIN",
    "E2E": "SAINT JOHN NB LCD MAIN",
    "E2G": "SAINT JOHN NB LCD MAIN",
    "E2H": "SAINT JOHN NB LCD MAIN",
    "E2J": "SAINT JOHN NB LCD MAIN",
    "E2K": "SAINT JOHN NB LCD MAIN",
    "E2L": "SAINT JOHN NB LCD MAIN",
    "E2M": "SAINT JOHN NB LCD MAIN",
    "E2N": "SAINT JOHN NB LCD MAIN",
    "E2P": "SAINT JOHN NB LCD MAIN",
    "E2R": "SAINT JOHN NB LCD MAIN",
    "E2S": "SAINT JOHN NB LCD MAIN",
    "E2V": "OROMOCTO NB STN MAIN",
    "E3A": "FREDERICTON NB STN LCD 1",
    "E3B": "FREDERICTON NB STN LCD 1",
    "E3C": "FREDERICTON NB STN LCD 1",
    "E3E": "FREDERICTON NB STN LCD 1",
    "E3G": "FREDERICTON NB STN LCD 1",
    "E3L": "ST STEPHEN NB LCD MAIN",
    "E3N": "CAMPBELLTON NB LCD MAIN",
    "E3V": "EDMUNDSTON NB LCD 1",
    "E3Y": "GRAND FALLS-GRAND-SAULT NB STN MAIN",
    "E3Z": "GRAND FALLS-GRAND-SAULT NB STN MAIN",
    "E4A": "CHIPMAN NB STN MAIN",
    "E4B": "SUSSEX AREA NB STN B",
    "E4C": "SUSSEX AREA NB STN C",
    "E4E": "SUSSEX NB STN MAIN",
    "E4G": "SUSSEX NB STN MAIN",
    "E4H": "MONCTON AREA NB STN H",
    "E4J": "SALISBURY NB STN MAIN",
    "E4K": "MONCTON AREA NB STN K",
    "E4L": "SACKVILLE NB STN MAIN",
    "E4M": "MONCTON AREA NB STN M",
    "E4N": "MONCTON AREA NB STN N",
    "E4P": "MONCTON AREA NB STN P",
    "E4R": "MONCTON AREA NB STN R",
    "E4S": "MONCTON AREA NB STN S",
    "E4T": "MONCTON AREA NB STN T",
    "E4V": "MONCTON AREA NB STN V",
    "E4W": "MONCTON AREA NB STN W",
    "E4X": "MONCTON AREA NB STN X",
    "E4Y": "ROGERSVILLE NB STN MAIN",
    "E4Z": "MONCTON AREA NB STN Z",
    "E5A": "MOORES MILLS NB STN MAIN",
    "E5B": "ST ANDREWS NB STN MAIN",
    "E5C": "SAINT JOHN AREA NB STN C",
    "E5E": "SAINT JOHN AREA NB STN E",
    "E5G": "SAINT JOHN AREA NB STN G",
    "E5H": "SAINT JOHN AREA NB STN H",
    "E5J": "SAINT JOHN AREA NB STN J",
    "E5K": "SAINT JOHN AREA NB STN K",
    "E5L": "SAINT JOHN AREA NB STN L",
    "E5M": "SAINT JOHN AREA NB STN M",
    "E5N": "HAMPTON NB STN MAIN",
    "E5P": "SAINT JOHN AREA NB STN P",
    "E5R": "ST MARTINS NB STN MAIN",
    "E5S": "SAINT JOHN NB LCD MAIN",
    "E5T": "SAINT JOHN AREA NB STN T",
    "E5V": "SAINT JOHN AREA NB STN V",
    "E6A": "BOIESTOWN NB STN MAIN",
    "E6B": "STANLEY NB STN MAIN",
    "E6C": "FREDERICTON NB STN LCD 1",
    "E6E": "FREDERICTON AREA NB STN E",
    "E6G": "FREDERICTON AREA NB STN G",
    "E6H": "FREDERICTON AREA NB STN H",
    "E6J": "MCADAM NB STN MAIN",
    "E6K": "FREDERICTON AREA NB STN K",
    "E6L": "FREDERICTON AREA NB STN L",
    "E7A": "EDMUNDSTON AREA NB STN A",
    "E7B": "EDMUNDSTON NB LCD 1",
    "E7C": "EDMUNDSTON AREA NB STN C",
    "E7E": "EDMUNDSTON AREA NB STN E",
    "E7G": "WOODSTOCK AREA NB STN G",
    "E7H": "WOODSTOCK AREA NB STN H",
    "E7J": "WOODSTOCK AREA NB STN J",
    "E7K": "WOODSTOCK AREA NB STN K",
    "E7L": "WOODSTOCK AREA NB STN L",
    "E7M": "WOODSTOCK NB LCD MAIN",
    "E7N": "WOODSTOCK NB LCD MAIN",
    "E7P": "HARTLAND NB STN MAIN",
    "E8A": "SAINT-QUENTIN NB STN MAIN",
    "E8B": "KEDGWICK NB STN MAIN",
    "E8C": "DALHOUSIE NB STN MAIN",
    "E8E": "BATHURST AREA NB STN E",
    "E8G": "BATHURST AREA NB STN G",
    "E8J": "BATHURST AREA NB STN J",
    "E8K": "BATHURST AREA NB STN K",
    "E8L": "ALLARDVILLE NB STN MAIN",
    "E8M": "SAINT-ISIDORE NB STN MAIN",
    "E8N": "BATHURST AREA NB STN N",
    "E8P": "BATHURST AREA NB STN P",
    "E8R": "PAQUETVILLE NB STN BUREAU-CHEF",
    "E8S": "BATHURST AREA NB STN S",
    "E8T": "BATHURST AREA NB STN T",
    "E9A": "MIRAMICHI AREA NB STN A",
    "E9B": "MIRAMICHI AREA NB STN B",
    "E9C": "MIRAMICHI AREA NB STN C",
    "E9E": "MIRAMICHI AREA NB STN E",
    "E9G": "MIRAMICHI AREA NB STN G",
    "E9H": "MIRAMICHI AREA NB STN H",
    "E0A": "MONCTON  NB DCF",
    "E0B": "BATHURST  NB DCF",
    "E0C": "MIRAMICHI  NB DCF",
    "E0E": "SUSSEX  NB DCF",
    "E0G": "SAINT JOHN  NB DCF",
    "E0H": "FREDERICTON  NB DCF",
    "E0J": "STJ EXT-WOODSTOCK  NB DCF",
    "E0K": "CAMPBELLTON  NB DCF",
    "E0L": "STJ EXT-EDMUNDSTON  NB DCF",
    "G1A": "QUEBEC QC SUCC TERMINUS",
    "G1B": "QUEBEC QC PDF BEAUPORT",
    "G1C": "QUEBEC QC PDF BEAUPORT",
    "G1E": "QUEBEC QC PDF BEAUPORT",
    "G1G": "QUEBEC QC PDF CHARLESBOURG",
    "G1H": "QUEBEC QC PDF CHARLESBOURG",
    "G1J": "QUEBEC QC PDF JOLY",
    "G1K": "QUEBEC QC PDF LEON-HARMEL",
    "G1L": "QUEBEC QC PDF JOLY",
    "G1M": "QUEBEC QC PDF LEON-HARMEL",
    "G1N": "QUEBEC QC PDF GRAHAM-BELL",
    "G1P": "QUEBEC QC PDF JEAN-PERRIN",
    "G1R": "QUEBEC QC PDF GRAHAM-BELL",
    "G1S": "QUEBEC QC PDF GRAHAM-BELL",
    "G1T": "QUEBEC QC PDF GRAHAM-BELL",
    "G1V": "QUEBEC QC PDF GRAHAM-BELL",
    "G1W": "QUEBEC QC PDF DUPLESSIS",
    "G1X": "QUEBEC QC PDF AUVERGNE 3",
    "G1Y": "QUEBEC QC PDF AUVERGNE 1",
    "G2A": "QUEBEC QC PDF AUVERGNE 2",
    "G2B": "QUEBEC QC PDF AUVERGNE 2",
    "G2C": "QUEBEC QC PDF JEAN-PERRIN",
    "G2E": "QUEBEC QC PDF AUVERGNE 1",
    "G2G": "QUEBEC QC PDF AUVERGNE 1",
    "G2J": "QUEBEC QC PDF LEON-HARMEL",
    "G2K": "QUEBEC QC PDF JEAN-PERRIN",
    "G2L": "QUEBEC QC PDF CHARLESBOURG",
    "G2M": "QUEBEC QC PDF CHARLESBOURG",
    "G2N": "QUEBEC QC PDF CHARLESBOURG",
    "G3A": "SAINT-AUGUSTIN-DE-DESMAURES QC SUCC BUREAU-CHEF",
    "G3B": "LAC-BEAUPORT QC SUCC BUREAU-CHEF",
    "G3C": "STONEHAM-ET-TEWKESBURY QC SUCC BUREAU-CHEF",
    "G3E": "QUEBEC QC SUCC SAINT-EMILE",
    "G3G": "QUEBEC QC SUCC LAC-ST-CHARLES",
    "G3H": "PONT-ROUGE QC SUCC BUREAU-CHEF",
    "G3J": "QUEBEC QC SUCC VAL-BELAIR",
    "G3K": "QUEBEC QC SUCC VAL-BELAIR",
    "G3L": "SAINT-RAYMOND QC SUCC BUREAU-CHEF",
    "G3M": "DONNACONA QC SUCC BUREAU-CHEF",
    "G3N": "STE-CATHERINE-DE-LA-J-CARTIER QC SUCC BUREAU-CHEF",
    "G3S": "QUEBEC QC SUCC VAL-BELAIR",
    "G3Z": "BAIE-SAINT-PAUL QC SUCC BUREAU CHEF",
    "G4A": "CLERMONT QC SUCC BUREAU-CHEF",
    "G4R": "SEPT-ILES QC PDF BUREAU-CHEF",
    "G4S": "SEPT-ILES QC PDF BUREAU-CHEF",
    "G4T": "CAP-AUX-MEULES QC SUCC PRINCIPALE",
    "G4V": "SAINTE-ANNE-DES-MONTS QC SUCC BUREAU-CHEF",
    "G4W": "MATANE QC PDF BUREAU-CHEF",
    "G4X": "GASPE QC SUCC BUREAU-CHEF",
    "G4Z": "BAIE-COMEAU QC PDF BUREAU-CHEF",
    "G5A": "LA MALBAIE QC PDF BUREAU-CHEF",
    "G5B": "PORT-CARTIER QC PDF BUREAU-CHEF",
    "G5C": "BAIE-COMEAU QC PDF BUREAU-CHEF",
    "G5H": "MONT-JOLI QC PDF BUREAU-CHEF",
    "G5J": "AMQUI QC SUCC BUREAU-CHEF",
    "G5L": "RIMOUSKI QC PDF RIMOUSKI",
    "G5M": "RIMOUSKI QC PDF RIMOUSKI",
    "G5N": "RIMOUSKI QC PDF RIMOUSKI",
    "G5R": "RIVIERE-DU-LOUP QC PDF BUREAU-CHEF",
    "G5T": "DEGELIS QC SUCC BUREAU-CHEF",
    "G5V": "MONTMAGNY QC PDF BUREAU-CHEF",
    "G5X": "BEAUCEVILLE QC SUCC BUREAU-CHEF",
    "G5Y": "SAINT-GEORGES QC PDF BUREAU-CHEF",
    "G5Z": "SAINT-GEORGES QC PDF BUREAU-CHEF",
    "G6A": "SAINT-GEORGES QC PDF BUREAU-CHEF",
    "G6B": "LAC-MEGANTIC QC PDF BUREAU-CHEF",
    "G6C": "LEVIS QC SUCC PINTENDRE",
    "G6E": "SAINTE-MARIE QC SUCC BUREAU-CHEF",
    "G6G": "THETFORD MINES QC PDF BUREAU-CHEF",
    "G6H": "THETFORD MINES QC PDF BUREAU-CHEF",
    "G6J": "LEVIS QC SUCC SAINT-ETIENNE",
    "G6K": "LEVIS QC SUCC ST-REDEMPTEUR",
    "G6L": "PLESSISVILLE QC PDF BUREAU-CHEF",
    "G6P": "VICTORIAVILLE QC PDF BUREAU-CHEF",
    "G6R": "VICTORIAVILLE QC PDF BUREAU-CHEF",
    "G6S": "VICTORIAVILLE QC PDF BUREAU-CHEF",
    "G6T": "VICTORIAVILLE QC PDF BUREAU-CHEF",
    "G6V": "LEVIS QC PDF LEVIS",
    "G6W": "LEVIS QC PDF LEVIS",
    "G6X": "LEVIS QC PDF LEVIS",
    "G6Y": "LEVIS QC PDF LEVIS",
    "G6Z": "LEVIS QC SUCC SAINT-JEAN",
    "G7A": "LEVIS QC SUCC SAINT-NICOLAS",
    "G7B": "CHICOUTIMI CITE QC PDF LA BAIE",
    "G7G": "CHICOUTIMI CITE QC PDF CHICOUTIMI",
    "G7H": "CHICOUTIMI CITE QC PDF CHICOUTIMI",
    "G7J": "CHICOUTIMI CITE QC PDF CHICOUTIMI",
    "G7K": "CHICOUTIMI CITE QC PDF CHICOUTIMI",
    "G7N": "CHICOUTIMI CITE QC PDF LA BAIE",
    "G7P": "CHICOUTIMI QC SUCC DESSERTE 1-A",
    "G7S": "JONQUIERE CITE QC PDF JONQUIERE",
    "G7T": "JONQUIERE CITE QC PDF JONQUIERE",
    "G7X": "JONQUIERE CITE QC PDF JONQUIERE",
    "G7Y": "JONQUIERE CITE QC PDF JONQUIERE",
    "G7Z": "JONQUIERE CITE QC PDF JONQUIERE",
    "G8A": "JONQUIERE CITE QC PDF JONQUIERE",
    "G8B": "ALMA QC PDF BUREAU-CHEF",
    "G8C": "ALMA QC PDF BUREAU-CHEF",
    "G8E": "ALMA QC PDF BUREAU-CHEF",
    "G8G": "METABETCHOUAN-LAC-A-LA-CROIX QC SUCC BUREAU-CHEF",
    "G8H": "ROBERVAL QC PDF BUREAU-CHEF",
    "G8J": "CHICOUTIMI QC SUCC DESSERTE 2-A",
    "G8K": "SAINT-FELICIEN QC SUCC BUREAU-CHEF",
    "G8L": "DOLBEAU-MISTASSINI QC PDF BUREAU-CHEF",
    "G8M": "NORMANDIN QC PDF PRINCIPAL",
    "G8N": "HEBERTVILLE QC SUCC BUREAU-CHEF",
    "G8P": "CHIBOUGAMAU QC PDF BUREAU-CHEF",
    "G8T": "TROIS-RIVIERES QC PDF 2",
    "G8V": "TROIS-RIVIERES QC PDF 2",
    "G8W": "TROIS-RIVIERES QC PDF 2",
    "G8Y": "TROIS-RIVIERES QC PDF 1",
    "G8Z": "TROIS-RIVIERES QC PDF 1",
    "G9A": "TROIS-RIVIERES QC PDF 1",
    "G9B": "TROIS-RIVIERES QC PDF 1",
    "G9C": "TROIS-RIVIERES QC PDF 1",
    "G9H": "BECANCOUR QC SUCC BUREAU-CHEF",
    "G9N": "SHAWINIGAN QC PDF BUREAU-CHEF",
    "G9P": "SHAWINIGAN QC PDF BUREAU-CHEF",
    "G9R": "SHAWINIGAN QC PDF BUREAU-CHEF",
    "G9T": "SHAWINIGAN QC PDF GRAND-MERE",
    "G9X": "LA TUQUE QC PDF BUREAU-CHEF",
    "G0A": "QUEBEC # 1  QC CDP",
    "G0B": "RIMOUSKI # 1  QC CDP",
    "G0C": "MONT-JOLI # 1  QC CDP",
    "G0E": "MONT-JOLI # 1  QC CDP",
    "G0G": "SEPT-ILES  QC CDP",
    "G0H": "BAIE COMEAU  QC CDP",
    "G0J": "MONT-JOLI # 2  QC CDP",
    "G0K": "RIMOUSKI # 1  QC CDP",
    "G0L": "RIVIERE-DU-LOUP  QC CDP",
    "G0M": "ST-GEORGES  QC CDP",
    "G0N": "THETFORD MINES  QC CDP",
    "G0P": "VICTORIAVILLE  QC CDP",
    "G0R": "QUEBEC # 2-A  QC CDP",
    "G0S": "QUEBEC # 2-B  QC CDP",
    "G0T": "QUEBEC # 1  QC CDP",
    "G0V": "CHICOUTIMI  QC CDP",
    "G0W": "CHICOUTIMI  QC CDP",
    "G0X": "TROIS-RIVIERES  QC CDP",
    "G0Y": "ST-GEORGES  QC CDP",
    "G0Z": "VICTORIAVILLE  QC CDP",
    "H1A": "MONTREAL QC PDF PTE-AUX-TREMB 1",
    "H1B": "MONTREAL QC PDF PTE-AUX-TREMB 1",
    "H1C": "MONTREAL QC PDF PTE-AUX-TREMB 1",
    "H1E": "MONTREAL QC PDF PTE-AUX-TREMB 2",
    "H1G": "MONTREAL QC PDF PTE-AUX-TREMB 2",
    "H1H": "MONTREAL QC PDF MTL-NORD",
    "H1J": "MONTREAL QC PDF PTE-AUX-TREMB 2",
    "H1K": "MONTREAL QC PDF PTE-AUX-TREMB 2",
    "H1L": "MONTREAL QC PDF PTE-AUX-TREMB 1",
    "H1M": "MONTREAL QC PDF ANJOU",
    "H1N": "MONTREAL QC PDF ANJOU",
    "H1P": "MONTREAL QC PDF SAINT-LEONARD",
    "H1R": "MONTREAL QC PDF SAINT-LEONARD",
    "H1S": "MONTREAL QC PDF SAINT-LEONARD",
    "H1T": "MONTREAL QC PDF ANJOU",
    "H1V": "MONTREAL QC PDF DE MARSEILLE 2",
    "H1W": "MONTREAL QC PDF DE MARSEILLE 2",
    "H1X": "MONTREAL QC PDF DE MARSEILLE 3",
    "H1Y": "MONTREAL QC PDF DE MARSEILLE 3",
    "H1Z": "MONTREAL QC PDF SAINT-MICHEL",
    "H2A": "MONTREAL QC PDF SAINT-MICHEL",
    "H2B": "MONTREAL QC PDF MTL-NORD",
    "H2C": "MONTREAL QC PDF SAINT-MICHEL",
    "H2E": "MONTREAL QC PDF SAINT-MICHEL",
    "H2G": "MONTREAL QC PDF CHABANEL 2",
    "H2H": "MONTREAL QC PDF BRIDGE 3",
    "H2J": "MONTREAL QC PDF CHABANEL 2",
    "H2K": "MONTREAL QC PDF DE MARSEILLE 2",
    "H2L": "MONTREAL QC PDF BRIDGE 3",
    "H2M": "MONTREAL QC PDF CHABANEL 1",
    "H2N": "MONTREAL QC PDF CHABANEL 1",
    "H2P": "MONTREAL QC PDF CHABANEL 1",
    "H2R": "MONTREAL QC PDF CHABANEL 1",
    "H2S": "MONTREAL QC PDF CHABANEL 1",
    "H2T": "MONTREAL QC PDF CHABANEL 2",
    "H2V": "MONTREAL QC PDF CHABANEL 2",
    "H2W": "MONTREAL QC PDF BRIDGE 3",
    "H2X": "MONTREAL QC PDF BRIDGE 1",
    "H2Y": "MONTREAL QC PDF BRIDGE 2",
    "H2Z": "MONTREAL QC PDF BRIDGE 2",
    "H3A": "MONTREAL QC PDF BRIDGE 1",
    "H3B": "MONTREAL QC PDF BRIDGE 1",
    "H3C": "MONTREAL QC PDF BRIDGE 2",
    "H3E": "MONTREAL QC PDF BRIDGE 4",
    "H3G": "MONTREAL QC PDF BRIDGE 1",
    "H3H": "MONTREAL QC PDF BRIDGE 1",
    "H3J": "MONTREAL QC PDF BRIDGE 2",
    "H3K": "MONTREAL QC PDF BRIDGE 2",
    "H3L": "MONTREAL QC PDF AHUNTSIC",
    "H3M": "MONTREAL QC PDF AHUNTSIC",
    "H3N": "MONTREAL QC PDF CHABANEL 1",
    "H3P": "MONTREAL QC PDF CHABANEL 1",
    "H3R": "MONTREAL QC PDF DEPOT OUEST 1",
    "H3S": "MONTREAL QC PDF CHABANEL 2",
    "H3T": "MONTREAL QC PDF CHABANEL 2",
    "H3V": "MONTREAL QC PDF SNOWDON",
    "H3W": "MONTREAL QC PDF SNOWDON",
    "H3X": "MONTREAL QC PDF SNOWDON",
    "H3Y": "MONTREAL QC PDF BRIDGE 4",
    "H3Z": "MONTREAL QC PDF BRIDGE 4",
    "H4A": "MONTREAL QC PDF N.D.-D-GRACE",
    "H4B": "MONTREAL QC PDF N.D.-D-GRACE",
    "H4C": "MONTREAL QC PDF BRIDGE 1",
    "H4E": "MONTREAL QC PDF CHAMPLAIN 2",
    "H4G": "VERDUN QC SUCC BUREAU-CHEF",
    "H4H": "VERDUN QC SUCC BUREAU-CHEF",
    "H4J": "MONTREAL QC PDF DEPOT OUEST 2",
    "H4K": "MONTREAL QC PDF DEPOT OUEST 1",
    "H4L": "MONTREAL QC PDF DEPOT OUEST 2",
    "H4M": "MONTREAL QC PDF DEPOT OUEST 1",
    "H4N": "MONTREAL QC PDF CHABANEL 1",
    "H4P": "MONTREAL QC PDF DEPOT OUEST 2",
    "H4R": "MONTREAL QC PDF DEPOT OUEST 1",
    "H4S": "POINTE-CLAIRE QC PDF POINTE-CLAIRE 2",
    "H4T": "MONTREAL QC PDF DEPOT OUEST 1",
    "H4V": "MONTREAL QC PDF COTE-ST-LUC",
    "H4W": "MONTREAL QC PDF COTE-ST-LUC",
    "H4X": "MONTREAL QC PDF COTE-ST-LUC",
    "H4Y": "POINTE-CLAIRE QC PDF POINTE-CLAIRE",
    "H4Z": "MONTREAL QC SUCC TOUR D/L BOURSE",
    "H5A": "MONTREAL QC PDF BRIDGE 1",
    "H5B": "MONTREAL QC SUCC PL-DESJARDINS",
    "H7A": "LAVAL QC PDF DUVERNAY",
    "H7B": "LAVAL QC PDF DUVERNAY",
    "H7C": "LAVAL QC PDF DUVERNAY",
    "H7E": "LAVAL QC PDF DUVERNAY",
    "H7G": "LAVAL QC PDF L-D-RAPIDES",
    "H7H": "LAVAL QC PDF MONTEREY 2",
    "H7J": "LAVAL QC PDF DUVERNAY",
    "H7K": "LAVAL QC PDF MONTEREY 2",
    "H7L": "LAVAL QC PDF MONTEREY 1",
    "H7M": "LAVAL QC PDF MONTEREY 2",
    "H7N": "LAVAL QC PDF L-D-RAPIDES",
    "H7P": "LAVAL QC PDF LAVAL OUEST",
    "H7R": "LAVAL QC PDF LAVAL OUEST",
    "H7S": "LAVAL QC PDF MONTEREY 1",
    "H7T": "LAVAL QC PDF MONTEREY 1",
    "H7V": "LAVAL QC PDF L-D-RAPIDES",
    "H7W": "LAVAL QC PDF LAVAL OUEST",
    "H7X": "LAVAL QC PDF LAVAL OUEST",
    "H7Y": "LAVAL QC PDF LAVAL OUEST",
    "H8N": "MONTREAL QC PDF CHAMPLAIN 1",
    "H8P": "MONTREAL QC PDF CHAMPLAIN 1",
    "H8R": "MONTREAL QC PDF LASALLE",
    "H8S": "MONTREAL QC PDF LASALLE",
    "H8T": "POINTE-CLAIRE QC PDF POINTE-CLAIRE",
    "H8Y": "MONTREAL QC PDF ROXBORO",
    "H8Z": "MONTREAL QC PDF ROXBORO",
    "H9A": "MONTREAL QC PDF PIERREFONDS",
    "H9B": "MONTREAL QC PDF ROXBORO",
    "H9C": "MONTREAL QC PDF PIERREFONDS",
    "H9E": "MONTREAL QC PDF PIERREFONDS",
    "H9G": "POINTE-CLAIRE QC PDF POINTE-CLAIRE",
    "H9H": "MONTREAL QC PDF PIERREFONDS",
    "H9J": "MONTREAL QC PDF PIERREFONDS",
    "H9K": "MONTREAL QC PDF PIERREFONDS",
    "H9P": "POINTE-CLAIRE QC PDF POINTE-CLAIRE",
    "H9R": "POINTE-CLAIRE QC PDF POINTE-CLAIRE",
    "H9S": "POINTE-CLAIRE QC PDF POINTE-CLAIRE",
    "H9W": "BEACONSFIELD QC PDF BEACONSFIELD",
    "H9X": "BEACONSFIELD QC PDF BEACONSFIELD",
    "H0A": "MONTREAL  QC CDP",
    "H0M": "OTT EXT-CORNWALL  ON DCF",
    "J1A": "COATICOOK QC PDF BUREAU-CHEF",
    "J1C": "SHERBROOKE QC SUCC BROMPTONVILLE",
    "J1E": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1G": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1H": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1J": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1K": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1L": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1M": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1N": "SHERBROOKE QC SUCC BUREAU-CHEF",
    "J1R": "SHERBROOKE QC SUCC ROCK FOREST",
    "J1S": "WINDSOR QC PDF BUREAU-CHEF",
    "J1T": "VAL-DES-SOURCES QC PDF BUREAU-CHEF",
    "J1X": "MAGOG QC PDF BUREAU-CHEF",
    "J1Z": "SAINT-CYRILLE-DE-WENDOVER QC SUCC BUREAU-CHEF",
    "J2A": "DRUMMONDVILLE QC PDF BUREAU-CHEF",
    "J2B": "DRUMMONDVILLE QC PDF BUREAU-CHEF",
    "J2C": "DRUMMONDVILLE QC PDF BUREAU-CHEF",
    "J2E": "DRUMMONDVILLE QC PDF BUREAU-CHEF",
    "J2G": "GRANBY QC PDF BUREAU-CHEF",
    "J2H": "GRANBY QC PDF BUREAU-CHEF",
    "J2J": "GRANBY QC PDF BUREAU-CHEF",
    "J2K": "COWANSVILLE QC PDF BUREAU-CHEF",
    "J2L": "BROMONT QC SUCC BUREAU-CHEF",
    "J2M": "SHEFFORD QC SUCC BUREAU-CHEF",
    "J2N": "FARNHAM QC PDF BUREAU-CHEF",
    "J2R": "SAINT-HYACINTHE QC PDF BUREAU-CHEF",
    "J2S": "SAINT-HYACINTHE QC PDF BUREAU-CHEF",
    "J2T": "SAINT-HYACINTHE QC PDF BUREAU-CHEF",
    "J2W": "SAINT-JEAN-SUR-RICHELIEU QC PDF SAINT-LUC",
    "J2X": "SAINT-JEAN-SUR-RICHELIEU QC PDF BUREAU-CHEF",
    "J2Y": "SAINT-JEAN-SUR-RICHELIEU QC PDF BUREAU-CHEF",
    "J3A": "SAINT-JEAN-SUR-RICHELIEU QC PDF BUREAU-CHEF",
    "J3B": "SAINT-JEAN-SUR-RICHELIEU QC PDF BUREAU-CHEF",
    "J3E": "SAINTE-JULIE QC PDF BUREAU-CHEF",
    "J3G": "BELOEIL QC PDF BUREAU-CHEF",
    "J3H": "BELOEIL QC PDF BUREAU-CHEF",
    "J3L": "CHAMBLY QC PDF BUREAU-CHEF",
    "J3M": "MARIEVILLE QC SUCC BUREAU-CHEF",
    "J3N": "CHAMBLY QC PDF BUREAU-CHEF",
    "J3P": "SOREL-TRACY QC PDF BUREAU-CHEF",
    "J3R": "SOREL-TRACY QC PDF BUREAU-CHEF",
    "J3T": "NICOLET QC SUCC BUREAU-CHEF",
    "J3V": "CHAMBLY QC PDF BUREAU-CHEF",
    "J3X": "VARENNES QC SUCC BUREAU-CHEF",
    "J3Y": "SAINT-HUBERT QC PDF DE L'AEROPORT 1",
    "J3Z": "SAINT-HUBERT QC PDF DE L'AEROPORT 1",
    "J4B": "BOUCHERVILLE QC PDF BUREAU-CHEF",
    "J4G": "SAINT-HUBERT QC PDF DE L'AEROPORT 2",
    "J4H": "SAINT-HUBERT QC PDF DE L'AEROPORT 2",
    "J4J": "SAINT-HUBERT QC PDF DE L'AEROPORT 3",
    "J4K": "SAINT-HUBERT QC PDF DE L'AEROPORT 3",
    "J4L": "SAINT-HUBERT QC PDF DE L'AEROPORT 3",
    "J4M": "SAINT-HUBERT QC PDF DE L'AEROPORT 3",
    "J4N": "SAINT-HUBERT QC PDF DE L'AEROPORT 3",
    "J4P": "BROSSARD QC PDF BROSSARD 2",
    "J4R": "BROSSARD QC PDF BROSSARD 2",
    "J4S": "BROSSARD QC PDF BROSSARD 2",
    "J4T": "SAINT-HUBERT QC PDF DE L'AEROPORT 1",
    "J4V": "BROSSARD QC PDF BROSSARD 2",
    "J4W": "BROSSARD QC PDF BROSSARD 1",
    "J4X": "BROSSARD QC PDF BROSSARD 1",
    "J4Y": "BROSSARD QC PDF BROSSARD 1",
    "J4Z": "BROSSARD QC PDF BROSSARD 1",
    "J5A": "SAINT-CONSTANT QC PDF BUREAU-CHEF",
    "J5B": "DELSON QC SUCC BUREAU-CHEF",
    "J5C": "SAINTE-CATHERINE QC PDF BUREAU-CHEF",
    "J5J": "SAINT-LIN-LAURENTIDES QC SUCC BUREAU-CHEF",
    "J5K": "SAINT-COLOMBAN QC SUCC PRINCIPALE",
    "J5L": "SAINT-JEROME QC SUCC BELLEFEUILLE",
    "J5M": "SAINT-LIN-LAURENTIDES QC SUCC BUREAU-CHEF",
    "J5N": "SAINT-LIN-LAURENTIDES QC SUCC BUREAU-CHEF",
    "J5R": "LA PRAIRIE QC PDF BUREAU-CHEF",
    "J5T": "LAVALTRIE QC SUCC BUREAU-CHEF",
    "J5V": "LOUISEVILLE QC PDF BUREAU-CHEF",
    "J5W": "L'ASSOMPTION QC SUCC BUREAU-CHEF",
    "J5X": "L'EPIPHANIE QC SUCC BUREAU-CHEF",
    "J5Y": "REPENTIGNY QC PDF BUREAU-CHEF",
    "J5Z": "REPENTIGNY QC PDF BUREAU-CHEF",
    "J6A": "REPENTIGNY QC PDF BUREAU-CHEF",
    "J6E": "JOLIETTE QC PDF BUREAU-CHEF",
    "J6J": "CHATEAUGUAY QC SUCC BUREAU-CHEF",
    "J6K": "CHATEAUGUAY QC SUCC BUREAU-CHEF",
    "J6N": "CHATEAUGUAY QC SUCC BUREAU-CHEF",
    "J6R": "CHATEAUGUAY QC SUCC BUREAU-CHEF",
    "J6S": "SALABERRY-DE-VALLEYFIELD QC PDF BUREAU-CHEF",
    "J6T": "SALABERRY-DE-VALLEYFIELD QC PDF BUREAU-CHEF",
    "J6V": "REPENTIGNY QC PDF 2",
    "J6W": "TERREBONNE QC PDF 1",
    "J6X": "TERREBONNE QC PDF 1",
    "J6Y": "TERREBONNE QC PDF 1",
    "J6Z": "BOISBRIAND QC SUCC RIVE-NORD 2",
    "J7A": "BOISBRIAND QC SUCC RIVE-NORD 2",
    "J7B": "BOISBRIAND QC PDF DEPOT RIVE-NORD",
    "J7C": "BOISBRIAND QC PDF DEPOT RIVE-NORD",
    "J7E": "BOISBRIAND QC PDF DEPOT RIVE-NORD",
    "J7G": "BOISBRIAND QC PDF DEPOT RIVE-NORD",
    "J7H": "BOISBRIAND QC PDF DEPOT RIVE-NORD",
    "J7J": "MIRABEL QC SUCC BUREAU-CHEF",
    "J7K": "TERREBONNE QC PDF 2",
    "J7L": "TERREBONNE QC PDF 2",
    "J7M": "TERREBONNE QC PDF 1",
    "J7N": "MIRABEL QC SUCC BUREAU-CHEF",
    "J7P": "SAINT-EUSTACHE QC PDF BUREAU-CHEF",
    "J7R": "SAINT-EUSTACHE QC PDF BUREAU-CHEF",
    "J7T": "SAINT-LAZARE QC SUCC BUREAU-CHEF",
    "J7V": "VAUDREUIL-DORION QC PDF BUREAU-CHEF",
    "J7W": "VAUDREUIL-DORION QC PDF BUREAU-CHEF",
    "J7X": "SALABERRY-DE-VALLEYFIELD QC PDF BUREAU-CHEF",
    "J7Y": "SAINT-JEROME QC PDF BUREAU-CHEF",
    "J7Z": "SAINT-JEROME QC PDF BUREAU-CHEF",
    "J8A": "SAINT-HIPPOLYTE QC SUCC BUREAU-CHEF",
    "J8B": "SAINTE-ADELE QC SUCC BUREAU-CHEF",
    "J8C": "SAINTE-AGATHE-DES-MONTS QC PDF BUREAU-CHEF",
    "J8E": "MONT-TREMBLANT QC SUCC PRINCIPALE",
    "J8G": "BROWNSBURG-CHATHAM QC SUCC BUREAU-CHEF",
    "J8H": "LACHUTE QC PDF BUREAU-CHEF",
    "J8L": "GATINEAU QC PDF BUCKINGHAM",
    "J8M": "GATINEAU QC SUCC MASSON-ANGERS",
    "J8N": "GATINEAU QC PDF BUCKINGHAM",
    "J8P": "GATINEAU QC SUCC BUREAU-CHEF",
    "J8R": "GATINEAU QC SUCC BUREAU-CHEF",
    "J8T": "GATINEAU QC SUCC BUREAU-CHEF",
    "J8V": "GATINEAU QC SUCC BUREAU-CHEF",
    "J8X": "GATINEAU QC PDF HULL",
    "J8Y": "GATINEAU QC PDF HULL",
    "J8Z": "GATINEAU QC PDF HULL",
    "J9A": "GATINEAU QC PDF HULL",
    "J9B": "CHELSEA QC SUCC BUREAU-CHEF",
    "J9E": "MANIWAKI QC PDF BUREAU-CHEF",
    "J9H": "GATINEAU QC PDF AYLMER",
    "J9J": "GATINEAU QC PDF AYLMER",
    "J9L": "MONT-LAURIER QC PDF BUREAU-CHEF",
    "J9P": "VAL-D'OR QC PDF BUREAU-CHEF",
    "J9T": "AMOS QC PDF BUREAU-CHEF",
    "J9V": "VILLE-MARIE QC SUCC BUREAU-CHEF",
    "J9X": "ROUYN-NORANDA QC PDF BUREAU-CHEF",
    "J9Y": "ROUYN-NORANDA QC PDF BUREAU-CHEF",
    "J9Z": "LA SARRE QC PDF BUREAU-CHEF",
    "J0B": "SHERBROOKE  QC CDP",
    "J0C": "DRUMMONDVILLE  QC CDP",
    "J0E": "GRANBY  QC CDP",
    "J0G": "SOREL  QC CDP",
    "J0H": "ST-HYACINTHE  QC CDP",
    "J0J": "ST-JEAN  QC CDP",
    "J0K": "JOLIETTE  QC CDP",
    "J0L": "MONTREAL #1  QC CDP",
    "J0M": "MTL (NV-QC)  QC CDP",
    "J0N": "MONTREAL #2  QC CDP",
    "J0P": "VALLEYFIELD  QC CDP",
    "J0R": "SAINT-JEROME  QC CDP",
    "J0S": "VALLEYFIELD  QC CDP",
    "J0T": "ST-AGATHE  QC CDP",
    "J0V": "SAINT-JEROME  QC CDP",
    "J0W": "MONT-LAURIER  QC CDP",
    "J0X": "HULL  QC CDP",
    "J0Y": "VAL D'OR  QC CDP",
    "J0Z": "ROUYN-NORANDA  QC CDP",
    "K1A": "OTTAWA ON STN FED-GOVT/GOUV",
    "K1B": "OTTAWA ON LCD LANCASTER",
    "K1C": "ORLEANS ON STN ORLEANS",
    "K1E": "ORLEANS ON STN ORLEANS",
    "K1G": "OTTAWA ON LCD LANCASTER",
    "K1H": "OTTAWA ON LCD LANCASTER",
    "K1J": "OTTAWA ON STN TERMINAL 2",
    "K1K": "OTTAWA ON STN TERMINAL 2",
    "K1L": "OTTAWA ON STN VANIER",
    "K1M": "OTTAWA ON STN VANIER",
    "K1N": "OTTAWA ON STN VANIER",
    "K1P": "OTTAWA ON LCD S. FLEMING",
    "K1R": "OTTAWA ON LCD S. FLEMING",
    "K1S": "OTTAWA ON LCD S. FLEMING",
    "K1T": "OTTAWA ON LCD S",
    "K1V": "OTTAWA ON LCD S",
    "K1W": "ORLEANS ON STN ORLEANS",
    "K1X": "OTTAWA ON LCD LANCASTER",
    "K1Y": "OTTAWA ON STN C",
    "K1Z": "OTTAWA ON STN C",
    "K2A": "OTTAWA ON STN J",
    "K2B": "OTTAWA ON STN J",
    "K2C": "NEPEAN ON LCD MERIVALE",
    "K2E": "NEPEAN ON LCD MERIVALE",
    "K2G": "NEPEAN ON LCD MERIVALE",
    "K2H": "NEPEAN ON STN H",
    "K2J": "NEPEAN ON LCD N",
    "K2K": "KANATA ON STN KANATA",
    "K2L": "KANATA ON STN KANATA",
    "K2M": "KANATA ON STN KANATA",
    "K2P": "OTTAWA ON LCD S. FLEMING",
    "K2R": "NEPEAN ON LCD N",
    "K2S": "STITTSVILLE ON STN B",
    "K2T": "KANATA ON STN KANATA",
    "K2V": "KANATA ON STN KANATA",
    "K2W": "KANATA ON STN KANATA",
    "K4A": "ORLEANS ON STN ORLEANS",
    "K4B": "NAVAN ON STN MAIN",
    "K4C": "CUMBERLAND ON STN MAIN",
    "K4K": "ROCKLAND ON STN MAIN",
    "K4M": "MANOTICK ON STN MAIN",
    "K4P": "MANOTICK ON STN MAIN",
    "K4R": "RUSSELL ON STN MAIN",
    "K6A": "HAWKESBURY ON LCD MAIN",
    "K6H": "CORNWALL ON LCD 1",
    "K6J": "CORNWALL ON LCD 1",
    "K6K": "CORNWALL ON LCD 1",
    "K6T": "BROCKVILLE ON LCD MAIN",
    "K6V": "BROCKVILLE ON LCD MAIN",
    "K7A": "SMITHS FALLS ON LCD MAIN",
    "K7C": "CARLETON PLACE ON LCD MAIN",
    "K7G": "GANANOQUE ON LCD MAIN",
    "K7H": "PERTH ON STN MAIN",
    "K7K": "KINGSTON ON LCD MAIN",
    "K7L": "KINGSTON ON LCD MAIN",
    "K7M": "KINGSTON ON STN A",
    "K7N": "KINGSTON ON STN A",
    "K7P": "KINGSTON ON STN A",
    "K7R": "NAPANEE ON LCD MAIN",
    "K7S": "ARNPRIOR ON LCD MAIN",
    "K7V": "RENFREW ON LCD MAIN",
    "K8A": "PEMBROKE ON LCD MAIN",
    "K8B": "PEMBROKE ON LCD MAIN",
    "K8H": "PETAWAWA ON LCD MAIN",
    "K8N": "BELLEVILLE ON STN MAIN",
    "K8P": "BELLEVILLE ON STN MAIN",
    "K8R": "BELLEVILLE ON STN MAIN",
    "K8V": "TRENTON ON LCD MAIN",
    "K9A": "COBOURG ON LCD MAIN",
    "K9H": "PETERBOROUGH ON STN DELIVERY CENTRE",
    "K9J": "PETERBOROUGH ON STN DELIVERY CENTRE",
    "K9K": "PETERBOROUGH ON STN DELIVERY CENTRE",
    "K9L": "PETERBOROUGH ON STN DELIVERY CENTRE",
    "K9V": "LINDSAY ON LCD MAIN",
    "K0A": "OTTAWA  ON DCF",
    "K0B": "OTT EXT-HAWKESBURY  ON DCF",
    "K0C": "OTT EXT-CORNWALL  ON DCF",
    "K0E": "OTT EXT-BROCKVILLE  ON DCF",
    "K0G": "OTT EXT-SMITHS FALLS  ON DCF",
    "K0H": "TOR EXT-KINGSTON  ON DCF",
    "K0J": "OTT EXT-PEMBROKE  ON DCF",
    "K0K": "TOR EXT-BELLEVILLE  ON DCF",
    "K0L": "TOR EXT-PETERBOROUGH  ON DCF",
    "K0M": "TOR EXT-PETERBOROUGH  ON DCF",
    "L1A": "PORT HOPE ON LCD MAIN",
    "L1B": "BOWMANVILLE ON LCD MAIN",
    "L1C": "BOWMANVILLE ON LCD MAIN",
    "L1E": "BOWMANVILLE ON LCD MAIN",
    "L1G": "OSHAWA ON STN A",
    "L1H": "OSHAWA ON STN A",
    "L1J": "OSHAWA ON STN A",
    "L1K": "OSHAWA ON STN A",
    "L1L": "OSHAWA ON STN A",
    "L1M": "WHITBY ON LCD BROOKLIN",
    "L1N": "WHITBY ON LCD WHITBY",
    "L1P": "WHITBY ON LCD WHITBY",
    "L1R": "WHITBY ON LCD WHITBY",
    "L1S": "AJAX ON STN DELIVERY CENTRE",
    "L1T": "AJAX ON STN DELIVERY CENTRE",
    "L1V": "PICKERING ON LCD MAIN",
    "L1W": "PICKERING ON LCD MAIN",
    "L1X": "PICKERING ON LCD MAIN",
    "L1Y": "PICKERING ON LCD MAIN",
    "L1Z": "AJAX ON STN DELIVERY CENTRE",
    "L2A": "FORT ERIE ON LCD MAIN",
    "L2E": "NIAGARA FALLS ON LCD MAIN",
    "L2G": "NIAGARA FALLS ON LCD MAIN",
    "L2H": "NIAGARA FALLS ON LCD MAIN",
    "L2J": "NIAGARA FALLS ON LCD MAIN",
    "L2M": "ST CATHARINES ON STN LCD 1",
    "L2N": "ST CATHARINES ON STN LCD 1",
    "L2P": "ST CATHARINES ON LCD 2",
    "L2R": "ST CATHARINES ON LCD 2",
    "L2S": "ST CATHARINES ON LCD 2",
    "L2T": "ST CATHARINES ON LCD 2",
    "L2V": "ST CATHARINES ON LCD 2",
    "L2W": "ST CATHARINES ON STN LCD 1",
    "L3B": "WELLAND ON LCD MAIN",
    "L3C": "WELLAND ON LCD MAIN",
    "L3J": "BEAMSVILLE ON LCD MAIN",
    "L3K": "PORT COLBORNE ON LCD MAIN",
    "L3L": "WOODBRIDGE ON STN MAIN",
    "L3M": "GRIMSBY ON LCD MAIN",
    "L3P": "UNIONVILLE ON LCD 1",
    "L3R": "UNIONVILLE ON LCD 1",
    "L3S": "UNIONVILLE ON LCD 2",
    "L3T": "THORNHILL ON LCD 20",
    "L3V": "ORILLIA ON LCD MAIN",
    "L3X": "NEWMARKET ON LCD 1",
    "L3Y": "NEWMARKET ON LCD 1",
    "L3Z": "BRADFORD ON LCD MAIN",
    "L4A": "STOUFFVILLE ON LCD MAIN",
    "L4B": "RICHMOND HILL ON LCD 20",
    "L4C": "RICHMOND HILL ON STN A",
    "L4E": "RICHMOND HILL ON LCD 14",
    "L4G": "AURORA ON LCD MAIN",
    "L4H": "WOODBRIDGE ON STN MAIN",
    "L4J": "THORNHILL ON LCD MAIN",
    "L4K": "CONCORD ON STN MAIN",
    "L4L": "WOODBRIDGE ON STN MAIN",
    "L4M": "BARRIE ON LCD DISTRIBUTION",
    "L4N": "BARRIE ON LCD DISTRIBUTION",
    "L4P": "KESWICK ON LCD MAIN",
    "L4R": "MIDLAND ON LCD MAIN",
    "L4S": "RICHMOND HILL ON LCD 14",
    "L4T": "MISSISSAUGA ON LCD MALTON",
    "L4V": "MISSISSAUGA ON LCD MALTON",
    "L4W": "MISSISSAUGA ON LCD 2",
    "L4X": "MISSISSAUGA ON LCD 2",
    "L4Y": "MISSISSAUGA ON LCD 2",
    "L4Z": "MISSISSAUGA ON LCD 5",
    "L5A": "MISSISSAUGA ON LCD 5",
    "L5B": "MISSISSAUGA ON LCD 5",
    "L5C": "MISSISSAUGA ON STN LCD 3",
    "L5E": "MISSISSAUGA ON STN PORT CREDIT",
    "L5G": "MISSISSAUGA ON STN PORT CREDIT",
    "L5H": "MISSISSAUGA ON STN PORT CREDIT",
    "L5J": "MISSISSAUGA ON STN PORT CREDIT",
    "L5K": "MISSISSAUGA ON STN LCD 3",
    "L5L": "MISSISSAUGA ON STN LCD 3",
    "L5M": "MISSISSAUGA ON LCD 4",
    "L5N": "MISSISSAUGA ON LCD 4",
    "L5P": "MISSISSAUGA ON STN TORONTO AMF",
    "L5R": "MISSISSAUGA ON LCD 5",
    "L5S": "MISSISSAUGA ON LCD MALTON",
    "L5T": "MISSISSAUGA ON LCD MALTON",
    "L5V": "MISSISSAUGA ON LCD 4",
    "L5W": "MISSISSAUGA ON LCD 4",
    "L6A": "MAPLE ON STN DELIVERY CENTRE",
    "L6B": "UNIONVILLE ON LCD 2",
    "L6C": "UNIONVILLE ON LCD 2",
    "L6E": "UNIONVILLE ON LCD 2",
    "L6G": "UNIONVILLE ON LCD 1",
    "L6H": "OAKVILLE ON STN MAIN",
    "L6J": "OAKVILLE ON STN MAIN",
    "L6K": "OAKVILLE ON LCD 1",
    "L6L": "OAKVILLE ON LCD 1",
    "L6M": "OAKVILLE ON LCD 1",
    "L6P": "BRAMPTON ON LCD 4",
    "L6R": "BRAMPTON ON LCD 4",
    "L6S": "BRAMPTON ON LCD B",
    "L6T": "BRAMPTON ON LCD B",
    "L6V": "BRAMPTON ON LCD 1",
    "L6W": "BRAMPTON ON LCD B",
    "L6X": "BRAMPTON ON LCD 1",
    "L6Y": "BRAMPTON ON LCD B",
    "L6Z": "BRAMPTON ON LCD 1",
    "L7A": "BRAMPTON ON LCD 1",
    "L7B": "KING CITY ON STN MAIN",
    "L7C": "CALEDON ON STN MAIN",
    "L7E": "CALEDON ON STN MAIN",
    "L7G": "GEORGETOWN ON LCD MAIN",
    "L7J": "ACTON ON LCD MAIN",
    "L7K": "CALEDON ON STN C",
    "L7L": "BURLINGTON ON LCD 1",
    "L7M": "BURLINGTON ON LCD 1",
    "L7N": "BURLINGTON ON LCD 1",
    "L7P": "BURLINGTON ON LCD 1",
    "L7R": "BURLINGTON ON LCD 1",
    "L7S": "BURLINGTON ON LCD 1",
    "L7T": "BURLINGTON ON LCD 1",
    "L8B": "WATERDOWN ON STN WATERDOWN",
    "L8E": "STONEY CREEK ON STN LCD 3",
    "L8G": "STONEY CREEK ON STN LCD 3",
    "L8H": "STONEY CREEK ON STN LCD 3",
    "L8J": "HAMILTON ON STN LCD 5",
    "L8K": "STONEY CREEK ON STN LCD 3",
    "L8L": "HAMILTON ON STN LCD 1",
    "L8M": "HAMILTON ON STN LCD 1",
    "L8N": "HAMILTON ON STN LCD 1",
    "L8P": "HAMILTON ON STN LCD 1",
    "L8R": "HAMILTON ON STN LCD 1",
    "L8S": "HAMILTON ON STN LCD 1",
    "L8T": "HAMILTON ON STN LCD 5",
    "L8V": "HAMILTON ON STN LCD 5",
    "L8W": "HAMILTON ON STN LCD 5",
    "L9A": "HAMILTON ON STN LCD 5",
    "L9B": "HAMILTON ON LCD WEST",
    "L9C": "HAMILTON ON STN LCD 5",
    "L9E": "MILTON ON LCD MAIN",
    "L9G": "HAMILTON ON LCD WEST",
    "L9H": "HAMILTON ON LCD WEST",
    "L9J": "BARRIE ON LCD DISTRIBUTION",
    "L9K": "HAMILTON ON LCD WEST",
    "L9L": "PORT PERRY ON LCD MAIN",
    "L9M": "PENETANGUISHENE ON STN MAIN",
    "L9N": "EAST GWILLIMBURY ON LCD MAIN",
    "L9P": "UXBRIDGE ON STN MAIN",
    "L9R": "ALLISTON ON LCD MAIN",
    "L9S": "INNISFIL ON LCD MAIN",
    "L9T": "MILTON ON LCD MAIN",
    "L9V": "SHELBURNE ON STN MAIN",
    "L9W": "ORANGEVILLE ON LCD MAIN",
    "L9X": "BARRIE ON LCD DISTRIBUTION",
    "L9Y": "COLLINGWOOD ON LCD COLLINGWOOD",
    "L9Z": "WASAGA BEACH ON LCD MAIN",
    "L0A": "TORONTO WEST # 2  ON DCF",
    "L0B": "TORONTO WEST # 2  ON DCF",
    "L0C": "TOR EXT-NEWMARKET  ON DCF",
    "L0E": "TOR EXT-NEWMARKET  ON DCF",
    "L0G": "TOR EXT-NEWMARKET  ON DCF",
    "L0H": "TORONTO WEST # 2  ON DCF",
    "L0J": "TORONTO WEST # 2  ON DCF",
    "L0K": "BARRIE  ON DCF",
    "L0L": "BARRIE  ON DCF",
    "L0M": "BARRIE  ON DCF",
    "L0N": "TORONTO WEST # 2  ON DCF",
    "L0P": "TORONTO WEST # 2  ON DCF",
    "L0R": "HAMILTON  ON DCF",
    "L0S": "HAMILTON  ON DCF",
    "L0V": "OTTAWA  ON DCF",
    "M1B": "TORONTO ON LCD MALVERN",
    "M1C": "TORONTO ON LCD WEST HILL",
    "M1E": "TORONTO ON LCD WEST HILL",
    "M1G": "SCARBOROUGH ON LCD C",
    "M1H": "SCARBOROUGH ON LCD C",
    "M1J": "SCARBOROUGH ON LCD 11",
    "M1K": "SCARBOROUGH ON LCD 11",
    "M1L": "SCARBOROUGH ON LCD 11",
    "M1M": "SCARBOROUGH ON LCD 11",
    "M1N": "SCARBOROUGH ON LCD 11",
    "M1P": "SCARBOROUGH ON LCD D",
    "M1R": "SCARBOROUGH ON LCD D",
    "M1S": "TORONTO ON LCD AGINCOURT",
    "M1T": "TORONTO ON LCD AGINCOURT",
    "M1V": "TORONTO ON LCD AGINCOURT 4",
    "M1W": "SCARBOROUGH ON STN F",
    "M1X": "TORONTO ON LCD AGINCOURT 4",
    "M2H": "WILLOWDALE ON LCD 2",
    "M2J": "SCARBOROUGH ON STN F",
    "M2K": "WILLOWDALE ON LCD B",
    "M2L": "WILLOWDALE ON LCD L",
    "M2M": "WILLOWDALE ON LCD 2",
    "M2N": "WILLOWDALE ON LCD N",
    "M2P": "WILLOWDALE ON LCD L",
    "M2R": "WILLOWDALE ON STN D",
    "M3A": "TORONTO ON LCD DON MILLS",
    "M3B": "TORONTO ON LCD DON MILLS",
    "M3C": "TORONTO ON LCD DON MILLS",
    "M3H": "TORONTO ON LCD DOWNSVIEW A",
    "M3J": "TORONTO ON LCD DOWNSVIEW C",
    "M3K": "TORONTO ON LCD DOWNSVIEW A",
    "M3L": "TORONTO ON LCD DOWNSVIEW A",
    "M3M": "TORONTO ON LCD DOWNSVIEW A",
    "M3N": "TORONTO ON LCD DOWNSVIEW C",
    "M4A": "NORTH YORK ON STN O",
    "M4B": "NORTH YORK ON STN O",
    "M4C": "TORONTO ON STN H",
    "M4E": "TORONTO ON STN H",
    "M4G": "TORONTO ON STN R",
    "M4H": "TORONTO ON STN R",
    "M4J": "TORONTO ON STN J",
    "M4K": "TORONTO ON STN J",
    "M4L": "TORONTO ON STN G",
    "M4M": "TORONTO ON STN G",
    "M4N": "TORONTO ON LCD K",
    "M4P": "TORONTO ON LCD K",
    "M4R": "TORONTO ON LCD K",
    "M4S": "TORONTO ON LCD Q",
    "M4T": "TORONTO ON LCD Q",
    "M4V": "TORONTO ON LCD Q",
    "M4W": "TORONTO ON LCD F",
    "M4X": "TORONTO ON LCD ADELAIDE",
    "M4Y": "TORONTO ON LCD CHARLES ST",
    "M5A": "TORONTO ON LCD ADELAIDE",
    "M5B": "TORONTO ON LCD 2",
    "M5C": "TORONTO ON LCD 2",
    "M5E": "TORONTO ON LCD 2",
    "M5G": "TORONTO ON LCD 2",
    "M5H": "TORONTO ON LCD 1",
    "M5J": "TORONTO ON LCD 1",
    "M5K": "TORONTO ON STN TORONTO DOM",
    "M5L": "TORONTO ON STN COMMERCE COURT",
    "M5M": "TORONTO ON STN S",
    "M5N": "TORONTO ON STN S",
    "M5P": "TORONTO ON STN S",
    "M5R": "TORONTO ON LCD P",
    "M5S": "TORONTO ON LCD P",
    "M5T": "TORONTO ON LCD B",
    "M5V": "TORONTO ON LCD B",
    "M5W": "TORONTO ON STN A",
    "M5X": "TORONTO ON STN 1ST CAN PLACE",
    "M6A": "TORONTO ON STN T",
    "M6B": "TORONTO ON STN T",
    "M6C": "TORONTO ON STN L",
    "M6E": "TORONTO ON STN L",
    "M6G": "TORONTO ON LCD E",
    "M6H": "TORONTO ON LCD E",
    "M6J": "TORONTO ON LCD C",
    "M6K": "TORONTO ON LCD C",
    "M6L": "NORTH YORK ON STN W",
    "M6M": "NORTH YORK ON STN W",
    "M6N": "TORONTO ON STN L",
    "M6P": "TORONTO ON LCD 3",
    "M6R": "TORONTO ON LCD C",
    "M6S": "TORONTO ON LCD 3",
    "M7A": "TORONTO ON STN PROVINCIAL GOVT",
    "M7R": "MISSISSAUGA ON STN GATEWAY CR",
    "M7Y": "TORONTO ON STN BRM B",
    "M8V": "ETOBICOKE ON STN N",
    "M8W": "ETOBICOKE ON STN N",
    "M8X": "ETOBICOKE ON LCD D",
    "M8Y": "ETOBICOKE ON LCD U",
    "M8Z": "ETOBICOKE ON LCD U",
    "M9A": "ETOBICOKE ON LCD D",
    "M9B": "ETOBICOKE ON LCD D",
    "M9C": "ETOBICOKE ON LCD A",
    "M9L": "TORONTO ON LCD WESTON B",
    "M9M": "TORONTO ON LCD WESTON B",
    "M9N": "YORK ON STN WESTON A",
    "M9P": "YORK ON STN WESTON A",
    "M9R": "YORK ON STN WESTON A",
    "M9V": "ETOBICOKE ON STN C",
    "M9W": "ETOBICOKE ON LCD B",
    "M0B": "TORONTO WEST # 2  ON DCF",
    "N1A": "DUNNVILLE ON LCD MAIN",
    "N1C": "GUELPH ON LCD ROYAL CITY MAIL",
    "N1E": "GUELPH ON LCD ROYAL CITY MAIL",
    "N1G": "GUELPH ON LCD ROYAL CITY MAIL",
    "N1H": "GUELPH ON LCD ROYAL CITY MAIL",
    "N1K": "GUELPH ON LCD ROYAL CITY MAIL",
    "N1L": "GUELPH ON LCD ROYAL CITY MAIL",
    "N1M": "FERGUS ON LCD MAIN",
    "N1P": "KITCHENER ON LCD GALT",
    "N1R": "KITCHENER ON LCD GALT",
    "N1S": "KITCHENER ON LCD GALT",
    "N1T": "KITCHENER ON LCD GALT",
    "N2A": "KITCHENER ON LCD 1",
    "N2B": "KITCHENER ON LCD 2",
    "N2C": "KITCHENER ON LCD 1",
    "N2E": "KITCHENER ON LCD 3",
    "N2G": "KITCHENER ON LCD 2",
    "N2H": "KITCHENER ON LCD 2",
    "N2J": "KITCHENER ON LCD WATERLOO",
    "N2K": "KITCHENER ON LCD WATERLOO",
    "N2L": "KITCHENER ON LCD WATERLOO",
    "N2M": "KITCHENER ON LCD 3",
    "N2N": "KITCHENER ON LCD 3",
    "N2P": "KITCHENER ON LCD 3",
    "N2R": "KITCHENER ON LCD 3",
    "N2T": "KITCHENER ON LCD WATERLOO",
    "N2V": "KITCHENER ON LCD WATERLOO",
    "N2Z": "KINCARDINE ON STN MAIN",
    "N3A": "NEW HAMBURG ON STN MAIN",
    "N3B": "ELMIRA ON LCD MAIN",
    "N3C": "KITCHENER ON LCD PRESTON",
    "N3E": "KITCHENER ON LCD PRESTON",
    "N3H": "KITCHENER ON LCD PRESTON",
    "N3L": "BRANTFORD ON LCD MAIN",
    "N3P": "BRANTFORD ON LCD MAIN",
    "N3R": "BRANTFORD ON LCD MAIN",
    "N3S": "BRANTFORD ON LCD MAIN",
    "N3T": "BRANTFORD ON LCD MAIN",
    "N3V": "BRANTFORD ON LCD MAIN",
    "N3W": "CALEDONIA ON STN MAIN",
    "N3Y": "SIMCOE ON LCD MAIN",
    "N4B": "DELHI ON LCD MAIN",
    "N4G": "TILLSONBURG ON LCD MAIN",
    "N4K": "OWEN SOUND ON LCD MAIN",
    "N4L": "MEAFORD ON STN MAIN",
    "N4N": "HANOVER ON LCD MAIN",
    "N4S": "WOODSTOCK ON LCD MAIN",
    "N4T": "WOODSTOCK ON LCD MAIN",
    "N4V": "WOODSTOCK ON LCD MAIN",
    "N4W": "LISTOWEL ON LCD MAIN",
    "N4X": "ST MARYS ON STN MAIN",
    "N4Z": "STRATFORD ON LCD MAIN",
    "N5A": "STRATFORD ON LCD MAIN",
    "N5C": "INGERSOLL ON LCD MAIN",
    "N5H": "AYLMER ON LCD MAIN",
    "N5L": "PORT STANLEY ON STN MAIN",
    "N5P": "ST THOMAS ON LCD MAIN",
    "N5R": "ST THOMAS ON LCD MAIN",
    "N5V": "LONDON ON LCD 1",
    "N5W": "LONDON ON LCD 1",
    "N5X": "LONDON ON LCD 1",
    "N5Y": "LONDON ON LCD 1",
    "N5Z": "LONDON ON LCD 6",
    "N6A": "LONDON ON LCD 6",
    "N6B": "LONDON ON LCD 6",
    "N6C": "LONDON ON LCD 5",
    "N6E": "LONDON ON LCD 5",
    "N6G": "LONDON ON LCD 4",
    "N6H": "LONDON ON LCD 4",
    "N6J": "LONDON ON LCD 3",
    "N6K": "LONDON ON LCD 3",
    "N6L": "LONDON ON LCD 5",
    "N6M": "LONDON ON LCD 1",
    "N6N": "LONDON ON LCD 5",
    "N6P": "LONDON ON STN LAMBETH",
    "N7A": "GODERICH ON LCD MAIN",
    "N7G": "STRATHROY ON LCD MAIN",
    "N7L": "CHATHAM ON LCD MAIN",
    "N7M": "CHATHAM ON LCD MAIN",
    "N7S": "SARNIA ON LCD MAIN",
    "N7T": "SARNIA ON LCD MAIN",
    "N7V": "SARNIA ON LCD MAIN",
    "N7W": "SARNIA ON LCD MAIN",
    "N7X": "SARNIA ON LCD MAIN",
    "N8A": "WALLACEBURG ON LCD MAIN",
    "N8H": "LEAMINGTON ON LCD MAIN",
    "N8M": "ESSEX ON LCD MAIN",
    "N8N": "WINDSOR ON LCD TECUMSEH",
    "N8P": "WINDSOR ON LCD TECUMSEH",
    "N8R": "WINDSOR ON LCD 4",
    "N8S": "WINDSOR ON LCD 1",
    "N8T": "WINDSOR ON LCD 1",
    "N8V": "WINDSOR ON LCD 4",
    "N8W": "WINDSOR ON LCD 4",
    "N8X": "WINDSOR ON LCD 3",
    "N8Y": "WINDSOR ON LCD 4",
    "N9A": "WINDSOR ON LCD 3",
    "N9B": "WINDSOR ON LCD 3",
    "N9C": "WINDSOR ON LCD 3",
    "N9E": "WINDSOR ON LCD 2",
    "N9G": "WINDSOR ON LCD 4",
    "N9H": "WINDSOR ON LCD 4",
    "N9J": "WINDSOR ON LCD 2",
    "N9K": "WINDSOR ON LCD TECUMSEH",
    "N9V": "AMHERSTBURG ON LCD MAIN",
    "N9Y": "KINGSVILLE ON LCD MAIN",
    "N0A": "HAMILTON  ON DCF",
    "N0B": "KITCHENER  ON DCF",
    "N0C": "OWEN SOUND  ON DCF",
    "N0E": "HAMILTON  ON DCF",
    "N0G": "KITCHENER  ON DCF",
    "N0H": "OWEN SOUND  ON DCF",
    "N0J": "LONDON  ON DCF",
    "N0K": "KITCHENER  ON DCF",
    "N0L": "LONDON  ON DCF",
    "N0M": "LONDON  ON DCF",
    "N0N": "LONDON  ON DCF",
    "N0P": "LONDON  ON DCF",
    "N0R": "WINDSOR  ON DCF",
    "P1A": "NORTH BAY ON LCD MAIN",
    "P1B": "NORTH BAY ON LCD MAIN",
    "P1C": "NORTH BAY ON LCD MAIN",
    "P1H": "HUNTSVILLE ON STN MAIN",
    "P1L": "BRACEBRIDGE ON LCD MAIN",
    "P1P": "GRAVENHURST ON STN MAIN",
    "P2A": "PARRY SOUND ON LCD MAIN",
    "P2B": "STURGEON FALLS ON STN MAIN",
    "P2N": "KIRKLAND LAKE ON LCD MAIN",
    "P3A": "SUDBURY ON LCD MAIN",
    "P3B": "SUDBURY ON LCD MAIN",
    "P3C": "SUDBURY ON LCD MAIN",
    "P3E": "SUDBURY ON LCD MAIN",
    "P3G": "SUDBURY ON LCD MAIN",
    "P3L": "GARSON ON STN MAIN",
    "P3N": "VAL CARON ON STN MAIN",
    "P3P": "HANMER ON STN MAIN",
    "P3Y": "LIVELY ON STN MAIN",
    "P4N": "TIMMINS ON LCD MAIN",
    "P4P": "TIMMINS ON LCD MAIN",
    "P4R": "TIMMINS ON LCD MAIN",
    "P5A": "ELLIOT LAKE ON LCD MAIN",
    "P5E": "ESPANOLA ON STN MAIN",
    "P5N": "KAPUSKASING ON LCD MAIN",
    "P6A": "SAULT STE. MARIE ON LCD MAIN",
    "P6B": "SAULT STE. MARIE ON LCD MAIN",
    "P6C": "SAULT STE. MARIE ON LCD MAIN",
    "P7A": "THUNDER BAY ON STN P",
    "P7B": "THUNDER BAY ON STN P",
    "P7C": "THUNDER BAY ON STN F",
    "P7E": "THUNDER BAY ON STN F",
    "P7G": "THUNDER BAY ON STN P",
    "P7J": "THUNDER BAY ON STN F",
    "P7K": "THUNDER BAY ON STN F",
    "P7L": "THUNDER BAY ON STN F",
    "P8N": "DRYDEN ON LCD MAIN",
    "P8T": "SIOUX LOOKOUT ON STN MAIN",
    "P9A": "FORT FRANCES ON LCD MAIN",
    "P9N": "KENORA ON LCD MAIN",
    "P0A": "BARRIE  ON DCF",
    "P0B": "BARRIE  ON DCF",
    "P0C": "BARRIE  ON DCF",
    "P0E": "BARRIE  ON DCF",
    "P0G": "BARRIE  ON DCF",
    "P0H": "NORTH BAY  ON DCF",
    "P0J": "SUDBURY  ON DCF",
    "P0K": "SUDBURY  ON DCF",
    "P0L": "TIMMINS  ON DCF",
    "P0M": "SUDBURY  ON DCF",
    "P0N": "TIMMINS  ON DCF",
    "P0P": "SUDBURY  ON DCF",
    "P0R": "SUDBURY  ON DCF",
    "P0S": "SAULT STE. MARIE  ON DCF",
    "P0T": "THUNDER BAY  ON DCF",
    "P0V": "DRYDEN  ON DCF",
    "P0W": "DRYDEN  ON DCF",
    "P0X": "DRYDEN  ON DCF",
    "P0Y": "WINNIPEG # 2  MB DCF",
    "R1A": "SELKIRK MB LCD MAIN",
    "R1B": "SELKIRK MB LCD MAIN",
    "R1C": "WINNIPEG MB LCD NORTHEAST 4",
    "R1N": "PORTAGE LA PRAIRIE MB LCD MAIN",
    "R2C": "WINNIPEG MB LCD NORTHEAST 5",
    "R2E": "WINNIPEG MB LCD NORTHEAST 4",
    "R2G": "WINNIPEG MB LCD NORTHEAST 4",
    "R2H": "WINNIPEG MB LCD 1",
    "R2J": "WINNIPEG MB LCD NORTHEAST 5",
    "R2K": "WINNIPEG MB LCD NORTHEAST 4",
    "R2L": "WINNIPEG MB LCD NORTHEAST 4",
    "R2M": "WINNIPEG MB LCD ST VITAL",
    "R2N": "WINNIPEG MB LCD SOUTH WEST 6",
    "R2P": "WINNIPEG MB LCD G",
    "R2R": "WINNIPEG MB LCD R",
    "R2V": "WINNIPEG MB LCD G",
    "R2W": "WINNIPEG MB STN B",
    "R2X": "WINNIPEG MB LCD R",
    "R2Y": "WINNIPEG MB LCD MORAY",
    "R3A": "WINNIPEG MB LCD 1",
    "R3B": "WINNIPEG MB LCD 1",
    "R3C": "WINNIPEG MB LCD 1",
    "R3E": "WINNIPEG MB LCD W",
    "R3G": "WINNIPEG MB LCD D",
    "R3H": "WINNIPEG MB LCD W",
    "R3J": "WINNIPEG MB LCD MORAY",
    "R3K": "WINNIPEG MB LCD MORAY",
    "R3L": "WINNIPEG MB LCD SOUTH WEST 2",
    "R3M": "WINNIPEG MB LCD SOUTH WEST 2",
    "R3N": "WINNIPEG MB LCD SOUTH WEST 2",
    "R3P": "WINNIPEG MB LCD SOUTH WEST 2",
    "R3R": "WINNIPEG MB LCD SOUTH WEST 1",
    "R3S": "WINNIPEG MB LCD ST JAMES",
    "R3T": "WINNIPEG MB LCD SOUTH WEST 3",
    "R3V": "WINNIPEG MB LCD SOUTH WEST 3",
    "R3W": "WINNIPEG MB LCD NORTHEAST 5",
    "R3X": "WINNIPEG MB LCD NORTHEAST 7",
    "R3Y": "WINNIPEG MB LCD ST JAMES",
    "R4A": "WINNIPEG MB LCD G",
    "R4G": "WINNIPEG MB LCD SOUTH WEST 3",
    "R4H": "WINNIPEG MB LCD MORAY",
    "R4J": "WINNIPEG MB LCD MORAY",
    "R4K": "WINNIPEG MB LCD MORAY",
    "R4L": "WINNIPEG MB LCD MORAY",
    "R5A": "NIVERVILLE MB STN ST ADOLPHE",
    "R5G": "STEINBACH MB STN MAIN",
    "R5H": "STE ANNE MB STN MAIN",
    "R5J": "STEINBACH MB STN DELIVERY CTR",
    "R5K": "DUGALD MB STN MAIN",
    "R5L": "DUGALD MB STN MAIN",
    "R5M": "DUGALD MB STN MAIN",
    "R5N": "DUGALD MB STN MAIN",
    "R5P": "DUGALD MB STN MAIN",
    "R5R": "WINNIPEG MB LCD NORTHEAST 5",
    "R5T": "WINNIPEG MB LCD NORTHEAST 5",
    "R6M": "MORDEN MB STN MAIN",
    "R6P": "WINKLER MB STN MAIN",
    "R6T": "MORDEN MB STN MAIN",
    "R6W": "WINKLER MB STN MAIN",
    "R7A": "BRANDON MB STN MAIN",
    "R7B": "BRANDON MB STN MAIN",
    "R7C": "BRANDON MB STN MAIN",
    "R7N": "DAUPHIN MB LCD MAIN",
    "R8A": "FLIN FLON MB LCD MAIN",
    "R8N": "THOMPSON MB LCD MAIN",
    "R9A": "THE PAS MB STN MAIN",
    "R0A": "WINNIPEG # 5  MB DCF",
    "R0B": "WINNIPEG # 4  MB DCF",
    "R0C": "WINNIPEG # 1  MB DCF",
    "R0E": "WINNIPEG # 2  MB DCF",
    "R0G": "WINNIPEG # 3  MB DCF",
    "R0H": "WINNIPEG # 6  MB DCF",
    "R0J": "WINNIPEG # 8  MB DCF",
    "R0K": "WINNIPEG # 7  MB DCF",
    "R0L": "WINNIPEG # 9  MB DCF",
    "R0M": "WINNIPEG # 10  MB DCF",
    "S2V": "BUENA VISTA SK STN MAIN",
    "S3N": "YORKTON SK STN MAIN",
    "S4A": "ESTEVAN SK LCD MAIN",
    "S4H": "WEYBURN SK LCD MAIN",
    "S4K": "REGINA SK LCD MAIN",
    "S4L": "REGINA SK LCD MAIN",
    "S4M": "REGINA SK LCD MAIN",
    "S4N": "REGINA SK LCD 1",
    "S4P": "REGINA SK LCD MAIN",
    "S4R": "REGINA SK LCD 2",
    "S4S": "REGINA SK LCD 1",
    "S4T": "REGINA SK LCD 2",
    "S4V": "REGINA SK LCD 1",
    "S4W": "REGINA SK LCD 1",
    "S4X": "REGINA SK LCD 2",
    "S4Y": "REGINA SK LCD 1",
    "S4Z": "REGINA SK LCD 1",
    "S6H": "MOOSE JAW SK LCD MAIN",
    "S6J": "MOOSE JAW SK LCD MAIN",
    "S6K": "MOOSE JAW SK LCD MAIN",
    "S6V": "PRINCE ALBERT SK STN MPP",
    "S6W": "PRINCE ALBERT SK STN MPP",
    "S6X": "PRINCE ALBERT SK STN MPP",
    "S7A": "SASKATOON SK LCD MAIN",
    "S7B": "SASKATOON SK LCD MAIN",
    "S7C": "SASKATOON SK LCD 3",
    "S7H": "SASKATOON SK LCD 1",
    "S7J": "SASKATOON SK LCD 1",
    "S7K": "SASKATOON SK LCD MAIN",
    "S7L": "SASKATOON SK LCD 2",
    "S7M": "SASKATOON SK LCD 2",
    "S7N": "SASKATOON SK LCD 3",
    "S7P": "SASKATOON SK LCD 4",
    "S7R": "SASKATOON SK LCD 2",
    "S7S": "SASKATOON SK LCD 4",
    "S7T": "SASKATOON SK LCD 4",
    "S7V": "SASKATOON SK LCD 4",
    "S7W": "SASKATOON SK LCD 4",
    "S9A": "NORTH BATTLEFORD SK LCD MAIN",
    "S9H": "SWIFT CURRENT SK LCD MAIN",
    "S9V": "LLOYDMINSTER SK LCD MAIN",
    "S9X": "MEADOW LAKE SK STN MAIN",
    "S0A": "REGINA # 4  SK DCF",
    "S0C": "REGINA # 3  SK DCF",
    "S0E": "SASKATOON # 5  SK DCF",
    "S0G": "REGINA # 1  SK DCF",
    "S0H": "REGINA # 2  SK DCF",
    "S0J": "SASKATOON # 4  SK DCF",
    "S0K": "SASKATOON # 1  SK DCF",
    "S0L": "SASKATOON # 2  SK DCF",
    "S0M": "SASKATOON # 3  SK DCF",
    "S0N": "REG EXT-SWIFT CURRENT  SK DCF",
    "S0P": "WINNIPEG # 4  MB DCF",
    "T1A": "MEDICINE HAT AB LCD 1",
    "T1B": "MEDICINE HAT AB LCD 1",
    "T1C": "MEDICINE HAT AB LCD 1",
    "T1G": "TABER AB STN MAIN",
    "T1H": "LETHBRIDGE AB LCD MAIN",
    "T1J": "LETHBRIDGE AB LCD MAIN",
    "T1K": "LETHBRIDGE AB LCD MAIN",
    "T1L": "BANFF AB STN MAIN",
    "T1M": "COALDALE AB STN MAIN",
    "T1P": "STRATHMORE AB STN MAIN",
    "T1R": "BROOKS AB STN MAIN",
    "T1S": "OKOTOKS AB STN MAIN",
    "T1V": "HIGH RIVER AB STN MAIN",
    "T1W": "CANMORE AB STN MAIN",
    "T1X": "CALGARY AB LCD 7",
    "T1Y": "CALGARY AB LCD 14",
    "T1Z": "CALGARY AB LCD 7",
    "T2A": "CALGARY AB LCD 6",
    "T2B": "CALGARY AB LCD 6",
    "T2C": "CALGARY AB LCD 17",
    "T2E": "CALGARY AB LCD 14",
    "T2G": "CALGARY AB LCD 6",
    "T2H": "CALGARY AB LCD 3",
    "T2J": "CALGARY AB LCD 8",
    "T2K": "CALGARY AB LCD 10",
    "T2L": "CALGARY AB LCD 10",
    "T2M": "CALGARY AB LCD 9",
    "T2N": "CALGARY AB LCD 9",
    "T2P": "CALGARY AB LCD 1",
    "T2R": "CALGARY AB LCD 1",
    "T2S": "CALGARY AB LCD 2",
    "T2T": "CALGARY AB LCD 2",
    "T2V": "CALGARY AB LCD 3",
    "T2W": "CALGARY AB LCD 12",
    "T2X": "CALGARY AB LCD 18",
    "T2Y": "CALGARY AB LCD 18",
    "T2Z": "CALGARY AB LCD 19",
    "T3A": "CALGARY AB LCD 11",
    "T3B": "CALGARY AB LCD 4",
    "T3C": "CALGARY AB LCD 20",
    "T3E": "CALGARY AB LCD 5",
    "T3G": "CALGARY AB LCD 11",
    "T3H": "CALGARY AB LCD 35",
    "T3J": "CALGARY AB LCD 7",
    "T3K": "CALGARY AB LCD 16",
    "T3L": "CALGARY AB LCD 4",
    "T3M": "CALGARY AB LCD 19",
    "T3N": "CALGARY AB LCD 7",
    "T3P": "CALGARY AB LCD 22",
    "T3R": "CALGARY AB LCD 22",
    "T3S": "CALGARY AB LCD 19",
    "T3T": "CALGARY AB LCD 18",
    "T3Z": "CALGARY AB LCD 23",
    "T4A": "AIRDRIE AB STN MAIN",
    "T4B": "AIRDRIE AB STN MAIN",
    "T4C": "COCHRANE AB LCD MAIN",
    "T4E": "RED DEER AB LCD 1",
    "T4G": "INNISFAIL AB STN MAIN",
    "T4H": "OLDS AB STN MAIN",
    "T4J": "PONOKA AB STN MAIN",
    "T4L": "LACOMBE AB STN MAIN",
    "T4M": "BLACKFALDS AB LCD MAIN",
    "T4N": "RED DEER AB LCD 1",
    "T4P": "RED DEER AB LCD 1",
    "T4R": "RED DEER AB LCD 1",
    "T4S": "RED DEER AB LCD 1",
    "T4T": "ROCKY MOUNTAIN HOUSE AB STN MAIN",
    "T4V": "CAMROSE AB LCD MAIN",
    "T4X": "NISKU AB LCD MAIN",
    "T5A": "EDMONTON AB LCD DELTON",
    "T5B": "EDMONTON AB LCD 1",
    "T5C": "EDMONTON AB LCD DELTON",
    "T5E": "EDMONTON AB LCD DELTON",
    "T5G": "EDMONTON AB LCD 1",
    "T5H": "EDMONTON AB LCD 11",
    "T5J": "EDMONTON AB LCD 11",
    "T5K": "EDMONTON AB LCD 11",
    "T5L": "EDMONTON AB LCD 2",
    "T5M": "EDMONTON AB LCD 2",
    "T5N": "EDMONTON AB LCD 2",
    "T5P": "EDMONTON AB LCD 2",
    "T5R": "EDMONTON AB LCD 6",
    "T5S": "EDMONTON AB LCD 2",
    "T5T": "EDMONTON AB LCD 6",
    "T5V": "EDMONTON AB LCD 2",
    "T5W": "EDMONTON AB LCD 1",
    "T5X": "EDMONTON AB LCD DELTON",
    "T5Y": "EDMONTON AB LCD DELTON",
    "T5Z": "EDMONTON AB LCD DELTON",
    "T6A": "EDMONTON AB LCD 3",
    "T6B": "EDMONTON AB LCD 3",
    "T6C": "EDMONTON AB LCD 3",
    "T6E": "EDMONTON AB LCD 9",
    "T6G": "EDMONTON AB LCD 9",
    "T6H": "EDMONTON AB LCD 9",
    "T6J": "EDMONTON AB LCD 8",
    "T6K": "EDMONTON AB LCD 4",
    "T6L": "EDMONTON AB LCD 4",
    "T6M": "EDMONTON AB LCD 6",
    "T6N": "EDMONTON AB LCD 4",
    "T6P": "EDMONTON AB LCD 3",
    "T6R": "EDMONTON AB LCD 9",
    "T6S": "EDMONTON AB LCD 3",
    "T6T": "EDMONTON AB LCD 4",
    "T6V": "EDMONTON AB LCD 2",
    "T6W": "EDMONTON AB LCD 8",
    "T6X": "EDMONTON AB LCD 4",
    "T6Y": "EDMONTON AB LCD 6",
    "T7A": "DRAYTON VALLEY AB STN MAIN",
    "T7E": "EDSON AB STN MAIN",
    "T7N": "BARRHEAD AB STN MAIN",
    "T7P": "WESTLOCK AB STN MAIN",
    "T7S": "WHITECOURT AB STN MAIN",
    "T7V": "HINTON AB STN MAIN",
    "T7X": "SPRUCE GROVE AB LCD MAIN",
    "T7Y": "STONY PLAIN AB LCD MAIN",
    "T7Z": "STONY PLAIN AB LCD MAIN",
    "T8A": "SHERWOOD PARK AB LCD MAIN",
    "T8B": "SHERWOOD PARK AB LCD MAIN",
    "T8C": "SHERWOOD PARK AB LCD MAIN",
    "T8E": "SHERWOOD PARK AB LCD MAIN",
    "T8G": "SHERWOOD PARK AB LCD MAIN",
    "T8H": "SHERWOOD PARK AB LCD MAIN",
    "T8L": "FORT SASKATCHEWAN AB LCD MAIN",
    "T8N": "ST. ALBERT AB LCD MAIN",
    "T8R": "MORINVILLE AB STN MAIN",
    "T8S": "PEACE RIVER AB STN MAIN",
    "T8T": "ST. ALBERT AB LCD MAIN",
    "T8V": "GRANDE PRAIRIE AB LCD MAIN",
    "T8W": "GRANDE PRAIRIE AB LCD MAIN",
    "T8X": "GRANDE PRAIRIE AB LCD MAIN",
    "T9A": "WETASKIWIN AB LCD MAIN",
    "T9C": "VEGREVILLE AB STN MAIN",
    "T9E": "NISKU AB LCD MAIN",
    "T9G": "DEVON AB STN MAIN",
    "T9H": "FORT MCMURRAY AB LCD MAIN",
    "T9J": "FORT MCMURRAY AB LCD MAIN",
    "T9K": "FORT MCMURRAY AB LCD MAIN",
    "T9M": "COLD LAKE AB STN MAIN",
    "T9N": "BONNYVILLE AB STN MAIN",
    "T9S": "ATHABASCA AB STN MAIN",
    "T9V": "LLOYDMINSTER SK LCD MAIN",
    "T9W": "WAINWRIGHT AB STN MAIN",
    "T9X": "VERMILION AB STN MAIN",
    "T0A": "EDMONTON # 1  AB DCF",
    "T0B": "EDMONTON # 2  AB DCF",
    "T0C": "EDMONTON # 6  AB DCF",
    "T0E": "EDMONTON # 3  AB DCF",
    "T0G": "EDMONTON # 4  AB DCF",
    "T0H": "GRANDE PRAIRIE  AB DCF",
    "T0J": "CALGARY # 3  AB DCF",
    "T0K": "LETHBRIDGE  AB DCF",
    "T0L": "CALGARY # 2  AB DCF",
    "T0M": "CALGARY  AB DCF",
    "T0N": "EDMONTON  AB DCF",
    "T0P": "EDMONTON # 7  AB DCF",
    "T0T": "EDMONTON  AB DCF",
    "T0V": "EDMONTON  AB DCF",
    "V1A": "KIMBERLEY BC LCD MAIN",
    "V1B": "VERNON BC LCD MAIN",
    "V1C": "CRANBROOK BC LCD MAIN",
    "V1E": "SALMON ARM BC STN MAIN",
    "V1G": "DAWSON CREEK BC STN LCD",
    "V1H": "VERNON BC LCD MAIN",
    "V1J": "FORT ST. JOHN BC LCD MAIN",
    "V1K": "MERRITT BC STN MAIN",
    "V1L": "NELSON BC LCD MAIN",
    "V1M": "SURREY BC LCD CLOVERDALE",
    "V1N": "CASTLEGAR BC LCD MAIN",
    "V1P": "KELOWNA BC STN RUTLAND",
    "V1R": "TRAIL BC LCD MAIN",
    "V1S": "KAMLOOPS BC STN DELIVERY CENTRE",
    "V1T": "VERNON BC LCD MAIN",
    "V1V": "KELOWNA BC STN OKANAGAN DC",
    "V1W": "KELOWNA BC STN OKANAGAN DC",
    "V1X": "KELOWNA BC STN RUTLAND",
    "V1Y": "KELOWNA BC STN OKANAGAN DC",
    "V1Z": "KELOWNA BC STN OKANAGAN DC",
    "V2A": "PENTICTON BC LCD MAIN",
    "V2B": "KAMLOOPS BC STN DELIVERY CENTRE",
    "V2C": "KAMLOOPS BC STN DELIVERY CENTRE",
    "V2E": "KAMLOOPS BC STN DELIVERY CENTRE",
    "V2G": "WILLIAMS LAKE BC LCD MAIN",
    "V2H": "KAMLOOPS BC STN DELIVERY CENTRE",
    "V2J": "QUESNEL BC LCD MAIN",
    "V2K": "PRINCE GEORGE BC STN B",
    "V2L": "PRINCE GEORGE BC LCD A",
    "V2M": "PRINCE GEORGE BC STN B",
    "V2N": "PRINCE GEORGE BC STN B",
    "V2P": "CHILLIWACK BC LCD MAIN",
    "V2R": "CHILLIWACK BC STN S",
    "V2S": "ABBOTSFORD BC STN DEL CTR",
    "V2T": "ABBOTSFORD BC STN DEL CTR",
    "V2V": "MISSION BC LCD MAIN",
    "V2W": "MAPLE RIDGE BC LCD DEL CTR",
    "V2X": "MAPLE RIDGE BC LCD DEL CTR",
    "V2Y": "LANGLEY BC LCD LCD 1",
    "V2Z": "LANGLEY BC LCD LOGAN",
    "V3A": "LANGLEY BC LCD LCD 1",
    "V3B": "PORT COQUITLAM BC LCD MAIN",
    "V3C": "PORT COQUITLAM BC LCD MAIN",
    "V3E": "PORT COQUITLAM BC LCD MAIN",
    "V3G": "ABBOTSFORD BC STN DEL CTR",
    "V3H": "COQUITLAM BC LCD PORT MOODY",
    "V3J": "COQUITLAM BC LCD MAIN",
    "V3K": "COQUITLAM BC LCD 1",
    "V3L": "NEW WESTMINSTER BC LCD NEW WESTMINSTER",
    "V3M": "NEW WESTMINSTER BC LCD NEW WESTMINSTER",
    "V3N": "NEW WESTMINSTER BC LCD NEW WESTMINSTER",
    "V3R": "SURREY BC LCD 3",
    "V3S": "SURREY BC LCD CLOVERDALE",
    "V3T": "SURREY BC LCD 3",
    "V3V": "SURREY BC LCD 1",
    "V3W": "SURREY BC LCD 2",
    "V3X": "SURREY BC LCD 2",
    "V3Y": "MAPLE RIDGE BC LCD DEL CTR",
    "V3Z": "SURREY BC LCD CLOVERDALE",
    "V4A": "WHITE ROCK BC STN A",
    "V4B": "WHITE ROCK BC STN A",
    "V4C": "DELTA BC LCD 1",
    "V4E": "DELTA BC LCD 1",
    "V4G": "DELTA BC LCD MAIN",
    "V4K": "DELTA BC LCD MAIN",
    "V4L": "DELTA BC STN A",
    "V4M": "DELTA BC STN A",
    "V4N": "SURREY BC LCD CLOVERDALE",
    "V4P": "WHITE ROCK BC STN A",
    "V4R": "MAPLE RIDGE BC LCD DEL CTR",
    "V4S": "MISSION BC LCD MAIN",
    "V4T": "WEST KELOWNA BC LCD MAIN",
    "V4V": "LAKE COUNTRY BC STN MAIN",
    "V4W": "LANGLEY BC LCD LOGAN",
    "V4X": "ABBOTSFORD BC STN DEL CTR",
    "V4Z": "CHILLIWACK BC LCD MAIN",
    "V5A": "BURNABY BC STN NORTH",
    "V5B": "BURNABY BC STN NORTH",
    "V5C": "BURNABY BC STN NORTH",
    "V5E": "NEW WESTMINSTER BC LCD NEW WESTMINSTER",
    "V5G": "BURNABY BC STN NORTH",
    "V5H": "BURNABY BC LCD NORTH FRASER 3",
    "V5J": "BURNABY BC LCD NORTH FRASER 3",
    "V5K": "VANCOUVER BC LCD 2",
    "V5L": "VANCOUVER BC LCD 2",
    "V5M": "BURNABY BC LCD NORTH BURNABY 2",
    "V5N": "VANCOUVER BC STN F",
    "V5P": "BURNABY BC LCD NORTH FRASER 2",
    "V5R": "BURNABY BC LCD 3",
    "V5S": "BURNABY BC LCD NORTH FRASER 3",
    "V5T": "VANCOUVER BC LCD 2",
    "V5V": "VANCOUVER BC LCD 2",
    "V5W": "BURNABY BC LCD NORTH FRASER 1",
    "V5X": "BURNABY BC LCD NORTH FRASER 1",
    "V5Y": "VANCOUVER BC LCD 2",
    "V5Z": "VANCOUVER BC LCD 2",
    "V6A": "VANCOUVER BC LCD 1",
    "V6B": "VANCOUVER BC LCD 1",
    "V6C": "VANCOUVER BC LCD 1",
    "V6E": "VANCOUVER BC LCD 1",
    "V6G": "VANCOUVER BC LCD 1",
    "V6H": "VANCOUVER BC STN F",
    "V6J": "VANCOUVER BC LCD SOUTH VAN 2",
    "V6K": "VANCOUVER BC LCD SOUTH VAN 3",
    "V6L": "VANCOUVER BC LCD SOUTH VAN 3",
    "V6M": "VANCOUVER BC LCD SOUTH VAN 1",
    "V6N": "VANCOUVER BC LCD SOUTH VAN 1",
    "V6P": "VANCOUVER BC LCD SOUTH VAN 1",
    "V6R": "VANCOUVER BC STN G",
    "V6S": "VANCOUVER BC STN G",
    "V6T": "VANCOUVER BC STN G",
    "V6V": "RICHMOND BC LCD 23",
    "V6W": "RICHMOND BC LCD 23",
    "V6X": "RICHMOND BC LCD 24",
    "V6Y": "RICHMOND BC LCD 22",
    "V6Z": "VANCOUVER BC LCD 1",
    "V7A": "RICHMOND BC LCD 23",
    "V7B": "RICHMOND BC LCD 24",
    "V7C": "RICHMOND BC LCD 24",
    "V7E": "RICHMOND BC LCD 23",
    "V7G": "NORTH VANCOUVER BC LCD 1",
    "V7H": "NORTH VANCOUVER BC LCD 1",
    "V7J": "NORTH VANCOUVER BC LCD 1",
    "V7K": "NORTH VANCOUVER BC LCD 1",
    "V7L": "NORTH VANCOUVER BC LCD 2",
    "V7M": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7N": "NORTH VANCOUVER BC LCD 2",
    "V7P": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7R": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7S": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7T": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7V": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7W": "NORTH VANCOUVER BC LCD CAPILANO",
    "V7X": "VANCOUVER BC STN BENTALL CENTRE",
    "V7Y": "VANCOUVER BC LCD PACIFIC CENTRE",
    "V7Z": "SECHELT BC LCD MAIN",
    "V8A": "POWELL RIVER BC LCD MAIN",
    "V8B": "SQUAMISH BC STN MAIN",
    "V8C": "KITIMAT BC LCD MAIN",
    "V8E": "WHISTLER BC LCD MAIN",
    "V8G": "TERRACE BC LCD MAIN",
    "V8J": "PRINCE RUPERT BC STN MAIN",
    "V8K": "SALT SPRING ISLAND BC STN GANGES",
    "V8L": "SIDNEY BC LCD DEL CTR",
    "V8M": "CENTRAL SAANICH BC LCD DEL CTR",
    "V8N": "VICTORIA BC LCD 1",
    "V8P": "VICTORIA BC LCD 1",
    "V8R": "VICTORIA BC LCD GLANFORD 9",
    "V8S": "VICTORIA BC LCD GLANFORD 9",
    "V8T": "VICTORIA BC LCD GLANFORD 3",
    "V8V": "VICTORIA BC LCD GLANFORD 3",
    "V8W": "VICTORIA BC LCD GLANFORD 3",
    "V8X": "VICTORIA BC LCD 2",
    "V8Y": "VICTORIA BC LCD 2",
    "V8Z": "VICTORIA BC LCD 2",
    "V9A": "VICTORIA BC LCD 4",
    "V9B": "VICTORIA BC LCD 4",
    "V9C": "VICTORIA BC LCD 4",
    "V9E": "VICTORIA BC LCD 2",
    "V9G": "LADYSMITH BC STN MAIN",
    "V9H": "CAMPBELL RIVER BC LCD 1",
    "V9J": "COURTENAY BC LCD MAIN",
    "V9K": "QUALICUM BEACH BC STN MAIN",
    "V9L": "DUNCAN BC LCD MAIN",
    "V9M": "COURTENAY BC LCD MAIN",
    "V9N": "COURTENAY BC LCD MAIN",
    "V9P": "PARKSVILLE BC STN MAIN",
    "V9R": "NANAIMO BC LCD MAIN",
    "V9S": "NANAIMO BC LCD MAIN",
    "V9T": "NANAIMO BC LCD MAIN",
    "V9V": "NANAIMO BC LCD MAIN",
    "V9W": "CAMPBELL RIVER BC LCD 1",
    "V9X": "NANAIMO BC LCD MAIN",
    "V9Y": "PORT ALBERNI BC LCD MAIN",
    "V9Z": "SOOKE BC STN MAIN",
    "V0A": "KAMLOOPS  BC DCF",
    "V0B": "CRANBROOK  BC DCF",
    "V0C": "DAWSON CREEK  BC DCF",
    "V0E": "KAMLOOPS  BC DCF",
    "V0G": "CASTLEGAR  BC DCF",
    "V0H": "KELOWNA  BC DCF",
    "V0J": "PRINCE GEORGE  BC DCF",
    "V0K": "KAMLOOPS  BC DCF",
    "V0L": "WILLIAMS LAKE  BC DCF",
    "V0M": "VANCOUVER # 2  BC DCF",
    "V0N": "VANCOUVER # 1  BC DCF",
    "V0P": "CAMPBELL RIVER  BC DCF",
    "V0R": "VICTORIA  BC DCF",
    "V0S": "VICTORIA  BC DCF",
    "V0T": "VANCOUVER # 1  BC DCF",
    "V0V": "PRINCE RUPERT  BC DCF",
    "V0W": "WHITEHORSE  YT DCF",
    "V0X": "VANCOUVER # 2  BC DCF",
    "X1A": "YELLOWKNIFE NT LCD MAIN",
    "X0A": "MONTREAL (QC)  NU DCF",
    "X0B": "EDMONTON AB #6  NU DCF",
    "X0C": "WINNIPEG (MB)  NU DCF",
    "X0E": "EDMONTON AB #5  NT DCF",
    "X0G": "FT NELSON (BC)  NT DCF",
    "Y1A": "WHITEHORSE YT STN CENTRAL",
    "Y0A": "DAWSON CREEK  BC DCF",
    "Y0B": "WHITEHORSE  YT DCF"
}
export default fsa;