import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import cookie from 'react-cookies';
var initials = require("initials");

class Notifications extends React.Component {
    constructor(props) {
        super(props)
        functions.handleBackOne(this, 'notification');
        this.AcceptRelation = this.AcceptRelation.bind(this);
        this.DeclineRelation = this.DeclineRelation.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.state = {
            activeTab: '1',
            main_loader: false,
            activeIndex: 0,
            new_notifications: [],
            viewed_notification: [],
            error: 0,
            error_message: "",
            loader: false,
            dynamic_content: ""
        };
    }

    handleShowPrize = () => {
        localStorage.setItem("detail_page", 'false')
        functions.pushToRoute(this, 'prize_detail', 'main')
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }

        let success_action = "User landed on notification page"
        let image = 'notification_page_landing.png'
        functions.logUserActivity(success_action, image);
        this.LoadNotification()
    }

    LoadNotification = () => {
        let self = this;
        let new_notifications = [];
        let viewed_notification = [];
        let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
        if (sub_id != "") {
            self.setState({ loader: true })
            functions.getNotifications(sub_id, 'true', function (response_json) {
                let response = JSON.parse(response_json);
                if (response?.data?.error == 1) {
                    let success_action = "User get error response on getNotifications function on notification page"
                    functions.logUserActivity(success_action, null);
                    self.setState({ error: 1, error_message: response?.data?.message })
                }
                else {
                    let success_action = "User get success response on getNotifications function on notification page"
                    functions.logUserActivity(success_action, null);
                    if (response.data) {
                        new_notifications = response?.data?.new_notification;
                        viewed_notification = response?.data?.viewed_notification;
                        new_notifications.sort(function (a, b) {
                            let date1 = a.notification_date + a.notification_time
                            let date2 = b.notification_date + b.notification_time
                            var dateA = new Date(date1), dateB = new Date(date2);
                            return dateA - dateB;
                        })
                        viewed_notification.sort(function (a, b) {
                            let date1 = a.notification_date + a.notification_time
                            let date2 = b.notification_date + b.notification_time
                            var dateA = new Date(date1), dateB = new Date(date2);
                            return dateA - dateB;
                        })
                        let notify_number = new_notifications.length;
                        self.setState({})
                        self.setState({ notify_number: notify_number, new_notifications: new_notifications, viewed_notification: viewed_notification })
                        if (response?.data?.error) {
                            self.setState({ error: 1, error_message: response?.data?.message })
                        }
                    }
                }
                self.setState({ loader: false })
            })
        }
    }

    AcceptRelation = (relative_id, state_name, index, child_id = null) => {
        let success_action = "User clicked on Accept on notification page"
        let image = 'accept_button_notification_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
        let user_email = cookie.load('username') ? cookie.load('username') : localStorage.getItem('email');

        self.setState({ loader: true });

        if (child_id) {
            functions.AcceptRelationChild(this, sub_id, relative_id, user_email, child_id, function (response_json) {
                let notification_state = self.state[state_name];
                // let notification_state1 = notification_state.splice(index, 1);
                self.setState({ [state_name]: notification_state })
                self.setState({ loader: false });
                self.LoadNotification()
            });
        } else {
            functions.AcceptRelation(this, sub_id, relative_id, user_email, function (response_json) {
                let notification_state = self.state[state_name];
                // let notification_state1 = notification_state.splice(index, 1);
                self.setState({ [state_name]: notification_state })
                self.LoadNotification()
            });
        }
    }

    DeclineRelation = (relative_id, state_name, index, child_id = null) => {
        let success_action = "User clicked on Decline on notification page"
        let image = 'decline_button_notification_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let sub_id = cookie.load('auto_id') ? cookie.load('auto_id') : localStorage.getItem("auto_id");
        let user_email = cookie.load('username') ? cookie.load('username') : localStorage.getItem('email');

        self.setState({ loader: true });
        if (child_id) {
            functions.DeclineRelationChild(this, sub_id, relative_id, user_email, child_id, function (response_json) {
                let notification_state = self.state[state_name];
                let notification_state1 = notification_state.splice(index, 1);
                self.setState({ [state_name]: notification_state })
            });
        } else {
            functions.DeclineRelation(this, sub_id, relative_id, user_email, function (response_json) {
                let notification_state = self.state[state_name];
                let notification_state1 = notification_state.splice(index, 1);
                self.setState({ [state_name]: notification_state })
            });
        }
        self.setState({ loader: false });
    }

    handlePreviousPage = () => {
        let success_action = "User clicked on back button on notification page"
        let image = 'back_button_notification_page.png'
        functions.logUserActivity(success_action, image);
        functions.pushToRoute(this, "main", "notification")
    }

    handleNotification = (value) => {
        let success_action = "User clicked on list on notification page"
        let image = 'list_button_notification_page.png'
        functions.logUserActivity(success_action, image);
        if (value.notification_type == "WinnerInvitation") {
            if (value.winner_id == value.sub_id) {
                let winner_data = localStorage.getItem("winner_data") ? JSON.parse(localStorage.getItem("winner_data")) : "";
                let winner_program_id = cookie.load("winner_program_id")
                let next = false;
                if (winner_data) {
                    winner_data.forEach((element, index) => {
                        if (winner_program_id == element.program_id && element.claim_status == 'pending') {
                            cookie.save("claim_status", element.claim_status, { path: '/' })
                            next = true;
                        }
                    });
                    if (next) {
                        if (value.sub_id == value.winner_id) {
                            cookie.save("winner_id", value.winner_id, { path: '/' })
                        }
                        this.handleShowPrize()
                    }
                    else {
                        cookie.save("claim_status", "claimed", { path: "/" })
                        cookie.save("winner_program_id", value.program_id, { path: "/" })

                        cookie.save("winner_id", value.winner_id, { path: '/' })
                        this.handleShowPrize()
                    }
                }
            }
        }
        else if (value.notification_type == "ReviewRejected" || value.notification_type == "ReviewApproved") {
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
            if (program_list.length > 0) {
                localStorage.setItem('entered_id', value.program_id)
                localStorage.setItem("detail_page", 'true')
                this.props.history.push("/main")
            }

        }
        else if (value.notification_type == "WinnerReview") {
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : []
            if (program_list.length > 0) {
                var index = program_list.findIndex(p => p.program_id == value.program_id)
                let temp_program = program_list[index]
                localStorage.setItem("program", JSON.stringify(temp_program))
                localStorage.setItem("program_index", index + 1)
                localStorage.setItem("program_id", value.program_id)
                functions.pushToRoute(this, 'winner_review', 'main')
            }
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                    {dynamic_content != "" &&
                        <div className="common_mainAddOn">
                            <div className="common_main notify_main">

                                <div className="sign_backlink sign_backlinkNw" onClick={() => { this.handlePreviousPage() }} >
                                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>

                                <div className="sign_up">
                                    <h1>{dynamic_content.static_text.notification ? dynamic_content.static_text.notification.notifications.en : "Notifications"}</h1>
                                </div>
                                {this.state.loader &&
                                    <center>
                                        <LottieLoader />
                                    </center>
                                }
                                {!this.state.loader &&
                                    <div className="no_notification notifyCards">
                                        {this.state.error == 1 &&
                                            <div className='noNotify'>{this.state.error_message}</div>
                                        }

                                        {/* Start notification design */}
                                        {this.state.new_notifications && this.state.new_notifications.length > 0 &&
                                            <div className="notify_section"
                                                style={{
                                                    backgroundColor: "#ffffffa6",
                                                    padding: "12px 20px",
                                                    marginLeft: "-22px",
                                                    marginRight: "-22px"
                                                }}>
                                                {this.state.new_notifications && this.state.new_notifications.length > 0 &&
                                                    <label style={{ color: "red" }}>{dynamic_content.static_text.notification ? dynamic_content.static_text.notification.new.en : "New"}</label>
                                                }
                                                {this.state.new_notifications && this.state.new_notifications.length > 0 && this.state.new_notifications.map((notification, index) => (
                                                    <div key={index}>
                                                        <div className="notify" onClick={() => { this.handleNotification(notification) }}
                                                        >
                                                            <div className="notify_left" style={{ position: "relative" }}>

                                                                {/* {notification.prize_image && notification.prize_image.length > 2 && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                            <img className="notify_left_image" src={url.cloudflare_image_path + notification.prize_image+url.varient} alt="" title="" />
                                                        } */}
                                                                {/* For notification having program image*/}
                                                                {notification.program_image && notification.program_image.trim().length > 0 && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                                    <img className="notify_left_image" src={url.cloudflare_image_path + notification.program_image + url.varient} alt="" title="" />
                                                                }
                                                                {/* End For notification having program image*/}

                                                                {/* For notification having user image*/}
                                                                {notification.program_image && notification.program_image.trim().length > 0 && notification.user_image && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                                    <div>
                                                                        {notification.user_image != 'no_image' &&
                                                                            <img className="notify_left_image" src={notification.user_image} alt="" title="" />
                                                                        }
                                                                        {notification.user_image == 'no_image' && notification.user_name &&
                                                                            <div className="all_family_left_circle" >
                                                                                <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {notification.program_image && notification.program_image.trim().length == 0 && notification.user_name && notification.user_image == 'no_image' && notification.user_name && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                                    <div className="all_family_left_main" >
                                                                        <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                    </div>
                                                                }
                                                                {/* End For notification having user image*/}

                                                                {/* For Info related notification */}
                                                                {notification.user_image && notification.notification_type == "Info" &&
                                                                    <div>
                                                                        {notification.user_image != 'no_image' &&
                                                                            <img className="notify_left_image" src={notification.user_image} alt="" title="" />
                                                                        }
                                                                        {notification.user_image == 'no_image' && notification.user_name &&
                                                                            <div className="all_family_left_main" >
                                                                                <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {/* End For Info related notification */}

                                                                {/* For Invitation Accepted related notification */}
                                                                {notification.user_image && notification.notification_type == "Invitation Accepted" &&
                                                                    <div>
                                                                        {notification.user_image != 'no_image' &&
                                                                            <img className="notify_left_image" src={notification.user_image} alt="" title="" />
                                                                        }
                                                                        {notification.user_image == 'no_image' && notification.user_name &&
                                                                            <div className="all_family_left_main" >
                                                                                <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                {/* End For Invitation Accepted related notification */}

                                                                {/* For review related notification */}
                                                                {notification.notification_type == "ReviewRejected" &&
                                                                    <img className="notify_left_image" src={url.family_one_image_url + notification.program_image} alt="" title="" />
                                                                }
                                                                {notification.notification_type == "ReviewRejected" &&
                                                                    <a className="cross_review">
                                                                        <img src={url.family_one_image_url + 'cross_review.png'} alt="" title="" />
                                                                    </a>
                                                                }
                                                                {notification.notification_type == "ReviewApproved" &&
                                                                    <img className="notify_left_image" src={url.family_one_image_url + notification.program_image} alt="" title="" />
                                                                }
                                                                {notification.notification_type == "ReviewApproved" &&
                                                                    <a className="cross_review">
                                                                        <img src={url.family_one_image_url + 'review_tick.png'} alt="" title="" />
                                                                    </a>
                                                                }

                                                                {/* End for review related notification */}
                                                            </div>
                                                            {notification.notification_type == "Relative Accepted" &&
                                                                <div className="notify_rght">
                                                                    {ReactHtmlParser(notification.message_accept_response)}
                                                                    <span>{notification.display_time_message}</span>
                                                                </div>
                                                            }
                                                            {notification.notification_type != "Relative Accepted" &&
                                                                <div className="notify_rght">
                                                                    {ReactHtmlParser(notification.message)}
                                                                    <span>{notification.display_time_message}</span>
                                                                </div>
                                                            }
                                                            <div className="clear"></div>
                                                        </div>
                                                        {notification.notification_type == 'Invitation' &&
                                                            <div className="accept_decline">
                                                                <div className="notify_left">
                                                                </div>
                                                                <div className="notify_acpt">
                                                                    <Button size="medium" color="primary" onClick={() => { this.AcceptRelation(notification.invitedbyid, 'new_notifications', index) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.accept.en : "Accept"}
                                                                    </Button>
                                                                    <Button size="medium" color="primary" onClick={() => { this.DeclineRelation(notification.invitedbyid, 'new_notifications', index) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.decline.en : "Decline"}
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        }
                                                        {notification.notification_type == 'Invitation_child' &&
                                                            <div className="accept_decline">
                                                                <div className="notify_left">
                                                                </div>
                                                                <div className="notify_acpt">
                                                                    <Button size="medium" color="primary" onClick={() => { this.AcceptRelation(notification.invitedbyid, 'new_notifications', index, notification.child_id) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.accept.en : "Accept"}
                                                                    </Button>
                                                                    <Button size="medium" color="primary" onClick={() => { this.DeclineRelation(notification.invitedbyid, 'new_notifications', index, notification.child_id) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.decline.en : "Decline"}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {this.state.viewed_notification && this.state.viewed_notification.length > 0 &&
                                            <div className="notify_section">
                                                {this.state.viewed_notification && this.state.viewed_notification.length > 0 &&
                                                    <div className="notifyList">
                                                        <label>{dynamic_content.static_text.notification ? dynamic_content.static_text.notification.viewed.en : "Viewed"}</label>
                                                        <div className="notifyListMsg">
                                                            <span>
                                                                <img src={require('../../assets/images/notifIcons.png')} alt="" title="" />
                                                                {this.state.notify_number && this.state.notify_number !== 0 ? <a>{this.state.notify_number}</a> : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.viewed_notification && this.state.viewed_notification.length > 0 && this.state.viewed_notification.map((notification, index) => (
                                                    <div key={index}>
                                                        <div className="notify" onClick={() => { this.handleNotification(notification) }}
                                                        >
                                                            <div className="notify_left" style={{ position: "relative" }}>

                                                                {/* {notification.prize_image && notification.prize_image.length > 2 && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                            <img className="notify_left_image" src={url.cloudflare_image_path + notification.prize_image+url.varient} alt="" title="" />
                                                        } */}
                                                                {/* For notification having program image*/}
                                                                {notification.program_image && notification.program_image.trim().length > 0 && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                                    <img className="notify_left_image" src={url.cloudflare_image_path + notification.program_image + url.varient} alt="" title="" />
                                                                }
                                                                {/* End For notification having program image*/}

                                                                {/* For notification having user image*/}
                                                                {notification.program_image && notification.program_image.trim().length > 0 && notification.user_image && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                                    <div>
                                                                        {notification.user_image != 'no_image' &&
                                                                            <img className="notify_left_image" src={notification.user_image} alt="" title="" />
                                                                        }
                                                                        {notification.user_image == 'no_image' && notification.user_name &&
                                                                            <div className="all_family_left_circle" >
                                                                                <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {notification.program_image && notification.program_image.trim().length == 0 && notification.user_name && notification.user_image == 'no_image' && notification.user_name && notification.notification_type != "ReviewRejected" && notification.notification_type != "ReviewApproved" && notification.notification_type != "Invitation Accepted" && notification.notification_type != "Info" &&
                                                                    <div className="all_family_left_main" >
                                                                        <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                    </div>
                                                                }
                                                                {/* End For notification having user image*/}

                                                                {/* For Info related notification */}
                                                                {notification.user_image && notification.notification_type == "Info" &&
                                                                    <div>
                                                                        {notification.user_image != 'no_image' &&
                                                                            <img className="notify_left_image" src={notification.user_image} alt="" title="" />
                                                                        }
                                                                        {notification.user_image == 'no_image' &&
                                                                            <div className="all_family_left_main" >
                                                                                <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {/* End For Info related notification */}

                                                                {/* For Invitation Accepted related notification */}
                                                                {notification.user_image && notification.notification_type == "Invitation Accepted" &&
                                                                    <div>
                                                                        {notification.user_image != 'no_image' &&
                                                                            <img className="notify_left_image" src={notification.user_image} alt="" title="" />
                                                                        }
                                                                        {notification.user_image == 'no_image' &&
                                                                            <div className="all_family_left_main" >
                                                                                <label style={{ borderRadius: "20px" }}>{initials((notification.user_name).toUpperCase()).substring(0, 2)}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                {/* End For Invitation Accepted related notification */}

                                                                {/* For review related notification */}
                                                                {notification.notification_type == "ReviewRejected" &&
                                                                    <img className="notify_left_image" src={url.family_one_image_url + notification.program_image} alt="" title="" />
                                                                }
                                                                {notification.notification_type == "ReviewRejected" &&
                                                                    <a className="cross_review">
                                                                        <img src={url.family_one_image_url + 'cross_review.png'} alt="" title="" />
                                                                    </a>
                                                                }
                                                                {notification.notification_type == "ReviewApproved" &&
                                                                    <img className="notify_left_image" src={url.family_one_image_url + notification.program_image} alt="" title="" />
                                                                }
                                                                {notification.notification_type == "ReviewApproved" &&
                                                                    <a className="cross_review">
                                                                        <img src={url.family_one_image_url + 'review_tick.png'} alt="" title="" />
                                                                    </a>
                                                                }
                                                                {/* End for review related notification */}
                                                            </div>
                                                            {notification.notification_type == "Relative Accepted" &&
                                                                <div className="notify_rght">
                                                                    {ReactHtmlParser(notification.message_accept_response)}
                                                                    <span>{notification.display_time_message}</span>
                                                                </div>
                                                            }
                                                            {notification.notification_type != "Relative Accepted" &&
                                                                <div className="notify_rght">
                                                                    {ReactHtmlParser(notification.message)}
                                                                    <span>{notification.display_time_message}</span>
                                                                </div>
                                                            }
                                                            <div className="clear"></div>
                                                        </div>
                                                        {notification.notification_type == 'Invitation' &&
                                                            <div className="accept_decline">
                                                                <div className="notify_left">
                                                                </div>
                                                                <div className="notify_acpt">
                                                                    <Button size="medium" color="primary" onClick={() => { this.AcceptRelation(notification.invitedbyid, 'viewed_notification', index) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.accept.en : "Accept"}
                                                                    </Button>
                                                                    <Button size="medium" color="primary" onClick={() => { this.DeclineRelation(notification.invitedbyid, 'viewed_notification', index) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.decline.en : "Decline"}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                        {notification.notification_type == 'Invitation_child' &&
                                                            <div className="accept_decline">
                                                                <div className="notify_left">
                                                                </div>
                                                                <div className="notify_acpt">
                                                                    <Button size="medium" color="primary" onClick={() => { this.AcceptRelation(notification.invitedbyid, 'viewed_notification', index, notification.child_id) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.accept.en : "Accept"}
                                                                    </Button>
                                                                    <Button size="medium" color="primary" onClick={() => { this.DeclineRelation(notification.invitedbyid, 'viewed_notification', index, notification.child_id) }}>
                                                                        {dynamic_content.static_text.notification ? dynamic_content.static_text.notification.decline.en : "Decline"}
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        }
                                                    </div>
                                                ))}

                                            </div>
                                        }
                                        {/* End of notification design */}
                                    </div>
                                }
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }
}
export default Notifications;

