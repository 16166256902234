import axios from "axios";
import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import queryString from 'query-string';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import LottieLoader from '../LottieLoader';
export default class PhoneSent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Partners: [],
            loader: false,
            terms_and_condition: ""
        };
    }
    componentDidMount = () => {
        let self = this
        let params = queryString.parse(this.props.location.search)
        
        if (params && params.program_id && params.program_id.trim().length > 0) {
            functions.getProgramDetail(self, { "program_id": params.program_id }, function (json_response) {
                let response = JSON.parse(json_response)
                if (response && response.data && response.data.data && response.data.data.term_and_condition) {
                    self.setState({
                        terms_and_condition: response.data.data.term_and_condition
                    })
                } else {
                    functions.pushToRoute(self, "", "terms_and_condition")
                }
            })
        } else {
            functions.pushToRoute(self, "", "terms_and_condition")
        }
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.state.loader &&
                        <center>
                            <LottieLoader />
                        </center>
                    }
                    {!this.state.loader &&
                        <div className="common_mainAddOn"> 
                            <div className="common_main">
                                <div className="call_content margin0">
                                    <div className="sharegain_up">
                                        <h1>Terms and Conditions</h1>
                                    </div>
                                    {ReactHtmlParser(this.state.terms_and_condition)}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}