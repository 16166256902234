import React from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import LottieLoader from '../LottieLoader';
import session from '../../assets/functions/session';
import cookie from 'react-cookies';

export default class Verification extends React.Component {
    constructor(props) {
        localStorage.setItem("resend_counter", 32)
        super(props)
        this.state = {
            time: "",
            loader: false,
            show_error_message: "",
            phone_number: localStorage.getItem("phone_number") ? localStorage.getItem("phone_number") : "",
            verify_code: "",
            dynamic_content: "",
            resend_counter: localStorage.getItem("resend_counter") ? parseInt(localStorage.getItem("resend_counter")) : 4
        }
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
    }

    componentDidMount() {
        let success_action = "User landed on verification page"
        let image = 'verification_page_landing.png'
        functions.logUserActivity(success_action, image);
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
        if (session_id != "null") {
            session.updatePageSession(session_id, "verification")
        }
        this.interval = setInterval(() => { this.handleCounter() }, 1000);
    }

    handleCounter = () => {
        if (this.state.resend_counter > 0) {
            this.setState({ resend_counter: this.state.resend_counter - 1 })
            localStorage.setItem("resend_counter", this.state.resend_counter)
        }
        if (this.state.resend_counter < 0) {
            clearInterval(this.interval);
        }
    }

    handleResend = () => {
        let success_action = "User clicked on resend button on verification page"
        let image = 'resend_button_verification_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let phone = localStorage.getItem("phone_number");
        let phone_number = this.formatPhoneNumber(phone);
        this.setState({ phone_number: phone_number, show_error_message: "" });
        self.setState({ resend_counter: 32 })
        localStorage.setItem("resend_counter", 32)
        let finalJson = {
            "phone_number": phone_number,
            "action": "forget_email",
            "country_code": url.phone_code,
            "mode": "sms"
        }
        axios
            .request({
                method: "POST",
                url: url.forget_email,
                data: JSON.stringify(finalJson),
                headers: {
                    "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                }
            })
            .then(function (response) {
                if (response.data.failed && response.data.failed == 1) {
                    functions.handleErrorPopup()
                }
                if (response.data.result === "user_not_exist") {
                    let user_action = "User got error on phone/verification file handleResend function";
                    let body = url.dynamic_content.error_message.user_not_exist_with_phone.en
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: url.dynamic_content.error_message.user_not_exist_with_phone.en })
                } else if (response.data.result === "successfull") {
                    let success_action = "User got success on forget_email function on verification page"
                    let image = 'success_response_for_forget_email_verification_page.png'
                    functions.logUserActivity(success_action, image);
                }
            })
            .catch(function (error) {
                let user_action = "User got error on phone/verification file handleResend function";
                let body = error.message
                functions.logUserError(user_action, body);
            });
    }

    handleCall = () => {
        let success_action = "User clicked on call button on verification page"
        let image = 'call_button_verification_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let phone = localStorage.getItem("phone_number");
        let phone_number = this.formatPhoneNumber(phone);
        this.setState({ phone_number: phone_number, show_error_message: "" });
        let finalJson = {
            "phone_number": phone_number,
            "action": "forget_email",
            "country_code": url.phone_code,
            "mode": "call"
        }
        axios
            .request({
                method: "POST",
                url: url.forget_email,
                data: JSON.stringify(finalJson),
                headers: {
                    "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                }
            })
            .then(function (response) {
                if (response.data.failed && response.data.failed == 1) {
                    functions.handleErrorPopup()
                }
                if (response.data.result === "user_not_exist") {
                    let user_action = "User got error on phone/verification file handleResend function";
                    let body = url.dynamic_content.error_message.user_not_exist_with_phone.en
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: url.dynamic_content.error_message.user_not_exist_with_phone.en })
                } else if (response.data.result === "successfull") {
                    let success_action = "User got success on forget_email on verification page"
                    let image = 'success_response_for_forget_email_verification_page.png'
                    functions.logUserActivity(success_action, image);
                }
            })
            .catch(function (error) {
                let user_action = "User got error on phone/verification file handleResend function";
                let body = error.message
                functions.logUserError(user_action, body);
            });
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
        return null
    }

    handleNext = () => {
        let success_action = "User clicked on enter button on verification page"
        let image = 'enter_button_verification_page.png'
        functions.logUserActivity(success_action, image);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        let self = this;
        if (this.state.verify_code === "" || !this.state.verify_code) {
            let user_action = "User got error on phone/verification file handleResend function";
            let body = url.dynamic_content.error_message.verify_code_missing.en
            functions.logUserError(user_action, body);
            this.setState({
                show_error_message: url.dynamic_content.error_message.verify_code_missing.en
            });
        } else if (!this.state.verify_code.match(/^\d{6}$/)) {
            let user_action = "User got error on phone/verification file handleResend function";
            let body = url.dynamic_content.error_message.invalid_verify_code.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_verify_code.en });
        } else {
            self.setState({ loader: true })
            let finalJson = {
                "phone_number": phone_number,
                "verification_code": this.state.verify_code,
                "country_code": url.phone_code
            }

            axios
                .request({
                    method: "POST",
                    url: url.verifyCode,
                    data: JSON.stringify(finalJson),
                    headers: {
                        "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                    }
                })
                .then(function (response) {
                    if (response.data.failed && response.data.failed == 1) {
                        functions.handleErrorPopup()
                    }
                    if (response.data.error === 1) {
                        self.setState({ loader: false })
                        let user_action = "User got error on phone/verification file handleResend function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        self.setState({ show_error_message: response.data.message, verify_code: '' })
                    }
                    else if (response.data.success === 1) {
                        let success_action = "User got success on verifyCode function on verification page"
                        let image = 'success_response_for_verifycode_verification_page.png'
                        functions.logUserActivity(success_action, image);
                        self.setState({ loader: false })
                        localStorage.setItem("phone_number_verified", "true");
                        functions.pushToRoute(self, 'phonesent', 'verification')
                    }
                })
                .catch(function (error) {
                    let user_action = "User got error on phone/verification file handleResend function";
                    let body = error.message
                    functions.logUserError(user_action, body);
                    self.setState({ loader: false })
                });
        }
    }


    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                <div className="common_mainAddOn">
                    {dynamic_content != "" &&
                        <div className="common_main">

                            <div className="sign_backlink white_circle_div" onClick={() => { this.props.history.push("/phone") }}>
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>

                            <div className="sign_up">
                                <h1>{dynamic_content.static_text.verification ? dynamic_content.static_text.verification.restore_email.en : "Restore Email"}</h1>
                                <h2>{dynamic_content.static_text.verification ? dynamic_content.static_text.verification.enter_mobile_number.en : "Enter verification code"}</h2>
                            </div>
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                            {!this.state.loader &&
                                <div>


                                    <div className="restoreNumEmail">
                                        <TextField
                                            autoFocus={true}
                                            autoComplete='off'
                                            hiddenlabel="Verification code"
                                            placeholder='Verification code'
                                            // type="text"
                                            margin="normal"
                                            inputProps={{ maxLength: 6 }}
                                            type="number"
                                            value={this.state.verify_code}
                                            onChange={(e) => {
                                                if (e.target.value.length < 7) {
                                                    this.setState({ verify_code: e.target.value, show_error_message: "" })
                                                }
                                            }}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleNext()
                                                }
                                            }}
                                        />
                                    </div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message">
                                            {this.state.show_error_message}
                                        </p>
                                    }
                                    <div className="resend_call">
                                        {this.state.resend_counter < 1 &&
                                            <div onClick={() => { this.handleResend() }}><p>Resend</p></div>
                                        }
                                        {this.state.resend_counter > 0 &&
                                            <div> <p><span>Resend (00:{this.state.resend_counter > 9 ? this.state.resend_counter : "0" + this.state.resend_counter})</span></p></div>
                                        }
                                        <div onClick={() => { this.handleCall() }}><p>Call</p></div>
                                    </div>

                                    <div className="receive_msg">
                                        <div className="phone_opt phoneOptCstm">
                                            <Button size="medium" color="primary" onClick={() => { this.handleNext() }}>
                                                {dynamic_content.static_text.verification ? dynamic_content.static_text.verification.next.en : "Next"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <div className="or_contact">
                                <p>{dynamic_content.static_text.verification ? dynamic_content.static_text.verification.contact_us.en : "or contact us"}</p>
                                <Button size="medium" color="primary">
                                    {dynamic_content.static_text.verification ? dynamic_content.static_text.verification.email.en : "Email"}
                                </Button>
                                <Button size="medium" color="primary">
                                    {dynamic_content.static_text.verification ? dynamic_content.static_text.verification.chat.en : "Chat"}
                                </Button>
                            </div> */}

                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }
}